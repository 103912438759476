import Vuex from "vuex";
import Vue from "vue";

import pathify from "./pathify";
import { make } from "vuex-pathify";

import createPersistedState from "vuex-persistedstate";

const state = {
  token: null,
  loginData: null,
  decodedToken: null,
  projectID: null,
  projectName: null,
  rootImageUrl: ""
};

const mutations = make.mutations(state);

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  plugins: [
    pathify.plugin,
    createPersistedState({ key: "pms", storage: window.sessionStorage })
  ]
});
