<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍການເມນູ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
                <div class="column">
                  <b-tabs v-model="activeTab">
                    

                    <b-tab-item label="ລາຍການເມນູ">
                      <!-- <b-field label="ເລືອກປະເພດເມນູ">
                        <Multiselect
                          placeholder="ເລືອກ"
                          v-model.trim="selectedType"
                          label="NAME"
                          track-by="ID"
                          :options="typeList"
                          @input="onTypeSelectedForMenuList()"
                        >
                        </Multiselect>
                      </b-field> -->
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ເມນູ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ປະເພດຍ່ອຍ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ສ່ວນປະກອບ
                            </th>
                           
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in filteredMenuList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-centered">
                              {{ data.NAME }}<br>
                              <img
                                data-v-xcxcc
                                :src="getImgUrl(data.PHOTONAME)"
                                width="100"
                                height="100"
                              />
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUSUBTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUUNIT }}
                            </td>
                            <td class="has-text-centered">
                              {{  data.COUNTINGREDIENT | formatNumberLocal }}
                            </td>
                            <td class="has-text-centered">
                              <b-button 
                                class="btn-group-margin"
                                type="is-info"
                                size="is-small"
                                @click="inGredientForm(data)"
                                >ເບິ່ງສ່ວນປະກອບ
                              </b-button>
                             
                               
                           
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>
                    <b-tab-item label="ສ່ວນປະກອບເມນູ">
                      <div class="columns" >
                          <div class="column is-one-fifths" style="padding-top: 30px;"> 
                            <div class="columns" >
                              <div class="column " > 
                                <b-button 
                                  class="btn-group-margin"
                                  type="is-info"
                                  @click="tocockmenu"
                                  >ໄປໜ້າຮັບເມນູ
                                </b-button>
                              </div>
                            </div>
                            <div class="columns" >
                              <div class="column " > 
                                <div style=" text-align: right;">
                                  ເມນູ: 
                                </div>
                                <div style=" text-align: right;">
                                  ປະເພດ: 
                                </div>
                                <div style=" text-align: right;">
                                  ປະເພດຍ່ອຍ: 
                                </div>
                                <div style=" text-align: right;">
                                  ຫົວໜ່ວຍ:
                                </div>
                              </div>
                              <div class="column">
                                <div style=" text-align: left; font-weight: bold;">
                                {{ ing_SelectedMenu }}
                                </div>
                                <div style=" text-align: left; font-weight: bold;">
                                {{ ing_SelectedTYPE }}
                                </div>
                                <div style=" text-align: left; font-weight: bold;">
                                {{ ing_SelectedSubType }}
                                </div>
                                <div style=" text-align: left; font-weight: bold;">
                                {{ ing_SelectedUnit }}
                                </div>
                              </div>
                            </div>
                            <div class="columns" >
                              <div class="column " >
                              VIDEO
                              </div>
                            </div>
                          </div>
                          <div class="column is-three-fifths"> 
                            <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                              ສ່ວນປະກອບ
                            </div>
                            <table class="table is-fullwidth is-narrow is-small">
                              <thead>
                                <tr>
                                  <th class="has-text-centered " width="5%">#</th>

                                  <th class="has-text-left " width="15%">
                                    ສ່ວນປະກອບ
                                  </th>

                                  <th class="has-text-centered " width="15%">
                                    ປະລິນມານ 
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) in inGredientMenuList"
                                  :key="data.ID"
                                >
                                  <td class="has-text-centered">{{ index + 1 }}</td>

                                  <td class="has-text-left">
                                    {{ data.PRODUCTNAME }}
                                  </td>
                                  <td class="has-text-centered">
                                    {{ data.QUANTITY }}  {{ data.UNIT }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                
                      
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import axios from "axios";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      roleid: null,
      username: null,
      url: "uploadmenu", // function name in api site
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      photopreview: this.photopath +'unknown.png',
      isSelectPhoto: false,

      activeTab: 0,


      photoname: null,
      menuid: null,
      oldmenuname: null,
      menuname: null,
      selectedType: null,
      typeList:[],

      selectedUsedIngredient: [],

      activeMenuList: [],
      originalMenuList: [],
      inActiveMenuList: [],

      ing_SelectedMenu: null,
      ing_SelectedMenuID: null,
      ing_SelectedTYPE: null,
      ing_SelectedTYPEID: null,
      ing_SelectedSubType: null,
      ing_SelectedUnit: null,

      selectedProduct: null,
      productList: [],
      productUnit: null,
      productQuantity: 0,

      inGredientMenuList: [],
  
      
      
    };
  },
  methods: {
    tocockmenu(){
      this.$router.push({ name: 'chef' });
    },
    getProductIngredientList(){
      RestAPI.getProductIngredientList(this.ing_SelectedMenuID)
        .then(res => {
          console.log("res: " + JSON.stringify(res.data))
          this.inGredientMenuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("Error", "There was an error fetching data", "error");
        });
    },
    
    onProductSelected() {
      this.productList.forEach(element => {
        if(element['ID'] == this.selectedProduct.ID){
          this.productUnit = element['UNIT']
        }
      });
    },
    
    inGredientForm(data){
      this.activeTab =1;
      this.ing_SelectedMenu = data.NAME;
      this.ing_SelectedMenuID = data.ID;
      this.ing_SelectedTYPE = data.MENUTYPE;
      this.ing_SelectedTYPEID = data.MENUTYPEID;
      this.ing_SelectedSubType = data.MENUSUBTYPE;
      this.ing_SelectedUnit = data.MENUUNIT;
      this.getProductIngredientList();

    },
    
    getMenuList(){
      RestAPI.getSettingMenuListforCooking()
        .then(res => {
          this.activeMenuList = res.data;
          this.originalMenuList = res.data;
      
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    onTypeSelectedForMenuList() {
      this.activeMenuList = [...this.originalMenuList];
    },
    onTypeSelected(){
      this.selectedsubtype = null;
      this.selectedunit = null;
      this.selectedUsedIngredient = null;
      this.getSettingMenuSubTypeByType();
      this.getSettingMenuUnitByType();

    },
    getMenuType(){
      RestAPI.getSettingMenuType()
        .then(res => {
          this.typeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    onFileSelected(event) {
      this.isSelectPhoto = true;
      console.log("url: "+ this.url)
      console.log("photopath: " + this.photopath)
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile);
      axios.post(this.url, fd).then((res) => {
        this.photoname = res.data;
        this.photopreview = this.photopath + res.data;
        
      });
    },

    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
   
    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getMenuType();
    this.getMenuList();
  },
  // created() {
  //   this.originalMenuList = [...this.activeMenuList]; // Copy the original list on component creation
  // },
  computed: {
    filteredMenuList() {
      if (this.selectedType && this.selectedType.ID) {
        return this.activeMenuList.filter(element => {
          return element['MENUTYPEID'] === this.selectedType.ID;
        });
      } else {
        return this.activeMenuList; // Return the entire list if selectedType or its ID is not available
      }
    },
  },
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}


</style>
