<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຕັ້ງວັນທີຊຳລະ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
              <div class="column"></div>
                <div class="column">
                  <b-field>
                    ຕັ້ງວັນທີຊຳລະ: &nbsp; <span v-if="isSelecteDate == 1" style="color: blue;"> ກຳລັງເປີດໃຊ້ງານ</span> <span v-if="isSelecteDate == 0" style="color: red"> ກຳລັງປິດໃຊ້ງານ</span>
                    <b-field style="margin-left: 20px; margin-top: -10px;">
                      <b-button v-if="isSelecteDate ==1"
                        class="btn-group-margin"
                        type="is-danger"
                        @click="changePayDateStatus()"
                        >ປິດການຕັ້ງຄ່າວັນທີຊຳລະ
                      </b-button>

                      <b-button v-else
                        class="btn-group-margin"
                        type="is-success"
                        @click="changePayDateStatus()"
                        >ເປີດການຕັ້ງຄ່າວັນທີຊຳລະ
                      </b-button>
                    </b-field>
                    
                  </b-field>
                  <b-field>
                    ວັນທີ: <span style="color: red;" v-if="payDate"> {{ dateFormatter(payDate) }}</span>
                  </b-field>
                  <b-field>
                    
                    <b-field >
                      <b-datepicker
                        :date-formatter="dateFormatter"
                        v-model="selectedPayDate"
                      >
                      </b-datepicker>
                    </b-field>
                  </b-field>
                  <b-field>
                    <b-button
                      class="btn-group-margin"
                      type="is-info"
                      @click="savePayDate()"
                      >ບັນທຶກວັນທີ
                    </b-button>
                  </b-field>
                     
                  
                </div>
                <div class="column"></div>
                
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  props: ["prop_activetab"],
  data() {
    return {
      roleid: null,
      username: null,
      payDate : null,
      isSelecteDate: 0,
      selectedPayDate: null

      
    };
  },
  methods: {
    changePayDateStatus(){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            status: this.isSelecteDate == 1 ? 0 : 1,
          };
          RestAPI.changePayDateStatus(params)
            .then(() => {
              this.getPosSelectDate();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ອັບເດດສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    savePayDate(){
     

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            paydate: this.dateFormatterYYYYMMDD(this.selectedPayDate),
            paydate2: this.selectedPayDate
          };

          console.log("params" + JSON.stringify(params));

          RestAPI.savePayDate(params)
            .then(() => {
              this.getPosSelectDate();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ອັບເດດສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },

    getPosSelectDate(){
      RestAPI.getPosSelectDate()
        .then(res => {
          console.log(res.data);
          this.payDate = res.data.PAYDATE;
          this.isSelecteDate = res.data.ISSELECTDATE;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getPosSelectDate();
    this.selectedPayDate = new Date();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}


</style>
