import axios from "axios";
import store from "./vuex/store";

export default {
  
  login(credential) {
    return axios.post("/user/login", credential);
  },
  register(params) {
    return axios.post("/user/register", params);
  },

  getPosSellerID(posuserid){
    return axios.get(`/user/getPosSellerID/${posuserid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  

  // table
  
  getExchangeRate(currency){
    return axios.get(`/sell/getExchangeRate/${currency}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getVatDiscount(posid){
    return axios.get(`/sell/getVatDiscount/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  

  getVatDiscountForCustomer(posid){
    return axios.get(`/sell/getVatDiscountForCustomer/${posid}`);
  },
  getPosTable(posid){
    return axios.get(`/sell/getPosTable/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosTableFromNoti(posid, tableid){
    return axios.get(`/sell/getPosTableFromNoti/${posid}/${tableid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getOrderedPos(tableid){
    return axios.get(`/sell/getOrderedPos/${tableid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosMenu(posid){
    return axios.get(`/sell/getPosMenu/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  // getPosMenuDetail(posid, menuid){
  //   return axios.get(`/sell/getPosMenuDetail/${posid}/${menuid}`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },
  
  getPosRemainMenu(posid, typeid){
    return axios.get(`/sell/getPosRemainMenu/${posid}/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosRemainMenuDetail(posid, menuid){
    return axios.get(`/sell/getPosRemainMenuDetail/${posid}/${menuid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  searchIngredient(params) {
    return axios.post(`/sell/searchIngredient`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getCustomerPOSInfor(posid){
    return axios.get(`/sell/getCustomerPOSInfor/${posid}`);
  },
  getCustomerTotalMenuOrder(tableid){
    return axios.get(`/sell/getCustomerTotalMenuOrder/${tableid}`);
  },

  getPosMenuForCustomer(posid){
    return axios.get(`/sell/getPosMenuForCustomer/${posid}`);
  },
  
  addMenutoOrder(params) {
    return axios.post(`/sell/addMenutoOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addMenutoOrderForCustomer(params) {
    return axios.post(`/sell/addMenutoOrderForCustomer`, params);
  },

  addMenutoOrderForCustomerFromOtherPos(params) {
    return axios.post(`/sell/addMenutoOrderForCustomerFromOtherPos`, params);
  },

  addMenutoOrderForCustomerBySeller(params) {
    return axios.post(`/sell/addMenutoOrderForCustomerBySeller`, params);
  },


  forcelogoutForCustomer(params) {
    return axios.post(`/sell/forcelogoutForCustomer`, params);
  },
  
  getMenuInOrder(posid, tableid){
    return axios.get(`/sell/getMenuInOrder/${posid}/${tableid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMenuInOrderByDate(posid, tableid, txdate){
    return axios.get(`/sell/getMenuInOrderByDate/${posid}/${tableid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMenuInOrderForCustomer(posid, tableid){
    return axios.get(`/sell/getMenuInOrderForCustomer/${posid}/${tableid}`);
  },
  
  updateOrderMenuStatus(params) {
    return axios.post(`/sell/updateOrderMenuStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateOrderMenuDetailStatus(params) {
    return axios.post(`/sell/updateOrderMenuDetailStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateOrderMenuDetailStatusByBatch(params) {
    return axios.post(`/sell/updateOrderMenuDetailStatusByBatch`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateOrderMenuDetailStatusOnlyOne(params) {
    return axios.post(`/sell/updateOrderMenuDetailStatusOnlyOne`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  removeMenuFromOrder(params) {
    return axios.post(`/sell/removeMenuFromOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  removeAllMenuFromOrder(params) {
    return axios.post(`/sell/removeAllMenuFromOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateMenuQuantity(params) {
    return axios.post(`/sell/updateMenuQuantity`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuQuantityFromCustomer(params) {
    return axios.post(`/sell/updateMenuQuantityFromCustomer`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  

  addPaymentItem(params) {
    return axios.post(`/sell/addPaymentItem`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPaymentItem (orderid, isfullbill, batchid){
    return axios.get(`/sell/getPaymentItem/${orderid}/${isfullbill}/${batchid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  removePaymentItem(params) {
    return axios.post(`/sell/removePaymentItem`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  clearTable(params) {
    return axios.post(`/sell/clearTable`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  removeAllPaymentItem(params) {
    return axios.post(`/sell/removeAllPaymentItem`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  setPrintTimeToOrder(params) {
    return axios.post(`/sell/setPrintTimeToOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  confirmPayment(params) {
    return axios.post(`/sell/confirmPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeTableStatus(params) {
    return axios.post(`/sell/changeTableStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeCheckBillStatus(params) {
    return axios.post(`/sell/changeCheckBillStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },



  getPaymentHistoryDetail(orderid, isfullbill, batchid){
    return axios.get(`/sell/getPaymentHistoryDetail/${orderid}/${isfullbill}/${batchid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPaymentReport(roleid, username, posid, startdate, enddate){
    return axios.get(`/sell/getPaymentReport/${roleid}/${username}/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPaymentReportLevel2(roleid, username, startdate, enddate){
    return axios.get(`/sell/getPaymentReportLevel2/${roleid}/${username}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPaymentReportVCallback(roleid, username, startdate, enddate){
    return axios.get(`/sell/getPaymentReportVCallback/${roleid}/${username}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // start callback report
  getAllPaymentReportVCallback(posid,startdate, enddate){
    return axios.get(`/sell/getAllPaymentReportVCallback/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getDuplicatePayemntReport(posid,startdate, enddate){
    return axios.get(`/sell/getDuplicatePayemntReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getWaitPaymentReport(posid,startdate, enddate){
    return axios.get(`/sell/getWaitPaymentReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  clearWaitPayment(params) {
    return axios.post(`/sell/clearWaitPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  refund(params) {
    return axios.post(`/sell/refund`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  

  // end callback report


  // all report
  getAllPaymentReport(startdate, enddate, posid){
    return axios.get(`/sell/getAllPaymentReport/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAllRefundReport(startdate, enddate, posid){
    return axios.get(`/sell/getAllRefundReport/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosPaymentReport(posid, sellerid, username, txdate){
    return axios.get(`/sell/getPosPaymentReport/${posid}/${sellerid}/${username}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosRefundReport(posid, sellerid, username, txdate){
    return axios.get(`/sell/getPosRefundReport/${posid}/${sellerid}/${username}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosPaymentDetailReport(orderid, batchid){
    return axios.get(`/sell/getPosPaymentDetailReport/${orderid}/${batchid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getPosPaymentDetailReportVCallback(orderid){
    return axios.get(`/sell/getPosPaymentDetailReportVCallback/${orderid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  getTopMenuList(startdate, enddate, posid){
    return axios.get(`/sell/getTopMenuList/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosList(){
    return axios.get(`/sell/getPosList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAllProductBroken(posid = null, startdate, enddate){
    return axios.get(`/sell/getAllProductBroken/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartAllProductBroken(posid = null, startdate, enddate){
    return axios.get(`/minimart/getMinimartAllProductBroken/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // getPosProductBroken(posid, startdate, enddate){
  //   return axios.get(`/sell/getPosProductBroken/${posid}/${startdate}/${enddate}`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },


  getAllMoneyReport(posid,startdate, enddate){
    return axios.get(`/sell/getAllMoneyReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getBossMoneyReport(posid,startdate, enddate){
    return axios.get(`/sell/getBossMoneyReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosMoneyReport(posid, txdate){
    return axios.get(`/sell/getPosMoneyReport/${posid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getBossPosMoneyReport(posid, txdate){
    return axios.get(`/sell/getBossPosMoneyReport/${posid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosMoneyDetailReport(posid, txdate, paymenttype, currency, orderid){
    return axios.get(`/sell/getPosMoneyDetailReport/${posid}/${txdate}/${paymenttype}/${currency}/${orderid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getAllMenuReport(startdate, enddate){
    return axios.get(`/sell/getAllMenuReport/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosMenuReport(posid, txdate){
    return axios.get(`/sell/getPosMenuReport/${posid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosMenuIngredientReport(posid, txdate){
    return axios.get(`/sell/getPosMenuIngredientReport/${posid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getChefPrintBatch(batchid){
    return axios.get(`/sell/getChefPrintBatch/${batchid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getChefMenu(posid){
    return axios.get(`/sell/getChefMenu/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getCookingReport(posid, startdate, enddate){
    return axios.get(`/sell/getCookingReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getMenuType(){
    return axios.get(`/sell/getMenuType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosRemainMenuSubType(posid){
    return axios.get(`/sell/getPosRemainMenuSubType/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosProductType(){
    return axios.get(`/sell/getPosProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  onMenuSelectedList(posid,selectedMenu){
    return axios.get(`/sell/onMenuSelectedList/${posid}/${selectedMenu}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosMenuByType(typeid, posid){
    return axios.get(`/sell/getPosMenuByType/${typeid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // getPosProductByType(typeid, productkey, posid){
  //   return axios.get(`/sell/getPosProductByType/${typeid}/${productkey}/${posid}`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },
  getPosProductByType(params){
    return axios.post(`/sell/getPosProductByType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  addMenuToRequest(params) {
    return axios.post(`/sell/addMenuToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addEmergencyMenuToRequest(params) {
    return axios.post(`/sell/addEmergencyMenuToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  addPosProductToRequest(params) {
    return axios.post(`/sell/addPosProductToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  addPosEmergencyProductToRequest(params) {
    return axios.post(`/sell/addPosEmergencyProductToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosProductBuyPricehistoryByType(typeid, posid){
    return axios.get(`/sell/getPosProductBuyPricehistoryByType/${typeid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  getPosProductBuyPricehistoryDetail(productid, posid){
    return axios.get(`/sell/getPosProductBuyPricehistoryDetail/${productid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductBuyPricehistoryByType(typeid, posid){
    return axios.get(`/minimart/getMinimartProductBuyPricehistoryByType/${typeid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductBuyPricehistoryDetail(productid, posid){
    return axios.get(`/minimart/getMinimartProductBuyPricehistoryDetail/${productid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAllStock(){
    return axios.get(`/sell/getAllStock`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosStock(posid, type){
    return axios.get(`/sell/getPosStock/${posid}/${type}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getPosStockHistory(posid, type, productid, stocktype, startdate, enddate){
    return axios.get(`/sell/getPosStockHistory/${posid}/${type}/${productid}/${stocktype}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  turnnoti(params) {
    return axios.post(`/sell/turnnoti`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  clearNotification(params) {
    return axios.post(`/sell/clearNotification`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  getUserNotification(roleid, username){
    return axios.get(`/sell/getUserNotification/${roleid}/${username}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAllNotification(roleid, posid, username,startdate, enddate){
    return axios.get(`/sell/getAllNotification/${roleid}/${posid}/${username}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getProductType(){
    return axios.get(`/sell/getProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSellProductByProductType(typeid){
    return axios.get(`/sell/getSellProductByProductType/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getProductRequest(posid,startdate, enddate){
    return axios.get(`/product/getProductRequest/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosProductRequest(posid,startdate, enddate){
    return axios.get(`/product/getDirectProductRequest/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getProductRequestDetail(requestid){
    return axios.get(`/product/getProductRequestDetail/${requestid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeRequestStatus(params) {
    return axios.post(`/product/changeRequestStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getProductRequestProvide(posid,startdate, enddate){
    return axios.get(`/product/getProductRequestProvide/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getProductRequestProvideDetail(requestid){
    return axios.get(`/product/getProductRequestProvideDetail/${requestid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateProductToPos(params) {
    return axios.post(`/product/updateProductToPos`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getPosProductBrokenRequest(posid,startdate, enddate){
    return axios.get(`/sell/getPosProductBrokenRequest/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  addEmergencyProductBrokenRequest(params) {
    return axios.post(`/sell/addEmergencyProductBrokenRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getProductBrokenRequestDetail(requestid){
    return axios.get(`/sell/getProductBrokenRequestDetail/${requestid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  changeProductBrokenRequestStatus(params) {
    return axios.post(`/sell/changeProductBrokenRequestStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  
  //end table

  // sell
  getSellerProducts(posid){
    return axios.get(`/sell/getSellerProducts/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSellerProductsByType(posid,typeid){
    return axios.get(`/sell/getSellerProductsByType/${posid}/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSellerProductsByKeyword(posid,keyword){
    return axios.get(`/sell/getSellerProductsByKeyword/${posid}/${keyword}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getProductbyBarcode(posid,barcode){
    return axios.get(`/sell/getProductbyBarcode/${posid}/${barcode}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSellerProductTypes(posid){
    return axios.get(`/sell/getSellerProductTypes/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  
  
  

  

  doPayment(params) {
    return axios.post(`/sell/doPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  doManaulPayment(params) {
    return axios.post(`/sell/doManaulPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getPaymentHistory(sellerid){
    return axios.get(`/sell/getPaymentHistory/${sellerid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  
  doCustomerPayment(params) {
    return axios.post(`/sell/doCustomerPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  doCustomerPaymentBySeller(params) {
    return axios.post(`/sell/doCustomerPaymentBySeller`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  


  checkPaymentStatus(params) {
    return axios.post(`/sell/checkPaymentStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  checkPosSelfPaymentStatus(params) {
    return axios.post(`/sell/checkPosSelfPaymentStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  posCheckPaymentStatus(params) {
    return axios.post(`/sell/posCheckPaymentStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  checkRemainCallback(params) {
    return axios.post(`/sell/checkRemainCallback`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  getPosMenuList(pos){
    return axios.get(`/sell/getPosMenuList/${pos}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  cancelPayment(params) {
    return axios.post(`/sell/cancelPayment`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  MenuInOut(params){
    return axios.post(`/sell/MenuInOut`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updatePosMenuPrice(params){
    return axios.post(`/sell/updatePosMenuPrice`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  // manager 
  getPaymentReportGroupBySeller(posid, startdate, enddate){
    return axios.get(`/sell/getPaymentReportGroupBySeller/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getStockReport(posid, startdate, enddate){
    return axios.get(`/stock/getStockReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  // admin 
  getPaymentReportGroupByPos( startdate, enddate){
    return axios.get(`/sell/getPaymentReportGroupByPos/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getStockPosRemainReport(){
    return axios.get(`/stock/getStockPosRemainReport`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  

  //admin
  getAllProducts(){
    return axios.get(`/product/getProduct`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // saveProduct(params) {
  //   return axios.post(`/product/addProduct`, params, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },
  getProductTypes(){
    return axios.get(`/product/getProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // updateProduct(params) {
  //   return axios.post(`/product/updateProduct`, params, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },

  changeProductStatus(params) {
    return axios.post(`/product/changeProductStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getProductGrade(posid,productid){
    return axios.get(`/product/getProductGrade/${posid}/${productid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateProductGrade(params) {
    return axios.post(`/product/updateProductGrade`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addProductGrade(params) {
    return axios.post(`/product/addProductGrade`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  searchAdminProducts(params){
    return axios.post(`/product/searchAllProducts`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  //
  getManagerProducts(posid){
    return axios.get(`/product/getManagerProduct/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  searchManagerProducts(params){
    return axios.post(`/product/searchManagerProducts`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  // stock

  getProductsByType(posid,typeid){
    return axios.get(`/stock/getProductsByType/${posid}/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSupplier(){
    return axios.get(`/supplier/getSupplier`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  stockIn(params){
    return axios.post(`/stock/productIn`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  stockOut(params){
    return axios.post(`/stock/productOut`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getStockHistory(posid){
    return axios.get(`/stock/getStockHistory/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getStockProductRemainReport(posid){
    return axios.get(`/stock/getStockProductRemainReport/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getStockProductRemainReportDetail(posid, productid){
    return axios.get(`/stock/getStockProductRemainReportDetail/${posid}/${productid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAfterSellReport(posid){
    return axios.get(`/stock/getAfterSellReport/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  approveAfterSell(params){
    return axios.post(`/stock/approveAfterSell`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getAfterSellApprovedReport(posid, startdate, enddate){
    return axios.get(`/stock/getAfterSellApprovedReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  }, 

  // setting

  getSettingSelect_date(){
    return axios.get(`/setting/getSettingSelect_date`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosSelectDate(){
    return axios.get(`/setting/getPosSelectDate`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  savePayDate(params){
    return axios.post(`/setting/savePayDate`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changePayDateStatus(params){
    return axios.post(`/setting/changePayDateStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // customer
  checkCustomerPosandTable(posid,tableid){
    return axios.get(`/setting/checkCustomerPosandTable/${posid}/${tableid}`);
  },

  getSettingPosList(){
    return axios.get(`/setting/getPosList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuType(){
    return axios.get(`/setting/getMenuType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSettingMenuTypeForCustomer(){
    return axios.get(`/setting/getSettingMenuTypeForCustomer`);
  },

  
  getSettingMenuSubTypeByType(typeid){
    return axios.get(`/setting/getMenuSubTypeByType/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSettingMenuUnitByType(typeid){
    return axios.get(`/setting/getMenuMenuByType/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveMenu(params){
    return axios.post(`/setting/saveMenu`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuList(){
    return axios.get(`/setting/getMenuList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuListforCooking(){
    return axios.get(`/setting/getMenuListForCooking`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenu(params){
    return axios.post(`/setting/updateMenu`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  changeMenuStatus(params){
    return axios.post(`/setting/changeMenuStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuTypeList(){
    return axios.get(`/setting/getSettingMenuTypeList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  saveMenuType(params){
    return axios.post(`/setting/saveMenuType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuType(params){
    return axios.post(`/setting/updateMenuType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  changeMenuTypeStatus(params){
    return axios.post(`/setting/changeMenuTypeStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuSubTypeList(){
    return axios.get(`/setting/getSettingMenuSubTypeList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  saveMenuSubType(params){
    return axios.post(`/setting/saveMenuSubType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuSubType(params){
    return axios.post(`/setting/updateMenuSubType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMenuUnitList(){
    return axios.get(`/setting/getSettingMenuUnitList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  saveMenuUnit(params){
    return axios.post(`/setting/saveMenuUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuUnit(params){
    return axios.post(`/setting/updateMenuUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getIngredient(typeid, menuid){
    return axios.get(`/setting/getIngredient/${typeid}/${menuid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveIngredient(params){
    return axios.post(`/setting/saveIngredient`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuIngredient(params){
    return axios.post(`/setting/updateMenuIngredient`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateMenuVideoName(params){
    return axios.post(`/setting/updateMenuVideoName`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  cancelMenuIngredient(params){
    return axios.post(`/setting/cancelMenuIngredient`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getProductIngredientList(menuid){
    return axios.get(`/setting/getProductIngredientList/${menuid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingProductList(){
    return axios.get(`/setting/getSettingProductList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeItemStatus(params){
    return axios.post(`/setting/changeItemStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingProductType(){
    return axios.get(`/setting/getSettingProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingProductUnitByType(typeid){
    return axios.get(`/setting/getSettingProductUnitByType/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveProduct(params){
    return axios.post(`/setting/saveProduct`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateProduct(params){
    return axios.post(`/setting/updateProduct`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateMinimartProduct(params){
    return axios.post(`/setting/updateMinimartProduct`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingProductTypeList(){
    return axios.get(`/setting/getSettingProductTypeList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getSettingMinimartProductTypeList(){
    return axios.get(`/setting/getSettingMinimartProductTypeList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveProductType(params){
    return axios.post(`/setting/saveProductType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveMinimartProductType(params){
    return axios.post(`/setting/saveMinimartProductType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  updateProductType(params){
    return axios.post(`/setting/updateProductType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateMinimartProductType(params){
    return axios.post(`/setting/updateMinimartProductType`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingProductUnitList(){
    return axios.get(`/setting/getSettingProductUnitList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMinimartProductUnitList(){
    return axios.get(`/setting/getSettingMinimartProductUnitList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveProductUnit(params){
    return axios.post(`/setting/saveProductUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  saveMinimartProductUnit(params){
    return axios.post(`/setting/saveMinimartProductUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateProductUnit(params){
    return axios.post(`/setting/updateProductUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
 

  updateMinimartProductUnit(params){
    return axios.post(`/setting/updateMinimartProductUnit`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
 

  getPosVatList(){
    return axios.get(`/setting/getPosVatList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  saveVat(params){
    return axios.post(`/setting/saveVat`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getPosDiscount(){
    return axios.get(`/setting/getPosDiscount`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveDiscount(params){
    return axios.post(`/setting/saveDiscount`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getTableList(posid){
    return axios.get(`/sell/getTableList/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveTable(params){
    return axios.post(`/sell/saveTable`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateTable(params){
    return axios.post(`/sell/updateTable`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  // end sell systmem

  // start mini mart
  getSettingMinimartProductType(){
    return axios.get(`/setting/getMinimartProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProduct(posid){
    return axios.get(`/minimart/getMinimartProduct/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductinOrder(posid){
    return axios.get(`/minimart/getMinimartProductinOrder/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getMiniMartProductsByKeyword(posid,key){
    return axios.get(`/minimart/getMiniMartProductsByKeyword/${posid}/${key}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addPrducttoOrder(params){
    return axios.post(`/minimart/addPrducttoOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addPrducttoOrderByBarCode(params){
    return axios.post(`/minimart/addPrducttoOrderByBarCode`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  removeProductFromOrder(params){
    return axios.post(`/minimart/removeProductFromOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateProductQuantity(params){
    return axios.post(`/minimart/updateProductQuantity`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getTopProductList(startdate, enddate, posid){
    return axios.get(`/minimart/getTopProductList/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartList(){
    return axios.get(`/minimart/getMinimartList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartAllPaymentReport(startdate, enddate, posid){
    return axios.get(`/minimart/getMinimartAllPaymentReport/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMiniPaymentReport(posid, sellerid, username, txdate){
    return axios.get(`/minimart/getMiniPaymentReport/${posid}/${sellerid}/${username}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartPaymentDetailReport(orderid, batchid){
    return axios.get(`/minimart/getMinimartPaymentDetailReport/${orderid}/${batchid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartAllRefundReport(startdate, enddate, posid){
    return axios.get(`/minimart/getMinimartAllRefundReport/${startdate}/${enddate}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartRefundReport(posid, sellerid, username, txdate){
    return axios.get(`/minimart/getMinimartRefundReport/${posid}/${sellerid}/${username}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartAllMoneyReport(posid,startdate, enddate){
    return axios.get(`/minimart/getMinimartAllMoneyReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartBossMoneyReport(posid,startdate, enddate){
    return axios.get(`/minimart/getMinimartBossMoneyReport/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartMoneyReport(posid, txdate){
    return axios.get(`/minimart/getMinimartMoneyReport/${posid}/${txdate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartMoneyDetailReport(posid, txdate, paymenttype, currency, orderid){
    return axios.get(`/minimart/getMinimartMoneyDetailReport/${posid}/${txdate}/${paymenttype}/${currency}/${orderid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  // geetAllStock(){
  //   return axios.get(`/sell/getAllStock`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },

  getMinimartStock(posid, type){
    return axios.get(`/minimart/getMinimartStock/${posid}/${type}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  getMinimartStockHistory(posid, type, startdate, enddate){
    return axios.get(`/minimart/getMinimartStockHistory/${posid}/${type}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductType(){
    return axios.get(`/minimart/getMinimartProductType`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductByType(typeid, posid){
    return axios.get(`/minimart/getMinimartProductByType/${typeid}/${posid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addEmergencyProductToRequest(params) {
    return axios.post(`/minimart/addEmergencyProductToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  addEmergencyProductBrokenToRequest(params) {
    return axios.post(`/minimart/addEmergencyProductBrokenToRequest`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  
  getMinimartProductBrokenRequest(posid,startdate, enddate){
    return axios.get(`/minimart/getMinimartProductBrokenRequest/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeMinimartProductBrokenRequestStatus(params) {
    return axios.post(`/minimart/changeMinimartProductBrokenRequestStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getMinimartProductRequest(posid,startdate, enddate){
    return axios.get(`/minimart/getMinimartProductRequest/${posid}/${startdate}/${enddate}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getMinimartProductRequestDetail(requestid){
    return axios.get(`/minimart/getMinimartProductRequestDetail/${requestid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  changeMinimartRequestStatus(params) {
    return axios.post(`/minimart/changeMinimartRequestStatus`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  // getProductRequestProvide(posid,startdate, enddate){
  //   return axios.get(`/product/getProductRequestProvide/${posid}/${startdate}/${enddate}`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },

  // getProductRequestProvideDetail(requestid){
  //   return axios.get(`/product/getProductRequestProvideDetail/${requestid}`, {
  //     headers: { Authorization: "Bearer " + store.getters.getJwt }
  //   });
  // },

  updateProductToMinimart(params) {
    return axios.post(`/minimart/updateProductToMinimart`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMinimartProductList(){
    return axios.get(`/minimart/getSettingMinimartProductList`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  saveMinimartProduct(params){
    return axios.post(`/setting/saveMinimartProduct`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getSettingMinimartProductUnitByType(typeid){
    return axios.get(`/setting/getSettingMinimartProductUnitByType/${typeid}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getMinimartProductList(pos){
    return axios.get(`/minimart/getMinimartProductList/${pos}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  getMinimartProductListbyType(pos,type){
    return axios.get(`/minimart/getMinimartProductListbyType/${pos}/${type}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },


  getMinimartProductUsageListBytype(pos, type){
    return axios.get(`/minimart/getMinimartProductUsageListBytype/${pos}/${type}`, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  minimartProductInOut(params){
    return axios.post(`/minimart/minimartProductInOut`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },

  updateMinimartProductPrice(params){
    return axios.post(`/minimart/updateMinimartProductPrice`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  

  updateMinimartOrder(params){
    return axios.post(`/minimart/updateMinimartOrder`, params, {
      headers: { Authorization: "Bearer " + store.getters.getJwt }
    });
  },
  
  // end minimart 
  
};
