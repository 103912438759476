<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດການ ເມນູ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
                <div class="column">
                  <b-tabs v-model="activeTab" v-if="roleid == 2">
                    <b-tab-item label="ຟອມເພີ່ມ/ ແກ້ໄຂເມນູ">
                      <div class="columns">
                        <div class="column">
                          <h3 class="title">ເພິ່ມ/ ແກ້ໄຂເມນູ</h3>
                        </div>
                      </div>
                      <div class="columns" v-if="adddataform">
                        <div class="column">
                          <div class="columns">
                            <div class="column">
                              <b-field label="ຊື່ເມນູນ">
                                <b-input v-model.trim="menuname"> </b-input>
                              </b-field>
                            </div>
                          
                            <div class="column">
                              <b-field grouped style=" margin-bottom: -5px;">
                                <b-field label="ປະເພດ">
                                </b-field>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-success"
                                    size="is-small"
                                    @click="addMenuType()"
                                    ><span style="font-size: 24px;">+</span>
                                  </b-button>
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedType"
                                  label="NAME"
                                  track-by="ID"
                                  :options="typeList"
                                  @input="onTypeSelected()"
                                >
                                </Multiselect>
                              </b-field>

                              
                            
                            </div>

                            <div class="column">
                              <b-field grouped style=" margin-bottom: -5px;">
                                <b-field label="ປະເພດຍ່ອຍ">
                                </b-field>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-success"
                                    size="is-small"
                                    @click="addMenuSubType()"
                                    ><span style="font-size: 24px;">+</span>
                                  </b-button>
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedsubtype"
                                  label="NAME"
                                  track-by="ID"
                                  :options="subtypeList"
                                >
                                </Multiselect>
                              </b-field>
                                
                              
                            
                            </div>

                            <div class="column">
                              <b-field grouped style=" margin-bottom: -5px;">
                                <b-field label="ຫົວໜ່ວຍ">
                                </b-field>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-success"
                                    size="is-small"
                                    @click="addMenuUnit()"
                                    ><span style="font-size: 24px;">+</span>
                                  </b-button>
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedunit"
                                  label="NAME"
                                  track-by="ID"
                                  :options="unitList"
                                >
                                </Multiselect>
                              </b-field>

                            
                            </div>
                            <div class="column">
                              <b-field grouped style=" margin-bottom: 5px;">
                                <b-field label="ໄປຫ້ອງຄົວ?">
                                </b-field>
                               
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectediscook"
                                  label="NAME"
                                  track-by="ID"
                                  :options="iscooklist"
                                >
                                </Multiselect>
                              </b-field>

                            
                            </div>
                            

                            
                          </div>
                          <div class="columns">
                            <div class="column"></div>
                            <div class="column">
                              <b-field grouped position="is-right">
                                <div class="buttons">
                                  <b-button
                                    expanded
                                    type="is-primary"
                                    onclick="document.getElementById('fileupload').click()"
                                    >ເລືອກຮູບ
                                  </b-button>
                                </div>
                              </b-field>
                              <b-field label="" style="float: right">
                                <b-field v-if="saveAction">
                                  <button class="button is-info" @click="saveMenu">
                                    ບັນທຶກເມນູ
                                  </button>
                                </b-field>
                                <b-field v-if="updateAction">
                                  <div class="columns">
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-primary"
                                          @click="updateMenu"
                                        >
                                          ແກ້ໄຂເມນູ
                                        </button>
                                      </b-field>
                                    </div>
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-info"
                                          @click="openSaveMenuForm"
                                        >
                                          ລ້າງຟອມ
                                        </button>
                                      </b-field>
                                    </div>
                                  </div>
                                </b-field>
                              </b-field>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column">
                              <b-field
                                grouped
                                position="is-centered"
                                v-if="isSelectPhoto || updateAction"
                              >
                                <img
                                  data-v-xcxcc
                                  :src="photopreview"
                                  width="500"
                                  height="500"
                                />
                              </b-field>
                              <input
                                type="file"
                                id="fileupload"
                                @change="onFileSelected"
                                style="visibility:hidden;"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab-item>

                    <b-tab-item label="ເມນູ ທີ່ເປີດໃຊ້ງານ">
                      <b-field label="ເລືອກປະເພດເມນູ">
                        <Multiselect
                          placeholder="ເລືອກ"
                          v-model.trim="selectedType"
                          label="NAME"
                          track-by="ID"
                          :options="typeList"
                          @input="onTypeSelectedForMenuList()"
                        >
                        </Multiselect>
                      </b-field>
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ເມນູ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ປະເພດຍ່ອຍ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ສ່ວນປະກອບ
                            </th>
                           
                            <th class="has-text-centered " width="15%">
                              ໄປຫ້ອງຄົວ?
                            </th>
                           
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in filteredMenuList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-centered">
                              {{ data.NAME }}<br>
                              <img
                                data-v-xcxcc
                                :src="getImgUrl(data.PHOTONAME)"
                                width="100"
                                height="100"
                              />
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUSUBTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUUNIT }}
                            </td>
                            <td class="has-text-centered">
                              {{  data.COUNTINGREDIENT | formatNumberLocal }}
                            </td>
                            <td class="has-text-centered">
                             
                              <span v-if="data.ISCOOK==1">
                                ໄປຫ້ອງຄົວ
                              </span>
                              <span v-else style="color:red">
                                ບໍ່ໄປ
                              </span>
                            </td>
                            <td class="has-text-centered">
                              <b-button v-if="data.USEDINGREDIENT ==1"
                                class="btn-group-margin"
                                type="is-info"
                                size="is-small"
                                @click="inGredientForm(data)"
                                >ເພີ່ມສ່ວນປະກອບ
                              </b-button>
                             
                                <b-button
                                  class="btn-group-margin"
                                  type="is-primary"
                                  size="is-small"
                                  @click="updateMenuForm(data)"
                                  >ແກ້ໄຂ
                                </b-button>
                          
                                <b-button
                                  class="btn-group-margin"
                                  type="is-danger"
                                  size="is-small"
                                  @click="changeMenuStatus(data.ID, 0)"
                                  >ປິດໃຊ້ງານ
                                </b-button>
                           
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>

                    <b-tab-item label="ເມນູ ທີ່ຖືກປິດ">
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ເມນູ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ປະເພດຍ່ອຍ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                          
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in inActiveMenuList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-centered">
                              {{ data.NAME }}<br>
                              <img
                                data-v-xcxcc
                                :src="getImgUrl(data.PHOTONAME)"
                                width="100"
                                height="100"
                              />
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUSUBTYPE }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.MENUUNIT }}
                            </td>
                            <td class="has-text-centered">
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-info"
                                  size="is-small"
                                  @click="changeMenuStatus(data.ID, 1)"
                                  >ເປີດໃຊ້ງານ
                                </b-button>
                              </b-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>
                    <b-tab-item label="ສ່ວນປະກອບເມນູ">
                      <div class="columns">
                        <div class="column" >
                          <div class="columns" >
                            <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                              ລາຍລະອຽດເມນູ
                            </div>
                          </div>
                          
                          <div class="columns" >
                            <div class="column" > 
                              <div style=" text-align: right;">
                                ເມນູ: 
                              </div>
                              <div style=" text-align: right;">
                                ປະເພດ: 
                              </div>
                              <div style=" text-align: right;">
                                ປະເພດຍ່ອຍ: 
                              </div>
                              <div style=" text-align: right;">
                                ຫົວໜ່ວຍ:
                              </div>
                            </div>
                            <div class="column">
                              <div style=" text-align: left; font-weight: bold;">
                               {{ ing_SelectedMenu }}
                              </div>
                              <div style=" text-align: left; font-weight: bold;">
                               {{ ing_SelectedTYPE }}
                              </div>
                              <div style=" text-align: left; font-weight: bold;">
                               {{ ing_SelectedSubType }}
                              </div>
                              <div style=" text-align: left; font-weight: bold;">
                               {{ ing_SelectedUnit }}
                              </div>
                            </div>
                          </div>
                          <div class="columns" style="margin-top: 30px;">
                            <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                              ຟອມປ້ອນສ່ວນປະກອບ
                            </div>
                          </div>
                          <div class="columns" >
                            <div class="column">
                              <b-field label="ສ່ວນປະກອບ">
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedProduct"
                                  label="NAME"
                                  track-by="ID"
                                  :options="productList"
                                  @input="onProductSelected()"
                                >
                                </Multiselect>
                              </b-field>
                              <b-field grouped>
                                <b-field label="ປະລິມານ">
                                  <b-input v-model.trim="productQuantity" type="number" step="0.01" min="0.01" max="1000.00"> </b-input> 
                                </b-field>
                                <b-field  style="padding-top: 35px;">
                                  <span v-if="productUnit">{{ productUnit }}</span>
                                </b-field>
                              </b-field>
                              <b-field >
                                <b-button v-if="isSaveIngredient"
                                  class="btn-group-margin"
                                  type="is-info" expanded 
                                  @click="saveIngredient()"
                                  >ບັນທຶກ
                                </b-button>
                                <b-button v-if="isUpdateIngredient"
                                 
                                  type="is-success" expanded 
                                  @click="updateMenuIngredient()"
                                  >ແກ້ໄຂ
                                </b-button>
                              </b-field>
                                
                               
                              
                            </div>
                          </div>
                          <div class="columns" style="margin-top: 30px;">
                            <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                              ອັບໂຫຼດວິດິໂອ
                            </div>
                          </div>
                          <div class="columns" >
                            <div class="column">
                                <div>
                                  <label for="fileInput" class="custom-file-upload">
                                    <span>Select a video</span>
                                    <input type="file" id="fileInput" @change="handleFileChange" accept="video/*">
                                  </label>
                                  {{ temp_filename }}
                                </div>
                                <div style="margin-top:10px">
                                  <b-button type="is-info" expanded @click="uploadVideo">Upload Video</b-button>
                                </div>
                                <div style="margin-top:10px">
                                  <b-button v-if="!isVideoOn"
                                  style="width: 100%; border-radius: 5px;"
                                  class="btn-group-margin"
                                  type="is-success" 
                                  size="is-small"
                                  expanded
                                  @click="playVideo()"
                                  >ເປີດ Video
                                </b-button>
                                <b-button v-else
                                  style="width: 100%; border-radius: 5px;"
                                  class="btn-group-margin"
                                  type="is-danger" 
                                  size="is-small"
                                  expanded
                                  @click="closeVideo()"
                                  >ປິດ Video
                                </b-button>
                                </div>

                                <div style="margin-top:10px">
                                  <img
                                    data-v-xcxcc
                                    :src="getImgUrl(menuphotname)"
                                    width="100%"
                                  />
                                </div>
                                <div style="margin-top:10px" v-show="isVideoOn">
                                  <video  style="width: 100%;" ref="videoref" controls ></video>
                                </div>
                                
                                <!-- <div >
                                  <video  style="width: 100%;" ref="videoref" controls ></video>
                                </div> -->
                                
                                
                           
                            </div>
                          </div>
                            
                          
                        </div>
                        <div class="column">
                          <table class="table is-fullwidth is-narrow is-small">
                            <thead>
                              <tr>
                                <th class="has-text-centered " width="5%">#</th>

                                <th class="has-text-left " width="15%">
                                  ສ່ວນປະກອບ
                                </th>

                                <th class="has-text-centered " width="15%">
                                  ປະລິນມານ 
                                </th>
                                <th class="has-text-centered " width="10%">
                                  ຈັດການ
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in inGredientMenuList"
                                :key="data.ID"
                              >
                                <td class="has-text-centered">{{ index + 1 }}</td>

                                <td class="has-text-left">
                                  {{data.PRODUCTID}} - {{ data.PRODUCTNAME }}
                                  <!-- <br> -->
                                  <!-- <img
                                    data-v-xcxcc
                                    :src="getImgUrl(data.PHOTONAME)"
                                    width="100"
                                    height="100"
                                  /> -->
                                </td>
                                <td class="has-text-centered">
                                  {{ data.QUANTITY }}  {{ data.UNIT }}
                                </td>
                                
                                <td class="has-text-centered">
                                  <b-field>
                                    <b-button
                                      class="btn-group-margin"
                                      type="is-info"
                                      size="is-small"
                                      @click="updateMenuIngredientForm(data)"
                                      >ແກ້ໄຂ
                                    </b-button>
                                  </b-field>
                                  <b-field>
                                    <b-button
                                      class="btn-group-margin"
                                      type="is-danger"
                                      size="is-small"
                                      @click="cancelMenuIngredient(data.ID)"
                                      >ຍົກເລີກ
                                    </b-button>
                                  </b-field>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      roleid: null,
      username: null,
      url: "uploadmenu", // function name in api site
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      photopreview: this.photopath +'unknown.png',
      isSelectPhoto: false,

      activeTab: 1,
      saveAction: true,
      updateAction: false,
      adddataform: true,
      updatedataform: false,

      photoname: null,
      menuid: null,
      oldmenuname: null,
      menuname: null,
      typeList: [],
      selectedType: null,
      subtypeList: [],
      selectedsubtype: null,
      unitList: [], 
      selectedunit: null,
      selectediscook: null,
      iscooklist: [
        { ID: "1", NAME: "ໄປຫ້ອງຄົວ" },
        { ID: "0", NAME: "ບໍ່ໄປ" }, 
      ], 
      usedIngredientType: [
        { ID: "1", NAME: "ມີ" },
        { ID: "0", NAME: "ບໍ່ມີ" },
      ], 
      selectedUsedIngredient: [],

      activeMenuList: [],
      originalMenuList: [],
      inActiveMenuList: [],

      ing_SelectedMenu: null,
      ing_SelectedMenuID: null,
      ing_SelectedTYPE: null,
      ing_SelectedTYPEID: null,
      ing_SelectedSubType: null,
      ing_SelectedUnit: null,

      selectedProduct: null,
      productList: [],
      productUnit: null,
      productQuantity: 0,
      inGredientMenuList: [],
      menuIngredientID: null,
      isSaveIngredient: true,
      isUpdateIngredient: false,

      videoFile: null,
      videoUrl: "uploadmenuvideo",
      // url: "uploadmenu", // function name in api site
      videopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/video/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/video/",
      videoname: null,
      temp_filename: null,
      isVideoOn: false,
      menuphotname: null,
      OpenInGredientForm: false,
      
    };
  },
  methods: {
    handleFileChange(event) {
      this.videoFile = event.target.files[0];
      this.temp_filename = this.videoFile.name;
    },
    async uploadVideo() {
      if (this.videoFile) {
        const formData = new FormData();
        formData.append('video', this.videoFile);

        try {
          const response = await axios.post(this.videoUrl, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          // Assuming the response contains the URL where the video is stored after upload
          // this.videoUrl = response.data.videoUrl;
          // console.log("filename: " + JSON.stringify(response.data))
          if(response.data){
            this.updateMenuVideoName(response.data);
          }
        } catch (error) {
          console.error('Error uploading the video', error);
        }
      } else {
        console.error('No video selected for upload');
      }
    },
    updateMenuVideoName(filename){
      var params = {
        menuid: this.ing_SelectedMenuID,
        filename: filename,
        username: this.username,
      };
      RestAPI.updateMenuVideoName(params)
        .then((res) => {
          if(res.data=="ok"){
            this.$swal({
              title: "ສຳເລັດ",
              text: "ອັບໂຫຼດວິດິໂອສຳເລັດ",
              icon: "success",
              timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the confirmation button
            });
            this.videoname = filename;
            this.temp_filename = null;
          }else{
            this.$swal({
              title: "error",
              text: "error",
              icon: "error",
              timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the confirmation button
            });
          }
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    playVideo(){
      this.OpenInGredientForm = false;
      console.log("this.videoname: " + this.videoname)
      console.log("this.videopath: " + this.videopath)
      if(this.videoname){
        this.$refs.videoref.src = this.videopath + this.videoname;
        // Play the video
        this.$refs.videoref.play();
        this.isVideoOn = true;
      }
    },
    closeVideo(){
      this.isVideo = false;
      this.$refs.videoref.pause();
      // this.videoname = null;
      this.isVideoOn = false;
    },

    updateMenuIngredientForm(data){
      console.log("data: " + JSON.stringify(data))
      this.selectedProduct = {
        ID: data.PRODUCTID,
        NAME: data.PRODUCTNAME,
      };
      this.productQuantity = data.QUANTITY;
      this.menuIngredientID = data.ID;
      this.isSaveIngredient= false;
      this.isUpdateIngredient= true;
    },
    updateMenuIngredient(){
      
      if (
        this.selectedProduct === null ||
        this.productQuantity === 0 ||
        this.productQuantity === 0.00 
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuIngredientID: this.menuIngredientID,
            productid: this.selectedProduct.ID,
            quantity: this.productQuantity,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.updateMenuIngredient(params)
            .then(() => {
              this.getProductIngredientList();
              this.selectedProduct = null;
              this.productQuantity = 0;
              this.isSaveIngredient= true;
              this.isUpdateIngredient= false;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ແກ້ໄຂສ່ວນປະກອບສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    cancelMenuIngredient(ID){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuIngredientID: ID,
            username: this.username,
          };
          console.log("params: " + JSON.stringify(params))
          RestAPI.cancelMenuIngredient(params)
            .then(() => {
              this.getProductIngredientList();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຍົກເລີກສ່ວນປະກອບສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    getProductIngredientList(){
      RestAPI.getProductIngredientList(this.ing_SelectedMenuID)
        .then(res => {
          this.inGredientMenuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("Error", "There was an error fetching data", "error");
        });
    },
    saveIngredient(){
      if (
        this.selectedProduct === null ||
        this.productQuantity === 0 ||
        this.productQuantity === 0.00 
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuid: this.ing_SelectedMenuID,
            productid: this.selectedProduct.ID,
            quantity: this.productQuantity,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.saveIngredient(params)
            .then(() => {
              this.getProductIngredientList();
              this.getIngredient();
              this.selectedProduct = null;
              this.productQuantity = 0;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ເພີ່ມສ່ວນປະກອບສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
  
    onProductSelected() {
      this.productList.forEach(element => {
        if(element['ID'] == this.selectedProduct.ID){
          this.productUnit = element['UNIT']
        }
      });
    },
    getIngredient() {
      RestAPI.getIngredient(this.ing_SelectedTYPEID, this.ing_SelectedMenuID)
        .then(res => {
          this.productList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("Error", "There was an error fetching data", "error");
        });
    },
    inGredientForm(data){
      this.activeTab =3;
      this.ing_SelectedMenu = data.NAME;
      this.ing_SelectedMenuID = data.ID;
      this.ing_SelectedTYPE = data.MENUTYPE;
      this.ing_SelectedTYPEID = data.MENUTYPEID;
      this.ing_SelectedSubType = data.MENUSUBTYPE;
      this.ing_SelectedUnit = data.MENUUNIT;
      if(this.videoname){
        this.closeVideo();
      }
      this.videoname = data.VIDEONAME;
      this.getIngredient();
      this.getProductIngredientList();

      this.menuphotname = data.PHOTONAME;
      this.OpenInGredientForm = true;

    },
    saveMenu(){ 
      
      if (
        this.menuname === null ||
        this.selectedType === null ||
        this.selectedsubtype === null ||
        this.selectedunit == null ||
        this.photoname === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuname: this.menuname,
            menutypeid: this.selectedType.ID,
            menusubtypeid: this.selectedsubtype.ID,
            menuunit: this.selectedunit.ID,
            photoname: this.photoname,
            isUseIngredient: 1,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.saveMenu(params)
            .then(() => {
              this.getMenuList();
              this.activeTab = 1
              this.menuname = null;
              this.selectedType = null;
              this.selectedsubtype = null;
              this.selectedunit = null;
              this.photoname = null;
              this.photopreview = null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ເພີ່ມເມູນສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });  

    },
    getMenuList(){
      RestAPI.getSettingMenuList()
        .then(res => {
          this.activeMenuList = res.data.activemenu;
          this.originalMenuList = res.data.activemenu;
          this.inActiveMenuList = res.data.inactivemenu;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    updateMenu(){
      if (
        this.menuname === null ||
        this.selectedType === null ||
        this.selectedsubtype === null ||
        this.selectedunit == null ||
        this.selectediscook == null ||
        this.photoname === null 
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuid : this.menuid,
            oldmenuname: this.oldmenuname,
            menuname: this.menuname,
            menutypeid: this.selectedType.ID,
            menusubtypeid: this.selectedsubtype.ID,
            menuunit: this.selectedunit.ID,
            iscook: this.selectediscook.ID,
            photoname: this.photoname,
            isUseIngredient: 1,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.updateMenu(params)
            .then((res) => {
              // console.log("res: " + JSON.stringify(res.data))
              if(res.data==='duplicate menuname'){
                this.$swal("ຊື່ເມນູຊ້ຳ, ລອງໃໝ່", "", "error");
                return false;
              }
              this.getMenuList();
              this.activeTab = 1;
              this.menuname = null;
              this.selectedType = null;
              this.selectedsubtype = null;
              this.selectedunit = null;
              this.photoname = null;
              this.photopreview = null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ແກ້ໄຂຂໍ້ມູນເມນູສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    openSaveMenuForm(){
      this.menuname = null;
      this.selectedType = null;
      this.selectedsubtype = null;
      this.selectedunit = null;
      this.photoname = null;
      this.photopreview = this.photopath +'unknown.png';
    },
    updateMenuForm(data){
      this.activeTab = 0;
      this.saveAction = false;
      this.updateAction = true;
      this.menuid = data.ID
      this.oldmenuname = data.NAME;
      this.menuname = data.NAME;
      this.selectedType = {
        ID: data.MENUTYPEID,
        NAME: data.MENUTYPE,
      };
      this.selectedsubtype = {
        ID: data.MENUSUBTYPEID,
        NAME: data.MENUSUBTYPE,
      };
      this.selectedunit = {
        ID: data.MENUUNITID,
        NAME: data.MENUUNIT,
      };
      this.selectediscook= {
        ID: data.ISCOOK,
        NAME: data.ISCOOK ==1 ? 'ໄປຫ້ອງຄົວ' : 'ບໍ່ໄປ',
      };

      this.photoname = data.PHOTONAME;
      this.photopreview = this.photopath + data.PHOTONAME;
    },
    changeMenuStatus(ID, STATUS){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            menuid : ID,
            status : STATUS,
            username: this.username,
          };
          RestAPI.changeMenuStatus(params)
            .then(() => {
              this.getMenuList();
              this.activeTab = 1
              this.$swal({
                title: "ສຳເລັດ",
                text: "ປ່ຽນສະຖານະສຳເລັດ",
                icon: "success",
                timer:1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },

    onTypeSelectedForMenuList() {
      this.activeMenuList = [...this.originalMenuList];
    },
    onTypeSelected(){
      this.selectedsubtype = null;
      this.selectedunit = null;
      this.selectedUsedIngredient = null;
      this.getSettingMenuSubTypeByType();
      this.getSettingMenuUnitByType();

    },
    getSettingMenuSubTypeByType(){
      RestAPI.getSettingMenuSubTypeByType(this.selectedType.ID)
        .then(res => {
          this.subtypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getSettingMenuUnitByType(){
      RestAPI.getSettingMenuUnitByType(this.selectedType.ID)
        .then(res => {
          this.unitList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getMenuType(){
      RestAPI.getSettingMenuType()
        .then(res => {
          this.typeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    addMenuType(){
      this.$router.push({ name: 'menutypesetting', params: { prop_activetab: 0 } });
    },
    addMenuSubType(){
      this.$router.push({ name: 'menusubtypesetting', params: { prop_activetab: 0 } });
    },
    addMenuUnit(){
      this.$router.push({ name: 'menuunitsetting', params: { prop_activetab: 0 } });
    },
    
    onFileSelected(event) {
      this.isSelectPhoto = true;
      console.log("url: "+ this.url)
      console.log("photopath: " + this.photopath)
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile);
      axios.post(this.url, fd).then((res) => {
        this.photoname = res.data;
        this.photopreview = this.photopath + res.data;
        
      });
    },

    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
   
    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getMenuType();
    this.getMenuList();
  },
  // created() {
  //   this.originalMenuList = [...this.activeMenuList]; // Copy the original list on component creation
  // },
  computed: {
    filteredMenuList() {
      if (this.selectedType && this.selectedType.ID) {
        return this.activeMenuList.filter(element => {
          return element['MENUTYPEID'] === this.selectedType.ID;
        });
      } else {
        return this.activeMenuList; // Return the entire list if selectedType or its ID is not available
      }
    },
  },
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

.custom-file-upload {
  display: inline-block;
  padding: 8px 15px;
  background: #23d160;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.custom-file-upload:hover {
  background: #1fb855;
}

.custom-file-upload input[type="file"] {
  display: none; /* Hide the original file input */
}


</style>
