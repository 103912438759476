<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານການຮັບເງິນ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;">
              <div class="column">
                <b-field label="ລາຍງານການຮັບເງິນທັງໝົດ"> </b-field>
                <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getAllMoneyReport()">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
              </div>
            </div>
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ລາຍງານການຮັບເງິນ (ບໍ່ລວມບິນຫົວໜ້າ)</span>
                </div>  
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="5%">
                          ວັນທີ
                        </th>
                        <th class="has-text-centered " width="20%">
                         Minimart
                        </th>
                        <th class="has-text-centered " width="10%">
                          ຈຳນວນເງິນ
                        </th>
                        <th class="has-text-right " width="20%">
                          ຈຳນວນ<br>ປະເພດ
                        </th>
                        <th class="has-text-right " width="20%">
                          ຈຳນວນ<br>ສະກຸນເງິນ
                        </th>
                        <th class="has-text-centered " width="15%">
                          ລາຍລະອຽດ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in allmoney"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.TXTIME }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.POSNAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.TOTAL | formatNumberLocal }}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMOFPAYMENTTYPE | formatNumberLocal}}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMBEROFCURRENCY | formatNumberLocal}}
                        </td>
                        <td class="has-text-centered">
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="posMoney(data)"
                              >ລາຍລະອຽດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                      
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="3" class="has-text-centered">
                          ລວມ {{ allmoney.length | formatNumberLocal }} ລາຍການ
                        </th>
                        <th  class="has-text-right">
                          {{ _.sumBy(allmoney, i => Number(i["TOTAL"])) | formatNumberLocal }}
                        </th>
                        <th  class="has-text-right">
                          {{ _.sumBy(allmoney, i => Number(i["NUMOFPAYMENTTYPE"])) | formatNumberLocal }}
                        </th>
                        <th  class="has-text-right">
                          {{ _.sumBy(allmoney, i => Number(i["NUMBEROFCURRENCY"])) | formatNumberLocal }}
                        </th> 
                        <th  class="has-text-right">
                          
                        </th>              
                      </tr>
                    </tfoot>
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <b-tabs v-model="activeTab">
                  <b-tab-item label="ລາຍງານການຮັບເງິນ Minimart">
                    <div v-show="isDetailSelected">
                      <div style="margin-top: 0px;">
                          <span style="font-size: 20px;">ລາຍງານການຮັບເງິນ</span>
                      </div>
                      <div class="paymentdetailselected" >
                        ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                      </div>
                      <div class="paymentdetailselected" >
                        Minimart: <span class="paymentdetailselectedhightlight"> {{ selectedPosName }}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                      </div>
                        
                      <div>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-right " width="5%">
                                ລະຫັດ
                              </th>
                              <th class="has-text-right " width="15%">
                                ຈຳນວນ<br>ໃນບິນ(ກີບ)
                              </th>
                              <th class="has-text-centered " width="20%">
                                ປະເພດ
                              </th>
                              <th class="has-text-right " width="15%">
                                ຈຳນວນ
                              </th>
                              <th class="has-text-right " width="15%">
                                ອັດຕາ<br>ແລກປ່ຽນ
                              </th>
                              
                              <th class="has-text-right " width="10%">
                                ຈຳນວນ<br>ເປັນກີບ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in posMoneyList"
                              :key="data.ID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-right ">
                                {{ data.ID }}
                              </td>
                              <td class="has-text-right ">
                                {{ data.TOTALLAK | formatNumberLocal }}
                              </td>
                              <td class="has-text-centered ">
                                {{ data.PAYMENTTYPE }} 
                              </td>
                              <td class="has-text-right ">
                                {{ data.SOURCEAMOUNT | formatNumberLocal }} <br><span style="font-size: 14px;">{{ data.CURRENCY }} </span>
                              </td>
                              
                              <td class="has-text-right" >
                                {{ data.XRATE }}  
                              </td>
                              <td class="has-text-right" >
                                {{ data.PAYINLAK | formatNumberLocal}} <br>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-info"
                                    size="is-small"
                                    @click="posMoneyDetail(data)"
                                    >ລາຍລະອຽດ
                                  </b-button>
                                </b-field>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="6" class="has-text-centered">
                                ລວມ {{ posMoneyList.length | formatNumberLocal }} ລາຍການ
                              </th>
                              <th class="has-text-right">
                                {{ _.sumBy(posMoneyList, i => Number(i["PAYINLAK"])) | formatNumberLocal }}
                              </th>
                              
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </b-tab-item>
                  <b-tab-item label="ລາຍລະອຽດ">
                    <div style="margin-top: 0px;">
                          <span style="font-size: 20px;">ລາຍລະອຽດ</span>
                      </div>
                      <div class="paymentdetailselected" >
                        ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                      </div>
                      <div class="paymentdetailselected" >
                        POS: <span class="paymentdetailselectedhightlight"> {{ selectedPosName }}</span>
                      </div>
                      <div class="paymentdetailselected" >
                        Orderid: <span class="paymentdetailselectedhightlight"> {{ selectedOrderid }}</span>
                      </div>
                      <!-- <div class="paymentdetailselected">
                        ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                      </div> -->
                      <div class="paymentdetailselected">
                        ປະເພດ:  <span class="paymentdetailselectedhightlight">{{  selectedPaymentType}}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຈຳນວນຕົ້ນທາງ:  <span class="paymentdetailselectedhightlight">{{  selectedsourceamount | formatNumberLocal}} {{  selectedcurrency}}</span>
                      </div>
                      
                      <div class="paymentdetailselected">
                        ອັດຕາແລກປ່ຽນ:  <span class="paymentdetailselectedhightlight">{{  selectedxrate | formatNumberLocal}}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຈຳນວນເງິນເປັນກີບ:  <span class="paymentdetailselectedhightlight">{{  selectedpayinlak | formatNumberLocal}} LAK</span>
                      </div>
                  <!-- <div style=" float: right;margin-bottom: 5px;">
                    <b-button class="is-small" type="is-info" @click="printReceipt()">ພິມບິນ</b-button>
                  </div> -->
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-right " width="15%">
                              ຈຳນວນເງິນຕົ້ນ
                            </th>
                            <th class="has-text-right " width="15%">
                              ສະກຸນເງິນ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in posmoneydetail"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>
                            <td class="has-text-right" >
                              {{ data.SOURCEAMOUNT | formatNumberLocal }} {{ data.UNIT }}
                            </td>
                            
                            <td class="has-text-right" >
                              {{ data.CURRENCY  }} 
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th  class="has-text-centered">
                              ລວມ {{ posmoneydetail.length | formatNumberLocal }} ລາຍການ
                            </th>
                            <th class="has-text-right">
                              {{ _.sumBy(posmoneydetail, i => Number(i["SOURCEAMOUNT"])) | formatNumberLocal }}
                            </th>
                            <th class="has-text-right">
                             
                            </th>
                            
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </b-tab-item>
                </b-tabs>

                
                
              </div>
              
            </div>

          </div>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      allmoney: [],
      posMoneyList: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,
      startdate: null,
      enddate: null,
      selectedPosName: null,
      selectedPosId: null,
      activeTab: 0,
      selectedTxdate: null,
      selectedTableName : null,
      selectedPaymentType : null,
      posmoneydetail: [],
      selectedsourceamount: null,
      selectedxrate: null,
      selectedpayinlak: null,
      selectedPaymentTypeID : null,
      selectedcurrency: null,
      selectedOrderid: null,
    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
    posMoneyDetail(data){
      this.activeTab = 1;
      // this.selectedPaymentDate = data.TXTIME;
      // this.selectedTxdate = this.selectedPaymentDate;
      // this.selectedPosId = data.POSID;
      // this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      this.selectedPaymentTypeID = data.PAYMENTTYPEID;
      this.selectedsourceamount = data.SOURCEAMOUNT;
      this.selectedxrate = data.XRATE;
      this.selectedpayinlak = data.PAYINLAK;
      this.selectedcurrency  = data.CURRENCY;
      this.selectedOrderid = data.ID;

      // console.log("this.selectedTxdate : "+   this.selectedTxdate) 
      // console.log("this.selectedTxdate : "+   this.dateFormatterYYYYMMDD(this.selectedTxdate)) 
       
      
      RestAPI.getMinimartMoneyDetailReport(this.selectedPosId, this.dateFormatterYYYYMMDD(this.selectedPaymentDate),this.selectedPaymentTypeID, this.selectedcurrency, data.ID)
        .then(res => {
          
          this.posmoneydetail = res.data;
          // console.log("res.data : "+JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    posMoney(data){
      
      this.selectedPaymentDate = data.TXDATE;
      this.selectedTxdate = data.TXDATE;
      this.selectedPosName = data.POSNAME;
      this.selectedPosId = data.POSID;
      this.selectedTotal = data.TOTAL;
      this.activeTab = 0;
      // console.log("data: " + JSON.stringify(data))
      // console.log("this.TXTIME: " + this.dateFormatterYYYYMMDD(data.TXDATE))
      RestAPI.getMinimartMoneyReport(this.selectedPosId, this.dateFormatterYYYYMMDD(data.TXDATE))
        .then(res => {
          this.isDetailSelected = true;
          this.posMoneyList = res.data;
          console.log("res.data : "+JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    }, 
    getAllMoneyReport(){
      RestAPI.getMinimartAllMoneyReport(this.posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.allmoney = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID ? this.user.POSID : 'ALL';
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);  
    this.enddate = new Date();
    this.getAllMoneyReport();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
