<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານສິນຄ້າໃນ Stock</div>
              </div>
            </div>

            <div class="columns">
              <div class="column">  
                <b-field label="ລາຍງານສິນຄ້າໃນ Stock"> </b-field>
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>
                            <th class="has-text-left " width="15%">
                              ລະຫັດ/ ຊື່ສິນຄ້າ
                            </th>

                            <th class="has-text-right " width="15%">
                              ຈຳນວນ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                            <!-- <th class="has-text-centered " width="10%">
                              ລາຍລະອຽດ
                            </th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in report"
                            :key="data.PRODCUTID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>
                            <td class="has-text-left">
                              {{data.PRODCUTID}} / {{ data.PRODUCTNAME }}
                            </td>
                            <td class="has-text-right">
                              {{ data.QUANTITY | formatNumberLocal }}
                            </td>
                            
                            <td class="has-text-centered">
                              {{ data.UNIT }}
                            </td>
                          
                            

                            <!-- <td class="has-text-centered">
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-info"
                                  size="is-small"
                                  @click="stockDetail(data)"
                                  >ລາຍລະອຽດ
                                </b-button>
                              </b-field>
                            </td> -->
                          </tr>
                          
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="2" class="has-text-centered">
                              ລວມ {{ report.length | formatNumberLocal }} ລາຍການ
                            </th>
                            <th class="has-text-right">
                              {{ _.sumBy(report, i => i["TOTAL"]) | formatNumberLocal }}
                            </th>
                            <th colspan="2">
                            
                            </th>
                            
                          </tr>
                        </tfoot>
                      </table>
           
          
              </div>

              <!-- <div class="column">
                <div v-show="isDetailSelected">
                  <div style="margin-top: 25px;">
                    <b-field label="ລາຍລະອຽດ"></b-field> 
                  </div>
                  
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="5%">
                            ລະຫັດ
                          </th>
                          <th class="has-text-centered " width="20%">
                            ສິນຄ້າ
                          </th>
                          <th class="has-text-centered " width="20%">
                            ລາຍການ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຂາເຂົ້າ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຂາອອກ
                          </th>
                          <th class="has-text-right " width="15%">
                            ເປັນເງິນ
                          </th>
                          <th class="has-text-centered " width="20%">
                            ເວລາ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in reportdetail"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.STOCKID }} 
                          </td>
                          <td class="has-text-centered">
                            {{ data.PRODUCTID }} - {{ data.PRODUCTNAME }}
                          </td>
                          <td class="has-text-centered" >
                            {{ data.STOCKTPYE }} 
                          </td>
                          <td class="has-text-right" >
                            {{ data.QUANTITYIN | formatNumberLocal}}
                          </td>
                          <td class="has-text-right" >
                            {{ data.QUANTITYOUT | formatNumberLocal }}
                          </td>
                          
                          <td class="has-text-right" >
                            {{ data.TOTAL | formatNumberLocal }} 
                          </td>
                          <td class="has-text-centered" >
                            {{ data.TXTIME }} 
                          </td>
                        </tr>
                      </tbody>
                      
                    </table>
                  </div>
                </div>
                
              </div> -->
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/agri-pos/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      report: [],
      reportdetail: [],
      selectedPaymentDate: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,
      startdate: null,
      enddate: null,
    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
    stockDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getStockProductRemainReportDetail(this.posid, data.PRODCUTID)
        .then(res => {
          this.isDetailSelected = true;
          this.reportdetail = res.data;
          // console.log("d: " + JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getStockProduct(){
      RestAPI.getStockProductRemainReport(this.posid)
        .then(res => {
          this.report = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date();
    this.enddate = new Date();
    this.getStockProduct();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

</style>
