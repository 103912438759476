<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານເມນູ ທີ່ຍັງເຫຼືອ</div>
              </div>
            </div>

          
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ລາຍການເມນູ</span>
                </div>  
                <div class="columns">
                  <div class="column" style="text-align: right;">
                    <Multiselect
                      placeholder="ປະເພດເມນູ"
                      v-model="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="menuTypeList"
                      @input="onTypeSelectedForMenuList"
                    >
                    </Multiselect> 
                  </div>
                  <div class="column" style="text-align: right;">
                    <!-- <Multiselect
                      placeholder="ຊື່ເມນູ"
                      v-model="selectedMenu"
                      label="NAME"
                      track-by="ID"
                      :options="menuList"
                      @input="onMenuSelectedList"
                    >
                    </Multiselect>  -->
                    <input v-if="selectedType"
                      class="input"
                      placeholder="ຄົ້ນຫາສິນຄ້າ ຕາມປະເພດ"
                      v-model="searchmenu"
                      style=""
                      @keyup="searchMenu()"
                    />
                  </div>
                </div>
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="15%">
                          ປະເພດ
                        </th>
                        <th class="has-text-left " width="25%">
                          ເມນູ
                        </th>

                        <th class="has-text-right " width="10%">
                          ຈຳນວນ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in filteredMenuList"
                        :key="index"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-left">
                          {{ data.TYPENAME }}
                        </td>
                        <td class="has-text-left">
                          {{ data.NAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMBEROFMENU | formatNumberLocal }} {{ data.UNIT}}
                        </td>
                        
                      </tr>
                      
                    </tbody>
                    
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 30px 30px 30px 30px">
                
                
              </div>
              
            </div>

          </div>

          
          
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      startdate: null,
      enddate: null,
      menu: [],
      originalmenu: [],
      searchmenu: null,
      selectedType: null,

      stockhistory: [],
      selectedProductType: null,
      menuTypeList: [],
      producttype: 'ALL',
      selectedHistoryProductType: null,
      
    };
  },
  methods: {
    searchMenu(){
      // console.log("searchproduct: " + this.searchproduct);
      this.menu = [...this.originalmenu];

    },
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
    getPosRemainMenu(){
      RestAPI.getPosRemainMenu(this.posid)
      .then((res) => {
        // console.log("res: " + JSON.stringify(res.data)) 
        this.menu = res.data.product;
        this.originalmenu = res.data.product;
      })
      .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    onTypeSelectedForMenuList() {
      this.searchmenu = null;
      this.menu = [...this.originalmenu];
    },
   
    onProductTypeSelected(){
      this.producttype = this.selectedProductType.ID;
      this.getPosRemainMenu();
    },

    getMenuType(){
      // console.log("getMenuType.....")
      RestAPI.getSettingMenuType()
        .then(res => {
          this.menuTypeList = res.data;
        })
        .catch(error => { 
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getPosRemainMenu();
    this.getMenuType();

    
    
  },
  computed: {
    filteredMenuList() {
      if (this.selectedType && this.selectedType.ID) {
        if(!this.searchmenu){
          console.log("if searchmenu :" + this.searchmenu)
          return this.menu.filter(element => {
            return element['TYPEID'] === this.selectedType.ID;
          });
        }else{ // need help
          console.log("else searchproduct :" + this.searchmenu)
          return this.menu.filter(element => {
            return (
              element['TYPEID'] === this.selectedType.ID &&
              element['NAME'].toLowerCase().includes(this.searchmenu.toLowerCase())
            );
          });
        }
        
      } else {
        return this.menu; 
      }
    },
  },
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
