<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍການສິນຄ້າໃນ stock</div>
              </div>
            </div>

            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div >
                  <div style="margin-top: 0px;">
                        <span style="font-size: 28px;">ລາຍການສິນຄ້າໃນ stock</span>
                    </div>  
                    <table class="table is-fullwidth is-narrow is-small" >
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="20%">
                            POS
                          </th>
                          <th class="has-text-centered " width="20%">
                            ຈຳນວນສິນຄ້າ
                          </th>

                          <th class="has-text-centered " width="10%">
                            ລາຍລະອຽດ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in allstock"
                          :key="data.POSID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.POSNAME }}
                          </td>
                          <td class="has-text-centered">
                            {{ data.NUMOFPRODUCT }}
                          </td>
                          <td class="has-text-centered">
                            <b-button
                              class="btn-group-margin"
                              type="is-success"
                              style="width: 100%; margin-top: 0px;  margin-bottom: 10px;"
                              size="is-small"
                              @click="getStock(data)"
                              >ລາຍລະອຽດ 
                            </b-button>
                          </td>
                          
                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="5" class="has-text-centered">
                            ລວມ {{ allstock.length | formatNumberLocal }} ລາຍການ
                          </th>                 
                        </tr>
                      </tfoot>
                    </table>
                  </div>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div v-if="isDetailSelected">
                  <div style="margin-top: 0px;">
                        <span style="font-size: 28px;">ລາຍການເຄື່ອງໃນ stock</span>
                    </div>  
                    <div class="columns">
                      <div class="column" style="text-align: right;">
                        <Multiselect
                          placeholder="ປະເພດສິນຄ້າ"
                          v-model="selectedProductType"
                          label="NAME"
                          track-by="ID"
                          :options="productTypeList"
                          @input="onProductTypeSelected"
                        >
                        </Multiselect>
                      </div>
                    </div>
                    <table class="table is-fullwidth is-narrow is-small" >
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="20%">
                            ປະເພດ
                          </th>
                          <th class="has-text-left " width="20%">
                            ສິນຄ້າ
                          </th>

                          <th class="has-text-right " width="10%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຫົວໜ່ວຍ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in stock"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.TYPE }}
                          </td>
                          <td class="has-text-left">
                            {{ data.PRODUCTNAME }}
                          </td>
                          <td class="has-text-right">
                            {{ data.QUANTITY | formatNumberLocal }}
                          </td>
                          <td class="has-text-right">
                            {{ data.UNIT}}
                          </td>
                          
                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="5" class="has-text-centered">
                            ລວມ {{ stock.length | formatNumberLocal }} ລາຍການ
                          </th>                 
                        </tr>
                      </tfoot>
                    </table>
                  </div>
              </div>
              
            </div>

          </div>

          
          
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      startdate: null,
      enddate: null,
      stock: [],
      allstock: [],
      selectedProductType: null,
      productTypeList: [],
      producttype: 'ALL',
      selectedHistoryProductType: null,
      
    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
    getAllStock(){
      RestAPI.getAllStock()
        .then(res => {
          this.allstock = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getStock(data){
      this.posid = data.POSID;
      this.getPosStock() 
    },
    getPosStock(){
      this.isDetailSelected =true;
      RestAPI.getPosStock(this.posid, this.producttype)
        .then(res => {
          this.stock = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onProductTypeSelected(){
      this.producttype = this.selectedProductType.ID;
      this.getPosStock();
    },
    onHistoryProductTypeSelected(){
      this.producttype = this.selectedHistoryProductType.ID;
      this.getPosStockHistory();
    },
    getProductType(){
      RestAPI.getProductType()
        .then(res => {
          this.productTypeList = res.data;
        })
        .catch(error => { 
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getAllStock();
    this.getProductType();
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
