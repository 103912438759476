<template>
  <div>
    <div class="no-print">
      <PosSystemMenu v-if="roleid == '1' && !hideMenu" />
      <PosAdminMenu v-if="roleid == '2' && !hideMenu" />
      <PosAccountMenu v-if="roleid == '3' && !hideMenu" />
      <PosSellerMenu v-if="(roleid == '4' || roleid == '5') && !hideMenu" />
      <PosChefMenu v-if="roleid == '6' && !hideMenu" />
      <MinimartMenu v-if="(roleid == '10' || roleid == '11') && !hideMenu" />
      
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import PosSystemMenu from "./PosSystemMenu";
import PosAdminMenu from "./PosAdminMenu";
import PosAccountMenu from "./PosAccountMenu";
import PosSellerMenu from "./PosSellerMenu";
import PosChefMenu from "./PosChefMenu";
import MinimartMenu from "./MinimartMenu";

export default {
  name: "Menu",
  components: {
    PosAdminMenu,
    PosAccountMenu,
    PosSellerMenu,
    PosChefMenu,
    PosSystemMenu,
    MinimartMenu
  },
  computed: {
    roleid: get("loginData@ROLEID"),
    hideMenu() {
      let resolved = this.$router.resolve(this.$route.path);
      return resolved.route.name === "404";
    },
  },
};
</script>
