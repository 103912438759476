<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານການຂາຍທັງໝົດ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;">
              <div class="column">
                <b-field label="ລາຍງານການຂາຍທັງໝົດ"> </b-field>
                <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getAllPaymentReport()">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
              </div>
            </div>
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ລາຍງານການຂາຍທັງໝົດ</span>
                </div>  
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="5%">
                          ວັນທີ
                        </th>
                        <th class="has-text-centered " width="20%">
                          Minimart
                        </th>

                        <th class="has-text-centered " width="10%">
                          ຈຳນວນເງິນ
                        </th>
                        <th class="has-text-right " width="20%">
                          ຈຳນວນ<br>ອໍເດີ້
                        </th>
                        <th class="has-text-centered " width="15%">
                          ລາຍລະອຽດ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in allpayment"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.TXTIME }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.POSNAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.TOTAL | formatNumberLocal }}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMOFORDER | formatNumberLocal}}
                        </td>
                        <td class="has-text-centered">
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="posPaymentDetail(data)"
                              >ລາຍລະອຽດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                      
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="3" class="has-text-centered">
                          ລວມ {{ allpayment.length | formatNumberLocal }} ລາຍການ
                        </th>
                        <th  class="has-text-right">
                          {{ _.sumBy(allpayment, i => Number(i["TOTAL"])) | formatNumberLocal }}
                        </th>
                        <th colspan="2" class="has-text-right">
                          
                        </th>                 
                      </tr>
                    </tfoot>
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <b-tabs v-model="activeTab">
                  <b-tab-item label="ລາຍການຊຳລະຂອງ Minimart">
                    <div v-show="isDetailSelected">
                      <div style="margin-top: 0px;">
                          <span style="font-size: 20px;">ລາຍການຊຳລະຂອງ Minimart</span> 
                      </div>
                      <div class="paymentdetailselected" >
                        Minimart: <span class="paymentdetailselectedhightlight"> {{ selectedPosName }}</span>
                      </div>
                      <div class="paymentdetailselected" >
                        
                        <div class="columns" style="">
                          <div class="column">
                            <b-field>
                              ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span> 
                              <!-- <b-button style="margin-top: -5px;" type="is-warning" class="btn-group-margin" size="is-small" @click="getAllPaymentReport()">ກົດເພື່ອປ່ຽນວັນທີ</b-button> -->
                            </b-field>
                      
                            <!-- <b-field>
                                  
                              <b-field label="ປ່ຽນວັນທີ">
                                <b-datepicker
                                  :date-formatter="dateFormatter"
                                  v-model="startdate"
                                >
                                </b-datepicker>
                              </b-field>
                              
                              <b-field style="padding-left: 20px; padding-top: 32px;" >
                                <b-button type="is-info" expanded style="width: 100%;" @click="getAllPaymentReport()">ລາຍງານ</b-button>
                              </b-field>
                            </b-field> -->
                          </div>
                        </div>
                      </div>
                      
                      <div class="paymentdetailselected">
                        ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                      </div>
                        
                      
                      <div>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="10%">
                                ປະເພດ
                              </th>
                              <th class="has-text-centered " width="20%">
                                ເລກທີ
                              </th>
                              <th class="has-text-centered " width="20%">
                                ວັນ ແລະ ເວລາ
                              </th>
                              <th class="has-text-right " width="15%">
                                ຈຳນວນເງິນ
                              </th>
                              
                              <th class="has-text-right " width="10%">
                                ຜູ້ຮັບຜິດຊອບ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in pospaymentdetail"
                              :key="data.ID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-left " style="color: red;">
                                <span v-if="data.PAYMENTTYPE == 1" >ເງິນສົດ</span>
                                <span v-else-if="data.PAYMENTTYPE == 2" >ເງິນໂອນນ</span>
                                <span v-if="data.PAYMENTTYPE == 4" >ບິນຫົວໜ້າ</span>
                              </td>
                              <td class="has-text-centered ">
                                {{ data.ORDERID }} <span v-if="data.ISFULLBILL == 0">/ {{ data.BATCHID }}</span> 
                                <b-field v-if="data.ISREFUND ==1">
                                   <span style="color: red;">ຄືນເງິນແລ້ວ</span> 
                                 
                                </b-field>
                              </td>
                              <td class="has-text-centered ">
                                {{ data.TXTIME }}  
                                <b-field v-if="data.ISREFUND ==1">
                                   <span style="color: red;">{{ data.TXDATEREFUND }}</span>
                                 
                                </b-field>
                              </td>
                              
                              <td class="has-text-right" >
                                {{ data.TOTAL | formatNumberLocal }}  
                                <b-field v-if="data.ISREFUND ==1">
                                   <span style="color: red;">{{ data.REFUNDREASON }}</span>
                                 
                                </b-field>
                              </td>
                              <td class="has-text-right" >
                                {{ data.SELLERID }} <br>
                                <!-- <b-field v-if="data.ISREFUND==0"> 
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-danger"
                                    size="is-small"
                                    @click="refund(data.ID)"
                                    >ຄືນເງິນລູກຄ້າ
                                  </b-button>
                                </b-field> -->
                                <!-- <b-field v-else>
                                   <span style="color: red;">{{ data.REFUNDBY }}</span>
                                 
                                </b-field> -->
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-info"
                                    size="is-small"
                                    @click="posPaymentBillDetail(data)"
                                    >ລາຍລະອຽດ
                                  </b-button>
                                </b-field>
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="3" class="has-text-centered">
                                ລວມ {{ pospaymentdetail.length | formatNumberLocal }} ລາຍການ
                              </th>
                              <th class="has-text-right">
                                {{ _.sumBy(pospaymentdetail, i => Number(i["TOTAL"])) | formatNumberLocal }}
                              </th>
                              <th class="has-text-right">
                                
                              </th>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </b-tab-item>
                  <b-tab-item label="ລາຍລະອຽດ">
                    <div style="margin-top: 0px;">
                          <span style="font-size: 20px;">ລາຍລະອຽດ</span>
                      </div>
                    <div class="paymentdetailselected" >
                      ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ selectedTxtime }}</span>
                    </div>
                    <div class="paymentdetailselected" >
                      Minimart: <span class="paymentdetailselectedhightlight"> {{ selectedPosName }} </span>
                    </div>
                    <div class="paymentdetailselected">
                      OrderID:  <span class="paymentdetailselectedhightlight">{{  orderid}}</span>
                                <span v-if="isfullbill ==0" class="paymentdetailselectedhightlight">/ {{  batchid}}</span>
                    </div>
                    <div class="paymentdetailselected">
                      ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                    </div>
                    <div class="paymentdetailselected print-receipt-titel">
                    ຮູບແບບຊຳລະ:
                    <span class="">
                      <div v-for="data in PaymentTypeList" :key="data.PAYMENTTYPE">
                        <div style="font-size: 12px;">{{ data.PAYMENTTYPE }}: {{ data.PAYINLAK | formatNumberLocal}}</div>
                    </div>
                    
                    </span>
                  </div>
                  <div style=" float: right;margin-bottom: 5px;">
                    <b-button class="is-small" type="is-info" @click="printReceipt()">ພິມບິນ</b-button>
                  </div>
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>
                            <th class="has-text-left " width="20%">
                              ສິນຄ້າ
                            </th>

                            <th class="has-text-right " width="15%">
                              ຈຳນວນ
                            </th>
                            <th class="has-text-right " width="15%">
                              ລາຄາ
                            </th>
                            <th class="has-text-right " width="10%">
                              ລວມ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in pospaymentbilldetail"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>
                            <td>
                              {{ data.MENUNAME }}
                            </td>
                            <td class="has-text-right" >
                              {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                            </td>
                            
                            <td class="has-text-right" >
                              {{ data.UNITPRICE | formatNumberLocal }} 
                            </td>
                            <td class="has-text-right" >
                              {{ data.TOTAL | formatNumberLocal }} 
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <th colspan="4" class="has-text-centered">
                              ລວມ {{ pospaymentbilldetail.length | formatNumberLocal }} ລາຍການ
                            </th>
                            <th class="has-text-right">
                              {{ _.sumBy(pospaymentbilldetail, i => Number(i["TOTAL"])) | formatNumberLocal }}
                            </th>
                            
                          </tr>
                          <tr v-if="discount > 0">
                            <th colspan="4" class="has-text-right">
                              ສ່ວນລຸດ ({{ discount }}%)
                            </th>
                            <th class="has-text-right">
                              {{ discountamount | formatNumberLocal}}
                            </th>
                          </tr>
                          <tr>
                            <th colspan="4" class="has-text-right">
                              ອມພ ({{ vat}}%)
                            </th>
                            <th class="has-text-right">
                              {{ vatamount | formatNumberLocal}}
                            </th>
                          </tr>
                          <tr >
                            <th colspan="4" class="has-text-right">
                              ລວມທັງໝົດ
                            </th>
                            <th class="has-text-right">
                              {{  payamount | formatNumberLocal}}
                            </th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </b-tab-item>
                </b-tabs>

                
                
              </div>
              
            </div>

          </div>
        </div>
        
      </div>
    </div>
    <div class="contain print-only">
      <div class="column">
        <!-- <div v-show="isDetailSelected"> -->
          <div
            style="margin-top: 25px; text-align: center;">
              <span class="print-receipt-main-title">ໃບບິນຊຳລະເງິນ</span>
          </div>
          <div style="background-color: red; padding: 0 0 0 0; margin: 0 0 0 0 ;" >
            <img
                class="image-area"
                style="width: 150px;"
                :src="getImgUrl('symbol/logo.jpg')"
                alt="Placeholder image"
              />
          </div>
          <div class="paymentdetailselected" >
            ຮ້ານ: <span class="paymentdetailselectedhightlight"> {{ selectedPosName }} </span>
          </div>
          <div class="paymentdetailselected">
            OrderID:  <span class="paymentdetailselectedhightlight">{{  orderid}}</span>
                      <span v-if="isfullbill ==0" class="paymentdetailselectedhightlight">/ {{  batchid}}</span>
          </div>
          <div class="paymentdetailselected" >
            ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ selectedTxtime }}</span>
          </div>
          
          <div>
            <table class="table is-fullwidth is-narrow is-small">
              <!-- <thead>
                <tr>
                  <th class="has-text-centered " width="5%">#</th>
                  <th class="has-text-left " width="20%">
                    ສິນຄ້າ
                  </th>

                  <th class="has-text-right " width="15%">
                    ຈຳນວນ
                  </th>
                  <th class="has-text-right " width="15%">
                    ລາຄາ
                  </th>
                  <th class="has-text-right " width="10%">
                    ລວມ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in pospaymentbilldetail"
                  :key="data.ID"
                >
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td>
                    {{ data.MENUNAME }}
                  </td>
                  <td class="has-text-right" >
                    {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                  </td>
                  
                  <td class="has-text-right" >
                    {{ data.UNITPRICE | formatNumberLocal }} 
                  </td>
                  <td class="has-text-right" >
                    {{ data.TOTAL | formatNumberLocal }} 
                  </td>
                </tr>
              </tbody> -->
              <thead>
                <tr>
                  <th class="has-text-centered " width="5%">#</th>
                  <th class="has-text-left " width="20%">
                    ສິນຄ້າ
                  </th>

                  <th class="has-text-right " width="10%">
                    ລວມ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in pospaymentbilldetail" :key="data.ID">
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td class="paymentdetailselectedhightlight">
                    {{ data.MENUNAME }} <br />
                    ຈຳນວນ: {{ data.QUANTITY | formatNumberLocal }}
                    {{ data.UNIT }} <br />
                    ລາຄາ: {{ data.UNITPRICE | formatNumberLocal }}
                  </td>

                  <td class="has-text-right paymentdetailselectedhightlight">
                    {{ data.TOTAL | formatNumberLocal }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2" class="has-text-right">
                    ລວມ {{ pospaymentbilldetail.length | formatNumberLocal }} ລາຍການ
                  </th>
                  <th class="has-text-right">
                    {{ _.sumBy(pospaymentbilldetail, i => Number(i["TOTAL"])) | formatNumberLocal }}
                  </th>
                  
                </tr>
                <tr v-if="discount > 0">
                  <th colspan="2" class="has-text-right">
                    ສ່ວນລຸດ ( {{ discount}}%)
                  </th>
                  <th class="has-text-right">
                    {{ discountamount | formatNumberLocal}}
                  </th>
                </tr>
                <tr>
                  <th v-if="vatamount > 0" colspan="2" class="has-text-right">
                    ອມພ ( {{ vat}}%)
                  </th>
                  <th class="has-text-right">
                    {{ vatamount | formatNumberLocal}}
                  </th>
                </tr>
                <tr>
                  <th colspan="2" class="has-text-right">
                    ລວມທັງໝົດ
                  </th>
                  <th class="has-text-right">
                    {{ payamount | formatNumberLocal}}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="print-receipt">
            ຂອບໃຈທີ່ມາອຸດໜູນ
          </div>
          <div class="print-receipt">
            ເບີໂທຕິດຕໍ່ 020 52238878
          </div>

        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      allpayment: [],
      pospaymentdetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,
      startdate: null,
      enddate: null,
      selectedPosName: null,
      selectedPosId: null,
      activeTab: 0,
      selectedTxdate: null,
      selectedTableName : null,
      selectedTxtime : null,
      pospaymentbilldetail: [],
      PaymentTypeList: [],

      discount: null,
      discountamount: null,
      vat: null,
      vatamount: null,
      payamount: null,
      isfullbill: null,
      batchid: null,

      reason: null,
      posPaymentDetailData: [],
    };
  },
  methods: {
    refund(paymentid){
      this.$swal({
        title: "ເຫດຜົນການຄືນເງິນ",
        input: "text",
        inputPlaceholder: "ເຫດຜົນ",
        inputValue: "",
        showCancelButton: true,
        confirmButtonText: "ຢືນຢັນ",
        cancelButtonText: "ຍົກເລີກ",
        reverseButtons: true,
        inputValidator: (value) => {
          if (!value) {
            return "ກະລຸນາລະບຸເຫດຜົນ";
          }
        },
      }).then((reason) => {
        if (reason.value) {
          this.reason = reason.value;
          const params = { 
            paymentid: paymentid,
            reason: this.reason,
            username: this.username,
          };

          // console.log("params: " + JSON.stringify(params))
          RestAPI.refund(params)
            .then((res) => {
              if(res.data=='ok'){
                this.$swal("ຄືນເງິນສຳລັດ", "", "success");
              }else{
                this.$swal("ຄືນເງິນສຳລັດ", "-", "error");
              }
              this.posPaymentDetail(this.posPaymentDetailData);
            })
            .catch((error) => {  
              console.error("refund error:", error);
            });
        }
      });
      
      

    },
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.isPrint = true;
      setInterval(this.print(), 5000);
      this.isPrint = false;
    },
    posPaymentBillDetail(data){
      this.activeTab = 1;
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTxdate = data.TXDATE;
      // this.selectedPosName = data.POSNAME;
      this.selectedPosId = data.POSID;
      this.selectedTotal = data.TOTAL;
      this.selectedTableName = data.TABLENAME;
      this.selectedTxtime = data.TXTIME;
      this.orderid = data.ORDERID;
      this.isfullbill = data.ISFULLBILL;
      this.batchid = data.BATCHID ? data.BATCHID : 0;
      console.log("data: " + JSON.stringify(data))
      console.log("data.BATCHID: " + data.BATCHID)
      console.log("batchid: " + this.batchid)
      // this.vat = data.VAT;
      // this.vatamount = data.VATAMOUNT;
      // this.discount = data.DISCOUNT;
      // this.discountamount = data.DISCOUNTAMOUNT;
      // console.log("data:" + JSON.stringify(data)) 
      // console.log("this.discountamount:" + this.discountamount)
      
      RestAPI.getMinimartPaymentDetailReport(this.orderid, this.batchid)
        .then(res => {
          // console.log("pospaymentbilldetail:" + JSON.stringify(res.data.list))
                   
          this.pospaymentbilldetail = res.data.list;
          this.PaymentTypeList = res.data.paymenttype;
          this.vat = res.data.list[0].VAT;
          this.vatamount = res.data.list[0].VATAMOUNT;
          this.discount = res.data.list[0].DISCOUNT;
          this.discountamount = res.data.list[0].DISCOUNTAMOUNT;
          this.payamount = res.data.list[0].SOURCEAMOUNT;
          // console.log("pospaymentbilldetail[0].DISCOUNT:" + this.pospaymentbilldetail[0].DISCOUNT)
          // console.log("pospaymentbilldetail[0].VAT:" + this.pospaymentbilldetail[0].DISCOUNTAMOUNT)
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    posPaymentDetail(data){

      this.posPaymentDetailData= data;
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTxdate = data.TXDATE;
      this.selectedPosName = data.POSNAME;
      this.selectedPosId = data.POSID;
      this.selectedTotal = data.TOTAL;
      this.activeTab = 0;

      var selectedSellerid = this.roleid == 5 ? this.sellerid : 'ALL';
      
      RestAPI.getMiniPaymentReport(this.selectedPosId, selectedSellerid, this.username,this.dateFormatterYYYYMMDD(this.selectedTxdate))
        .then(res => {
          // console.log("getPosPaymentReport : "+JSON.stringify(res.data))
          this.isDetailSelected = true;
          this.pospaymentdetail = res.data;


         
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    getAllPaymentReport(){
      var posid = this.roleid ==4 ? this.posid : 'ALL';
      RestAPI.getMinimartAllPaymentReport(this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate), posid)
        .then(res => {
          this.allpayment = res.data;
          console.log("allpayment : "+JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getAllPaymentReport();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
  font-weight: bold;
}
.print-receipt-titel{
  font-weight: bold;
  font-size: 12px;
}

.print-receipt {
  font-weight: bold;
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
  font-weight: bold;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
