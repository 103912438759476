<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດສັນ ແລະ ຈັດສົ່ງສິນຄ້າ</div>
              </div>
            </div>

            
            <div class="columns" >

              <div class="column" v-if="roleid== 3 || roleid== 4 " style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <div >
                  <div style="margin-top: 28px;">
                      <span style="font-size: 28px;">ຈັດສັນ ແລະ ຈັດສົ່ງສິນຄ້າ</span>
                  </div>

                      <b-tabs v-model="activeTab" >
                        <b-tab-item label="ຈັດສັນ ແລະ ຈັດສົ່ງສິນຄ້າ">
                          <b-field>
                            <b-field label="ຈາກວັນທີ">
                              <b-datepicker
                                  :date-formatter="dateFormatter"
                                  v-model="startdate"
                                > 
                                </b-datepicker>
                              </b-field>
                              <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                              <b-field label="ເຖິງວັນທີ">
                                <b-datepicker
                                  :date-formatter="dateFormatter"
                                  v-model="enddate"
                                >
                                </b-datepicker>
                              </b-field>
                              <b-field style="padding-left: 20px; padding-top: 32px;" >
                                <b-button type="is-info" expanded style="width: 100%;" @click="getRequestProvide()">ລາຍງານ</b-button>
                              </b-field>
                          </b-field>

                          <table class="table is-fullwidth is-narrow is-small">
                            <thead>
                              <tr>
                                <th class="has-text-centered " width="5%">#</th>
                                <th class="has-text-centered " width="20%">
                                  POS 
                                </th>
                                <th class="has-text-centered " width="20%">
                                  ຈຳນວນ <br>ສິນຄ້າ
                                </th>

                                <th class="has-text-centered " width="20%">
                                  ວັນທີ
                                </th>
                                
                                <th class="has-text-centered " width="15%">
                                ລາຍລະອຽດ 
                                </th>
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in requests"
                                :key="data.REQUESTID"
                              >
                                <td class="has-text-centered">{{ index + 1 }}</td>
                                <td class="has-text-centered">
                                  {{ data.POSNAME }} 
                                </td>
                                <td class="has-text-centered" >
                                  {{ data.NUMBEROFPRODUCT | formatNumberLocal }} 
                                </td>
                                
                                <td class="has-text-centered" >
                                  {{ data.TXTIME}} 
                                </td>
                                
                                <td class="has-text-centered" >
                                  {{ data.STATUSTITLE }} <br> 
                                  <b-button 
                                      type="is-info"
                                      expanded
                                      style="width: 100%;"
                                      @click="requestProvideDetail(data.REQUESTID, data.STATUS)"
                                      >ລາຍລະອຽດ
                                  </b-button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        
                        </b-tab-item>

                        <b-tab-item label="ລາຍລະອຽດ">
                          
                          <b-field class="detailtitle">ສິນຄ້າທີ່ຕ້ອງການ</b-field>
                          <table class="table is-fullwidth is-narrow is-small">
                            <thead>
                              <tr>
                                <th class="has-text-centered " width="5%">#</th>
                                <th class="has-text-left " width="20%">
                                  ສິນຄ້າ 
                                </th>
                                <th class="has-text-centered " width="20%">
                                  ຈຳນວນ<br>ທີ່ຕ້ອງການ
                                </th>
                                <th class="has-text-centered " width="20%">
                                  ຈຳນວນຈັດສັນ
                                </th>
                                <th class="has-text-centered " width="20%">
                                  ລາຄາລວມ
                                </th>
                                                            
                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr 
                                v-for="(data, index) in summarydata"
                                :key="data.ID"
                              >
                                <td class="has-text-centered">{{ index + 1 }}</td>
                                <td class="has-text-left">
                                  {{ data.PRODUCTNAME }} 
                                </td>
                                <td class="has-text-centered" >
                                  {{ data.CHECKQUANTITY | formatNumberLocal }}  {{ data.UNIT}} 
                                </td>
                                <td class="has-text-centered" >
                                  <b-field v-if="roleid == 3 && data.STATUS==3">
                                    <input  
                                      type="number"
                                      class="input"
                                      v-model="data.CHECKQUANTITY_PROVIDE"
                                      style=" width: 70px; text-align: right;"
                                    />
                                  </b-field>
                                  <b-field v-if="(roleid == 3 || roleid == 4) && data.STATUS >=4">
                                    {{ data.CHECKQUANTITYPROVIDED }} 
                                  </b-field>
                                </td>
                                <td class="has-text-centered" >
                                  <b-field v-if="roleid == 3 && data.STATUS==3">
                                    <input  
                                      type="number"
                                      class="input"
                                      v-model="data.TOTAL_PRICE"
                                      style=" width: 100%; text-align: right;"
                                    />
                                  </b-field>
                                  <b-field v-if="(roleid == 3 || roleid == 4) && data.STATUS >=4">
                                    {{ data.TOTALPRICE | formatNumberLocal}} 
                                  </b-field>
                                  
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="columns">
                            <div class="column">
                              
                            </div>
                            <div class="column">
                              
                            </div>
                            <div class="column">
                            </div>
                            <div class="column">

                              <b-button  v-if="roleid == 3 && selectedRequestStatus==3"
                                  type="is-success"
                                  expanded
                                  style="width: 100%;"
                                  @click="updateProductToPos(selectedRequestid, 4)"
                                  >ບັນທຶການຊື້ສິນຄ້າ
                              </b-button>
                              <b-button  v-if="roleid == 3 && selectedRequestStatus == 4"
                                  type="is-danger"
                                  expanded
                                  style="width: 100%;"
                                  @click="updateProductToPos(selectedRequestid, 5)"
                                  >ສົ່ງສິນຄ້າໃຫ້ POS
                              </b-button>
                            </div>
                            
                          </div>
                            
                        </b-tab-item>

                        
                      </b-tabs>
                      
                     
                   
                  <div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
     
      menuTypeList: [],
      selectedMenuType: null,
      menuList:[],
      requests:[],
      startdate: null,
      enddate: null,
      activeTab: 0,
      formdata: [],
      summarydata: [],
      selectedRequestid: null,
      selectedRequestStatus: null,

    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
   
    getMenuType(){
      RestAPI.getMenuType()
        .then(res => {
          this.menuTypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onSeletedMenuType(){
      this.getPosMenuByType(this.selectedMenuType.ID);
    },

    getPosMenuByType(typeid){
      RestAPI.getPosMenuByType(typeid)
        .then(res => {
          this.menuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    addMenuToRequest(){
      var params = {
        posid: this.posid,
        menu: this.menuList,
        username: this.username,
      };
      RestAPI.addMenuToRequest(params)
        .then(() => {
          // call get request function
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    getRequestProvide(){
      var posid = this.roleid ==4 ? this.posid : 'ALL';
      RestAPI.getProductRequestProvide(posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.requests = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    requestProvideDetail(requestid, status){
      this.activeTab =1;
      this.isDetailSelected = true;
      this.selectedRequestid = requestid;
      this.selectedRequestStatus = status;
      RestAPI.getProductRequestProvideDetail(requestid)
        .then(res => {
          this.summarydata = res.data.summarydata;
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    changeRequestStatus(requestid, status){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            requestid: requestid,
            status: status,
            username: this.username,
          };
          RestAPI.changeRequestStatus(params)
            .then(() => {
              this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");    
              this.getRequestProvide();
              this.activeTab = 0;
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
          
    },
    updateProductToPos(requestid, status){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            posid: this.posid,
            requestid: requestid,
            summarydata : this.summarydata,
            status: status,
            username: this.username,
          };
          RestAPI.updateProductToPos(params)
            .then((res) => {
              if(status ==4 && res.data ==0){
                this.$swal("ກະລຸນາປ້ອນລາຄາໃຫ້ຄົບຖ້ວນ", "", "error");
                return false;
              }

              this.activeTab = 0;
              console.log("status:" + status)
              console.log("res:" + JSON.stringify(res.data))
              
              this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");    
              this.getRequestProvide();
              this.selectedRequestStatus = status;
              if(status==4){
                this.isDetailSelected = false;
              }
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
          
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getMenuType();
    this.getRequestProvide();
    
    
  },
  computed: {},
};
</script>

<style scoped>

.detailtitle{
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
