import jssha1 from "js-sha1";
import sha256 from "js-sha256";
// import store from '../vuex/store'
// import _ from 'lodash'
import moment from "moment";
import { get } from "vuex-pathify";

export default {
  computed: {
    user: get("loginData"),
    currentDateAndTime() {
      return new moment().format("YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {

    myConsole(){
      console.log("from mixin")
    },

    playSound() {
      var sound = "http://soundbible.com/mp3/Air Plane Ding-SoundBible.com-496729130.mp3";
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },

    checkSameMonth(startdate, enddate){
      // console.log("startdat: " + moment(startdate).format("YYYY-MM"));
      // console.log("enddate: " + moment(enddate).format("YYYY-MM"));
      if(moment(startdate).format("YYYY-MM") != moment(enddate).format("YYYY-MM")){
        this.$swal("ກະລຸນາເລືອກວັນທີ ໃນເດືອນດຽວກັນ", "", "error");
        return false;
      }
      
    },
    monthFormatter(date) {
      return new moment(date).format("MM/YYYY");
    },
    zeroPad(number, width) {
      width -= number.toString().length;
      if (width > 0) {
        return (
          new Array(width + (/\./.test(number) ? 2 : 1)).join("0") + number
        );
      }
      return number + ""; // always return a string
    },
    dfm(date, format) {
      if (format === undefined) {
        format = "DD/MM/YYYY";
      }
      return new moment(date).format(format);
    },
    sha1: function(text) {
      let hash = jssha1.create();
      hash.update(text);
      return hash.hex();
    },
    sha2: function(text) {
      let hash2 = sha256.create();
      hash2.update(text);
      return hash2.hex();
    },

    handleError(err) {
      return new Promise((resolve, reject) => {
        if (err.request) {
          return reject(err);
        }
        switch (err.response.status) {
          case 401:
            this.$router.replace("/login");
            return resolve("error handled");
          case 403:
            this.$swal({
              type: "error",
              title: "Permission Denied",
              text: err.response
                ? err.response.data
                : "ທ່ານບໍ່ໄດ້ຮັບອະນຸຍາດໃຫ້ເຂົ້າ"
            });
            return resolve("error handled");
          case 404:
            this.$swal({
              type: "error",
              title: "Not Found",
              text: err.response ? err.response.data : "Not Found"
            });
            return resolve("error handled");
          default:
            reject(err);
        }
      });
    },
    projectShortName(name){
      var shortname = name.split('ສະໜາມ', 2);
      return shortname[1];
    },
    monthAndYear(date){
      var month = date.split("/");
      return month[1] + "/" + month[2];
    },
    monthAndYear_(date){
      var month = date.split("-");
      return month[1] + "/" + month[0];
    },
    
  },
  filters: {
    simpleDate(date) {
      return new moment(date).format("DD-MM-YYYY");
    },

    formatNumberLocal(number) {
      // return isNaN(parseFloat(number)) ? '0' : parseFloat(number).toFixed(2).toLocaleString('de-DE')
      return isNaN(parseFloat(number))
        ? "-"
        : parseFloat(number).toLocaleString();
    },

    decimal2(number) {
      return number.toFixed(2);
    },
    
    getOnlytime(datetime) {
      // return isNaN(parseFloat(number)) ? '0' : parseFloat(number).toFixed(2).toLocaleString('de-DE')
      let time = datetime.split(" ");
      return time[1];
    },
    laoNumberString(n) {
      // var leveltext = ['', 'ສິບ', 'ຮ້ອຍ', 'ພັນ', 'ໝື່ນ', 'ແສນ']
      let leveltext = ["", "ສິບ", "ຮ້ອຍ", "ພັນ", "ໝື່ນ", "ແສນ"];
      let numtext = [
        "",
        "ໜຶ່ງ",
        "ສອງ",
        "ສາມ",
        "ສີ່",
        "ຫ້າ",
        "ຫົກ",
        "ເຈັດ",
        "ແປດ",
        "ເກົ້າ"
      ];

      let res = "";

      let num = n + "";
      num = num.replace(/\D/g, "");
      let et = false;
      for (let i = 0; i < num.length; i++) {
        let val = parseInt(num.substr(i, 1));
        let pos = num.length - i - 1;
        if (val > 0) {
          if (val === 1 && pos % leveltext.length === 1) {
            res += "ສິບ";
          } else if (val === 2 && pos % leveltext.length === 1) {
            res += "ຊາວ";
          } else if (val === 1 && pos % leveltext.length === 0 && et) {
            res += "ເອັດ";
          } else {
            res += numtext[val] + leveltext[pos % leveltext.length];
            et = false;
          }
        }
        if (pos % leveltext.length === 0 && pos > 0) {
          res += "ລ້ານ";
        }
        if (val !== 0 && pos % leveltext.length === 1) {
          et = true;
        }
      }
      return res ? res : "-";
    }
  }
};
