<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="title">
            Stock/ Products
          </h2>
        </div>
      </div>
      <b-tabs v-model="activeTab" v-if="roleid == 3">
        <b-tab-item label="ສິນຄ້າຂ້າເຂົ້າ">
          <div class="columns">
            <div class="column">
              <h3 class="title">ຈັດການສິນຄ້າ ຂາເຂົ້າ</h3>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="ສະແກນ barcode">
                    <b-input v-model.trim="barcode"> </b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="ປະເພດສິນຄ້າ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                      @input="onProductTypeSelected(selectedType.ID)"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ສິນຄ້າ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedProduct"
                      label="PRODUCTNAME"
                      track-by="PRODUCTID"
                      :options="productsin"
                      @input="onProductSelected(selectedProduct.UNIT, 0)"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ລາຄາລວມ">
                    <b-input v-model.trim="total"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :label="quantity_title">
                    <b-input v-model.trim="quantity"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ເຫດຜົນ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedStockType"
                      label="NAME"
                      track-by="ID"
                      :options="stockInType"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ຜູ້ສະໜອງ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedSupplier"
                      label="NAME"
                      track-by="ID"
                      :options="supplierList"
                    >
                    </Multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column">
                  <b-field label="" style="float: right">
                    <b-field>
                      <button class="button is-info" @click="stockIn">
                        ບັນທຶກ ສິນຄ້າຂາເຂົ້າ
                      </button>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="ລາຍການສິນຄ້າເຂົ້າສາງ ລ່າສຸດ">
                <table class="table is-fullwidth is-narrow is-small">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>

                      <th class="has-text-left " width="15%">
                        ສິນຄ້າ
                      </th>

                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="15%">
                        ລວມເງິນ
                      </th>
                      <th class="has-text-centered " width="15%">
                        ຮູບແບບ
                      </th>
                      <th class="has-text-centered " width="10%">
                        ຜູ້ສະໜອງ
                      </th>
                      <th class="has-text-centered " width="15%">
                        ວັນທີ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in stockInList"
                      :key="data.STOCKID"
                    >
                      <td class="has-text-centered">{{ index + 1 }}</td>

                      <td>
                        {{ data.PRODUCTNAME }}
                      </td>
                      <td class="has-text-right ">
                        {{ data.QUANTITY | formatNumberLocal}}
                      </td>
                      <td class="has-text-right ">
                        {{ data.TOTAL  | formatNumberLocal}}
                      </td>
                      <td class="has-text-centered "> 
                        {{ data.STOCKTPYE }}
                      </td>
                      <td class="has-text-centered ">
                        {{ data.SUPPLIER }}
                      </td>
                      <td class="has-text-centered ">
                        {{ data.TXTIME }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-field>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="ສິນຄ້າຂ້າອອກ">
          <div class="columns">
            <div class="column">
              <h3 class="title">ຈັດການສິນຄ້າ ຂາອອກ</h3>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="ສະແກນ barcode">
                    <b-input v-model.trim="barcode"> </b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="ປະເພດສິນຄ້າ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                      @input="onProductTypeSelected(selectedType.ID)"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ສິນຄ້າ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedProduct"
                      label="PRODUCTNAME"
                      track-by="PRODUCTID"
                      :options="productsout"
                      @input="onProductSelected(selectedProduct.UNIT, selectedProduct.QUANTITY)"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ລາຄາລວມ">
                    <b-input v-model.trim="total"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :label="quantity_title">
                    <b-input v-model.trim="productoutquantity"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ເຫດຜົນ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedStockType"
                      label="NAME"
                      track-by="ID"
                      :options="stockOutType"
                      @input="onStockOutTypeSelect"
                    >
                    </Multiselect>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ຜູ້ສະໜອງ">
                    <Multiselect
                      :disabled="isStockOutSupplierDisabled"
                      placeholder="ເລືອກ"
                      v-model.trim="selectedSupplier"
                      label="NAME"
                      track-by="ID"
                      :options="supplierList"
                    >
                    </Multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column">
                  <b-field label="" style="float: right">
                    <b-field>
                      <button class="button is-danger" @click="stockOut">
                        ບັນທຶກ ສິນຄ້າຂາອອກ
                      </button>
                    </b-field>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <b-field label="ລາຍການສິນຄ້າອອກສາງ ລ່າສຸດ">
                <table class="table is-fullwidth is-narrow is-small">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>

                      <th class="has-text-left " width="15%">
                        ສິນຄ້າ
                      </th>

                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="15%">
                        ລວມເງິນ
                      </th>
                      <th class="has-text-centered " width="15%">
                        ຮູບແບບ
                      </th>
                      <th class="has-text-centered " width="10%">
                        ຜູ້ສະໜອງ
                      </th>
                      <th class="has-text-centered " width="15%">
                        ວັນທີ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in stockOutList"
                      :key="data.STOCKID"
                    >
                      <td class="has-text-centered">{{ index + 1 }}</td>

                      <td>
                        {{ data.PRODUCTNAME }}
                      </td>
                      <td class="has-text-right ">
                        {{ data.QUANTITY | formatNumberLocal}}
                      </td>
                      <td class="has-text-right ">
                        {{ data.TOTAL  | formatNumberLocal}}
                      </td>
                      <td class="has-text-centered "> 
                        {{ data.STOCKTPYE }}
                      </td>
                      <td class="has-text-centered ">
                        {{ data.SUPPLIER }}
                      </td>
                      <td class="has-text-centered ">
                        {{ data.TXTIME }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-field>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: ["MONTH"],
  data() {
    return {
      roleid: null,
      posid: null,
      userName: null,
      selectedType: null,
      selectedProduct: null,
      typeList: [],
      productsin: [],
      productsout: [],
      barcode: null,
      quantity: 0,
      unit: null,
      total: null,
      productoutquantity: 0,
      productremainquantity: 0,
      quantity_title: "ຈຳນວນ",
      selectedStockType: null,
      selectedSupplier: null,
      supplierList: [],
      activeTab: 0,
      stockInList: [],
      stockOutList: [],
      isStockOutSupplierDisabled: true,
      // saveAction: true,
      // updateAction: false,
      // adddataform: true,
      // updatedataform: false,

      // recommenttitle: null,

      search: null,
      stockInType: [{ ID: "1", NAME: "ຮັບຈາກ Supplier" }],
      stockOutType: [
        { ID: "4", NAME: "ເສຍຫາຍ" },
        { ID: "5", NAME: "ສົ່ງຄືນ Supplier" },
      ],
    };
  },
  methods: {
    getProductTypes() {
      RestAPI.getProductTypes()
        .then((res) => {
          // console.log("con: " + JSON.stringify(res.data));
          this.typeList = res.data.activeProductType;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    onProductTypeSelected(typeid) {
      console.log("typeid: " + typeid);
      RestAPI.getProductsByType(this.posid, typeid)
        .then((res) => {
          this.productsin = res.data.productsin;
          this.productsout = res.data.productsout;
          
          // console.log("res.data + " + JSON.stringify(res.data))
          // console.log("this.quantity: " + this.quantity)
        })
        .catch((error) => { 
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    onProductSelected(unit, quantity) {
      this.quantity_title ="ຈຳນວນ (" + unit + ")";
      this.productoutquantity = quantity;
      this.productremainquantity = quantity;
    },
    stockIn() {
      if (
        this.selectedType === null ||
        this.selectedProduct === null ||
        this.selectedStockType === null ||
        this.total === null ||
        this.total < 0 ||
        this.quantity === null ||
        this.quantity < 1 ||
        this.selectedSupplier === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          posid: this.posid,
          productid: this.selectedProduct.PRODUCTID,
          quantity: this.quantity,
          total: this.total,
          total_after_discount: this.total,
          type: this.selectedStockType.ID,
          unit: this.selectedProduct.UNIT,
          supplierid: this.selectedSupplier.ID,
          username: this.username,
        };
        console.log("params: " + JSON.stringify(params));
        RestAPI.stockIn(params)
          .then(() => {
            // console.log("res: " + JSON.stringify(res))
            this.$swal("ບັນທືກ ສິນຄ້າຂາເຂົ້າ ສຳເລັດ!", "", "success");
            this.selectedProduct = null;
            this.selectedType = null;
            this.selectedSupplier = null;
            this.selectedProduct = null;
            this.selectedStockType = null;
            this.total = null;
            this.quantity = null;
            this.quantity_title = "ຈຳນວນ";
            this.getStockHistory();
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },
    onStockOutTypeSelect() {
      if (this.selectedStockType) {
        if (this.selectedStockType.ID == 4) {
          this.isStockOutSupplierDisabled = true;
          // console.log("disabled");
        } else {
          this.isStockOutSupplierDisabled = false;
          // console.log("enabled");
        }
      } else {
        this.isStockOutSupplierDisabled = true;
      }
    },
    stockOut() {
      if (this.selectedStockType.ID === "5") {
        if (this.selectedSupplier === null) {
          this.$swal("ກະລຸນາເລືອກປາຍທາງ", "", "error");
          return false;
        }
      }

      if (this.productoutquantity > this.productremainquantity) {
          this.$swal("ຈຳນວນສິນຄ້າອອກ ເກີນຈຳນວນທີ່ຍັງເຫຼືອ", "", "error");
          return false;
      }

      if (
        this.selectedType === null ||
        this.selectedProduct === null ||
        this.selectedStockType === null ||
        this.total === null ||
        this.productoutquantity === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          posid: this.posid,
          productid: this.selectedProduct.PRODUCTID,
          quantity: this.productoutquantity,
          total: this.total,
          total_after_discount: this.total,
          type: this.selectedStockType.ID,
          unit: this.selectedProduct.UNIT,
          supplierid:
            this.selectedStockType.ID == 4 ? 0 : this.selectedSupplier.ID,
          username: this.username,
        };
        // console.log("params: " + JSON.stringify(params));
        RestAPI.stockOut(params)
          .then(() => {
            // console.log("res: " + JSON.stringify(res))
            this.$swal("ບັນທືກ ສິນຄ້າຂາອອກ ສຳເລັດ!", "", "success");
            this.selectedProduct = null;
            this.selectedType = null;
            this.selectedSupplier = null;
            this.selectedProduct = null;
            this.selectedStockType = null;
            this.total = null;
            this.productoutquantity = null;
            this.quantity_title = "ຈຳນວນ";
            this.productremainquantity = null;
            this.getStockHistory();
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },
    getStockHistory() {
      RestAPI.getStockHistory(this.posid)
        .then((res) => {
          this.stockInList = res.data.stockin;
          this.stockOutList = res.data.stockout;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    productSearch() {
      if (this.search) {
        var params = {
          search: this.search,
        };
        RestAPI.searchAdminProducts(params)
          .then((res) => {
            this.activeproducts = res.data.activeproducts;
            this.inactiveproducts = res.data.inactiveproducts;
            // console.log("products: " + JSON.stringify(this.products));
          })
          .catch((error) => {
            console.log(error);
            this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
          });
      } else {
        this.getProducts();
      }
    },
    getSupplier() {
      RestAPI.getSupplier()
        .then((res) => {
          this.supplierList = res.data.activeSupplier;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    // console.log(JSON.stringify(this.user))
    this.getSupplier();
    this.getProductTypes();
    this.getStockHistory();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
