import { render, staticRenderFns } from "./PosSellerMenu.vue?vue&type=template&id=cfcefaf4&scoped=true&"
import script from "./PosSellerMenu.vue?vue&type=script&lang=js&"
export * from "./PosSellerMenu.vue?vue&type=script&lang=js&"
import style0 from "./PosSellerMenu.vue?vue&type=style&index=0&id=cfcefaf4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cfcefaf4",
  null
  
)

export default component.exports