<template>
  <nav
    class="navbar is-primary no-print"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <!-- <img src="@/assets/logo.png" width="30" height="30"> -->
        <span>&nbsp;&nbsp;{{ this.user.username }}</span>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': isMenuActive }"
        @click="isMenuActive = !isMenuActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isMenuActive }">
      <div class="navbar-start" v-if="roleid=='4'">
        <div class="navbar-item has-dropdown is-hoverable" >
          <!-- <a class="navbar-link">
            <span>ການຜະລິດ</span>
          </a>

          <div class="navbar-dropdown">
            

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/clinic/productionreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ບັນຫາການຜະລິດ</span>
            </router-link>
            
          </div> -->
        </div>

        

        
        
      </div>

      <div class="navbar-end">
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍງານ </span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='4'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/report"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>


            
          </div>

       
         
        </div> -->
        
        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='4'">
          <a class="navbar-link">
            <span>ຂາຍສິນຄ້າ </span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂາຍສິນຄ້າ</span>
            </router-link>       

            
          </div>

       
         
        </div>
        
        <div class="navbar-item has-dropdown is-hoverable"  v-if="roleid=='4'">
          <a class="navbar-link">
            <span>ລາຍການແຕ່ງອາຫານ </span>
          </a>
          <div class="navbar-dropdown">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/chef"
              :exact="true"
              active-class="is-active"
            >
              <span>ອາຫານທີ່ຕ້ອງການ</span>
            </router-link>

          </div>
         
        </div>

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='4'">
          <a class="navbar-link">
            <span>ຈັດການຮ້ານ</span>
          </a>
          <div class="navbar-dropdown" >
          

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍສິນຄ້າ ຜ່ານເມນູ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productdirectrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍສິນຄ້າ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productbrokenrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ແຈ້ງສິນຄ້າເສຍຫາຍ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/productbroken"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານສິນຄ້າເສຍຫາຍ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/menu"
              :exact="true"
              active-class="is-active"
            >
              <span>ຈັດການເມນູ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/menuremain"
              :exact="true"
              active-class="is-active"
            >
              <span>ເມນູທີ່ຍັງເຫຼືອ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/tablesetting"
              :exact="true"
              active-class="is-active"
            >
              <span>ຈັດການໂຕະ</span>
            </router-link>

          </div>

       
         
        </div>

        

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='4'">
          <a class="navbar-link">
            <span>ລາຍງານ </span>
          </a>
          <div class="navbar-dropdown" >
            <!-- <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/report"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link> -->

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/waitpayment"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍການລໍຖ້າການຊຳລະ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/duplicatepaymentreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍການລູກຄ້າຊຳລະຊ້ຳ</span>
            </router-link>





            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allmenureport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານເມນູຂາຍດີ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allpaymentreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/bossmoneyreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານບິນຫົວໜ້າ</span>
            </router-link> 

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allmoneyreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຮັບເງິນ</span>
            </router-link> 

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allrefundreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍການຄືນເງິນ</span>
            </router-link>


            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/remainmenu"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານເມນູ ທີ່ຍັງເຫຼືອ</span>
            </router-link>

            

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/stock"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານສິນຄ້າໃນ stock</span>
            </router-link>
          </div>

       
         
        </div>

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='5'">
          <a class="navbar-link">
            <span>ຂາຍສິນຄ້າ - {{ username }}</span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂາຍສິນຄ້າ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allpaymentreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>

        

            
          </div>

       
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <router-link style="color: white; font-size: 16px;padding-top: 0px; margin-top: -5px;" 
            @click.native="menuClicked"
            class="navbar-item custom-link"
            to="/pos/notification"
            :exact="true"
            active-class="is-active"
          >
            <span>ແຈ້ງເຕືອນ </span><br>
            <span style="color: red; font-weight: bold; margin-left: 5px;">
              {{ newNoti }}/ {{ newNotiSound }}
            </span>
          </router-link>
        </div>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ຜູ້ໃຊ້ງານ: {{ posname }}</span>
          </a>
          <div class="navbar-dropdown">
            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/form/userProfile"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍ້ມູນສ່ວນຕົວ</span>
            </router-link>

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/login"
              :exact="true"
              active-class="is-active"
            >
              <span>ອອກຈາກລະບົບ</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>



    

    <div class="popup-container" v-if="showPopup" >
      <div class="popup" >
        <div class="popup-content" >ສະບາຍດີ ຂໍຕອນຮັບເຂົ້າສູ່ລະບົບ POS</div> 
        <div   class="popup-buttons" >
          <button @click="initiateSound">ປິດ</button>
        </div>
      </div>
    </div>

  
  </nav>
</template>

<script>
import RestAPI from "../../RestAPI";
export default {
  name: "ReportMenu",
  data() {
    return {
      isMenuActive: false,
      roleid: null,
      newNoti: 0,
      newNotiSound: 0,
      loadingData: null,
      posname: null,
      username: null,
      posid: null,
      // audioSource: 'http://soundbible.com/mp3/analog-watch-alarm_daniel-simion.mp3',
      sound:
        "http://45.77.43.249/pos/api/uploads/sound/sound.mp3",
        showPopup: false,
    };
    
  },
  beforeDestroy () {
      clearInterval(this.loadingData);
  },
  created () {
      this.loadData()
  },

  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.posid = this.user.POSID;
    this.posname = this.user.POSNAME;
    // console.log("posid: " + this.posid)
    this.openPopup(); 
    
  },
  methods: {
    menuClicked() {
      this.isMenuActive = false;
    }
    ,
  

    
    initiateSound() {
      this.playSound();
      setTimeout(() => {
        this.showPopup = false; // Hide the popup after 2 seconds
      }, 2000); // 2000 milliseconds = 2 seconds
    },
    openPopup() {
      this.showPopup = true;
      this.initiateSound();
    },   
    getUserNotification(){
      RestAPI.getUserNotification( this.roleid, this.username) 
      .then((res) => {

        this.newNoti = res.data.noti;
        this.newNotiSound = res.data.sound;
        if(this.newNotiSound > 0){
          this.playSound();
        }
        
      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    loadData () {
        this.loadingData = setInterval(() => {
          this.getUserNotification();
          this.posCheckPaymentStatus();
        }, 5000)
    },
  
    posCheckPaymentStatus (){
      var params = { 
          posid: this.posid,
          username: this.username,
        };
      RestAPI.posCheckPaymentStatus(params)
        .then(() => {
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    playSound() {
      if (this.sound) {
        var audio = new Audio(this.sound);
        audio.play();
      }
    },
  }
};
</script>

<style scoped>
.navbar-item {
  white-space: nowrap;
}
.navbar-end {
  padding-right: 60px;
}

.custom-link {
 
}
.custom-link:hover,
.custom-link:focus {
  background-color: #31aeec;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.popup button:hover {
  background-color: #2980b9;
}

.popup-content{
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}



.popup-buttons {
  margin-top: auto; /* This pushes the buttons to the bottom */
}

.popup button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}
</style>
