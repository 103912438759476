<template>
  <div class="section">
    <div class="container">
      <h1 class="title">ສະບາຍດີ </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  computed() {}
};
</script>
