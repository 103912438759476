<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍການຊຳລະຊ້ຳ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;">
              <div class="column">
                <b-field label="ລາຍການຂາຍ"> </b-field>
                <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getAllPaymentReport()">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
              </div>
            </div>
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ລາຍການຊຳລະຊ້ຳ</span>
                </div>  
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="15%">
                          ວັນທີ
                        </th>
                        <th class="has-text-centered " width="10%">
                          POS
                        </th>
                        <th class="has-text-centered " width="5%">
                          ORDERID
                        </th>

                        <th class="has-text-centered " width="10%">
                          ໂຕະ
                        </th>
                        <th class="has-text-centered " width="10%">
                          ເບີໂທ
                        </th>
                        <th class="has-text-right " width="10%">
                          ຈຳນວນ<br>ຊຳລະຊ້ຳ
                        </th>
                        <th class="has-text-right " width="10%">
                          ຈຳນວນເງິນ<br>ຕໍ່ 1 ຄັ້ງ
                        </th>
                        <th class="has-text-centered " width="10%">
                          ລາຍລະອຽດ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in allpayment"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.LASTUPDATE }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.POSNAME }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.ORDERID }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.TABLENAME }}
                        </td>
                        <td class="has-text-centered">
                          {{ data.PHONENO }}
                        </td>
                        <td class="has-text-right">
                          {{ data.COUNTORDER | formatNumberLocal }}
                        </td>
                        <td class="has-text-right">
                          {{ data.TOTAL | formatNumberLocal }}
                        </td>
                        <td class="has-text-centered">
                          <!-- <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="paymentDetail(data)"
                              >ລາຍລະອຽດ
                            </b-button>
                          </b-field> -->
                        </td>
                      </tr>
                      
                    </tbody>
                    <!-- <tfoot>
                      <tr>
                        <th colspan="5" class="has-text-centered">
                          ລວມ {{ allpayment.length | formatNumberLocal }} ລາຍການ
                        </th>
                        <th  class="has-text-right">
                          {{ _.sumBy(allpayment, i => Number(i["TOTAL"])) | formatNumberLocal }}
                        </th>
                        <th colspan="2" class="has-text-right">
                          
                        </th>                 
                      </tr>
                    </tfoot> -->
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <div v-show="isDetailSelected">
                  <div style="margin-top: 28px;">
                      <span style="font-size: 28px;">ລາຍລະອຽດການຂາຍ</span>
                  </div>
                  <div class="paymentdetailselected" >
                    ລະຫັດ: <span class="paymentdetailselectedhightlight"> {{ orderid }}</span>
                  </div>
                  <div class="paymentdetailselected" >
                    ເວລາຊຳລະ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                  </div>
                  <div class="paymentdetailselected">
                    ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                  </div>
                  <div class="paymentdetailselected">
                    ຮູບແບບຊຳລະ: 
                    <span class="">
                    <div v-for="data in selectedPaymentType" :key="data.PAYMENTTYPE">
                      <div style="font-size: 12px;">{{ data.PAYMENTTYPE }}: {{ data.PAYINLAK | formatNumberLocal}}</div>
                  </div>
                  </span>
                  </div>
                  <div style=" float: right;margin-bottom: 5px;">
                    <b-button class="is-small" type="is-info" @click="printReceipt()">ພິມບິນ</b-button>
                  </div>
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-left " width="20%">
                            ສິນຄ້າ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="15%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in allpaymentdetail"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td>
                            {{ data.MENUNAME }}
                          </td>
                          <td class="has-text-right" >
                            {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                          </td>
                          
                          <td class="has-text-right" >
                            {{ data.UNITPRICE | formatNumberLocal }} 
                          </td>
                          <td class="has-text-right" >
                            {{ data.TOTAL | formatNumberLocal }} 
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ allpaymentdetail.length | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right">
                            {{ _.sumBy(allpaymentdetail, i => Number(i["TOTAL"])) | formatNumberLocal }}
                          </th>
                          
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
    <!-- <div class="contain print-only">
      <div  class="column">
        <div v-show="isDetailSelected">
          <div style="margin-top: 25px; text-align: center;" class="print-receipt-titel" > 
            <b-field label="ໃບບິນຊຳລະເງິນ"></b-field> 
          </div>
          <div class="paymentdetailselected print-receipt-titel" >
            ເວລາຊຳລະ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal }}</span>
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ຮູບແບບຊຳລະ:  <span class="paymentdetailselectedhightlight">{{  selectedPaymentType }}</span>
          </div>
          <div style=" float: right;margin-bottom: 5px;" class="no-print">
            <b-button class="is-small" type="is-info" @click="printReceipt()">ພິມບິນ</b-button>
          </div>
          <div>
            <table class="table print-receipt">
              <thead>
                <tr>
                  <th class="has-text-centered " width="5%">#</th>
                  <th class="has-text-left " width="20%">
                    ສິນຄ້າ
                  </th>


                  <th class="has-text-right " width="10%">
                    ລວມ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in allpaymentdetail"
                  :key="data.ID"
                >
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td>
                    {{ data.PRODUCTNAME_SUB }} <br>  ຈຳນວນ: {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }} <br>
                    ລາຄາ: {{ data.UNITPRICE | formatNumberLocal }}
                  </td>
            
                  
                  <td class="has-text-right" >
                    {{ data.TOTAL | formatNumberLocal }} 
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2" class="has-text-centered">
                    ລວມ {{ allpaymentdetail.length | formatNumberLocal }} ລາຍການ
                  </th>
                  <th class="has-text-right">
                    {{ _.sumBy(allpaymentdetail, i => i["TOTAL"]) | formatNumberLocal }}
                  </th>
                  
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        
      </div>
    </div> -->
    <div class="contain print-only">
      <div class="column">
        <!-- <div v-show="isDetailSelected"> -->
          <div
            style="margin-top: 25px; text-align: center;">
              <span class="print-receipt-main-title">ໃບບິນຊຳລະເງິນ</span>
          </div>
          <div style="background-color: red; padding: 0 0 0 0; margin: 0 0 0 0 ;" >
            <img
                class="image-area"
                style="width: 150px;"
                :src="getImgUrl('symbol/logo.jpg')"
                alt="Placeholder image"
              />
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ເວລາຊຳລະ:
            <span class="paymentdetailselectedhightlight">
              {{ selectedPaymentDate }}</span
            >
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ຈຳວນເງິນ:
            <span class="paymentdetailselectedhightlight">{{
              selectedTotal | formatNumberLocal
            }}</span>
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ຮູບແບບຊຳລະ:
            <span class="">
              <div v-for="data in selectedPaymentType" :key="data.PAYMENTTYPE">
                <div style="font-size: 12px;">{{ data.PAYMENTTYPE }}: {{ data.PAYINLAK | formatNumberLocal}}</div>
              </div>
            </span>
          </div>
          <div>
            <table class="table print-receipt">
              <thead>
                <tr>
                  <th class="has-text-centered " width="5%">#</th>
                  <th class="has-text-left " width="20%">
                    ເມນູ
                  </th>

                  <th class="has-text-right " width="10%">
                    ລວມ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in allpaymentdetail" :key="data.ID">
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td>
                    {{ data.MENUNAME }} <br />
                    ຈຳນວນ: {{ data.QUANTITY | formatNumberLocal }}
                    {{ data.UNIT }} <br />
                    ລາຄາ: {{ data.UNITPRICE | formatNumberLocal }}
                  </td>

                  <td class="has-text-right">
                    {{ data.TOTAL | formatNumberLocal }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2" class="has-text-centered">
                    ລວມ {{ allpaymentdetail.length | formatNumberLocal }} ລາຍການ
                  </th>
                  <th class="has-text-right">
                    {{
                      _.sumBy(allpaymentdetail, (i) => Number(i["TOTAL"]))
                        | formatNumberLocal
                    }}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="print-receipt">
            ຂອບໃຈທີ່ມາອຸດໜູນ
          </div>
          <div class="print-receipt">
            ເບີໂທຕິດຕໍ່ 020 52238878
          </div>

        <!-- </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      allpayment: [], 
      allpaymentdetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,
      startdate: null,
      enddate: null,
    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
    paymentDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.orderid = data.ID;
      this.selectedTotal = data.TOTAL;
      
      RestAPI.getPosPaymentDetailReport(data.ID)
        .then(res => {
          this.isDetailSelected = true;
          this.allpaymentdetail = res.data.list;
          this.selectedPaymentType = res.data.paymenttype;
          console.log("res.data : "+JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getAllPaymentReport(){
      RestAPI.getDuplicatePayemntReport(this.posid,this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.allpayment = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getAllPaymentReport();

    console.log("user: " + JSON.stringify(this.user))
    console.log("posid: " + JSON.stringify(this.posid))  
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
