<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດການ ຫົວໜ່ວຍສິນຄ້າ Minimart</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
                <div class="column">
                  <b-tabs v-model="activeTab" v-if="roleid == 2">
                    <b-tab-item label="ຟອມເພີ່ມ/ ແກ້ໄຂຫົວໜ່ວຍສິນຄ້າ">
                      <div class="columns">
                        <div class="column">
                          <h3 class="title">ເພິ່ມ/ ແກ້ໄຂຫົວໜ່ວຍສິນຄ້າ</h3>
                        </div>
                      </div>
                      <div class="columns" v-if="adddataform">
                        <div class="column">
                          <div class="columns">
                            <div class="column">
                              <b-field grouped style=" ">
                                <b-field label="ປະເພດ">
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedType"
                                  label="NAME"
                                  track-by="ID"
                                  :options="typeList"
                                  @input="onTypeSelected()"
                                >
                                </Multiselect>
                              </b-field>
                            </div>
                            <div class="column">
                              <b-field grouped>
                                <b-field label="ຫົວໜ່ວຍສິນຄ້າ">
                                </b-field>
                               
                              </b-field>
                              <b-field label="">
                                <b-input v-model.trim="unit"> </b-input>
                              </b-field>
                            </div>
                          
                          </div>
                          <div class="columns">
                            <div class="column"></div>
                            <div class="column">
                              <b-field label="" style="float: right">
                                <b-field v-if="saveAction">
                                  <button class="button is-info" @click="saveProductUnit">
                                    ບັນທຶກ
                                  </button>
                                </b-field>
                                <b-field v-if="updateAction">
                                  <div class="columns">
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-primary"
                                          @click="updateProductUnit"
                                        >
                                          ແກ້ໄຂ
                                        </button>
                                      </b-field>
                                    </div>
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-info"
                                          @click="openSaveProductUnitForm"
                                        >
                                          ລ້າງຟອມ
                                        </button>
                                      </b-field>
                                    </div>
                                  </div>
                                </b-field>
                              </b-field>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                    </b-tab-item>

                    <b-tab-item label="ຫົວໜ່ວຍສິນຄ້າ ທີ່ເປີດໃຊ້ງານ">
                      <b-field >
                        <button
                          class="button is-primary"
                          @click="backtoProduct"
                        >
                          ໄປໜ້າ ຈັດການສິນຄ້າ
                        </button>
                      </b-field>
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດສິນຄ້າ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍສິນຄ້າ
                            </th>

                            
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in activeProductUnitList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>
                            <td class="has-text-centered">
                              {{ data.TYPENAME }}<br>
                              
                            </td>
                            <td class="has-text-centered">
                              {{ data.UNIT }}<br>
                              
                            </td>
                            
                            <td class="has-text-centered">
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-primary"
                                  size="is-small"
                                  @click="updateForm(data)"
                                  >ແກ້ໄຂ
                                </b-button>
                              </b-field>
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-danger"
                                  size="is-small"
                                  @click="changeItemStatus(data.ID, 0)"
                                  >ປິດໃຊ້ງານ
                                </b-button>
                              </b-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>

                    <b-tab-item label="ຫົວໜ່ວຍສິນຄ້າ ທີ່ຖືກປິດ">
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດສິນຄ້າ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍສິນຄ້າ
                            </th>
                            
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in inActiveProductUnitList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>
                            <td class="has-text-centered">
                              {{ data.TYPENAME }}<br>
                              
                            </td>
                            <td class="has-text-centered">
                              {{ data.UNIT }}<br>
                              
                            </td>
                            <td class="has-text-centered">
                             
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-info"
                                  size="is-small"
                                  @click="changeItemStatus(data.ID, 1)"
                                  >ເປີດໃຊ້ງານ
                                </b-button>
                              </b-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>
                  </b-tabs>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: ["prop_activetab"],
  data() {
    return {
      roleid: null,
      username: null,
      activeTab: this.prop_activetab ? this.prop_activetab : 1,
      activetab: this.prop_activetab ? this.prop_activetab : null,
      saveAction: true,
      updateAction: false,
      adddataform: true,
      updatedataform: false,

      typeid: null,
      oldunit: null,
      unit: null,
      unitid: null,

      activeProductUnitList: [],
      inActiveProductUnitList: [],

      typeList: [],
      selectedType: null,
      
    };
  },
  methods: {
    backtoProduct(){
      this.$router.push({ name: 'settingminimartproduct', params: { prop_activetab: 0 } });
    },
    saveProductUnit(){ 
      
      if (
        this.unit === null ||
        this.selectedType === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            unit: this.unit,
            typeid: this.selectedType.ID,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.saveMinimartProductUnit(params)
            .then((res) => {
              if(res.data==='duplicate unit'){
                this.$swal("ຫົວໜ່ວຍຊ້ຳ, ລອງໃໝ່", "", "error");
                return false;
              }
              this.getProductUnitList();
              this.activeTab = 1
              this.selectedType = null;
              this.unit = null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ເພີ່ມຫົວໜ່ວຍເມູນສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });  

    },
    getProductUnitList(){
      RestAPI.getSettingMinimartProductUnitList()
        .then(res => {
          this.activeProductUnitList = res.data.activeunit;
          this.inActiveProductUnitList = res.data.inactiveunit;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    updateProductUnit(){
      if (
        this.unit === null 
        || this.selectedType === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            typeid : this.selectedType.ID,
            unitid : this.unitid,
            oldunit: this.oldunit,
            unit: this.unit,
            username: this.username,
          };
          console.log("params: " + JSON.stringify(params))
          RestAPI.updateMinimartProductUnit(params)
            .then((res) => {
              // console.log("res: " + JSON.stringify(res.data))
              if(res.data==='duplicate unit'){
                this.$swal("ຫົວໜ່ວຍຊ້ຳ, ລອງໃໝ່", "", "error");
                return false;
              }
              this.getProductUnitList();
              this.activeTab = 1
              this.unit = null;
              this.selectedType= null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ແກ້ໄຂຂໍ້ມູນສິນຄ້າສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    openSaveProductUnitForm(){
      this.unit = null;
    },
    updateForm(data){
      console.log("data: " + JSON.stringify(data))
      this.activeTab = 0;
      this.saveAction = false;
      this.updateAction = true;
      this.selectedType = {
        ID: data.TYPEID,
        NAME: data.TYPENAME,
      };
      this.unitid = data.ID;
      this.oldunit = data.UNIT;
      this.unit = data.UNIT;
     
    },
    changeItemStatus(ID, STATUS){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            table: 'minimart_product_unit',
            itemid : ID,
            status : STATUS,
            username: this.username,
          };
          RestAPI.changeItemStatus(params)
            .then(() => {
              this.getProductUnitList();
              this.activeTab = 1
              this.$swal({
                title: "ສຳເລັດ",
                text: "ປ່ຽນສະຖານະສຳເລັດ",
                icon: "success",
                timer:1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    getProductType(){
      RestAPI.getSettingMinimartProductTypeList()
        .then(res => {
          this.typeList = res.data.activetype;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onTypeSelected(){
      this.menuunit = null;
    },
    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getProductUnitList();
    this.getProductType();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}


</style>
