<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດການສິນຄ້າ</div>
              </div>
            </div>

            
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ສິນຄ້າທັງໝົດ</span>
                </div>  
                <b-field label="ເລືອກປະເພດສິນຄ້າ">
                  <Multiselect
                    placeholder="ເລືອກ"
                    v-model.trim="selectedType"
                    label="NAME"
                    track-by="ID"
                    :options="typeList"
                    @input="onTypeSelectedForMenuList()"
                  >
                  </Multiselect>
                </b-field>
                  <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="60%">
                          ສິນຄ້າ
                        </th>
                       
                        <th class="has-text-centered " width="30%">
                          ຈັດການ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in originalMenuList"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.NAME }}<br>
                          <img
                            data-v-xcxcc
                            :src="getImgUrl(data.PHOTONAME)"
                            width="100"
                            height="100"
                          />
                        </td>
                        <td class="has-text-centered">
                          
                          <b-button v-if="data.MINIMARTID === null"
                            class="btn-group-margin"
                            type="is-success"
                            @click="MenuInOut(data, 1)"
                            ><span style="font-size: 30px;">+ </span>  
                          </b-button>
                          
                        </td>
                        
                      </tr>
                      
                    </tbody>
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <div >
                  <div style="margin-top: 28px;">
                      <span style="font-size: 28px;">ສິນຄ້າທີ່ເລືອກ</span>
                  </div>

                  <b-field label="ເລືອກປະເພດສິນຄ້າ">
                  <Multiselect
                    placeholder="ເລືອກ"
                    v-model.trim="selectedPosType"
                    label="NAME"
                    track-by="ID"
                    :options="postypeList"
                    @input="onTypeSelectedPosMenuList()"
                  >
                  </Multiselect>
                </b-field>
                  
                  <div>
                    <table class="table is-fullwidth is-narrow is-small" >
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="40%">
                            ສິນຄ້ານ
                          </th>
                        
                          <th class="has-text-centered " width="30%">
                            ລາຄາ
                          </th>
                          <th class="has-text-centered " width="15%">
                            ຈັດການ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in originalPosMenuList"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.NAME }}<br>
                            <img
                              data-v-xcxcc
                              :src="getImgUrl(data.PHOTONAME)"
                              width="100"
                              height="100"
                            />
                          </td>
                          <td class="has-text-centered">
                              <input
                                type="number"
                                class="input"
                                v-model="data.UNITPRICE"
                                style=" width: 150px; text-align: right;"
                              />
                              
                              <br>
                              <b-button 
                                class="btn-group-margin"
                                type="is-info"
                                @click="updatePosMenuPrice(data)"
                                ><span >ອັບເດດລາຄາ</span>
                              </b-button>
                          </td>
                          
                          <td class="has-text-centered">
                            <b-button 
                            class="btn-group-margin"
                            type="is-danger"
                            @click="MenuInOut(data, 0)"
                            ><span style="font-size: 30px;">-</span>
                          </b-button>
                            
                          </td>
                          
                        </tr>
                        
                      </tbody>
                    </table>
                  </div>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/minimart/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/minimart/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      allpayment: [],
      allpaymentdetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,

      selectedType: null,
      typeList: [],
      activeMenuList: [],
      originalMenuList: [],

      selectedPosType: null,
      postypeList: [],
      posMenuList: [],
      originalPosMenuList: [],

      isUnitPrice: false,

    };
  },
  methods: {
    getMenuList(){
      RestAPI.getMinimartProductList(this.posid)
        .then(res => {
          this.originalMenuList = res.data.product;
          console.log("res.data: " + JSON.stringify(this.originalMenuList))

          this.originalPosMenuList  = res.data.minimartproduct;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    onTypeSelectedForMenuList() {
      RestAPI.getMinimartProductListbyType(this.posid, this.selectedType.ID)
        .then(res => {
          console.log("res.data: " + JSON.stringify(res.data))
          this.originalMenuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },


    onTypeSelectedPosMenuList() {
      RestAPI.getMinimartProductUsageListBytype(this.posid, this.selectedPosType.ID)
        .then(res => {
          this.originalPosMenuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getMenuType(){
      RestAPI.getSettingMinimartProductType()
        .then(res => {
          this.typeList = res.data;
          this.postypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    MenuInOut(data, type){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            posid: this.posid,
            menuid : data.ID,
            masterid: data.MASTERPRODUCTID,
            type : type,
            username: this.username,
          };
          RestAPI.minimartProductInOut(params)
            .then(() => {
              this.getMenuList();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ດຳເນີນການ ສຳເລັດ",
                icon: "success",
                timer:1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },

    // updatePosMenuPrice(data){
    //   if(data.UNITPRICE !=0 && this.isUnitPrice ==true ){
    //     this.updatePosMenuPriceDetail(data)
    //   }else{
    //     this.isUnitPrice = true;
    //   }

    //   console.log("UNITPRICE: " + data.UNITPRICE)
    //   console.log("isUnitPrice: " + this.isUnitPrice)
      
      
    // },
    updatePosMenuPrice(data){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            posid: this.posid,
            menuid : data.ID,
            price: data.UNITPRICE,
            username: this.username,
          };
          RestAPI.updateMinimartProductPrice(params)
            .then(() => {
              this.getMenuList();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ດຳເນີນການ ສຳເລັດ",
                icon: "success",
                timer:1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.getMenuList();
    this.getMenuType();
  
  },
  computed: {
    
  },
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
