<template>
  <section>
    <div class="contain no-print" >
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ປະຫວັດ ລາຄາຊື້ສິນຄ້າເຂົ້າ (Minimart)</div>
              </div>
            </div>

            
            <div class="columns" >
              <div class="column"  style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px; min-height: 500px;">  
               
                <div class="columns"> 
                  <div style="">
                      <span style="font-size: 28px;">ລາຍການສິນຄ້າ</span>
                  </div>
                 
                  <div class="column" style="text-align: right;">
                    <b-field>
                      <b-field>
                        <Multiselect
                          placeholder="ປະເພດສິນຄ້າ"
                          v-model="selectedProductType"
                          label="NAME"
                          track-by="ID"
                          :options="productTypeList"
                        >
                        </Multiselect>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">
                        <Multiselect
                          placeholder="ເລືອກຮ້ານ"
                          v-model="selectedPos"
                          label="NAME"
                          track-by="ID"
                          :options="posList"
                        >
                        </Multiselect>
                      </b-field>
                      
                      <b-field style="padding-left: 20px; padding-top: 2px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getProductHistory()">ດຶງລາຍງານ</b-button>
                      </b-field>
                    </b-field>
                      
                  </div>
                  
                  
                </div> 
                  <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="10%">ປະເພດ</th>
                        <th class="has-text-left " width="40%">
                          ສິນຄ້າ
                        </th>
                        <th class="has-text-centered " width="10%">
                          ລາຄາປະຈຸບັນ
                        </th>

                        <th class="has-text-centered " width="10%">
                          ລາຍລະອຽດ
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in productList"
                        :key="data.PRODUCTID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-left">
                          {{ data.PRODUCTTYPE }}
                           
                        </td>
                        <td class="has-text-left">
                          {{ data.PRODUCTNAME }} <br>
                            
                        </td>
                        <td class="has-text-centered">
                          {{ data.NEWPRICE | formatNumberLocal}} <br>
                            
                        </td>
                        <td class="has-text-centered">
                          <b-button 
                              type="is-info"
                              expanded
                              style="width: 100%;"
                              @click="requestDetail(data.PRODUCTID, data.PRODUCTNAME, data.POSID)"
                              >ລາຍລະອຽດ
                          </b-button>
                            
                        </td>
                      </tr>
                      
                    </tbody>
                   
                  </table>
              </div>

              <div class="column"  style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div >
                  <div>
                    <span style="font-size: 28px;">ລາຍລະອຽດ - {{ selectedProduct }}</span>
                  </div>
                  <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="30%">
                          ວັນ ແລະ ເວລາ
                        </th>
                        <th class="has-text-right " width="20%">
                          ລາຄາເກົ່າ
                        </th>

                        <th class="has-text-right " width="20%">
                          ລາຄາໃໝ່
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in detail"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.LASTUPDATE }}
                           
                        </td>
                        <td class="has-text-right">
                          {{ data.OLDPRICE | formatNumberLocal }} <br>
                            
                        </td>
                        <td class="has-text-right">
                          {{ data.NEWPRICE | formatNumberLocal}} <br>
                            
                        </td>
                        
                      </tr>
                      
                    </tbody>
                   
                  </table>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
    
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      selectedProductType: null,
      productTypeList: [],
      productList: [],
      selectedPos: null,
      posList: [],    
      detail: [],
      selectedProduct: null,


    };
  },
  methods: {
    
   
    getProductType(){
      RestAPI.getMinimartProductType()
        .then(res => {
          this.productTypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getProductHistory(){
      this.getPosProductBuyPricehistoryByType(this.selectedProductType.ID, this.selectedPos.ID);
    },

    getPosProductBuyPricehistoryByType(typeid, posid){
      // console.log("typeid: "+ typeid);
      RestAPI.getMinimartProductBuyPricehistoryByType(typeid, posid)
        .then(res => {
          console.log("res: "+ JSON.stringify(res.data));
          this.productList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    

    requestDetail(productid, productname, pos){
      this.selectedProduct = productname;
      RestAPI.getMinimartProductBuyPricehistoryDetail(productid, pos)  
        .then(res => {
          this.detail= res.data;
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getPosList(){
      RestAPI.getMinimartList()
        .then(res => {
          this.posList = res.data;
          // console.log("posList: "+ JSON.stringify(this.posList));
          // this.posList.push({ ID: "0", NAME: "ທັງໝົດ" });
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    
  },
  mounted() {
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.getProductType();
    this.getPosList();
   
    
    
  },
  computed: {},
};
</script>

<style scoped>

.detailtitle{
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    /* size: 6in 9in;
    size: portrait; */
  }
}

</style>
