/* eslint-disable vue/no-unused-components */
<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <h1 class="title has-text-centered">
            Organic Agriculture Production Plan
          </h1>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ຊື່ຟາມ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="farmname" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label"
                  >ຂະໜາດຟາມ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="farmsize" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ເຂດກະສິກຳ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="agrizone" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label"
                  >ແຂວງ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="province" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ເມືອງ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="district" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label"
                  >ບ້ານ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="village" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ປະເທດ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="country" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label">ລະຫັດໄປສະນີ :</label>
                <div class="control">
                  <input type="text" class="input" v-model="postcode" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ຊື່ຜູ້ຕິດຕໍ່ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="name" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label"
                  >ນາມສະກຸນ <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="surname" />
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-4 is-offset-2" >
              <div class="field">
                <label class="label"
                  >ເບີໂທຕິດຕໍ່ 1 <span class="required-star">*</span>:</label
                >
                <div class="control">
                  <input type="text" class="input" v-model="phonenumber1" />
                </div>
              </div>
            </div>
            <div class="column is-4">
              <div class="field">
                <label class="label">ເບີໂທຕິດຕໍ່ 2:</label>
                <div class="control">
                  <input type="text" class="input" v-model="phonenumber2" />
                </div>
              </div>
            </div>
          </div>

          
          <div class="columns">
            <div class="column is-4 is-offset-2">
              <div class="field">
                <div class="control">
                  <button
                    class="button is-primary is-fullwidth"
                    @click="doRegister"
                  >
                    ລົງທະບຽນ
                  </button>
                </div>
              </div>
            </div>

            <div class="column is-4">
              
              <div class="field">
                <div class="control">
                  <button
                    class="button is-info is-fullwidth"
                    @click="doLogin"
                  >
                    ເຂົ້າສູ່ລະບົບ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import restAPI from "./../RestAPI";

export default {
  name: "Login",
  data() {
    return {
      farmname: "",
      farmsize: "",
      agrizone: "",
      village: "",
      district: "",
      province: "",
      country: "",
      postcode: "",
      name: "",
      surname: "",
      phonenumber1: "",
      phonenumber2: "",
      errorMessage: null,
      role: null
    };
  },
  methods: {
    doRegister() {
      const params = {
        farmname: this.farmname,
        farmsize: this.farmsize,
        agrizone: this.agrizone,
        village: this.village,
        district: this.district,
        province: this.province,
        country: this.country,
        postcode: this.postcode,
        name: this.name,
        surname: this.surname,
        phonenumber1: this.phonenumber1,
        phonenumber2: this.phonenumber2
      };

      // console.log(params)

      restAPI
        .register(params)
        .then(res => {
          if (res.data == "duplicate phonenumber") {
            this.$swal(
              "ເບີໂທ " + this.phonenumber1 +" ມີການລົງທະບຽນແລ້ວ, ກະລຸນາປ່ຽນເບີໂທ",
              "-",
              "error"
            );
          }else if(res.data == "registered"){
            this.$swal("ການລົງທະບຽນສຳເລັດ, ພະນັກງານຈະຕິດຕໍ່ກັບໄປໂດຍໄວ!", "", "success");
            this.$router.replace("/login");
          }
          
        })
        .catch(() => {
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    doLogin() {
      this.$router.replace("/login");
    }
  }
};
</script>

<style scoped>
#login-box {
  padding: 20px;
  margin-top: 100px;
}
</style>
