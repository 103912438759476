<template>
  <section>
    <div class="contain no-print" v-if="customertable" >
      <div class="columns" style="margin-top: 20px;"> 
        <b-tabs v-model="activeTab">
          <b-tab-item :label="formattedMenuTitle"> 
            <div
              class="scrollme"
              style="background-color: #f2f4f8; padding: 20px;" 
            
              >
              <div class="columns" >
                <div class="column" style="padding: 5px ;">
                  <div
                    class="very-important-title has-text-centered"
                    style=" margin: 0px 20px 0px 20px ;"
                  >
                    {{posname}} - {{ tablename }} <span style="color: red;" v-if="originalTotalMenuOrder > 0 && username =='customer'">(ກຳລັງໃຫ້ບໍລິການລູກຄ້າທ່ານອື່ນ)</span>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="horizontal-scroll-container" style="display: flex; overflow-x: auto; white-space: nowrap;">

                  <div
                    v-for="(data, index) in posList"
                    :key="index"
                    class="column" 
                    style="display: inline-block; vertical-align: top; padding: 0 0 0 0; margin: 0 0 0 0"
                  >
                    <div>
                      <div class="card-content-table">
                        <div class="content" style="border-radius: 10px; background-color: rgb(207, 202, 202); max-width: 80px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                          <div @click="selectedTable(0, data.POSID, data)">
                            <img
                              class="image-area"
                              style="width: 80px; margin: auto; display: block;"
                              :src="getPosImgUrl('pos.jpg')"
                            />
                            
                          </div>
                          <div style="font-size: 12px;">
                            {{ data.POSNAME }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="columns" style="margin-top:5px; margin-left:5px; font-weight: bold;">
                  ຮ້ານທີ່ເລືອກ: {{ selectedposname }}
                </div>
              <div class="columns">
                <div class="column" style="min-height: 1000px; ">
                  <div class="columns">
                    

                    <div class="column" style="text-align: right;">
                      <Multiselect
                        placeholder="ປະເພດເມນູ"
                        v-model="selectedType"
                        label="NAME"
                        track-by="ID"
                        :options="typeList"
                        @input="onTypeSelectedForMenuList"
                      >
                      </Multiselect>
                    </div>
                    
                  </div>
                  

                  <!-- <div class="columns is-multiline"> -->
                  <div class="columns is-multiline is-mobile " style="overflow: auto; max-height: 600px;">
                    <div
                      v-for="(menu, index) in filteredMenuList"
                      :key="index"
                      class="column is-one-third"  style="padding: 3px; "
                      
                    >
                      <div :class="[menu.NUMBEROFMENU <= 0 ? 'runout' : 'normal']" style="padding: 0px 0px 0px 0px; border-radius: 10px;"> 
                        <b-card  class="card" name="card" > 
                          <div class="card-image" >
                            <!-- <figure class="image is-16by9"> -->
                            <figure class="image" >
                                <img v-if="menu.NUMBEROFMENU <=0"
                              
                                  style=" border-radius: 10px; "
                                  :src="getImgUrl(menu.PHOTONAME)"
                                />
                                <img v-else-if="username=='customer' && posid == selectedposid"
                                
                                  @click="addMenuCheckPhoneno(menu)"
                                  style=" border-radius: 10px;"
                                  :src="getImgUrl(menu.PHOTONAME)"
                                />
                                <img v-else-if="username=='customer' && posid != selectedposid"
                                
                                  @click="addMenuCheckPhonenoFromOtherPos(menu)"
                                  style=" border-radius: 10px;"
                                  :src="getImgUrl(menu.PHOTONAME)"
                                />
                                <img v-else-if="username !='customer' && posid != selectedposid"
                                
                                  @click="addMenuFromOtherPos(menu)"
                                  style=" border-radius: 10px;"
                                  :src="getImgUrl(menu.PHOTONAME)"
                                />
                                <img v-else-if="username !='customer'"
                                
                                  @click="addMenu(menu)"
                                  style=" border-radius: 10px;"
                                  :src="getImgUrl(menu.PHOTONAME)"
                                />
                              </figure>
                          </div>
                          <div class="card-content-menu" style=" margin-top: 5px; ">
                            <div class="content">
                       
                                <div style="font-size: 14px; font-weight: bold;"> 
                                  {{ menu.NAME }}
                                </div>
                                <div style="color: red">
                                  {{ menu.UNITPRICE | formatNumberLocal}} 
                                </div>
                                <!-- <div>
                                  ({{ menu.NUMBEROFMENU | formatNumberLocal}}) 
                                </div> -->
                              </div>
                          </div>
                        </b-card>
                      </div>
                    
                      
                    </div>
                  </div>
                </div>
                

              </div>
              
            </div>
          </b-tab-item>
          <b-tab-item :label="'ຕິດຕາມອາຫານ ' + TotalMenuOrder + ''"  v-if="username !='customer'"> 
            <div class="fullscreen-container" v-if=" username !='customer'" style="margin-bottom: 50px;">    
              <div class="columns " style="padding: 5px ; ">
                <div class="column">
                  <div
                    class="very-important-title has-text-centered"
                    style=" margin: -10px 20px -20px 20px ;  width: 100%;"
                  >
                    ລາຍການອາຫານທີ່ສັ່ງ
                  </div>
                </div>
              </div>
              
              <div class="columns" v-if="menuInWaitOrderList.length > 0" style="border-bottom: 2px solid #000; padding-bottom: 10px;">
                <div class="column"> 
                  <div style="font-size: 20px; font-weight: bold;">
                    ລໍຖ້າຊຳລະເງິນ
                  </div>
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="1%">#</th>
                        <th class="has-text-centered " width="45%">
                          ເມນູ
                        </th>
                        <th class="has-text-right " width="55%">
                          ລາຍລະອຽດ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuInWaitOrderList"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">
                          {{ index + 1 }}<br>
                          <b-button v-if="data.DETAILSTATUS ==0"
                            class="btn-group-margin"  
                            type="is-danger"
                            size="is-small"
                            @click="removeMenu(data)"
                            >X
                          </b-button>
                          
                        </td>
                        <td style="text-align: center;" >
                          OrderID: {{ data.ORDERID }}
                          <br>
                          <img 
                            style=" width: 100px; "
                            :src="getImgUrl(data.PHOTONAME)"
                          /><br />
                          {{ data.MENUNAME }} <br>
                          
                        </td>
                        <td class="has-text-right " >
                          <div style="display: flex; flex-direction: column; align-items: flex-end;">
                            <b-field>
                              {{ data.UNITPRICE | formatNumberLocal }}
                            </b-field>
                            <b-field style="margin-top: -10px; margin-bottom: 0px;">
                              <b-field style="margin-top: 15px;">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #FF2B56; color: white; margin: 0px 0px 10px 0px; padding: 5px 18px; border-radius: 5px; cursor: pointer;"
                                  @click="decreaseQuantity(data)"
                                >-</span>
                              </b-field>
                              <b-field style="margin: 15px 5px 5px 5px;">
                                <span style="font-size: 18px; font-weight: bold;">{{ data.QUANTITY }}</span>
                                {{ data.MENUNIT }}
                              </b-field>
                              <b-field style="margin-top: 15px;">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #22C65B; color: white; margin: 0px 0px 10px 0px; padding: 5px 15px; border-radius: 5px; cursor: pointer;"
                                  @click="increaseQuantity(data)"
                                >+</span>
                              </b-field>
                            </b-field>

                            <!-- Second Block -->
                            <b-field style="margin-top: 0px; margin-bottom: 0px;">
                              <span style="font-weight: bold; font-size: 20px;">
                                {{
                                  (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                                }}
                              </span>
                            </b-field>
                          </div>
                                                   
                        </td>
    

                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມ
                          {{ menuInWaitOrderList.length | formatNumberLocal }} ລາຍການ:  
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            <!-- {{
                            _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY) | formatNumberLocal
                            }} -->
                            {{ calculateTotal(menuInWaitOrderList) | formatNumberLocal }}   
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateDiscountedTotal(menuInWaitOrderList) > 0">
                        <th colspan="2" class="has-text-right set-border">
                          ສ່ວນລຸດ ({{ getDiscount(menuInWaitOrderList) }}%):
                        </th>
                        <th class="has-text-right set-border">
                          <!-- {{ _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)- ( _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)*discount/100) | formatNumberLocal }}  -->
                          -{{ calculateDiscountedTotal(menuInWaitOrderList) | formatNumberLocal }}
                          
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateDiscountedTotal(menuInWaitOrderList) > 0">
                        <th colspan="2" class="has-text-right set-border">
                          ຫຼັງຫັດສ່ວນລຸດ:
                        </th>
                        <th class="has-text-right set-border">
                          <!-- {{ _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)- ( _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)*discount/100) | formatNumberLocal }}  -->
                          {{ calculateTotal(menuInWaitOrderList)-calculateDiscountedTotal(menuInWaitOrderList) | formatNumberLocal }}
                          
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateVatTotal(menuInWaitOrderList) > 0">  
                        <th colspan="2" class="has-text-right set-border">
                          ອມພ ({{ getVat(menuInWaitOrderList) }}%):
                          
                        </th>
                        <th class="has-text-right set-border">
                          +{{ calculateVatTotal(menuInWaitOrderList) | formatNumberLocal }}
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມທັງໝົດ:
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            {{ calculateFinalTotal(menuInWaitOrderList) | formatNumberLocal }}
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>

                      <tr >
                        <th colspan="3" class="has-text-right set-border">
                          <b-button
                            class="btn-group-margin"
                            type="is-info"
                            @click="doPayment(menuInWaitOrderList)"
                            >ຊຳລະເງິນ
                          </b-button>
                        </th>
                        
                      </tr>

                      


                      
                    
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="columns" v-if="menuInWaitOrderFromOtherPosList.length > 0" style="border-bottom: 2px solid #000; padding-bottom: 10px;">
                <div class="column"> 
                  <div style="font-size: 20px; font-weight: bold;">
                    ລໍຖ້າຊຳລະເງິນ (ຮ້ານອື່ນ)
                  </div>
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="1%">#</th>
                        <th class="has-text-centered " width="45%">
                          ເມນູ
                        </th>
                        <th class="has-text-right " width="55%">
                          ລາຍລະອຽດ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuInWaitOrderFromOtherPosList"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">
                          {{ index + 1 }}<br>
                          <b-button v-if="data.DETAILSTATUS ==0"
                            class="btn-group-margin"  
                            type="is-danger"
                            size="is-small"
                            @click="removeMenu(data)"
                            >X
                          </b-button>
                          
                        </td>
                        <td style="text-align: center;" >
                          OrderID: {{ data.ORDERID }}
                          <br>
                          <img 
                            style=" width: 100px; "
                            :src="getImgUrl(data.PHOTONAME)"  
                          /><br />
                          {{ data.MENUNAME }} <br>
                          
                        </td>
                        <td class="has-text-right " >
                          <div style="display: flex; flex-direction: column; align-items: flex-end;">
                            <b-field>
                              {{ data.UNITPRICE | formatNumberLocal }}
                            </b-field>
                            <b-field style="margin-top: -10px; margin-bottom: 0px;">
                              <b-field style="margin-top: 15px;">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #FF2B56; color: white; margin: 0px 0px 10px 0px; padding: 5px 18px; border-radius: 5px; cursor: pointer;"
                                  @click="decreaseQuantity(data)"
                                >-</span>
                              </b-field>
                              <b-field style="margin: 15px 5px 5px 5px;">
                                <span style="font-size: 18px; font-weight: bold;">{{ data.QUANTITY }}</span>
                                {{ data.MENUNIT }}
                              </b-field>
                              <b-field style="margin-top: 15px;">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #22C65B; color: white; margin: 0px 0px 10px 0px; padding: 5px 15px; border-radius: 5px; cursor: pointer;"
                                  @click="increaseQuantity(data)"
                                >+</span>
                              </b-field>
                            </b-field>

                            <!-- Second Block -->
                            <b-field style="margin-top: 0px; margin-bottom: 0px;">
                              <span style="font-weight: bold; font-size: 20px;">
                                {{
                                  (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                                }}
                              </span>
                            </b-field>
                          </div>
                                                   
                        </td>
    

                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມ
                          {{ menuInWaitOrderFromOtherPosList.length | formatNumberLocal }} ລາຍການ:  
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            <!-- {{
                            _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY) | formatNumberLocal
                            }} -->
                            {{ calculateTotal(menuInWaitOrderFromOtherPosList) | formatNumberLocal }}   
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateDiscountedTotal(menuInWaitOrderFromOtherPosList) > 0">
                        <th colspan="2" class="has-text-right set-border">
                          ສ່ວນລຸດ ({{ getDiscount(menuInWaitOrderFromOtherPosList) }}%):
                        </th>
                        <th class="has-text-right set-border">
                          <!-- {{ _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)- ( _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)*discount/100) | formatNumberLocal }}  -->
                          -{{ calculateDiscountedTotal(menuInWaitOrderFromOtherPosList) | formatNumberLocal }}
                          
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateDiscountedTotal(menuInWaitOrderFromOtherPosList) > 0">
                        <th colspan="2" class="has-text-right set-border">
                          ຫຼັງຫັດສ່ວນລຸດ:
                        </th>
                        <th class="has-text-right set-border">
                          <!-- {{ _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)- ( _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY)*discount/100) | formatNumberLocal }}  -->
                          {{ calculateTotal(menuInWaitOrderFromOtherPosList)-calculateDiscountedTotal(menuInWaitOrderFromOtherPosList) | formatNumberLocal }}
                          
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr v-if="calculateVatTotal(menuInWaitOrderFromOtherPosList) > 0">  
                        <th colspan="2" class="has-text-right set-border">
                          ອມພ ({{ getVat(menuInWaitOrderFromOtherPosList) }}%):
                          
                        </th>
                        <th class="has-text-right set-border">
                          +{{ calculateVatTotal(menuInWaitOrderFromOtherPosList) | formatNumberLocal }}
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມທັງໝົດ:
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            {{ calculateFinalTotal(menuInWaitOrderFromOtherPosList) | formatNumberLocal }}
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>
                      </tr>

                      <tr >
                        <th colspan="3" class="has-text-right set-border">
                          <b-button
                            class="btn-group-margin"
                            type="is-info"
                            @click="doPayment(menuInWaitOrderFromOtherPosList)"
                            >ຊຳລະເງິນ
                          </b-button>
                        </th>
                        
                      </tr>

                      


                      
                    
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="columns" v-if="menuWaitPaymentCallBackList.length > 0">
                <div class="column"> 
                  <div style="font-size: 20px; font-weight: bold;">
                    ລໍຖ້າຢືນຢັນການຊຳລະ
                  </div>
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="1%">#</th>
                        <th class="has-text-centered " width="45%">
                          ເມນູ
                        </th>
                        <th class="has-text-right " width="55%">
                          ລາຍລະອຽດ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuWaitPaymentCallBackList"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">
                          {{ index + 1 }}
                          
                        </td>
                        <td style="text-align: center;" >
                          OrderID: {{ data.ORDERID }}
                          <br>
                          <img 
                            style=" width: 100px; "
                            :src="getImgUrl(data.PHOTONAME)"
                          /><br />
                          {{ data.MENUNAME }} <br>
                         
                        </td>
                        <td class="has-text-right " >
                          {{ data.UNITPRICE | formatNumberLocal }}<br>
                          x {{ data.QUANTITY }}
                         {{ data.MENUNIT }}<br>
                          <span style="font-weight: bold; font-size: 20px;">
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </span>
                          
                        </td>
    

                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມ
                          {{ menuWaitPaymentCallBackList.length | formatNumberLocal }} ລາຍການ:  
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            <!-- {{
                            _.sumBy(menuInWaitOrderList, (i) => i.UNITPRICE * i.QUANTITY) | formatNumberLocal
                            }} -->
                            {{ calculateTotal(menuWaitPaymentCallBackList) | formatNumberLocal }}   
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>  
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="columns" v-if="menuWaitPaymentCallBackFromOtherPosList.length > 0">  
                <div class="column"> 
                  <div style="font-size: 20px; font-weight: bold;">
                    ລໍຖ້າຢືນຢັນການຊຳລະ (ຮ້ານອື່ນ)
                  </div>
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="1%">#</th>
                        <th class="has-text-centered " width="45%">
                          ເມນູ
                        </th>
                        <th class="has-text-right " width="55%">
                          ລາຍລະອຽດ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuWaitPaymentCallBackFromOtherPosList"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">
                          {{ index + 1 }}
                          
                        </td>
                        <td style="text-align: center;" >
                          OrderID: {{ data.ORDERID }}
                          <br>
                          <img 
                            style=" width: 100px; "
                            :src="getImgUrl(data.PHOTONAME)"
                          /><br />
                          {{ data.MENUNAME }} <br>
                         
                        </td>
                        <td class="has-text-right " >
                          {{ data.UNITPRICE | formatNumberLocal }}<br>
                          x {{ data.QUANTITY }}
                         {{ data.MENUNIT }}<br>
                          <span style="font-weight: bold; font-size: 20px;">
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </span>
                          
                        </td>
    

                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <th colspan="2" class="has-text-right set-border">
                          ລວມ
                          {{ menuWaitPaymentCallBackFromOtherPosList.length | formatNumberLocal }} ລາຍການ:  
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            {{ calculateTotal(menuWaitPaymentCallBackFromOtherPosList) | formatNumberLocal }}   
                          </span>
                          
                        </th>
                        <td class="has-text-right set-border"></td>  
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div class="columns" v-if="menuInOrderList.length > 0">
                <div class="column">
                  <div style="font-size: 20px; font-weight: bold;">
                     ລາຍການຜ່ານມາ
                  </div>
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-left " width="50%"> 
                          ລາຍການ
                        </th>
                        <th class="has-text-right " width="50%">
                          ລາຍລະອຽດ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data) in menuInOrderList"
                        :key="data.DETAILID"
                      >
                        <td style="text-align: left;" >
                         ລະຫັດ: {{ data.ORDERID }}<br>
                          <div v-if="data.TOTAL >0">ລວມ: {{ data.TOTAL | formatNumberLocal }}</div>   
                          <div v-if="data.DISCOUNT >0">ສ່ວນລຸດ {{ data.DISCOUNT }} %: {{ data.DISCOUNTAMOUNT | formatNumberLocal }}</div>
                          <div v-if="data.VAT >0">ອມພ {{ data.VAT }} %: {{ data.VATAMOUNT | formatNumberLocal }}</div>
                          <div v-if="data.TOTALPAID >0">ຈ່າຍທັງໝົດ:  <span style="font-weight: bold; color: red; font-size: 18px;">{{ data.TOTALPAID | formatNumberLocal }}</span></div>  
                        
                          
                        </td>
                        <td class="has-text-right " >
                          <div v-for="(data2) in menuInOrderDetailList" :key="data2.DETAILID">
                            <div v-if="data2.ORDERID == data.ORDERID">
                              <span style="font-weight: bold; font-size: 16px;">{{data2.MENUNAME}}</span><br>
                              <span style="color: red">({{ data2.DETAILSTATUSTITLE }})</span>
                              <br> {{ data2.UNITPRICE | formatNumberLocal }}<br>
                                x {{ data2.QUANTITY }}
                              {{ data2.MENUNIT }}<br>
                                <span style="font-weight: bold; font-size: 16px;">
                                  {{
                                    (data2.UNITPRICE * data2.QUANTITY) | formatNumberLocal
                                  }} 
                                </span>
                            </div>
                          </div>
                         
                          
                        </td>
    

                      </tr>
                    </tbody>
                    
                  </table>
                </div>
              </div>
              
            </div>
            
          </b-tab-item>

          <b-tab-item :label="'ແຈ້ງເຕືອນ ' + notishow + ''" v-if="username !='customer'"> 
            <div class="fullscreen-container">
              <div class="columns " style="padding: 0px ; ">
                <div class="column" style="background-color: white;"> 
                  <div style="margin-bottom: 5px;">
                    

                      <b-field>
                        
                        <b-field style="font-size: 20px;">
                          ແຈ້ງເຕືອນ
                        </b-field>
                 

                        
                      </b-field>
                  </div>  
                  <table class="table is-fullwidth is-narrow is-small" >
                      <thead>
                        <tr>
                          <th class="has-text-left " width="100%">
                            ລາຍລະອຽດ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in notilist"
                          :key="data.ID"
                        >
                          <td class="has-text-left">
                            <b-field >
                              <b-field>
                                {{ index + 1 }}. {{ data.TXTIME }} 
                              </b-field>
                              <b-field>
                                <b-field v-if="data.TURNOFF==0">
                                    <b-button
                                      class="btn-group-margin"
                                      type="is-danger"
                                      size="is-small"
                                      @click="turnnoti(data,1)"
                                      >ຮູ້ແລ້ວ
                                    </b-button>
                                  </b-field>
                              </b-field>
                            </b-field>
                            <b-field style="margin-top: -20px;">
                              {{ data.DETAIL }}
                            </b-field>
                            
                          </td>
                        
                        </tr>
                      </tbody>
                      
                    </table>
                </div>
              </div>
            </div>
            
          </b-tab-item>

        </b-tabs>

        
      </div>

      
    </div>
    <div v-if="isLoading" class="loading-modal">
      <div class="modal-content">
        <p class="modal-title">ສະແກນ Lapnet QR Code ເພື່ອຊຳລະ</p>
        <img :src="qrCodeUrl" alt="QR Code" class="qr-code-image" />
        <div class="modal-content2">
          <div >ຮ້ານ: {{ posname }}</div><br>  
          <div >ໂຕະ: {{ tablename }}</div><br>
          <div >ຈຳນວນເງິນ: <span  style="font-weight: bold;">{{ qrtotal | formatNumberLocal }} ກີບ</span></div>
        </div>
      </div>
      
    </div>

    <div v-if="isSuccessPayment" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 80px;"/></p>
        <p class="modal-title">ຊຳລະເງິນ ສຳເລັດແລ້ວ</p>  
        <p style="font-size: 20px;" >ຮັບອໍເດີ້ແລ້ວ, ກຳລັງດຳເນີນການ</p>
        <b-button
          style="float: right; background-color: whitesmoke;"
          class="btn-group-margin"
           
          @click="hideSuccessModal()" 
          > <span style=" font-family: Phetsarath OT !important !important;">ປິດ</span>
        </b-button> 
          
      </div>  
        
    </div>

    <div v-if="isShowSuccessAddMenu" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 50px;"/></p>
        <p class="modal-title">ເພີ່ມເມນູສຳເລັດ</p>     
      </div>
      
    </div>

    <div v-if="isShowSuccessLogin" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 50px;"/></p>
        <p class="modal-title">ຍິນດີຕ້ອນຮັບ! ກະລຸນາເລືອກເມູນ ທີ່ຕ້ອງການ</p>       
      </div>
      
    </div>

    <div v-if="isNoti" class="loading-modal">
      <div class="modal-content">
        <p class="modal-title">ຂໍ້ຄວາມແຈ້ງ</p>  
        <p style="font-size: 20px;">ໄດ້ຮັບອໍເດີ້ແລ້ວ, ກຳລັງດຳເນີນການ</p>
        <b-button
          style="float: right; background-color: whitesmoke;"
          class="btn-group-margin"
           
          @click="hideNotiModal()" 
          > <span style=" font-family: Phetsarath OT !important !important;">ປິດ</span>
        </b-button> 
          
      </div>
      
    </div>

    <div class="popup-container" v-if="showPopup" >
      <div class="popup" >
        <div class="popup-content" >ສະບາຍດີ ຍິນດີຕ້ອນຮັບ</div> 
        <div   class="popup-buttons" >
          <button @click="closePopup()">ເລີ່ມສັ່ງອາຫານ</button>
        </div>
      </div>
    </div>

    <!-- <div v-if="isInService" class="loading-modal">
      <div class="modal-content">
        <p class="modal-title">ຂໍ້ຄວາມແຈ້ງ</p>  
        <p style="font-size: 20px;">ໂຕນີ້ ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່!</p>
        <b-button
          style="float: right; background-color: whitesmoke;"
          class="btn-group-margin"
           
          @click="()" 
          > <span style=" font-family: Phetsarath OT !important !important;">ປິດ</span>
        </b-button> 
          
      </div>
      
    </div> -->
  </section>
</template>


<script>
// import { sortBy } from "lodash";
// import { faJedi } from "@fortawesome/free-solid-svg-icons";
import RestAPI from "../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";
// import VueJwtDecode from "vue-jwt-decode";


export default {
  components: { Multiselect },
  props: [""],
  data() {
    return {
      products: [],
      sellerid: null,

      posid: null,
      posname: null,
  
      
      total: 0,
      finaltotal: 0,
      qrtotal: 0,
      allMenuInOrder: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle: "ເມນູ", 
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      selectedPaymentID: null,
      selectedPaymentApproved: null,
      selectedTotal: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,

      istablemenu: true,
      ismenu: false,
      ispayment: false,
      isreserve: false,
      tables: [],
      selectedtable: null,
      tablename: null,
      servedby: null,
      tableid: null,
      orderid: null,
      uuid: null,
      menuList: [],
      originalmenuList: [],
      menuInWaitOrderList: [],
      menuInWaitOrderFromOtherPosList: [],
      menuWaitPaymentCallBackList: [],
      menuWaitPaymentCallBackFromOtherPosList: [],
      menuInOrderList: [],
      menuInOrderDetailList: [],
      menuInOrderFromOtherPosList: [],
      countmenu: 0,
      rejectList: [],
      countrejectmenu: 0,
      countunservemenu: 0,
      countunconfirmmenu: 0,
      paymentTypeList: [
        { ID: 1, NAME: 'ເງິນສົດ' },
        { ID: 2, NAME: 'ເງິນໂອນ'  },
        { ID: 3, NAME: 'ຄູປ໋ອງ'  }
      ],
      selectedPaymentType: null,
      currencyList: [],
      selectedCurrency: null,
      xrate: null,
      inlak: 0,
      payamount: 0,
      paymentItem: [],
      totalPayInLAK: 0,
      orderStatus: null,
      showCouponCodeInput: false,
      couponcode: null,
      possellerid: null,
      loadingData: null,
      selectedCurrencyTitle: null,
      sourceAmount: 0,
      returnInLAK: 0,
      payable: false,
      checkbillstatus: 0,
      TABLEIDFROMNOTI: this.PROPS_TABLEIDFROMNOTI ? this.PROPS_TABLEIDFROMNOTI : '',

      selectedType: null,
      typeList: [],

      vat: null,
      vatamount: null,
      discount: null,
      discountamount: null,
      main_posname: null,
      customertable: false,
      activeTab: 0,
      TotalMenuOrder: 0,
      originalTotalMenuOrder: 0,
      tablePhoneno: null,

      selectedposname: null,
      selectedposid: null,

      phoneNumber: null,

      qrData: null,

      isLoading: false,
      isSuccessPayment: false,
      menulisttitle: "&nbsp; ເມນູ",  

      posList: [],
      posphotopath: 
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/pos/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/pos/",
      checkimagepath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/pos/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/pos/",
      
      newNoti: 0,
      newNotiSound: 0,
      notishow: "(0)",
      sound:
        "http://45.77.43.249/pos/api/uploads/sound/sound.mp3",
      loadingNotiData: null,

      sessionExpirationTime: null,  // Store session expiration time reactively
      username: "customer",         // Default value for username
      roleid: 0,   
      loadtableid: 0,

      isNoti: false,

      customerdata: [],

      notilist: [],
      countPlaySound: 0,

      showPopup: false,

      isShowSuccessAddMenu: false,
      isShowSuccessLogin: false,

      isMainWaitForCallBack: 0,
      
    }
  },
  methods: {

    getPaymentData(items) {
      // console.log("items: " + JSON.stringify(items))
      var total = this.calculateFinalTotal(items);
      var orderid = items[0].ORDERID || 0;  
      var selectedposid = items[0].POSID || 0;
      var selectedtableid = items[0].TABLEID || 0;
      var data = { total: total, orderid: orderid, selectedposid: selectedposid, selectedtableid: selectedtableid};  // Correct syntax for object property definition
      // console.log("data: " + JSON.stringify(data))
      return data;
    },

    calculateTotal(items) {  
      // this.getPaymentData(items);
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => item.UNITPRICE * item.QUANTITY);
    },

    getDiscount(items) {  
      // eslint-disable-next-line no-undef
      const discount = items[0].DISCOUNT  || 0;
      return discount;
    },

    getVat(items) {  
      // eslint-disable-next-line no-undef
      const vat = items[0].VAT  || 0;
      return vat;
    },


    calculateDiscountedTotal(items) {  
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => {
        const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = rowTotal * (item.DISCOUNT / 100)  || 0; // Use item-specific discount
        return rowDiscount;
      });
    },

    calculateVatTotal(items) {  
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = rowTotal * (item.DISCOUNT / 100)  || 0; // Use item-specific discount
        const rowVat = ((rowTotal -rowDiscount) * item.VAT/100)  || 0; 
        return rowVat;
     }); 
    },

    calculateFinalTotal(items) {  
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = rowTotal * (item.DISCOUNT / 100)  || 0; // Use item-specific discount
        const rowVat = ((rowTotal -rowDiscount) * item.VAT/100)  || 0; 
        return (rowTotal -  rowDiscount) + rowVat;
     }); 
    },  


    increaseQuantity(data){
      data.QUANTITY += 1;
      this.updateMenuQuantity(data);
    },
    decreaseQuantity(data){
      if (data.QUANTITY > 0) {
        data.QUANTITY -= 1;
        this.updateMenuQuantity(data);
      }
    },
    updateMenuQuantity(data) {
      var params = {
        posid: this.posid,
        tableid: this.tableid,
        menuid: data.ORDERMENUID,
        menudetailid: data.DETAILID,
        quantity: data.QUANTITY,
        orderid: data.ORDERID,
        quantitybeforupdate: data.QUANTITYBEFOREUPDATE,
        unitprice: data.UNITPRICE,
        username: this.username,
      };

      // console.log("updateMenuQuantity: ", JSON.stringify(params));
      RestAPI.updateMenuQuantityFromCustomer(params)
        .then(res => {
          this.getMenuInOrder(); 
          this.getMeun();
          if(res.data=="notEnoughIngredient"){
            this.$swal("ສ່ວນປະກອບເມນູບໍ່ຄົບ, ກວດຄືນ", "", "error");
            return false;
          }else if(res.data=="lessThanZero"){
            this.$swal("ຈຳນວນນ້ອຍກວ່າ 0, ກວດຄືນ", "", "error");
            return false;
          }

          

        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    removeMenu(data) {
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var params = {
            orderid: data.ORDERID,
            type: 0, // orderdertail status
            menuid: data.ORDERMENUID,
            menudetailid: data.DETAILID, 
            tableid: this.tableid,
            posid: this.posid, 
            username: this.username,
          };
          // console.log("params: ", JSON.stringify(params))
          RestAPI.removeMenuFromOrder(params)
            .then(res => {
              if(res.data =="isInPaymentProgress"){
                this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ບໍ່ສາມາດລືບໄດ້", "", "error");
                return false;
              }
              // console.log("res:" + JSON.stringify(res.data));
              this.getMeun();
              this.getMenuInOrder();
              this.getCustomerTotalMenuOrder();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },


    turnnoti(data, status){
      var params = {
        notiid: data.ID,
        status: status,
        username: this.username,
      };
      RestAPI.turnnoti(params)
        .then(() => {
          this.getAllNotification();
         
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    
    getAllNotification(){
          
    RestAPI.getAllNotification(this.roleid, this.posid, this.username, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
      .then((res) => {
        // console.log("res.data: "+ JSON.stringify(res.data))
        
        this.notilist = res.data ? res.data.newNoti : '';
       
      })
      .catch(error => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    closePopup(){
      this.showPopup = false;
      this.playSound(); 
    },
    // initiateSound() {
    //   this.playSound();
    //   setTimeout(() => {
    //     this.showPopup = false; // Hide the popup after 2 seconds
    //   }, 5760*5000); // 2000 milliseconds = 2 seconds
    // },
    openPopup() {
      this.showPopup = true;
      // this.initiateSound();
    }, 

    playSound() {
      
      if (this.sound) {
        var audio = new Audio(this.sound);
        audio.play();
      }
    },
    getUserNotification(){

      RestAPI.getUserNotification( this.roleid, this.username) 
      .then((res) => {
        this.newNoti = res.data.noti;
        this.newNotiSound = res.data.sound;
        this.notishow = '('+res.data.sound+')';
        // console.log("noti: "+JSON.stringify(res.data))

        if(this.newNotiSound > 0 && this.username !='customer'){
          // console.log("countPlaySound first : " +this.countPlaySound)  
          if(this.countPlaySound <=10){
            this.playSound();
            this.countPlaySound ++;
            // console.log("countPlaySound---: " +this.countPlaySound)
          }
          
        }
      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    loadNotiData () {
        // this.loadingNotiData = setInterval(() => {
          this.getUserNotification();
          this.getAllNotification();
        // }, 5000)
    },
    

    getPosImgUrl(photoname) {
      var photopreview = photoname
        ? this.posphotopath + photoname
        : this.posphotopath + "unknown.png";
      return photopreview;
    },
    getCheckImage() {
      return this.checkimagepath + "check.png";
    },
    getPostList(){
      RestAPI.getSettingPosList()
        .then((res) => {
          this.posList = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    
    getCustomerTotalMenuOrder(){
      RestAPI.getCustomerTotalMenuOrder(this.tableid)
      .then((res) => {
          // console.log("res menu: " + JSON.stringify(res.data))
          // console.log("TotalMenuOrder: " + JSON.stringify(res.data))
            this.TotalMenuOrder = this.username !='customer' ? '('+res.data.count+')' : '' ;
            this.originalTotalMenuOrder = res.data.count;
            this.tablePhoneno = res.data.customerphoneno;


      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    
   
   
    // getVatDiscount(){ 
    //   RestAPI.getVatDiscountForCustomer(this.posid)
    //     .then((res) => {
    //       this.vat = res.data.vat.VAT ;
    //       this.discount = res.data.discount.DISCOUNT ;
    //       this.discountamount = this.discount * this.total / 100;
    //       this.vatamount = (this.vat * ( this.total- this.discountamount )/100);
    //       // this.finaltotal = 100000; 
    //       this.finaltotal = this.total - this.discountamount + this.vatamount; 
           
    //     })
    //     .catch((error) => {
    //       console.log("my error:" + error);
    //       // this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
    //     });
    // },
  
    
    // selectedTable(table){
    //   console.log("table "+JSON.stringify(table))
    //   if(table.STATUSTITLE=='available'){
    //     this.isreserve = false;
    //   }else if(table.STATUSTITLE=='reserve'){
    //     this.isreserve = true;
    //   }
    //   // console.log(" this.isreserve "+  this.isreserve)
    //   this.tableid= table.ID;
    //   this.tablename = table.NAME;
    //   this.servedby = table.SELLERID ? table.SELLERID : null;
    //   this.istablemenu = false;
    //   this.ismenu = true;
    //   this.ispayment = false;
      
    //   this.getMeun();
    //   this.getMenuInOrder()
    // },
    selectedTable(type, posid, table){
      // console.log("type: " + type)
      // console.log("table: " + JSON.stringify(table))
      this.selectedposname = table.POSNAME;
      this.selectedposid = table.POSID;
      this.getMeun();

    },

    getMeun(){
      RestAPI.getPosMenuForCustomer(this.selectedposid)
      .then((res) => {
        this.menuList = res.data;
        this.originalmenuList = res.data;
        
        if(this.posid != this.selectedposid){
          if(res.data.otherpos==="noOtherTable"){
            this.$swal("ແຈ້ງ System Admin ເພື່ອເປີດຮັບອໍເດີ້", "", "error");
            return false; 
          }
        }

        // console.log("menuList: " + JSON.stringify(res.data));
      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },

    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
   

    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    addMenu(data) {

      // console.log("selectedposid: " + this.selectedposid)
      // console.log("posid: " + this.posid)
 

      // if(this.selectedposid != this.posid){
      //   this.$swal("ແຈ້ງພະນັກງານ ເພື່ອສັ່ງຈາກຮ້ານອື່ນ ", "", "error");
      //   return false;
      // }


      
      if(this.servedby== null){
        this.servedby = this.sellerid;
      }
      
      var params = {
        posid: this.posid,
        selectedposid: this.selectedposid,
        tableid: this.tableid,
        menuid: data.ID,
        unitprice: data.UNITPRICE,
        phonenumber: this.phoneNumber,
        // customerToken: this.customerdata ? this.customerdata : null,  
        customerToken: 0,  
        ischeckphonenumber: 0,
        username: this.username,
        usertableid: this.loadtableid,
        iscustomer: 1,  

      };
      // console.log("params: ", JSON.stringify(params))
      RestAPI.addMenutoOrderForCustomer(params)
        .then((res) => {
          // console.log("res-: " + JSON.stringify(res.data))
          // if(res.data.message =="isInPaymentProgress"){
          //   this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ດຳເນີນການບໍ່ໄດ້", "", "error");
          //   return false;
          // }

          // if(res.data.message =="DIFFERENTTABLE"){
          //   this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ! ພົວພັນຮ້ານ", "", "error");
          //   return false;
          // }

          // if(res.data.message =="CLEAREDDATA"){
          //   this.$swal("Clear ໂຕະສຳເລັດແລ້ວ , ລອງໃໝ່ອີກຄັ້ງ", "", "error");
          //   this.logout();
          //   return false;
          // }


          if(res.data.code =="03"){
             this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
              return false;
            }else if (res.data.code == "04") {
              this.$swal("ບໍ່ສາມາດລົງທະບຽນເບີໂທໄດ້, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "05") {
              this.$swal("ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "06") {
              this.$swal("ເບີໂທນີ້ກຳລັງສັ່ງອາຫານໂຕະອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }
            else if (res.data.code == "09") {
              this.$swal("ບໍ່ສາມາດກວດສອບເບີໂທ, ລອງໃໝ່", "-", "error");
              return false;
            }

          this.countPlaySound = 0;
          this.getMeun();
          this.getMenuInOrder(); 
          this.getCustomerTotalMenuOrder();

          
          // this.$swal({
          //   title: "ສຳເລັດ",
          //   text: "ເພີ່ມລາຍການ ສຳເລັດ!",
          //   icon: "success",
          //   timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
          //   showConfirmButton: false, // Hide the confirmation button
          // });
        

          // if (res.data.isNotEenough == 1) {
          //   this.$swal("ສິນຄ້າບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ", "-", "error");
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          //   return false;
          // } else {
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          // }
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    
    
      
    
    },

    // forcelogout(){
    //   var params = {
    //       tableid: this.loadtableid,
    //       username: this.username,
    //     };
    //   RestAPI.forcelogoutForCustomer(params)
    //       .then(() => {
    //         this.logout();
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         if (error == "Error: Request failed with status code 400") {
    //           this.$swal("Check Input Data", "", "error");
    //         } else {
    //           this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
    //         }
    //       });
      
    // },

    addMenuFromOtherPos(data) {

      // console.log("selectedposid: " + this.selectedposid)
      // console.log("posid: " + this.posid)


      // if(this.selectedposid != this.posid){
      //   this.$swal("ແຈ້ງພະນັກງານ ເພື່ອສັ່ງຈາກຮ້ານອື່ນ ", "", "error");
      //   return false;
      // }



      if(this.servedby== null){
        this.servedby = this.sellerid;
      }

      var params = {
        posid: this.posid,
        selectedposid: this.selectedposid,
        tableid: this.tableid,
        menuid: data.ID,
        unitprice: data.UNITPRICE,
        phonenumber: this.phoneNumber,
        // customerToken: this.customerdata ? this.customerdata : null,  
        customerToken: 0,  
        ischeckphonenumber: 0,
        username: this.username,
        usertableid: this.loadtableid,
        iscustomer: 1,

      };
      // console.log("params: ", JSON.stringify(params))
      RestAPI.addMenutoOrderForCustomerFromOtherPos(params)
        .then((res) => {
          // console.log("res-: " + JSON.stringify(res.data))
          // if(res.data.message =="isInPaymentProgress"){
          //   this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ດຳເນີນການບໍ່ໄດ້", "", "error");
          //   return false;
          // }

          // if(res.data.message =="DIFFERENTTABLE"){
          //   this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ! ພົວພັນຮ້ານ", "", "error");
          //   return false;
          // }

          // if(res.data.message =="CLEAREDDATA"){
          //   this.$swal("Clear ໂຕະສຳເລັດແລ້ວ , ລອງໃໝ່ອີກຄັ້ງ", "", "error");
          //   this.logout();
          //   return false;
          // }


          if(res.data.code =="03"){
            this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
              return false;
            }else if (res.data.code == "04") {
              this.$swal("ບໍ່ສາມາດລົງທະບຽນເບີໂທໄດ້, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "05") {
              this.$swal("ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "06") {
              this.$swal("ເບີໂທນີ້ກຳລັງສັ່ງອາຫານໂຕະອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }
            else if (res.data.code == "09") {
              this.$swal("ບໍ່ສາມາດກວດສອບເບີໂທ, ລອງໃໝ່", "-", "error");
              return false;
            }

          this.countPlaySound = 0;
          this.getMeun();
          this.getMenuInOrder(); 
          this.getCustomerTotalMenuOrder();

          this.showSuccessAddMenu();
          // this.$swal({
          //   title: "ສຳເລັດ",
          //   text: "ເພີ່ມລາຍການ ສຳເລັດ!",
          //   icon: "success",
          //   timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
          //   showConfirmButton: false, // Hide the confirmation button
          // });
        

          // if (res.data.isNotEenough == 1) {
          //   this.$swal("ສິນຄ້າບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ", "-", "error");
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          //   return false;
          // } else {
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          // }
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });




      },

    addMenuCheckPhonenoFromOtherPos(data) {
      this.$swal({
        title: "ໃສ່ເບີໂທລະສັບ 20xxxxxxxx",
        input: "text",
        inputPlaceholder: "ໃສ່ເບີໂທລະສັບຂອງທ່ານ",
        inputValue: "20",
        showCancelButton: true,
        confirmButtonText: "ຢືນຢັນເບີໂທລະສັບ",
        cancelButtonText: "ຍົກເລີກ",
        reverseButtons: true,
        inputValidator: (value) => {
          if (!value) {
            return "ກະລຸນາໃສ່ເບີໂທລະສັບ!";
          }
          if (!/^\d+$/.test(value)) {
            return "ເບີໂທຕ້ອງເປັນຕົວເລກເທົ່ານັ້ນ ແລະ ຫ້າມຍະວ່າງ!";
          }
          if (!value.startsWith("20")) {
            return "ເບີໂທຕ້ອງເລີ່ມດ້ວຍ 20!";
          }
          if (value.length !=10) {
            return "ເບີໂທຕ້ອງ 8 ຕົວເລກ!";
          }
        },
      }).then((phoneResult) => {
        if (phoneResult.value) {
          this.phoneNumber = phoneResult.value;

          if(this.servedby== null){
            this.servedby = this.sellerid;
          }
          var params = {
            posid: this.posid,
            selectedposid: this.selectedposid,
            tableid: this.tableid,
            menuid: data.ID,
            unitprice: data.UNITPRICE,
            phonenumber: this.phoneNumber,
            ischeckphonenumber: 1,
            customerToken: null,
            username: this.username,
            usertableid: this.loadtableid,
            iscustomer: 1,
          };
          // console.log("params: ", JSON.stringify(params))
          RestAPI.addMenutoOrderForCustomerFromOtherPos(params)
            .then((res) => {
              // console.log("res++: " + JSON.stringify(res.data))
              if(res.data.code =="03"){
                this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
                return false;
              }else if (res.data.code == "04") {
                this.$swal("ບໍ່ສາມາດລົງທະບຽນເບີໂທໄດ້, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }else if (res.data.code == "05") {
                this.$swal("ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }else if (res.data.code == "06") {
                this.$swal("ເບີໂທນີ້ກຳລັງສັ່ງອາຫານໂຕະອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }
              else if (res.data.code == "09") {
                this.$swal("ບໍ່ສາມາດກວດສອບເບີໂທ, ລອງໃໝ່", "-", "error");
                return false;
              }


              if(res.data.message =="DIFFERENTTABLE"){
                this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
                return false;
              }

              if(res.data.message =="CLEAREDDATA"){
                this.$swal("Clear ໂຕະສຳເລັດແລ້ວ , ລອງໃໝ່ອີກຄັ້ງ", "", "error");
                this.logout();
                return false;
              }

              this.countPlaySound = 0;
              this.getMeun();
              this.getMenuInOrder(); 
              this.getCustomerTotalMenuOrder();
              // console.log("customerdata: ", JSON.stringify(res.data)); 

              const session = res.data.data.customerdata;
              this.customerdata = res.data.data.customerdata;

              // Clear previous session data in localStorage
              localStorage.removeItem("loginData");
              localStorage.removeItem("token");

              // Save session data to localStorage
              localStorage.setItem("loginData", JSON.stringify(session));
              localStorage.setItem("token", session.jwt);

              // Set the session expiration (5 minutes) and store it
              this.setSessionExpiration();
              
              // Set user data
              this.username = session.USERNAME || "customer";
              this.roleid = session.ROLEID || 0;
              this.phoneNumber = session.PHONENO || "";  
              this.loadtableid = session.TABLEID || 0;

              this.showSuccesslogin();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
        
    },
    addMenuCheckPhoneno(data) {

    // console.log("selectedposid: " + this.selectedposid)
    // console.log("posid: " + this.posid)
      this.$swal({
        title: "ໃສ່ເບີໂທລະສັບ 20xxxxxxxx",
        input: "text",
        inputPlaceholder: "ໃສ່ເບີໂທລະສັບຂອງທ່ານ",
        inputValue: "20",
        showCancelButton: true,
        confirmButtonText: "ຢືນຢັນເບີໂທລະສັບ",
        cancelButtonText: "ຍົກເລີກ",
        reverseButtons: true,
        inputValidator: (value) => {
          if (!value) {
            return "ກະລຸນາໃສ່ເບີໂທລະສັບ!";
          }
          if (!/^\d+$/.test(value)) {
            return "ເບີໂທຕ້ອງເປັນຕົວເລກເທົ່ານັ້ນ ແລະ ຫ້າມຍະວ່າງ!";
          }
          if (!value.startsWith("20")) {
            return "ເບີໂທຕ້ອງເລີ່ມດ້ວຍ 20!";
          }
          if (value.length !=10) {
            return "ເບີໂທຕ້ອງ 8 ຕົວເລກ!";
          }
        },
      }).then((phoneResult) => {
        if (phoneResult.value) {
          this.phoneNumber = phoneResult.value;

          // if(this.selectedposid != this.posid){
          //   this.$swal("ແຈ້ງພະນັກງານ ເພື່ອສັ່ງຈາກຮ້ານອື່ນ ", "", "error");
          //   return false;
          // }

          if(this.phoneNumber != this.tablePhoneno && this.tablePhoneno != null){
              this.$swal("ທ່ານບໍ່ແມ່ນເຈົ້າຂອງໂຕະນີ້, ພົວພັນຮ້ານ", "", "error");
              return false;
          }
          
          if(this.servedby== null){
            this.servedby = this.sellerid;
          }
          var params = {
            posid: this.posid,
            selectedposid: this.selectedposid,
            tableid: this.tableid,
            menuid: data.ID,
            unitprice: data.UNITPRICE,
            phonenumber: this.phoneNumber,
            ischeckphonenumber: 1,
            customerToken: null,
            username: this.username,
            usertableid: this.loadtableid,
            iscustomer: 1,
          };
          // console.log("params: ", JSON.stringify(params))
          RestAPI.addMenutoOrderForCustomer(params)
            .then((res) => {
              // console.log("res++: " + JSON.stringify(res.data))
              if(res.data.code =="03"){ // use
                this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
                return false;
              }else if (res.data.code == "04") { // use
                this.$swal("ບໍ່ສາມາດລົງທະບຽນເບີໂທໄດ້, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }else if (res.data.code == "05") { // use
                this.$swal("ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }else if (res.data.code == "06") { // use
                this.$swal("ເບີໂທນີ້ກຳລັງສັ່ງອາຫານໂຕະອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
                return false;
              }
              else if (res.data.code == "09") {// use
                this.$swal("ບໍ່ສາມາດກວດສອບເບີໂທ, ລອງໃໝ່", "-", "error");
                return false;
              }


              if(res.data.message =="DIFFERENTTABLE"){ // use
                this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
                return false;
              }

              if(res.data.message =="CLEAREDDATA"){// use
                this.$swal("Clear ໂຕະສຳເລັດແລ້ວ , ລອງໃໝ່ອີກຄັ້ງ", "", "error");
                this.logout();
                return false;
              }

              this.countPlaySound = 0;
              this.getMeun();
              this.getMenuInOrder(); 
              this.getCustomerTotalMenuOrder();

              // console.log("customerdata: ", JSON.stringify(res.data)); 

              const session = res.data.data.customerdata;

              this.customerdata = res.data.data.customerdata;

              // console.log("customerdata-: " + JSON.stringify(session));

              // Clear previous session data in localStorage
              localStorage.removeItem("loginData");
              localStorage.removeItem("token");

              // Save session data to localStorage
              localStorage.setItem("loginData", JSON.stringify(session));
              localStorage.setItem("token", session.jwt);

              // Set the session expiration (5 minutes) and store it
              this.setSessionExpiration();
              

              // Set user data
              this.username = session.USERNAME || "customer";
              this.roleid = session.ROLEID || 0;
              this.phoneNumber = session.PHONENO || "";  
              this.loadtableid = session.TABLEID || 0;

              // console.log("session: ", JSON.stringify(session));  
              // console.log("Updated roleid: ", this.roleid);

              this.showSuccesslogin();

             
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });



    },
    
    getMenuInOrder() {
      // console.log("this.userProvinceID: " + this.userProvinceID)
      RestAPI.getMenuInOrderForCustomer(this.posid, this.tableid)
        .then((res) => {
          // console.log("menu: " + JSON.stringify(res.data.menu)); 
          // console.log("list: " + JSON.stringify(res.data.menu.list)); 
          
          // console.log("res.data.list: " + JSON.stringify(res.data.menu.list));    
          
          this.menuInWaitOrderList = res.data.menu.waitlist;
          this.menuInWaitOrderFromOtherPosList = res.data.menu.waitfromotherposlist;
          this.menuWaitPaymentCallBackList =  res.data.menu.waitcallbacklist;
          this.menuWaitPaymentCallBackFromOtherPosList =  res.data.menu.waitcallbackfromotherposlist;
          // console.log("menuInWaitOrderList: " + JSON.stringify(this.menuInWaitOrderList));        
          this.menuInOrderList = res.data.menu.orderlist;
          this.menuInOrderDetailList = res.data.menu.detaillist;
          this.menuInOrderFromOtherPosList = res.data.menu.fromotherposlist;
          // console.log("menuInOrderList: " + JSON.stringify(this.menuInOrderList));   
          // this.total = res.data.menu.waitlist.length  > 0 
          //   ? Number(res.data.menu.waitlist[0]["MENUTOTAL"])
          //   : 0;
          this.total = res.data.menu.waitlist.length > 0  
                      ? res.data.menu.waitlist.reduce((acc, item) => acc + Number(item.MENUTOTAL), 0)
                      : 0;

          this.orderid =res.data.menu.waitlist.length  > 0 
            ? res.data.menu.waitlist[0]["ORDERID"]
            : null;
          this.countmenu = res.data.menu.countmenu.count;
          this.allMenuInOrder = res.data.menu;

        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    showSuccessAddMenu(){
      this.isShowSuccessAddMenu = true;
      setTimeout(() => {
        this.isShowSuccessAddMenu = false;
      }, 1000);

    },

    showSuccesslogin(){
      this.isShowSuccessLogin = true;
      setTimeout(() => {
        this.isShowSuccessLogin = false;
      }, 2000);

    },



    // Show loading modal
    showLoadingModal() {
      // console.log("Loading modal shown");
      this.isLoading = true;
    },
    
    // Hide loading modal
    hideLoadingModal() {
      // console.log("Loading modal hidden");
      this.isMainWaitForCallBack = 0;
      this.isLoading = false;
    },

    showSuccessModal(){
      this.isSuccessPayment = true;
      this.activeTab = 0;
    },

    hideSuccessModal(){
      this.qrData = null;
      this.isSuccessPayment = false;
    },

    showNotiModal(){
      this.isNoti = true;
      setTimeout(() => {
        this.isNoti = false;
      }, 1000);
    },

    hideNotiModal(){
      this.isNoti = false;
    },


    async doPayment(item) {
      this.$swal({
        title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          
          const paymentData = await this.getPaymentData(item);  
          // console.log("paymentdata: " + JSON.stringify(paymentData))
          //wait untial getpaymentdate work done 
          this.qrtotal = paymentData.total;
          const params = {
            username: this.username,
            orderid: paymentData.orderid,
            finaltotal: paymentData.total,
            posid: this.posid,
            selectedposid: paymentData.selectedposid,
            phoneno: this.phoneNumber,
            tableid: this.tableid,
            selectedtableid: paymentData.selectedtableid
    
          };
          // console.log("params: " + JSON.stringify(params))
          const loadingComponent = this.$loading.open({});

          RestAPI.doCustomerPayment(params)
            .then((res) => {
              
              loadingComponent.close();  
              

              if (res.data.code === "00") {
                this.qrData = res.data.data.qrcode;
                this.uuid = res.data.data.uuid;

                this.showLoadingModal(); // Show modal before starting the payment API call
                this.getMenuInOrder();

                this.isMainWaitForCallBack = 1;
                
                this.waitForCallback(paymentData.orderid, this.uuid); // Start polling for callback only on success
              } else if (res.data.code === "01") {
                this.hideLoadingModal();
                this.$swal("ຈຳນວນບໍ່ຖືກຕ້ອງ", "-", "error");
              } else if (res.data.code === "02") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດສ້າງ QR Code ໄດ້", "-", "error");
              } else if (res.data.code === "03") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກຂໍ້ມູນ QR! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "04") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກເບີໂທໄດ້! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "05") {
                this.hideLoadingModal();
                this.$swal("ສ້າງ QR CODE ຊ້ຳ! ພົວພັນຮ້ານ", "-", "error");
              }
            })
            .catch((error) => {
              this.hideLoadingModal(); // Ensure modal is hidden if there's an error
              console.error("Payment error:", error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },

    waitForCallback(orderid, uuid) {
      const checkInterval = 5000; // 3 seconds interval
      const maxAttempts = 60; // Maximum attempts to wait
      let attempts = 0;

      // console.log("Starting polling process...");

      this.showLoadingModal();

      const interval = setInterval(() => {
        // console.log(`Polling attempt #${attempts + 1}`);

        const params = { 
          orderid: orderid,
          posid: this.posid,
          uuid: uuid,
          username: this.username,
        };

        // console.log('params: '+JSON.stringify(params));

        RestAPI.checkPaymentStatus(params)
          .then((res) => {
            // console.log(`Response on attempt #${attempts + 1}:`, res.data);
            // console.log("res.data-CALLBACK: " + JSON.stringify(res.data))
            if (res.data.CALLBACK === 1) {
              // console.log("Payment successful!");
              clearInterval(interval);
              this.hideLoadingModal();  
  
              // this.successModal();
              this.showSuccessModal();

              
              // this.$swal({
              //   title: "ສຳເລັດ",
              //   html: '<img :src="getCheckImage()" style="width: 50px;"/> ການຊຳລະສຳເລັດແລ້ວ' , 
              //   icon: "success", 
              //   confirmButtonText: "ປິດ",
              // });
            }
          })
          .catch((error) => {
            console.error("Polling error:", error);
          });

        attempts++;

        if (attempts >= maxAttempts) {
          // console.log("Max polling attempts reached.");
          clearInterval(interval);
          this.hideLoadingModal();

          this.$swal("ບໍ່ມີການຕອບກັບຈາກທະນາຄານ ! <br> ພົວພັນຮ້ານຄ້າ", "", "error");   

          // this.$swal({
          //   title: "ໝົດເວລາລໍຖ້າ",
          //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",
          //   icon: "warning",
          //   confirmButtonText: "ປິດ",
          // });
        }
      }, checkInterval);
    },

    // reWaitForCallback(orderid, uuid) {  
    //   const checkInterval = 5000; // 3 seconds interval
    //   const maxAttempts = 5000; // Maximum attempts to wait 
    //   let attempts = 0;

    //   console.log("Starting polling process...");

    //   this.showLoadingModal();

    //   const interval = setInterval(() => {
    //     // console.log(`Polling attempt #${attempts + 1}`);

    //     const params = { 
    //       orderid: orderid,
    //       posid: this.posid,
    //       uuid: uuid,
    //       username: this.username,
    //     };

    

    //     // console.log('params: '+JSON.stringify(params));

    //     RestAPI.checkPaymentStatus(params)
    //       .then((res) => {
    //         // console.log(`Response on attempt #${attempts + 1}:`, res.data);
    //         // console.log("res.data-CALLBACK: " + JSON.stringify(res.data))
    //         if (res.data.CALLBACK === 1) {
    //           // console.log("Payment successful!");
    //           clearInterval(interval);
    //           this.hideLoadingModal();  
  
    //           // this.successModal();
    //           this.showSuccessModal();

              
    //           // this.$swal({
    //           //   title: "ສຳເລັດ",
    //           //   html: '<img :src="getCheckImage()" style="width: 50px;"/> ການຊຳລະສຳເລັດແລ້ວ' , 
    //           //   icon: "success", 
    //           //   confirmButtonText: "ປິດ",
    //           // });
    //         }
    //       })
    //       .catch((error) => {
    //         console.error("Polling error:", error);
    //       });

    //     attempts++;

    //     if (attempts >= maxAttempts) {
    //       // console.log("Max polling attempts reached.");
    //       clearInterval(interval);
    //       this.hideLoadingModal();

    //       this.$swal("ບໍ່ມີການຕອບກັບຈາກທະນາຄານ ! <br> ພົວພັນຮ້ານຄ້າ", "", "error");   

    //       // this.$swal({
    //       //   title: "ໝົດເວລາລໍຖ້າ",
    //       //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",
    //       //   icon: "warning",
    //       //   confirmButtonText: "ປິດ",
    //       // });
    //     }
    //   }, checkInterval);
    // },

    waitNoti() {
      const checkInterval = 5000; // 5 seconds interval
      const maxAttempts = 5760; // Maximum attempts to wait 5 minute * 5760 = 8 hours
      let attempts = 0;

      // console.log("Starting wait for noit...");    

      const interval = setInterval(() => {
        //console.log(`Polling attempt #${attempts + 1}`);

        this.loadNotiData();

        attempts++;
 
        if (attempts >= maxAttempts) {
          // console.log("Max polling attempts reached.");
          clearInterval(interval);
     

          // this.$swal("ເກີນ 8 ຊໍົ່ວໂມງ", "", "error");   

          // this.$swal({
          //   title: "ໝົດເວລາລໍຖ້າ",
          //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",    
          //   icon: "warning",
          //   confirmButtonText: "ປິດ",
          // });
        }
      }, checkInterval);
    },

    waitData() {
      const checkInterval = 5000; // 3 seconds interval
      const maxAttempts = 9600; // Maximum attempts to wait 3 minute * 9600 = 8 hours
      let attempts = 0;

      // console.log("Starting wait for noit...");

      const interval = setInterval(() => {
        // console.log(`Polling attempt #${attempts + 1}`);

        this.loadData();

        this.checkRemainCallback();

        attempts++;

        if (attempts >= maxAttempts) {
          // console.log("Max polling attempts reached.");
          clearInterval(interval);
     

          // this.$swal("ເກີນ 8 ຊໍົ່ວໂມງ", "", "error");   

          // this.$swal({
          //   title: "ໝົດເວລາລໍຖ້າ",
          //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",
          //   icon: "warning",
          //   confirmButtonText: "ປິດ",
          // });
        }
      }, checkInterval);
    },




    onTypeSelectedForMenuList() {
      this.menuList = [...this.originalmenuList];
    },
    getMenuType(){
      RestAPI.getSettingMenuTypeForCustomer()
        .then(res => {
          this.typeList = res.data;
          this.getMeun();
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },


    checkCustomerPosandTable (){
      // console.log("start checkCustomerPosandTable") 
      RestAPI.checkCustomerPosandTable(this.posid, this.tableid)
        .then((res) => {
          //  console.log("checkCustomerPosandTable" +JSON.stringify(res.data))
           if(res.data.STATUS=="ok"){
            this.customertable= true;
            this.sellerid = this.username;
            this.main_posname = this.posid;
            this.tablename = res.data.TABLENAME;
            this.getMenuType();
            this.getMeun();
           }else{
            this.customertable = false;
            this.$swal("ບໍ່ພົບຂໍ້ມູນ", "-", "error");
            return false;
           }
           
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getPOSInfor (){
      RestAPI.getCustomerPOSInfor(this.posid)
        .then((res) => {
           this.posname = res.data.POSNAME;
        })
        // .catch((error) => {
        //   console.log(error);
        //   this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        // });
    },
    print() {
      window.print();
    },
    loadData () {
      
        // this.loadingData = setInterval(() => {
          this.getMenuInOrder()
          this.getMeun();
          this.getCustomerTotalMenuOrder();
          this.getPOSInfor(this.posid);

          const session = JSON.parse(localStorage.getItem("loginData"));
          if (session) {
            this.username = session.USERNAME || "customer"; // Update username
            this.roleid = session.ROLEID || 0; // Update role
            this.phoneNumber = session.PHONENO || "";
            this.loadtableid = session.TABLEID || 0;
          } else {
            this.username = "customer"; // Reset if session is expired
            this.roleid = 0;  // Reset if session is expired
            this.phoneNumber ="";
            this.loadtableid = 0;
          }

          if(this.username =='customer'){
            this.activeTab = 0;
          }
          // console.log("load-this.username: " + this.username)
          // console.log("load-this.loadtableid: " + this.loadtableid)
        // }, 5000)  
    },
    logout() {
      localStorage.removeItem("loginData");
      localStorage.removeItem("token");
      localStorage.removeItem("sessionExpiration");
      this.username = "customer";
      this.roleid = 0;
      this.phonenumber = "";

    },
    // This method sets the session expiration time in localStorage
    setSessionExpiration() {
      const sessionDuration = 8 * 60 * 60 * 1000; // 5 minutes in milliseconds
      const expirationTime = Date.now() + sessionDuration;

      // Store expiration time in localStorage and update reactively
      localStorage.setItem("sessionExpiration", expirationTime);
      this.sessionExpirationTime = expirationTime;  // Set the session expiration time reactively
    },
    checkRemainCallback(){

      // console.log("isMainWaitForCallBack: " + this.isMainWaitForCallBack)

      if(this.isMainWaitForCallBack ==0){  
        const params = { 
          tableid: this.tableid,
          username: this.username,
        };

        // console.log("params: " + JSON.stringify(params))
        RestAPI.checkRemainCallback(params)
          .then((res) => {
            if(res.data=='ok'){
              // console.log("checkRemainCallback");
            }
            
            
            // if (res.data.CALLBACK === 1) {
            
            //   // this.successModal();
            //   this.showSuccessModal();

            // }
          })
          .catch((error) => {
            console.error("checkRemainCallback error:", error);
          });
      }

    }
  },
  // beforeDestroy () {
  //     clearInterval(this.loadingData);
  //     clearInterval(this.loadingNotiData);
  // },
  // created () {
  //     this.loadData();
      
  // },

  watch: {
    // Watch the session expiration time to detect if the session expired
    sessionExpirationTime(newExpirationTime) {
      const remainingTime = newExpirationTime - Date.now();

      // If session has expired, log out
      if (remainingTime <= 0) {
        // console.log("Session has expired, logging out...");
        this.logout();
      } else {
        // console.log("Session is valid, logging out after " + remainingTime + " ms...");
        // Set a timeout to log the user out when the session expires
        setTimeout(() => {
          this.logout();  // Log out when session expires
        }, remainingTime);
      }
    }
  },
  
  mounted() {
    this.logout();
    
    this.openPopup();
   // Get session data from localStorage if available
    const savedLoginData = localStorage.getItem("loginData");
    const savedToken = localStorage.getItem("token");

    

    if (savedLoginData && savedToken) {
      const loginData = JSON.parse(savedLoginData);

      this.username = loginData.USERNAME || "customer";
      this.roleid = loginData.ROLEID || 0;
      this.loadtableid = loginData.TABLEID || 0;
      this.phoneNumber = loginData.PHONENO || "";  
      

      // console.log("this.username: " + this.username);
      // console.log("loadtableid: " + this.loadtableid);
      // console.log("phoneNumber: " + this.phoneNumber);  

      // Retrieve session expiration time from localStorage
      const expirationTime = localStorage.getItem("sessionExpiration");
      if (expirationTime) {
        this.sessionExpirationTime = Number(expirationTime);  // Set the expiration time reactively

        const remainingTime = this.sessionExpirationTime - Date.now();
        if (remainingTime <= 0) {
          // console.log("Session has expired, logging out...");
          this.logout();  // Log out if session expired
        } else {
          // If session is still valid, log out after the remaining time
          // console.log("Session is valid, logging out after " + remainingTime + " ms...");
          setTimeout(() => {
            this.logout();  // Log out when session expires
          }, remainingTime);
        }
      } else {
        // No expiration found, log out
        this.logout();
      }
    } else {
      // No login data found, set default values and log out
      this.username = "customer";
      this.roleid = 0;
      this.loadtableid = 0;
      this.logout();
    }

  
    this.posid = this.$route.params.posid;
    this.tableid =  this.$route.params.tableid;
    this.selectedposid = this.$route.params.posid;
    
    this.getPostList();
    this.getPOSInfor(this.posid);
    this.checkCustomerPosandTable();
    this.getCustomerTotalMenuOrder();
    this.getMeun();
    this.getMenuInOrder();
    this.waitNoti();
    this.waitData();


    this.startdate = new Date();
    this.enddate = new Date();
    
    

  },

  computed: {
   
    formattedMenuTitle() {
      // Replace the HTML entity &nbsp; with a regular space
      return this.menulisttitle.replace(/&nbsp;/g, '    ');  // 4 spaces
    },
    qrCodeUrl() {  
      // Use the qrserver API to generate the QR code image URL
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(this.qrData)}`;
    },
    filteredMenuList() {
      if (this.selectedType && this.selectedType.ID) {
        return this.menuList.filter(element => {
          return element['TYPEID'] === this.selectedType.ID;
        });
      } else {
        return this.menuList; // Return the entire list if selectedType or its ID is not available
      }
    },
  },
};
</script>

<style scoped>
/* .card {
  background-color: red;
} */

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.popup button:hover {
  background-color: #2980b9;
}

.popup-content{
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}



.popup-buttons {
  margin-top: auto; /* This pushes the buttons to the bottom */
}

.popup button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 0.2s ease-in-out;  /* Adjust duration as needed */    
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }  
}


/* Custom modal styles for loading */
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the modal is on top of other content */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%; /* Adjust width as needed */
  max-width: 500px;
}

.modal-content2 {
  padding: 0px 20px 20px 80px;
  border-radius: 10px;
  width: 100%; /* Full width of the modal */
  margin-top: 20px; /* Space between the QR code and content */
  text-align: left; /* Align content to the left */
}

.modal-content2 div {
  margin-bottom: 0px; /* Space between the lines */
  margin-top: -20px;
  font-size: 18px;
}

.modal-title {
  font-size: 26px;
  font-weight: bold; 
  margin-bottom: 10px; 
}

.qr-code-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

@media (max-width: 480px) {

  .modal-content2 {
    padding: 2px 2px 2px 50px; /* Adjust padding for very small screens */
  }

}

.spinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-border-color {
    border-color: red; /* Set your desired border color */
  }
.card-image {
  max-height: 400px; /* Set a maximum height for the image container */
}

.image {
  /* margin: 0 auto; */
}
.card-content-table {
  padding: 10px 10px 10px 10px;
  width: 100%;
  height: 100%; 
}

.card-content-menu {
  padding: 10px 10px 10px 10px; 
}
.danger {
  background-color: red;
  color: white;
  padding: 5px;
}
.warning {
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title { 
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected {
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight {
  font-weight: bold;
}

.available{
  background-color: rgb(37, 164, 248);
  border-radius: 10px;
  color: white;
}
.reserve{
  background-color: rgb(233, 233, 12);
  border-radius: 10px;
  color: red; 
  font-weight: bold;

}
.sitting{
  background-color: rgb(250, 7, 80);
  border-radius: 10px;
  color: white;
}

.float-container {
    /* padding: 20px; */ 
}

.float-child-left {
    width: 40%;
    float: left;
    padding: 5px 0px 5px 5px;
    text-align: right;
    /* background-color: red; */
} 

.float-child-right {
    width: 60%; 
    float: left;
    padding: 5px 0px 5px 10px;
    /* background-color: green; */
} 

.float-child {
    float: left;
    padding: 5px 0px 5px 10px;
} 


.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

.runout {
  background-color: grey;
}

.normal {
  background-color: white;
}

.fullscreen-container {
  height: 100vh;
  width: 100vw;
  padding: 0 30px 0 30px;
}

.custom-style {
    /* Add your custom styles here */
    color: red;
    font-weight: bold;
    /* Add any other styles you need */
  }


@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }

  
}
</style>
