<template>
  <section>
    <div class="contain no-print" >
      <div class="columns">
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານ ເມນູຂາຍດີ</div>
              </div>
            </div>

            
            <div class="columns">

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <div >
                  <div style="margin-top: 10px; margin-bottom: 10px;">
                      <span style="font-size: 28px;">ລາຍງານ ເມນູຂາຍດີ</span>
                  </div>

                    <b-field>
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                            :date-formatter="dateFormatter"
                            v-model="startdate"
                          >
                          </b-datepicker>
                        </b-field>
                        <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                        <b-field label="ເຖິງວັນທີ">
                          <b-datepicker
                            :date-formatter="dateFormatter"
                            v-model="enddate"
                          >
                          </b-datepicker>
                        </b-field>
                        <b-field style="padding-left: 20px; padding-right: 20px;" v-if="roleid < 4">  </b-field>
                        <b-field label="ເລືອກຮ້ານ" v-if="roleid < 4">
                            <Multiselect
                            placeholder="ເລືອກຮ້ານ"
                            v-model="selectedPos"
                            label="NAME"
                            track-by="ID"
                            :options="posList"
                            @input="onSelectPos"
                          >
                          </Multiselect>
                        </b-field>
                        <b-field style="padding-left: 20px; padding-top: 32px;" >
                          <b-button type="is-info" expanded style="width: 100%;" @click="getMenuList()">ດຶງລາຍງານ</b-button>
                        </b-field>
                        <b-field style="padding-left: 20px; padding-top: 32px;" >
                          <b-button type="is-success" expanded style="width: 100%;" @click="print()">ພິມລາຍງານ</b-button>
                        </b-field>

                            
                    </b-field>
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="10%">#</th>
                          <th class="has-text-lef " width="20%">
                            ເມນູ
                          </th>
                          <!-- <th class="has-text-centered " width="20%">
                            ຈຳນວນຮ້ານ
                          </th> -->
                          <th class="has-text-centered " width="20%">
                            ຈຳນວນຄັ້ງ
                          </th>
                          
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in menuList"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-left">
                           {{ data.MENU }} 
                          </td>
                          <!-- <td class="has-text-centered" >
                            {{ data.COUNTPOS | formatNumberLocal }} 
                          </td> -->
                          <td class="has-text-centered" >
                            {{ data.COUNTORDER | formatNumberLocal }} 
                          </td>
                          
                        </tr>
                      </tbody>
                    </table>
                    
                  <div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>

    <div class="contain print-only page-break">
      <div class="contain" style="padding: 20px 40px 20px 40px;">
        <div class="columns">
          <div class="column">
            <div
              style="margin-top: 25px; text-align: center;">
                <span style="font-size: 24px; font-weight: bold;;">ລາຍງານເມນູນຂາຍດີ</span>
            </div>
            <div style="background-color: red; padding: 0 0 0 0; margin: 0 0 0 0 ;" >
              <img
                  class="image-area"
                  style="width: 150px;"
                  :src="getImgUrl('symbol/logo.jpg')"
                  alt="Placeholder image"
                />
            </div>
            <div class="paymentdetailselected" >
              ຮ້ານ: <span class="paymentdetailselectedhightlight" v-if="selectedPosID !=0"> {{ selectedPosName }}</span>
                  <span class="paymentdetailselectedhightlight" v-else> ທັງໝົດ</span>
            </div>
            <div class="paymentdetailselected" >
              ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ dateFormatter(startdate) }} ເຖິງ {{ dateFormatter(enddate) }} </span>
            </div>
            
            <div style="height: 10px;"></div>
           
            <table class="table is-fullwidth is-narrow is-small">
              <thead>
                <tr>
                  <th class="has-text-centered " width="10%">ລຳດັບ</th>
                  <th class="has-text-lef " width="20%">
                    ເມນູ
                  </th>
                  <!-- <th class="has-text-centered " width="20%">
                    ຈຳນວນຮ້ານ
                  </th> -->
                  <th class="has-text-right " width="20%">
                    ຈຳນວນຄັ້ງ
                  </th>
                  
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(data, index) in menuList"
                  :key="data.ID"
                >
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td class="has-text-left">
                    {{ data.MENU }} 
                  </td>
                  <!-- <td class="has-text-centered" >
                    {{ data.COUNTPOS | formatNumberLocal }} 
                  </td> -->
                  <td class="has-text-right" >
                    {{ data.COUNTORDER | formatNumberLocal }} 
                  </td>
                  
                </tr>
              </tbody>
            </table>
            
            

            <div class="columns" style="page-break-inside: avoid;" >
              <div class="column" >
                
              </div>
              <div
                class="column"
                style="text-align: center; font-weight: bold; font-size: 16px;"
                
              >
                <div>
                  ຜູ້ລາຍງານ
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
     
      startdate: null,
      enddate: null,
      menuList: [],
      selectedPos: null,
      selectedPosID: null,
      posList: [],
      selectedPosName: null,
     

    };
  },
  methods: {

    onSelectPos(){
      this.getMenuList();
    },
    getMenuList(){
      var posid;
      if(this.roleid < 4){
        posid = this.selectedPos === null ? 0 : this.selectedPos.ID;
        this.selectedPosID = posid;
      }else{
        posid = this.posid;
        
      }
      
      console.log("posid: " + JSON.stringify(posid))
      RestAPI.getTopMenuList(this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate), posid)
        .then(res => {
          
          if (res.data.length > 0) {
            this.menuList = res.data;
              this.selectedPosName = res.data[0].POSNAME;
            //   console.log("selectedPosName: " + res.data[0].POSNAME)

            //   console.log("res.data[0]: " + JSON.stringify(res.data[0]))
            // console.log(" res.data[0].POSNAME: " + JSON.stringify( res.data[0].POSNAME))
          }

    
          
            
          // }
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getPosList(){
      RestAPI.getPosList()
        .then(res => {
          this.posList = res.data;
          this.posList.push({ ID: "0", NAME: "ທັງໝົດ" });
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
          

    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getMenuList();
    this.getPosList();
    
    
  },
  computed: {},
};
</script>

<style scoped>

.detailtitle{
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
