<template>
  <section>
    <div class="contain no-print" >
      <div class="columns" style="min-height: auto;">
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍການອາຫານ</div>
                <div v-show="isVideo">
                  
                  <div class="columns">
                    <div class="column" style="padding-top: 10px;">
                      <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                        ວິດິໂອການເຮັດອາຫານ
                      </div>
                      <video  style="width: 100%;" ref="videoref" controls v-if="videoname"></video>
                      <div v-else style="text-align: center; padding-top: 20%; font-size: 32px;">
                        No Video
                      </div> 
                    </div>
                    <div class="column" style=" font-weight: bold;">
                      <div class="column" style="text-align: center; font-size: 20px; font-weight: bold;"> 
                        ສ່ວນປະກອບ ເມນູ {{ selectedMenu }}
                      </div>
                      <table class="table is-fullwidth is-narrow is-small" style="border-radius: 10px;">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-left " width="15%">
                              ສ່ວນປະກອບ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະລິນມານ 
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in inGredientMenuList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-left">
                              {{ data.PRODUCTNAME }}
                            </td>
                            <td class="has-text-centered">
                              {{ data.QUANTITY }}  {{ data.UNIT }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    
                  </div>
                  <div class="columns">
                    <div class="column"   style="width: 100%; float: right; text-align: right;">
                      <b-button
                      class="btn-group-margin"
                      type="is-danger"
                      expanded
                      @click="closeVideo()"
                      >ປິດວິດິໂອ ແລະ ສ່ວນປະກອບອາຫານ
                    </b-button>
                    </div>
                    <div class="column">

                    </div>
                    
                   
                  </div>
                  
                  
                </div>
              </div>
            </div>
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ອາຫານມາໃໝ່</span>
                </div>  
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="5%">
                          ໂຕະ
                        </th>
                        <th class="has-text-centered " width="30%">
                          ເມນູ
                        </th>

                        <th class="has-text-centered " width="10%">
                          ຈັດການ
                        </th>
                      </tr>
                    </thead>
                    <tbody v-for="(batch) in batchlist" :key="batch.BATCHID">
                      <tr
                        v-for="(data, index) in orderedMenu"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered" v-if="batch.BATCHID == data.BATCHID">{{ index + 1 }}</td>
                        <td class="has-text-centered" v-if="batch.BATCHID == data.BATCHID">
                          <span v-if="data.ORDEREDPOSID != data.POSID">{{ data.ORDEREDPOSNAME }}<br></span> 
                          <span v-if="data.ORDEREDPOSID != data.POSID">{{ data.ORDEREDTABLENAME }}<br></span> 
                          {{ data.TABLENAME }}<br>
                          <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-success"
                              size="is-small"
                              expanded
                              @click="playVideo(data.MENUID, data.VIDEONAME, data.MENUNAME)"
                              >ເບິ່ງວິດິໂອ
                            </b-button>

                        </td>
                        <td class="has-text-centered" v-if="batch.BATCHID == data.BATCHID">
                          
                        <img
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                          @click="playVideo(data.MENUID, data.VIDEONAME, data.MENUNAME)"
                        /> <br />
                        {{ data.MENUNAME}} <br><span style="color: red;">X</span> {{ data.QUANTITY }} {{ data.MENUNIT }}
                        </td>
                        <td class="has-text-centered" v-if="batch.BATCHID == data.BATCHID">
                          <b-field style="margin-bottom: 0px; ">
                            <b-button
                              class="btn-group-margin"
                              type="is-primary" 
                              expanded
                              style="width: 100%;"
                              @click="doPrePrint(data,1)"
                              >ພິມລາຍການ
                            </b-button>
                          </b-field>

                          <b-field style="margin-bottom: 0px; ">
                            <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-info"
                              expanded
                              @click="updateOrderMenuDetailStatusOnlyOne(data.DETAILID,3)"
                              >ຮັບເມນູ
                            </b-button>
                          </b-field>
                          <b-field style="margin-top: 0px; ">
                            <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-danger"
                              expanded
                              @click="rejectMenu(data.DETAILID,-3,data.TABLEID)"
                              >ປະຕິເສດ
                            </b-button>
                          </b-field>
                          <b-field v-if="isReject == 1">
                            <Multiselect
                              placeholder="ເລືອກເຫດຜົນ"
                              v-model="selectedRejectReason"
                              label="NAME"
                              track-by="ID"
                              :options="rejectReasonList"
                              @input="confirmReject"
                            >
                            </Multiselect>
                            
                          </b-field>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <b-field style="margin-bottom: 0px; ">
                            <b-button
                              class="btn-group-margin"
                              type="is-primary" 
                              expanded
                              style="width: 100%;"
                              @click="doPrePrintSet(batch.BATCHID,2)"
                              >ພິມລາຍການ
                            </b-button>
                          </b-field>

                          <b-field style="margin-bottom: 0px; ">
                            <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-info"
                              expanded
                              @click="updateOrderMenuDetailStatusByBatch(batch.BATCHID,3)"
                              >ຮັບເມນູ
                            </b-button>
                          </b-field>
                          <b-field style="margin-top: 0px; ">
                            <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-danger"
                              expanded
                              @click="rejectMenuByBatch(batch.BATCHID,-3)"
                              >ປະຕິເສດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                      <tr style="background-color: black; margin-bottom: 100px;">
                        <td colspan="4">
                         
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ອາຫານກຳລັງເຮັດ</span>
                </div>  
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="5%">
                          ໂຕະ
                        </th>
                        <th class="has-text-centered " width="30%">
                          ເມນູ
                        </th>

                        <th class="has-text-centered " width="10%">
                          ຈັດການ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in cookingMenu"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.TABLENAME }}
                        </td>
                        <td class="has-text-centered">
                          
                        <img
                          style=" width: 50px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /> <br />
                        {{ data.MENUNAME}} <span style="color: red;">X</span> {{ data.QUANTITY }} {{ data.MENUNIT }}
                        </td>
                        <td class="has-text-centered">
                          <b-field style="margin-bottom: 0px; ">
                            <b-button
                              class="btn-group-margin"
                              type="is-primary" 
                              expanded
                              style="width: 100%;"
                             @click="doPrePrint(data,1)"
                              >ພິມລາຍການ
                            </b-button>
                          </b-field>
                          
                          <b-field style="margin-bottom: 0px; ">
                            <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-info"
                              expanded
                              @click="updateOrderMenuDetailStatus(data.DETAILID,4)"
                              >ສຳເລັດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                
              </div>
              
            </div>

          </div>

          
          
        </div>
        
      </div>
    </div>

    <div class="contain print-only">
      <div class="column" style=" padding-left: 10px; padding-right: 10px; margin-bottom: 20px;" v-if="printtype == 1">
          <div
            style="font-size: 20px; text-align: center;">
              ລາຍການອາຫານ
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ຮ້ານ:
              {{ selectedPosName }} / ໂຕ: {{ selectedTableName }}
          </div>
          <div class="paymentdetailselected print-receipt-titel">
            ເວລາສັ່ງ:
         
              {{ selectedTXTIME }}
          </div>
          
          <div>
            ເມນູ: {{ selectedMenuname }} x  {{ selectedMenuAmount }}
          </div>
      </div>

      <div class="column" style=" padding-left: 10px; padding-right: 10px; margin-bottom: 20px;" v-if="printtype == 2">
        
        <div v-for="data in printset " :key="data.ID"  style="margin-bottom: 20px;" class="print-page">
          <div
            style="font-size: 20px; text-align: left;">
              ລາຍການອາຫານ
          </div>
          <div>
            <div class="paymentdetailselected print-receipt-titel">
              ຮ້ານ:
                {{ data.ORDEREDPOSNAME }} - {{ data.ORDEREDTABLENAME }} / ໂຕ: {{ data.TABLENAME }}
            </div>
            <div class="paymentdetailselected print-receipt-titel">
              ເວລາສັ່ງ:
            
                {{ data.LASTUPDATE }}
            </div>
            
            <div>
              ເມນູ: {{ data.MENUNAME }} x  {{ data.QUANTITY }}
            </div>
          </div>
          <div>
            -----------------
          </div>
        </div>
        
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      videopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/video/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/video/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      orderedMenu: [],
      batchlist: [],
      cookingMenu: [],
      loadingData: null,
      isVideo: false,
      isReject: false,
      selectedRejectReason : null,
      rejectReasonList: [
        { ID: 1, NAME: 'ອາຫານໝົດ' },
        { ID: 2, NAME: 'ອື່ນໆ'  }
      ], 

      inGredientMenuList: [],
      selectedMenu: null,
      videoname: null,

      selectedMenuname: null,
      selectedMenuAmount: null,
      selectedPosName: null,
      selectedTXTIME: null,
      selectedTableName: null,

      printtype: null,
      printset: [],

      isSelect_date: 0,
      paydate: null,
    };
  },
  methods: {
    rejectMenuByBatch(batchid, status){

    this.$swal({
      title: "ເລືອກເຫດຜົນ",
      html: `
        <div>
          <select id="multi-select" multiple class="swal2-select">
            <option value="ອາຫານຫມົດ">ອາຫານຫມົດ</option>
            <option value="ເຫດຜົນອື່ນໆ">ເຫດຜົນອື່ນໆ</option>
          </select>
        </div>
      `,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "ປະຕິເສດ Menu",
      cancelButtonText: "ຍົກເລີກ",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then((result) => {
      if (result.value) {
        var reason = Array.from(document.getElementById("multi-select").selectedOptions).map(option => option.textContent).join(", ");
        if(!reason){
          this.$swal("ກະລຸນາເລືອກເຫດຜົນ", "", "error");
          return false;
        }
        var params = {
          batchid: batchid,
          status: status,
          paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),
          username: this.username,

        };

        console.log("params: " + JSON.stringify(params))
        
        RestAPI.updateOrderMenuDetailStatusByBatch(params)
          .then(() => {
            this.$swal({
              title: "ສຳເລັດ",
              text: "ດຳເນີນການ ສຳເລັດ!",
              icon: "success",
              timer: 500, // Set the timer in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the confirmation button
            });

            this.getChefMenu();
            
            
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    });  
    },
    updateOrderMenuDetailStatusByBatch(batchid, status){
      var params = {
        batchid: batchid,
        status: status,
        paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),
        username: this.username,
      };
      console.log("params: " + JSON.stringify(params))
      RestAPI.updateOrderMenuDetailStatusByBatch(params)
      .then(() => {
        this.$swal({
          title: "ສຳເລັດ",
          text: "ດຳເນີນການ ສຳເລັດ!",
          icon: "success",
          timer: 500, // Set the timer in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the confirmation button
        });

        this.getChefMenu();
        
        
      })
      .catch((error) => {
        console.log(error);
        if (error == "Error: Request failed with status code 400") {
          this.$swal("Check Input Data", "", "error");
        } else {
          this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
        }
      });
    },

    updateOrderMenuDetailStatusOnlyOne(detailid, status){  
      var params = {
        detailid: detailid,
        status: status,
        paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),
        username: this.username,
      };
      console.log("params: " + JSON.stringify(params))

      RestAPI.updateOrderMenuDetailStatusOnlyOne(params)
      .then(() => {
        this.$swal({
          title: "ສຳເລັດ",
          text: "ດຳເນີນການ ສຳເລັດ!",
          icon: "success",
          timer: 500, // Set the timer in milliseconds (2 seconds in this example)
          showConfirmButton: false, // Hide the confirmation button
        });

        this.getChefMenu();
        
        
      })
      .catch((error) => {
        console.log(error);
        if (error == "Error: Request failed with status code 400") {
          this.$swal("Check Input Data", "", "error");
        } else {
          this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
        }
      });
    },
    updateOrderMenuDetailStatus(detailid, status){
      
          var params = {
            orderdetailidid: detailid,
            status: status,
            paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),
            username: this.username,
          };
          console.log("para: " +JSON.stringify(params))
          
          RestAPI.updateOrderMenuDetailStatus(params)
            .then(() => {
              // this.$swal("ສຳເລັດ", "ດຳເນີນການ ສຳເລັດ", "success");

              this.$swal({
                title: "ສຳເລັດ",
                text: "ດຳເນີນການ ສຳເລັດ!",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });

              this.getChefMenu();
              
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
 
    },

    rejectMenu(detailid, status, tableid = null){

      this.$swal({
        title: "ເລືອກເຫດຜົນ",
        html: `
          <div>
            <select id="multi-select" multiple class="swal2-select">
              <option value="ອາຫານຫມົດ">ອາຫານຫມົດ</option>
              <option value="ເຫດຜົນອື່ນໆ">ເຫດຜົນອື່ນໆ</option>
            </select>
          </div>
        `,
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ປະຕິເສດ Menu",
        cancelButtonText: "ຍົກເລີກ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var reason = Array.from(document.getElementById("multi-select").selectedOptions).map(option => option.textContent).join(", ");
          if(!reason){
            this.$swal("ກະລຸນາເລືອກເຫດຜົນ", "", "error");
            return false;
          }
          var params = {
            orderdetailidid: detailid,
            status: status,
            reason: reason,
            tableid: tableid,
            paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),
            username: this.username,
          };
          console.log("para: " +JSON.stringify(params))
          RestAPI.updateOrderMenuDetailStatus(params)
            .then(() => {
              // this.$swal("ສຳເລັດ", "ດຳເນີນການ ສຳເລັດ", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຊຳລະສິນຄ້າ ສຳເລັດ!",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
              this.getChefMenu();
              
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });  
    },

    getChefMenu(){
      RestAPI.getChefMenu(this.posid)
        .then(res => {
          // console.log("getChefMenu: "+ JSON.stringify(res.data))
          this.orderedMenu = res.data.orderedmenu;
          this.batchlist = res.data.batchlist;
          this.cookingMenu = res.data.cookingmenu;
          this.isReject = false;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    doPrePrint(data, type) {
      this.printtype = type;
      this.setData(data);
      setTimeout(() => {
        this.print();
      }, 1000);
    },

    setData(data) {
      this.selectedMenuname = data.MENUNAME;
      this.selectedMenuAmount = data.QUANTITY;
      this.selectedPosName = data.ORDEREDPOSNAME;
      this.selectedTXTIME = data.LASTUPDATE;
      this.selectedTableName = data.TABLENAME;
      this.selectedPosName = data.POSNAME;
      console.log("data: " + JSON.stringify(data))
    },


    doPrePrintSet(batchid, type) {
      this.printtype = type;

      RestAPI.getChefPrintBatch(batchid)
        .then(res => {
          this.printset = res.data;          
          setTimeout(() => {
            this.print();
          }, 1000);
        })
        .catch(error => {
          console.log(error);
          this.$swal("Error", "There was an error fetching data", "error");
        });

      
      
    },

    

    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
    loadData () {
        this.loadingData = setInterval(() => {
            this.getChefMenu()
        }, 5000)
    },
    playVideo(menuid, videoname, menuname){
      // Set the video source
      this.getProductIngredientList(menuid, videoname);
      this.selectedMenu = menuname;
      this.videoname = videoname;
      
    },
    closeVideo(){
      this.isVideo = false;
      this.$refs.videoref.pause();
      this.videoname = null;
    },
    getProductIngredientList(menuid, videoname){
      RestAPI.getProductIngredientList(menuid)
        .then(res => {
          this.inGredientMenuList = res.data;
          this.isVideo =true;
          if(videoname){
            this.$refs.videoref.src = this.videopath + videoname;
            // Play the video
            this.$refs.videoref.play();
          }
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("Error", "There was an error fetching data", "error");
        });
    },

    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },

    getPosSelectDate(){
      RestAPI.getPosSelectDate()
        .then(res => {
          console.log(res.data);
          this.isSelect_date = res.data.ISSELECTDATE;
          this.paydate = this.isSelect_date == 1 ? res.data.PAYDATE : moment().format("YYYY-MM-DD");
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
  },

  
 
  beforeDestroy () {
      clearInterval(this.loadingData);
  },
  created () {
      this.loadData()
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.getChefMenu();
    this.getPosSelectDate();
    this.paydate = this.isSelect_date == 1 ? this.paydate : moment().format("YYYY-MM-DD");
    // this.$refs.videoref.src = "http://iandevlin.github.io/mdn/video-player/video/tears-of-steel-battle-clip-medium.mp4";
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

.swal2-select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
  }
 
  /* @media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
} */

@media print {
  @page {
    margin: 0;
    padding: 0;
    size: 6in 9in;
  }

  .print-only {
    display: block;
  }

  .print-page {
    page-break-after: always; /* Ensures each loop item starts on a new page */
  }

  .print-page:last-child {
    page-break-after: auto; /* Avoids an extra blank page at the end */
  }
}

</style>
