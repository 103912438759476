<template>
  <div id="app">
    <Menu />
    <vue-progress-bar />
    <router-view />
  </div>
</template>

<style lang="scss">
html,
body {
  height: 100%;
  min-height: 100%;
  font-family: "Phetsarath OT", sans-serif;
}

#app {
  height: 100%;
}

.print-only {
  /*visibility: hidden;*/
  display: none;
}
@media print {
  @page {
    margin: 0.2cm 1cm 0 1cm;
    size: A4;
    size: portrait;
  }
  .section {
    margin-top: 5px !important;
  }
  html,
  body {
    background: white;
    margin: 0px;
    color: #000; /* Black text */
    background-color: #fff; /* White background */
    font-weight: bold; /* Use a bold font weight */
  }

  .print-only {
    /*visibility: visible;*/
    display: inherit;
    
  }

  .no-print {
    /* visibility: hidden;
            height: 0 !important;
            width: 0 !important;*/
    display: none;
  }

 .no-print-hide {
    display: none;
  }
  .print-only-hide {
    display: block;
  }
  th,
  td {
    white-space: nowrap;
  }
}
.navbar.is-primary {
  background-color: #039be5 !important;
  color: #fff !important;
  height: 10px;
}
.navbar-link { 
    background-color: #039be5 !important;
    color: #fff !important;
    height: 40px;
}
.navbar.is-primary .navbar-dropdown a.navbar-item.is-active { 
    background-color: #039be5 !important;
    color: #fff !important;
}
.navbar.is-primary .navbar-brand > a.navbar-item:focus, .navbar.is-primary .navbar-brand > a.navbar-item:hover{
  background-color: #039be5 !important;
}
.navbar-dropdown{
  margin-top:0px;
}
.navbar-burger{
  // background-color: black;
  margin-top:-13px !important;
}

.tooltip-botton {
  padding-right: 5px;
}
table > td .text-right {
  text-align: right !important;
}
table > td .text-left {
  text-align: left !important;
}
table > td .text-center {
  text-align: center !important;
}
.title {
  font-size: 28px !important;
}
.subtitle {
  font-size: 20px;
  font-weight: bold;
}

.subtitle-bold {
  font-size: 20px;
  font-weight: bold;
}

body {
  font-family: "Phetsarath OT" !important;
}
.content-link {
  font-weight: 700;
}
.group-button-right {
  display: block;
  margin-left: auto;
  margin-bottom: 15px;
  margin-top: -10px;
}
.menu-sperator {
  margin-top: 5px;
  margin-bottom: 10px;
}
.page-sperator {
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 900;
}
.text-small {
  font-size: 14px;
  font-weight: 300;
}
.vertical-middle {
  vertical-align: middle !important !important;
}
.sumary-area {
  height: 50px;
  vertical-align: middle;
}
// .equipment-label {
//   width: 30% !important;
// }
// .equipment-input {
//   width: 100% !important;
// }
.equipment-table {
  margin-top: 30px;
}
.equipment-tr {
  height: 45px;
}
.equipment-item-table {
  // margin-left: 20px;
  // margin-top: 30px;
}
.supplierModal {
  padding: 15px 15px;
}

.paymentform-area {
  margin: auto;
}
.paymentform-top {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
}
.paymentform-title {
  font-size: 30px;
  font-weight: 900;
  text-align: center;
  margin-bottom: 10px;
}
.paymentdata-table {
  font-size: 14px;
}
.paymentform-signature-tr {
  height: 120px;
}
.no-label {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}
.scrollme {
  overflow-x: auto;
}
.max-width {
  width: 100%;
}
.equipmenttypetitle {
  font-size: 20px;
  font-weight: 800;
}
.bnt-sperator {
  margin: 3px 3px 3px 3px;
}

.user-info {
  font-size: 18px;
}
.user-info-highlight {
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;
}
.important-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}
.msg {
  color: red;
}
// .button{
//   background-color: #c22034 !important;
// }

.button:focus:not(:active), .button.is-primary.is-focused:not(:active) {
  box-shadow: 0 0 0 0.125em rgba(238, 36, 36, 1) !important;
}

.approved-lable{
  background-color: #3eaf7c; 
  color: white;
  padding: 5px 10px 5px 10px;
  box-shadow: 0 0 0 0.125em #3eaf7c;
  border-radius: 2px 2px;
}
.waitapprove-lable{
  background-color: rgb(224, 141, 33);
  color: white;
  padding: 5px 10px 5px 10px;
  box-shadow: 0 0 0 0.125em rgb(224, 141, 33);
  border-radius: 2px 2px;
}

.lable-rejected{
  background-color: rgba(238, 36, 36, 1);
  color: white;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  border-radius: 2px 2px;
}
.lable-wait{
  background-color: #ffdb4a;
  color: black;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  border-radius: 2px 2px;
}
.lable-approved{
  background-color: #3eaf7c;
  color: white;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  border-radius: 2px 2px;
}

.lable-approved-receieveall{
  background-color: #1496ed;
  color: white;
  padding: 2px 5px 2px 5px;
  font-size: 14px;
  border-radius: 2px 2px;
}


.notiarea{
  background-color: rgb(238, 200, 151) !important;
}

.small-button{
  font-size: 14px !important;
}

.notibuble{
  font-size: 20px;
  font-weight: 900;
  background-color: white;
  color: red;
  padding: 9px 20px 8px 20px;
  border-radius: 50%
}
.notimenulist{
  color: red;
}

.equipmentApproveModal {
  padding: 15px 15px;
}

.photoModal{
  width: 100% !important !important;
  height: 100% !important !important;
  background-color: red;
  padding: 0;
}

.input-text-right input {
    text-align: right;
}

.btn-group-margin{
  margin:3px;
}

.disabledtablerowcolor{
  background-color: #ffccbc;
}

.approvedoctype{
  background-color: yellow !important;
  -webkit-print-color-adjust: exact !important;
}

.debtdoctype{
  background-color: #f3354b !important;
  -webkit-print-color-adjust: exact !important;
  color: white !important;
  
  
}

.paiddoctype{
  background-color: rgb(24, 172, 24) !important;
  -webkit-print-color-adjust: exact !important;
  color: white !important;
}



.waitandpaiddoctype{
  background-color: rgb(128, 37, 212) !important;
  -webkit-print-color-adjust: exact !important;
  color: white !important;
}

.officepaymentdoctype{
  background-color: rgb(16, 140, 241) !important;
  -webkit-print-color-adjust: exact !important;
  color: white !important;
}

.debtdoctypecolor{
  color: white !important !important;
}

.debtdoctype th {
     color:white !important; // Replace it with your desired color
 }


.doctypetop{
  height: 30px;
  width: 100%;
}

.doctype-header{
  display: inline-block; 
  width: 100%; 
  height: 130px;
  // background-color: #ce8888;
  text-align: left;
  
}

.doctype-header-logo{
  margin-top: 5px;
  display: inline-block; 
  width: 20%; 
  height: 130px;
  // background-color: red;
  text-align: left;
  vertical-align: top;
  
}

.doctype-header-company-info{
  display: inline-block; 
  width: 50%; 
  height: 130px;
  // background-color: orange;
  text-align: left;
  font-size: 20px;
}

.doctype-header-doctopleft{
  display: inline-block; 
  width: 30%; 
  height: 130px;
  // background-color: orange;
  text-align: left;
  font-size: 20px;
}



.doctype-header-right{
  display: inline-block; 
  width: 25%; 
  height: 130px;
  // background-color: green;
  text-align: left;
}

.color-white{
  color: white;
}

.nbsp{
  margin-left: 10px;
  margin-right: 10px;
  vertical-align: middle !important;
}

.modal-content{
  padding: 15px;
}

.label-succes{
  background-color: #3eaf7c;
  box-shadow: 0 0 0 0.125em #3eaf7c;
  border-radius: 2px 2px;
  color: white;
}

.text-highlight{
  font-weight: bold;
}

.issue-date{
  color: #ffccbc;
}
.issue-response{
  text-align: right;
}

.statusLabel-gray{
  border-radius: 5px;
  background: #a3a89c;
  padding: 3px;
  text-align: center;
  font-size: 12px;
}

.statusLabel-green{
  border-radius: 5px;
  background: #1caf1c;
  padding: 3px;
  text-align: center;
  font-size: 12px;
}

.statusLabel-yellow{
  border-radius: 5px;
  background: rgb(214, 214, 17);
  padding: 3px;
  text-align: center;
  font-size: 12px;
}

.statusLabel-pink{
  border-radius: 3ypx;
  background: #ff3860;
  color: white;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  font-size: 12px;
}
.statusLabel-organge{
  border-radius: 3ypx;
  background: #ec4807;
  color: white;
  font-weight: bold;
  padding: 5px;
  text-align: center;
  font-size: 12px;
}


.statusLabel-warning{
  border-radius: 5px;
  background: rgb(60, 76, 97);
  color: rgb(240, 231, 231);
  padding: 3px;
  text-align: center;
  font-size: 12px;
}
.statusLabel-passed{
  border-radius: 5px;
  background: rgb(0, 121, 235);
  color: white;
  padding: 3px;
  text-align: center;
  font-size: 12px;
}
</style>

<script>
import Menu from "./components/menu/Menu";
export default {
  components: { Menu }
};
</script>
