<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="title">
            Stock/ After Sell
          </h2>
        </div>
      </div>
      <b-tabs v-model="activeTab">
        <b-tab-item label="ສິນຄ້າລໍຖ້າອະນຸມັດອອກສາງ">
          <div class="scrollme" style=" padding: 20px;">
            <div class="columns">
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <b-field label="ສິນຄ້າຫຼັງການຂາຍ ລໍຖ້າອະນຸມັດອອກສາງ"></b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="10%">
                            ເວລາ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນເງິນ
                          </th>
                          <th class="has-text-centered " width="15%">
                            ຮູບແບບຊຳລະ
                          </th>
                          <th class="has-text-centered " width="10%">
                            ລາຍລະອຽດ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in history"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.TXTIME }}
                          </td>
                          <td class="has-text-right">
                            {{ data.TOTAL | formatNumberLocal }}
                          </td>
                          
                          <td class="has-text-centered">
                            {{ data.SELLERID }}
                            <br>
                            {{ data.PAYMENTTYPE }}
                          </td>
                        
                          

                          <td class="has-text-centered">
                            <b-field>
                              <b-button
                                class="btn-group-margin"
                                type="is-info"
                                size="is-small"
                                @click="paymentDetail(data)"
                                >ລາຍລະອຽດ
                              </b-button>
                            </b-field>
                          </td>
                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="2" class="has-text-centered">
                            ລວມ {{ history.length | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right">
                            {{ _.sumBy(history, i => i["TOTAL"]) | formatNumberLocal }}
                          </th>
                          <th colspan="2">
                          
                          </th>
                          
                        </tr>
                      </tfoot>
                    </table>

                  </div>
                  <div class="column">
                    <div v-show="isDetailSelected">
                      <div style="margin-top: 25px;">
                        <b-field label="ລາຍລະອຽດການຂາຍ"></b-field> 
                      </div>
                      <div class="paymentdetailselected" >
                        ເວລາຊຳລະ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຮູບແບບຊຳລະ:  <span class="paymentdetailselectedhightlight">{{  selectedPaymentType }}</span>
                      </div>
                      <div class="paymentdetailselected">
                        <b-button
                          class="btn-group-margin"
                          type="is-success"
                          size="is-small"
                          @click="approveAfterSell()"
                          >ອະນຸມັດ
                        </b-button>

                       
                      </div>
                      
                      
                      <div>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="20%">
                                ສິນຄ້າ
                              </th>

                              <th class="has-text-right " width="15%">
                                ຈຳນວນ
                              </th>
                              <th class="has-text-right " width="15%">
                                ລາຄາ
                              </th>
                              <th class="has-text-right " width="10%">
                                ລວມ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in historydetail"
                              :key="data.ID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td>
                                {{ data.PRODUCTNAME_SUB }}
                              </td>
                              <td class="has-text-right" >
                                {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                              </td>
                              
                              <td class="has-text-right" >
                                {{ data.UNITPRICE | formatNumberLocal }} 
                              </td>
                              <td class="has-text-right" >
                                {{ data.TOTAL | formatNumberLocal }} 
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="ສິນຄ້າຜ່ານການກວດສອບແລ້ວ">
          <div class="columns">
            <div class="column">
              <b-field label="ສິນຄ້າຫຼັງການຂາຍ ຜ່ານການກວດສອບແລ້ວ"></b-field>
                <div class="columns">
                  <div class="column">
                    <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getAfterSellApprovedReport()">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="10%">
                            ເວລາ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນເງິນ
                          </th>
                          <th class="has-text-centered " width="15%">
                            ຮູບແບບຊຳລະ
                          </th>
                          <th class="has-text-centered " width="10%">
                            ລາຍລະອຽດ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in approvedhistory"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.TXTIME }}
                          </td>
                          <td class="has-text-right">
                            {{ data.TOTAL | formatNumberLocal }}
                          </td>
                          
                          <td class="has-text-centered">
                            {{ data.SELLERID }}
                            <br>
                            {{ data.PAYMENTTYPE }}
                          </td>
                        
                          

                          <td class="has-text-centered">
                            <b-field>
                              <b-button
                                class="btn-group-margin"
                                type="is-info"
                                size="is-small"
                                @click="paymentDetail(data)"
                                >ລາຍລະອຽດ
                              </b-button>
                            </b-field>
                          </td>
                        </tr>
                        
                      </tbody>
                     
                    </table>

                  </div>
                  <div class="column">
                    <div v-show="isDetailSelected">
                      <div style="margin-top: 25px;">
                        <b-field label="ລາຍລະອຽດການຂາຍ"></b-field> 
                      </div>
                      <div class="paymentdetailselected" >
                        ເວລາຊຳລະ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                      </div>
                      <div class="paymentdetailselected">
                        ຮູບແບບຊຳລະ:  <span class="paymentdetailselectedhightlight">{{  selectedPaymentType }}</span>
                      </div>
                      
                      
                      
                      <div>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="20%">
                                ສິນຄ້າ
                              </th>

                              <th class="has-text-right " width="15%">
                                ຈຳນວນ
                              </th>
                              <th class="has-text-right " width="15%">
                                ລາຄາ
                              </th>
                              <th class="has-text-right " width="10%">
                                ລວມ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in historydetail"
                              :key="data.ID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td>
                                {{ data.PRODUCTNAME_SUB }}
                              </td>
                              <td class="has-text-right" >
                                {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                              </td>
                              
                              <td class="has-text-right" >
                                {{ data.UNITPRICE | formatNumberLocal }} 
                              </td>
                              <td class="has-text-right" >
                                {{ data.TOTAL | formatNumberLocal }} 
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="4" class="has-text-centered">
                                ລວມ {{ historydetail.length | formatNumberLocal }} ລາຍການ
                              </th>
                              <th class="has-text-right">
                                {{ _.sumBy(historydetail, i => i["TOTAL"]) | formatNumberLocal }}
                              </th>
                              
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                    
                  </div>
                </div>
             
            </div>
          </div>
        </b-tab-item>

        

        
      </b-tabs>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";

export default {
  // components: { Multiselect },
  props: ["MONTH"],
  data() {
    return {
      roleid: null,
      userName: null,
      activeTab: 0,
      reportstockin: [],
      reportstockout: [],
      reportproduct: [],
      reportproductdetail: [],
      startdate: null,
      enddate: null,
      history: [],
      approvedhistory: [],
      historydetail: [],
      isDetailSelected: false,
      selectedPaymentDate : null,
      selectedTotal : null,
      selectedPaymentType : null,
      selectedSellerid: null,
      sellertitle: null,
      selectedPaymentID: null,
      selectedPaymentApproved: null,

    };
  },
  methods: {

    approveAfterSell(){
      var params = {
          paymentid: this.selectedPaymentID,
          username: this.username
        };
      RestAPI.approveAfterSell(params)
        .then((res) => {
          if(res.data =="ok"){
            this.getAfterSellReport();
            this.getAfterSellApprovedReport();
            this.$swal("ສຳເລັດ", "ອະນຸມັດລາຍການສຳເລັດ", "succsss");
          }else{
            this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
              return false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getAfterSellReport(){
      this.isDetailSelected = false;
      console.log("start:" + this.startdate)
      RestAPI.getAfterSellReport(this.posid)
      .then(res => {
        this.history = res.data;
      })
      .catch(error => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },

    getAfterSellApprovedReport(){
      this.isDetailSelected = false;
      RestAPI.getAfterSellApprovedReport(this.posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
      .then(res => {
        this.approvedhistory = res.data;
      })
      .catch(error => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },


    paymentDetail(data){
      this.selectedPaymentID = data.ID;
      this.selectedPaymentApproved = data.ISAPPROVED;
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getPaymentReportDetail(data.ID)
        .then(res => {
          this.isDetailSelected = true;
          this.historydetail = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    getStockReport(){
      console.log("ok")
      RestAPI.getStockReport(this.posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.reportstockin = res.data.stockin;
          this.reportstockout = res.data.stockout;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getStockProduct(){
      RestAPI.getStockProductRemainReport(this.posid)
        .then(res => {
          this.reportproduct = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    stockDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getStockProductRemainReportDetail(this.posid, data.PRODCUTID)
        .then(res => {
          this.isDetailSelected = true;
          this.reportproductdetail = res.data;
          console.log("d: " + JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.posid = this.user.POSID;
    this.startdate = new Date();
    this.enddate = new Date();
    // this.getStockReport();
    // this.getStockProduct();
    this.getAfterSellReport();
    this.getAfterSellApprovedReport();
    
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
