<template>
  <section>
    <div class="contain no-print">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ເມນູທີ່ຍັງເຫຼືອ</div>
              </div>
            </div>

           
            <div class="columns" >
              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <b-field grouped>
                  <b-field>
                    <span style="font-size: 28px;">ລາຍງານ ເມນູທີ່ຍັງເຫຼືອ</span>
                  </b-field>
                  <b-field>
                    <Multiselect
                      placeholder="ປະເພດເມນູ"
                      v-model="selectedMenuType"
                      label="NAME"
                      track-by="ID"
                      :options="menuTypeList"
                      @input="onSeletedMenuType"
                    >
                    </Multiselect>
                  </b-field>
                </b-field>
                    
       
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="5%">
                          ປະເພດ
                        </th>
                        <th class="has-text-left " width="20%">
                          ເມນູ
                        </th>

                        <th class="has-text-right " width="10%">
                          ຈຳນວນ
                        </th>
                        <th class="has-text-centered " width="15%">
                          ລາຍລະອຽດ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuremain"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-left">
                          {{ data.TYPENAME }}
                        </td>
                        <td class="has-text-left">
                          {{ data.NAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMBEROFMENU | formatNumberLocal}}
                        </td>
                        <td class="has-text-centered">
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="getPosRemainMenuDetail(data.ID, data.NAME)"
                              >ລາຍລະອຽດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                      
                    </tbody>
                    
                  </table>
              </div>

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                  <b-field style="font-size: 28px;">
                    ລາຍລະອຽດສ່ວນປະກອບ ເມນູ <span style="color: red;">{{ selectedMenu }}</span>
                  </b-field>
                  <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="30%">
                          ສ່ວນປະກອບ
                        </th>
                        <th class="has-text-right " width="10%">
                          ອັດຕາສ່ວນ
                        </th>


                        <th class="has-text-right " width="10%">
                          ຈຳນວນ<br>
                          ທີ່ມີ
                        </th>
                        <th class="has-text-right " width="10%">
                          ຈຳນວນ<br>ເມນູ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuIngredientList"
                        :key="data.PRODUCTID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-left">
                          {{data.PRODUCTID}} - {{ data.PRODUCTNAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.Q_INGREDIENT | formatNumberLocal}}  {{ data.UNIT }}  
                        </td>
                        <td class="has-text-right">

                          {{ data.Q_PRODUCT | formatNumberLocal}} {{ data.UNIT }}
                        </td>
                        <td class="has-text-right">
                          {{ data.NUMBEROFMENU | formatNumberLocal}} {{ data.UNIT }} 
                        </td>
                      </tr>
                      
                    </tbody>
                    
                  </table>
                  <b-field style="margin-top: 80px;">
                    <b-field grouped>
                      <b-field label="ຄົນຫາສ່ວນປະກອບເມນູ">
                        <input 
                          class="input"
                          placeholder="ສ່ວນປະກອບເມນູ"
                          v-model="ingredientkey"
                          style=""
                        />
                      </b-field>
                      <b-field style="padding-top: 28px;">
                        <b-button
                          class="btn-group-margin"
                          type="is-danger"
                          @click="searchIngredient()"
                          >ຄົ້ນຫາ
                        </b-button>
                      </b-field>
                    </b-field>
                  </b-field>
                  <b-field style="margin-top: 10px; ">ຜົນການຄົ້ນຫາ</b-field>
                  <table class="table is-fullwidth is-narrow is-small" style="width: 40%;">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="30%">
                          ລາຍການ
                        </th>


                        <th class="has-text-right " width="10%">
                          ຈຳນວນ
                          ທີ່ມີ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in ingredientList"
                        :key="data.PRODUCTID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-left">
                          {{data.PRODUCTID}} - {{ data.PRODUCTNAME }}
                        </td>
                        <td class="has-text-right">
                          {{ data.QUANTITY | formatNumberLocal}} {{ data.UNIT }}
                        </td>
                      </tr>
                      
                    </tbody>
                    
                  </table>

                
                
              </div>
              
            </div>

          </div>
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      
      menuremain: [],
      selectedMenuType: null,
      menuTypeList: [],
      menuIngredientList: [],
      isDetail: 0,
      selectedMenu: null,

      ingredientkey: null,
      ingredientList: [],
    };
  },
  methods: {

    searchIngredient(){
      var params = {
            posid: this.posid,
            key: this.ingredientkey,
            username: this.username,
          };
          RestAPI.searchIngredient(params)
            .then(res => {
                this.ingredientList = res.data;
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });

    },

    getPosRemainMenuDetail(menuid,menuname){
      this.selectedMenu = menuname,
      this.isDetail = 1;
      RestAPI.getPosRemainMenuDetail(this.posid, menuid)  
        .then(res => {
          this.menuIngredientList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    onSeletedMenuType(){
      this.getPosMenuRemainReport(this.selectedMenuType.ID);
    },

    
    getPosMenuRemainReport(typeid){ 
      var menutype = typeid ? typeid : 'All';  
      RestAPI.getPosRemainMenu(this.posid, menutype)  
        .then(res => {
          this.menuremain = res.data.product;
          // console.log("allpayment : "+JSON.stringify(res.data.product))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getMenutype(){
      RestAPI.getPosRemainMenuSubType(this.posid)
        .then(res => {
          this.menuTypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();
    this.getPosMenuRemainReport();
    this.getMenutype();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
  font-weight: bold;
}
.print-receipt-titel{
  font-weight: bold;
  font-size: 12px;
}

.print-receipt {
  font-weight: bold;
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
  font-weight: bold;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
