<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="title">
            Admin/ Stock Report
          </h2>
        </div>
      </div>

      <b-tabs v-model="activeMainTab">
        <b-tab-item label="ລາຍການ POS">
          <b-field label="ລາຍການ POS"></b-field>
          <table class="table is-fullwidth is-narrow is-small">
            <thead>
              <tr>
                <th class="has-text-centered " width="5%">#</th>
                <th class="has-text-centered " width="5%">
                  ລະຫັດ
                </th>
                <th class="has-text-left " width="15%">
                  POS
                </th>

                <th class="has-text-right " width="15%">
                  ຈຳນວນສິນຄ້າ
                </th>
                
                <th class="has-text-centered " width="15%">
                  ລາຍລະອຽດ
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(data, index) in reportPos"
                :key="data.POSID"
              >
                <td class="has-text-centered">{{ index + 1 }}</td>
                <td class="has-text-centered">
                  {{ data.POSID }}
                </td>
                <td class="has-text-left">
                  {{ data.POSNAME }}
                </td>
                
                <td class="has-text-right">
                  {{ data.NUMOFPRODUCT | formatNumberLocal }}
                </td>
                
                <td class="has-text-centered">
                  <b-field>
                    <b-button
                      class="btn-group-margin"
                      type="is-info"
                      size="is-small"
                      @click="posDetail(data)"
                      >ລາຍລະອຽດ
                    </b-button>
                  </b-field>
                </td>
              
                
              </tr>
              
            </tbody>
            
          </table>
        </b-tab-item>
        <b-tab-item label="ລາຍລະອຽດ" >
          <b-field :label="postitle"></b-field>
          <b-tabs v-model="activeTab" v-if="isDetailPosSelected">
            <b-tab-item label="ລາຍງານສິນຄ້າຂາເຂົ້າ">
              <div class="columns">
                <div class="column">
                  <b-field label="ລາຍງານສິນຄ້າຂາເຂົ້າ"></b-field>
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          
                          <b-field label="ຈາກວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="startdate"
                            >
                            </b-datepicker>
                          </b-field>
                          <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                          <b-field label="ເຖິງວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="enddate"
                            >
                            </b-datepicker>
                          </b-field>
                          <b-field style="padding-left: 20px; padding-top: 32px;" >
                            <b-button type="is-info" expanded style="width: 100%;" @click="getStockReport()">ລາຍງານ</b-button>
                          </b-field>

                          
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-centered " width="5%">
                                ລະຫັດ
                              </th>
                              <th class="has-text-left " width="15%">
                                ສິນຄ້າ
                              </th>

                              <th class="has-text-right " width="15%">
                                ຈຳນວນ / ຫົວໜ່ວຍ
                              </th>
                              <th class="has-text-right " width="15%">
                                ຈຳນວນເງິນ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ປະເພດ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ຜູ້ສະໜອງ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ວັນທີ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in reportstockin"
                              :key="data.STOCKID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-centered">
                                {{ data.STOCKID }}
                              </td>
                              <td class="has-text-left">
                                {{ data.PRODUCTID }} {{ data.PRODUCTNAME }}
                              </td>
                              
                              <td class="has-text-right">
                                {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                              </td>
                              <td class="has-text-right">
                                {{ data.TOTAL | formatNumberLocal }}
                              </td>
                              <td class="has-text-centered">
                                {{ data.STOCKTPYE }} 
                              </td>
                              <td class="has-text-centered">
                                {{ data.SUPPLIER }} 
                              </td>
                              <td class="has-text-centered">
                                {{ data.TXTIME }} 
                              </td>
                            
                              
                            </tr>
                            
                          </tbody>
                          
                        </table>
                      </div>
                    </div>
                
                </div>
              </div>
            </b-tab-item>
            <b-tab-item label="ລາຍງານສິນຄ້າຂາອອກ">
              <div class="columns">
                <div class="column">
                  <b-field label="ລາຍງານສິນຄ້າຂາອອກ"></b-field>
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          
                          <b-field label="ຈາກວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="startdate"
                            >
                            </b-datepicker>
                          </b-field>
                          <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                          <b-field label="ເຖິງວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="enddate"
                            >
                            </b-datepicker>
                          </b-field>
                          <b-field style="padding-left: 20px; padding-top: 32px;" >
                            <b-button type="is-info" expanded style="width: 100%;" @click="getStockReport()">ລາຍງານ</b-button>
                          </b-field>

                          
                        </b-field>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-centered " width="5%">
                                ລະຫັດ
                              </th>
                              <th class="has-text-left " width="15%">
                                ສິນຄ້າ
                              </th>

                              <th class="has-text-right " width="15%">
                                ຈຳນວນ / ຫົວໜ່ວຍ
                              </th>
                              <th class="has-text-right " width="15%">
                                ຈຳນວນເງິນ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ປະເພດ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ຜູ້ສະໜອງ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ວັນທີ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in reportstockout"
                              :key="data.STOCKID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-centered">
                                {{ data.STOCKID }}
                              </td>
                              <td class="has-text-left">
                                {{ data.PRODUCTID }} {{ data.PRODUCTNAME }}
                              </td>
                              
                              <td class="has-text-right">
                                {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                              </td>
                              <td class="has-text-right">
                                {{ data.TOTAL | formatNumberLocal }}
                              </td>
                              <td class="has-text-centered">
                                {{ data.STOCKTPYE }} 
                              </td>
                              <td class="has-text-centered">
                                {{ data.SUPPLIER }} 
                              </td>
                              <td class="has-text-centered">
                                {{ data.TXTIME }} 
                              </td>
                            
                              
                            </tr>
                            
                          </tbody>
                          
                        </table>
                      </div>
                    </div>
                
                </div>
              </div>
            </b-tab-item>

            <b-tab-item label="ລາຍງານຕາມສິນຄ້າ">
              <div class="scrollme" style=" padding: 20px;">
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column">
                        <b-field label="ລາຍງານຕາມສິນຄ້າ"></b-field>
                        <!-- <b-field>
                          <b-field style="padding-left: 20px; padding-top: 32px;" >
                            <b-button type="is-info" expanded style="width: 100%;" @click="getStockProduct()">ລາຍງານ</b-button>
                          </b-field>
                        </b-field> -->
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <b-field label="ລາຍງານສິນຄ້າໃນ Stock"> </b-field>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="15%">
                                ລະຫັດ/ ຊື່ສິນຄ້າ
                              </th>

                              <th class="has-text-right " width="15%">
                                ຈຳນວນ
                              </th>
                              <th class="has-text-centered " width="15%">
                                ຫົວໜ່ວຍ
                              </th>
                              <th class="has-text-centered " width="10%">
                                ລາຍລະອຽດ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in reportproduct"
                              :key="data.PRODCUTID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-left">
                                {{data.PRODCUTID}}/ {{ data.PRODUCTNAME }}
                              </td>
                              <td class="has-text-right">
                                {{ data.QUANTITY | formatNumberLocal }}
                              </td>
                              
                              <td class="has-text-centered">
                                {{ data.UNIT }}
                              </td>
                            
                              

                              <td class="has-text-centered">
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-info"
                                    size="is-small"
                                    @click="stockDetail(data)"
                                    >ລາຍລະອຽດ
                                  </b-button>
                                </b-field>
                              </td>
                            </tr>
                            
                          </tbody>
                          
                        </table>
                      </div>
                      <div class="column">
                        <div v-show="isDetailSelected">
                          <div style="margin-top: 25px;">
                            <b-field label="ລາຍລະອຽດ"></b-field> 
                          </div>
                          
                          <div>
                            <table class="table is-fullwidth is-narrow is-small">
                              <thead>
                                <tr>
                                  <th class="has-text-centered " width="5%">#</th>
                                  <th class="has-text-centered " width="5%">
                                    ລະຫັດ
                                  </th>
                                  <th class="has-text-centered " width="20%">
                                    ສິນຄ້າ
                                  </th>
                                  <th class="has-text-centered " width="20%">
                                    ລາຍການ
                                  </th>
                                  <th class="has-text-right " width="10%">
                                    ຂາເຂົ້າ
                                  </th>
                                  <th class="has-text-right " width="10%">
                                    ຂາອອກ
                                  </th>
                                  <th class="has-text-right " width="15%">
                                    ເປັນເງິນ
                                  </th>
                                  <th class="has-text-centered " width="20%">
                                    ເວລາ
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(data, index) in reportproductdetail"
                                  :key="data.ID"
                                >
                                  <td class="has-text-centered">{{ index + 1 }}</td>
                                  <td class="has-text-centered">
                                    {{ data.STOCKID }} 
                                  </td>
                                  <td class="has-text-centered">
                                    {{ data.PRODUCTID }}/ {{ data.PRODUCTNAME }}
                                  </td>
                                  <td class="has-text-centered" >
                                    {{ data.STOCKTPYE }} 
                                  </td>
                                  <td class="has-text-right" >
                                    {{ data.QUANTITYIN | formatNumberLocal}}
                                  </td>
                                  <td class="has-text-right" >
                                    {{ data.QUANTITYOUT | formatNumberLocal }}
                                  </td>
                                  
                                  <td class="has-text-right" >
                                    {{ data.TOTAL | formatNumberLocal }} 
                                  </td>
                                  <td class="has-text-centered" >
                                    {{ data.TXTIME }} 
                                  </td>
                                </tr>
                              </tbody>
                              
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  
                </div>
              </div>
            </b-tab-item>

            
          </b-tabs>
        </b-tab-item>
      </b-tabs>

      
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";

export default {
  // components: { Multiselect },
  props: ["MONTH"],
  data() {
    return {
      roleid: null,
      userName: null,
      activeMainTab: 0,
      activeTab: 0,
      reportstockin: [],
      reportstockout: [],
      reportproduct: [],
      reportproductdetail: [],
      startdate: null,
      enddate: null,
      history: [],
      historydetail: [],
      isDetailSelected: false,
      selectedPaymentDate : null,
      selectedTotal : null,
      selectedPaymentType : null,
      selectedSellerid: null,
      sellertitle: null,
      reportPos: [],
      isDetailPosSelected: false,
      postitle: null,

    };
  },
  methods: {
    paymentDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getPaymentReportDetail(data.ID)
        .then(res => {
          this.isDetailSelected = true;
          this.historydetail = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    sellerPaymentDetail(sellerid){
      this.sellertitle ="ລາຍການຊຳລະ: "+ sellerid;
      this.selectedSellerid = sellerid;
      this.activeTab  = 1;
      this.isDetailSelected = false;
      console.log("start:" + this.startdate)
      RestAPI.getPaymentReport(sellerid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
      .then(res => {
        this.history = res.data;
      })
      .catch(error => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    getStockReport(){
      RestAPI.getStockReport(this.posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.reportstockin = res.data.stockin;
          this.reportstockout = res.data.stockout;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getStockProduct(){
      RestAPI.getStockProductRemainReport(this.posid)
        .then(res => {
          this.reportproduct = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    stockDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getStockProductRemainReportDetail(this.posid, data.PRODCUTID)
        .then(res => {
          this.isDetailSelected = true;
          this.reportproductdetail = res.data;
          console.log("d: " + JSON.stringify(res.data))
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getStockPosRemainReport(){
      RestAPI.getStockPosRemainReport()
        .then(res => {
          this.reportPos = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    posDetail(data){
      this.postitle = "ລາຍລະອຽດ ສິນຄ້າໃນ Stock POS: " + data.POSID;
      this.activeMainTab = 1;
      this.posid = data.POSID;
      this.isDetailPosSelected = true;
      this.getStockReport();
      this.getStockProduct();
 
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
   
    this.startdate = new Date();
    this.enddate = new Date();
    this.getStockPosRemainReport();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
