<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="title">
            Manager/ Sell Report
          </h2>
        </div>
      </div>
      <b-tabs v-model="activeTab">
        

        <b-tab-item label="ລາຍງານການຂາຍ">
          <div class="columns">
            <div class="column">
              <b-field label="ລາຍງານການຂາຍ"></b-field>
                <div class="columns">
                  <div class="column">
                    <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="getPaymentReportGroupBySeller()">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="15%">
                            ພະນັກງານຂາຍ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="15%">
                            ຈຳນວນເງິນ
                          </th>
                          <th class="has-text-centered " width="10%">
                            ລາຍລະອຽດ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in report"
                          :key="data.SELLERID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td class="has-text-centered">
                            {{ data.SELLERID }}
                          </td>
                          <td class="has-text-right">
                            {{ data.PAYMENTCOUNT | formatNumberLocal }}
                          </td>
                          
                          <td class="has-text-right">
                            {{ data.TOTAL | formatNumberLocal }}
                          </td>
                        
                          <td class="has-text-centered">
                            <b-field>
                              <b-button
                                class="btn-group-margin"
                                type="is-info"
                                size="is-small"
                                @click="sellerPaymentDetail(data.SELLERID)"
                                >ລາຍລະອຽດ
                              </b-button>
                            </b-field>
                          </td>
                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="2" class="has-text-centered">
                            ລວມ {{ report.length | formatNumberLocal }} ພະນັກງານ
                          </th>
                          <th class="has-text-right">
                            {{ _.sumBy(report, i => i["PAYMENTCOUNT"]) | formatNumberLocal }}
                          </th>
                          <th class="has-text-right">
                            {{ _.sumBy(report, i => i["TOTAL"]) | formatNumberLocal }}
                          </th>
                          <th colspan="2">
                          
                          </th>
                          
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
             
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="ລາຍລະອຽດການຂາຍ">
          <div class="scrollme" style=" padding: 20px;">
            <div class="columns">
              <div class="column">
                <div class="columns">
                  <div class="column">
                    <b-field :label="sellertitle"></b-field>
                    <b-field>
                      
                      <b-field label="ຈາກວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="startdate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                      <b-field label="ເຖິງວັນທີ">
                        <b-datepicker
                          :date-formatter="dateFormatter"
                          v-model="enddate"
                        >
                        </b-datepicker>
                      </b-field>
                      <b-field style="padding-left: 20px; padding-top: 32px;" >
                        <b-button type="is-info" expanded style="width: 100%;" @click="sellerPaymentDetail(selectedSellerid)">ລາຍງານ</b-button>
                      </b-field>

                      
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">  
                          <table class="table is-fullwidth is-narrow is-small">
                            <thead>
                              <tr>
                                <th class="has-text-centered " width="5%">#</th>
                                <th class="has-text-centered " width="15%">
                                  ເວລາ
                                </th>

                                <th class="has-text-right " width="15%">
                                  ຈຳນວນເງິນ
                                </th>
                                <th class="has-text-centered " width="15%">
                                  ຮູບແບບຊຳລະ
                                </th>
                                <th class="has-text-centered " width="10%">
                                  ລາຍລະອຽດ
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in history"
                                :key="data.ID"
                              >
                                <td class="has-text-centered">{{ index + 1 }}</td>
                                <td class="has-text-centered">
                                  {{ data.TXTIME }}
                                </td>
                                <td class="has-text-right">
                                  {{ data.TOTAL | formatNumberLocal }}
                                </td>
                                
                                <td class="has-text-centered">
                                  {{ data.PAYMENTTYPE }}
                                </td>
                              
                                

                                <td class="has-text-centered">
                                  <b-field>
                                    <b-button
                                      class="btn-group-margin"
                                      type="is-info"
                                      size="is-small"
                                      @click="paymentDetail(data)"
                                      >ລາຍລະອຽດ
                                    </b-button>
                                  </b-field>
                                </td>
                              </tr>
                              
                            </tbody>
                            <tfoot>
                              <tr>
                                <th colspan="2" class="has-text-centered">
                                  ລວມ {{ history.length | formatNumberLocal }} ລາຍການ
                                </th>
                                <th class="has-text-right">
                                  {{ _.sumBy(history, i => i["TOTAL"]) | formatNumberLocal }}
                                </th>
                                <th colspan="2">
                                
                                </th>
                                
                              </tr>
                            </tfoot>
                          </table>
              
              
                  </div>
                  

                  
                  
                </div>
              </div>
              <div class="column">
                <div v-show="isDetailSelected">
                  <div style="margin-top: 25px;">
                    <b-field label="ລາຍລະອຽດການຂາຍ"></b-field> 
                  </div>
                  <div class="paymentdetailselected" >
                    ເວລາຊຳລະ: <span class="paymentdetailselectedhightlight"> {{ selectedPaymentDate }}</span>
                  </div>
                  <div class="paymentdetailselected">
                    ຈຳວນເງິນ:  <span class="paymentdetailselectedhightlight">{{  selectedTotal | formatNumberLocal}}</span>
                  </div>
                  <div class="paymentdetailselected">
                    ຮູບແບບຊຳລະ:  <span class="paymentdetailselectedhightlight">{{  selectedPaymentType }}</span>
                  </div>
                  
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-left " width="20%">
                            ສິນຄ້າ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="15%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in historydetail"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td>
                            {{ data.PRODUCTNAME_SUB }}
                          </td>
                          <td class="has-text-right" >
                            {{ data.QUANTITY | formatNumberLocal }} {{ data.UNIT }}
                          </td>
                          
                          <td class="has-text-right" >
                            {{ data.UNITPRICE | formatNumberLocal }} 
                          </td>
                          <td class="has-text-right" >
                            {{ data.TOTAL | formatNumberLocal }} 
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ historydetail.length | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right">
                            {{ _.sumBy(historydetail, i => i["TOTAL"]) | formatNumberLocal }}
                          </th>
                          
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </b-tab-item>

        
      </b-tabs>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";

export default {
  // components: { Multiselect },
  props: ["MONTH"],
  data() {
    return {
      roleid: null,
      userName: null,
      activeTab: 0,
      report: [],
      startdate: null,
      enddate: null,
      history: [],
      historydetail: [],
      isDetailSelected: false,
      selectedPaymentDate : null,
      selectedTotal : null,
      selectedPaymentType : null,
      selectedSellerid: null,
      sellertitle: null,

    };
  },
  methods: {
    paymentDetail(data){
      
      this.selectedPaymentDate = data.TXTIME;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;
      RestAPI.getPaymentReportDetail(data.ID)
        .then(res => {
          this.isDetailSelected = true;
          this.historydetail = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    sellerPaymentDetail(sellerid){
      this.sellertitle ="ລາຍການຊຳລະ: "+ sellerid;
      this.selectedSellerid = sellerid;
      this.activeTab  = 1;
      this.isDetailSelected = false;
      console.log("start:" + this.startdate)
      RestAPI.getPaymentReport(sellerid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
      .then(res => {
        this.history = res.data;
      })
      .catch(error => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    getPaymentReportGroupBySeller(){
      console.log("ok")
      RestAPI.getPaymentReportGroupBySeller(this.posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.report = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.posid = this.user.POSID;
    this.startdate = new Date();
    this.enddate = new Date();
    this.getPaymentReportGroupBySeller();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
