/* eslint-disable vue/no-unused-components */
<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="box column is-6 is-offset-3" id="login-box">
          <h1 class="title has-text-centered">Login</h1>
          <h1 v-if="false" class="title is-5 has-text-centered">ເຂົ້າລະບົບ</h1>
          <div class="columns">
            <div class="column is-8 is-offset-2">
              <div class="field">
                <label class="label">ຊື່ເຂົ້າໃຊ້:</label>
                <div class="control">
                  <input type="text" class="input" v-model="username" />
                </div>
              </div>
              <div class="field">
                <label class="label">ລະຫັດລັບ:</label>
                <div class="control">
                  <input
                    type="password"
                    class="input"
                    v-model="password"
                    @keyup.enter="doLogin"
                  />
                </div>
              </div>
              <div class="field">
                <div class="control">
                  <button
                    class="button is-primary is-fullwidth"
                    @click="doLogin"
                  >
                    ເຂົ້າລະບົບ
                  </button>
                </div>
              </div>
              <!-- <div class="field">
                <div class="control">
                  <button
                    class="button is-info is-fullwidth"
                    @click="goRegister"
                  >
                    ລົງທະບຽນ
                  </button>
                </div>
              </div> -->
              <div class="field">
                <b-field class="login-fail">
                  {{ this.errorMessage }}
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import restAPI from "./../RestAPI";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      errorMessage: null,
      role: null
    };
  },
  methods: {
    doRegister(){
       this.$router.replace("/register");
    },
    doLogin() {
      // console.log("there....");
      this.errorMessage = null;
      if (!this.validationForm()) {
        return;
      }
      const loadingComponent = this.$loading.open({});

      const req = {
        username: this.username,
        password: this.sha2(this.password)
      };
      console.log("req:" + JSON.stringify(this.username));
      restAPI
        .login(req)
        .then(res => {
          // console.log("res: " + JSON.stringify(res.data));
          //console.log("here....", res.data.jwt);
          // console.log("res.data.USERNAME....", res.data.USERNAME);
          this.role = res.data.ROLEID;
          const session = res.data;
          if (typeof res.data === "string") {
            console.log("error-: " + JSON.stringify(res.data));
            this.errorMessage = JSON.stringify(res.data);
            return;
          }

          console.log("data-: " + JSON.stringify(res.data));

          this.$store.set("token", session.jwt);
          this.$store.set("loginData", session);
          this.$store.set("decodedToken", VueJwtDecode.decode(session.jwt));
          if(res.data.TYPE !=2){
            if (res.data.ROLEID == "1") {
              this.$router.replace("/setting/menu");
            } else if (res.data.ROLEID == "2") {
              this.$router.replace("/pos/productrequest");
            }else if (res.data.ROLEID == "3") {
              this.$router.replace("/pos/productrequest");
            }else if (res.data.ROLEID == "4" || res.data.ROLEID == "5") {
              this.$router.replace("/seller");
            }else if (res.data.ROLEID == "6") {
              this.$router.replace("/chef");
            } 
          }else if(res.data.TYPE==2){
            if (res.data.ROLEID == "10" || res.data.ROLEID == "11") {
              this.$router.replace("/minimart");
            }
          }else {
            this.errorMessage = "ບໍ່ສາມາດເຂົ້າລະບົບໄດ້";
            return false;
          }
        })
        .catch(err => {
          if (err.response) {
            console.log("err-: " + err.response);
          } else {
            console.log("err+:" + err);
          }
        })
        .then(() => {
          loadingComponent.close();
        });
    },
    goRegister(){
      this.$router.replace("/register");
    },
    validationForm() {
      if (
        this.username === null ||
        this.username.length == 0 ||
        this.password === null ||
        this.password.length == 0
      ) {
        this.errorMessage = "ປ້ອນຊື່ເຂົ້າລະບົບ ແລະ/ຫຼື ລະຫັດລັບ ໃຫ້ຄົບ!";
        return false;
      }
      return true;
    }
  },
  mounted() {
    this.$store.set("token", null);
    this.$store.set("loginData", null);
    this.$store.set("decodedToken", null);
  }
};
</script>

<style scoped>
#login-box {
  padding: 20px;
  margin-top: 100px;
}
.login-fail{
  color:red;
}
</style>
