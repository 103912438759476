<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" v-if="istablemenu">
        <div class="columns ">

          <div class="column" style="padding: 30px 30px 30px 40px;"> 
            <div class="columns" style="margin: 0px 10px 10px 10px;"> 

                ຮ້ານ: {{ main_posname }}

            </div>
            <div class="columns is-multiline is-mobile" style="overflow: auto;">    
              <!-- <div class="columns is-multiline is-mobile" style="overflow: auto; max-height: 500px;">  -->
                <div
                  v-for="(table, index) in tables"
                  :key="index"
                  class="column is-one-quarter"
                >
                  <div class="card" style="width: 200px; height: 200px; border-radius: 10px;">
   
                    <div class="card-content-table " :class="table.STATUSTITLE" @click="selectedTable(1, posid, table)">
                      <div class="content">
                        <div>
                          {{ table.NAME }} <span v-if="table.SELLERID != null" style="font-size:16px"> ({{ table.SELLERID }})</span><br>
                          <span v-if="table.CUSTOMERPHONENO"> {{ table.CUSTOMERPHONENO }}</span>
                          <span v-else style> - </span>
                        </div>
                        <div style="margin: 5px 5px;"> 
                          <img
                          class="image-area"
                          style="width: 100px;  margin: auto;display: block;"
                          :src="getImgUrl('table.png')"
                        /> 
                        </div>
                        <div style="text-align: right; font-size: 16px; margin-top: 10px;">   
                          {{ table.DESCRIPTION }} ({{ table.STATUS }})
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        
      </div>
      <div class="columns" v-if="ismenu" style="">
        <div class="column is-three-fifths">
          <div
            class="scrollme"
            style="background-color: #f2f4f8; padding: 20px;"
            v-if="isRecent"
          >
            <div class="columns">
              <div class="column" style="padding: 5px ; ">
                <div
                  class="very-important-title has-text-centered"
                  style=" margin: 0px 20px 0px 20px ;"
                >
                  {{ productSwitchTittle }}
                </div>
              </div>
            </div>

            <div class="columns">
              <div class="column" >
                <b-field label="ລາຍການຊຳລະ">
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-left " width="15%">
                          ເວລາ
                        </th>

                        <th class="has-text-left " width="15%">
                          ຈຳນວນເງິນ
                        </th>
                        <th class="has-text-left " width="15%">
                          ຮູບແບບຊຳລະ
                        </th>
                        <th class="has-text-centered " width="10%">
                          ລາຍລະອຽດ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in history" :key="data.ID">
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td>
                          {{ data.TXTIME }}
                        </td>
                        <td>
                          {{ data.TOTAL | formatNumberLocal }}
                        </td>

                        <td>
                          {{ data.PAYMENTTYPE }}
                        </td>

                        <td class="has-text-centered">
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="paymentDetail(data)"
                              >ລາຍລະອຽດ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-field>
              </div>

              

              <div class="column">
                <div v-show="isDetailSelected">
                  <div style="margin-top: 25px;">
                    <b-field label="ລາຍລະອຽດການຊຳລະ"></b-field>
                  </div>
                  <div class="paymentdetailselected">
                    ເວລາຊຳລະ:
                    <span class="paymentdetailselectedhightlight">
                      {{ selectedPaymentDate }}</span
                    >
                  </div>
                  <div class="paymentdetailselected">
                    ຈຳວນເງິນ:
                    <span class="paymentdetailselectedhightlight">{{
                      selectedTotal | formatNumberLocal
                    }}</span>
                  </div>
                  <div class="paymentdetailselected">
                    ຮູບແບບຊຳລະ:
                    <span class="paymentdetailselectedhightlight">{{
                      selectedPaymentType
                    }}</span>
                  </div>
                  <div style=" float: right;margin-bottom: 5px;">
                    <b-button v-if="selectedPaymentApproved ==1"
                      class="is-small"
                      type="is-warning" 
                      >ອະນຸມັດສິນຄ້າ ອອກສາງແລ້ວ </b-button
                    >
                    <b-button v-if="selectedPaymentApproved !=1"
                      class="is-small"
                      type="is-danger"
                      @click="cancelPayment()"
                      >ຍົກເລີກການຊຳລະ </b-button
                    > &nbsp;
                    <b-button
                      class="is-small"
                      type="is-info"
                      @click="printReceipt()"
                      >ພິມບິນ</b-button
                    >
                  </div>
                  <div>
                    <table class="table is-fullwidth is-narrow is-small">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-left " width="20%">
                            ສິນຄ້າ
                          </th>

                          <th class="has-text-right " width="15%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="15%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in historydetail"
                          :key="data.ID"
                        >
                          <td class="has-text-centered">{{ index + 1 }}</td>
                          <td>
                            {{ data.PRODUCTNAME_SUB }}
                          </td>
                          <td class="has-text-right">
                            {{ data.QUANTITY | formatNumberLocal }}
                            
                          </td>

                          <td class="has-text-right">
                            {{ data.UNITPRICE | formatNumberLocal }}
                          </td>
                          <td class="has-text-right">
                            {{ data.TOTAL | formatNumberLocal }}
                          </td>
    
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ
                            {{ historydetail.length | formatNumberLocal }}
                            ລາຍການ
                          </th>
                          <th class="has-text-right">
                            {{
                              _.sumBy(historydetail, (i) => i["TOTAL"])
                                | formatNumberLocal
                            }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="scrollme"
            style="background-color: #f2f4f8; padding: 20px;"
            v-if="!isRecent"
          >
            <div class="columns" >
              <div class="column" style="padding: 5px ;">
                <div
                  class="very-important-title has-text-centered"
                  style=" margin: 0px 20px 0px 20px ;"
                >
                  {{posname}} {{ tablename }} <span v-if="servedby">({{servedby}})</span> 
                  
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column" style="min-height: 1000px; " >
                <div class="columns is-multiline is-mobile">
                  <div class="column is-half" style="text-align: right;">
                    <b-button
                      type="is-success"
                      expanded
                      style="width: 100%;"
                      @click="backtotable"
                      >ເບິ່ງໂຕະ</b-button
                    >
                  </div>
                  
                  <!-- <div class="column" style="text-align: right;">
                    <input
                      type="text"
                      placeholder="ຄົ້ນຫາສິນຄ້າ"
                      class="input"
                      v-model="productkeyword"
                      @keyup="searchProductkey"
                    />
                  </div> -->
                  <div class="column is-half" style="text-align: right;">
                    <b-button v-if="!isreserve && (total == null) && (countunconfirmmenu ==0 && menuInWaitOrderToOtherPosListLength == 0 )"
                      type="is-danger"
                      expanded
                      style="width: 100%;"
                      @click="changeTableStatus(2)"
                      >ຈອງໂຕະນີ້</b-button
                    >
                    <b-button v-else-if="isreserve && (countunconfirmmenu ==0 && menuInWaitOrderToOtherPosListLength == 0 )"
                      type="is-danger"
                      expanded
                      style="width: 100%;"
                      @click="changeTableStatus(1)"
                      >ຍົກເລີກການຈອງ</b-button
                    >
                    <b-button v-else
                      type="is-info"
                      expanded
                      style="width: 100%; background-color: yellow; color: black"  
                      >ກຳລັງສັ່ງອາຫານ</b-button
                    >

                    
                  </div>
                  <div class="column is-half" style="text-align: right;">
                    <b-button v-if="1==1"
                      type="is-info"
                      expanded
                      style="width: 100%; background-color: black; color: white"
                      @click="clearTable()"  
                      >Clear ໂຕະ</b-button
                    >
                  </div>

                  <!-- <div class="column" style="text-align: right;">
                    <Multiselect
                      placeholder="ປະເພດເມນູ"
                      v-model="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                      @input="onTypeSelectedForMenuList"
                    >
                    </Multiselect>
                  </div> -->
                  
                </div>
                <!-- <div class="columns" v-if="ismenudetail && tabletypeid !=4">
                  <div class="horizontal-scroll-container" style="text-align: left; overflow-x: auto; white-space: nowrap; width: 500px;">

                    <div
                      v-for="(data, index) in posList"
                      :key="index"
                      class="column" 
                      style="display: inline-block; vertical-align: top; padding:0 0 0 0; margin: 0 0 0 0"
                    >
                      <div >
                        <div class="card-content-table">
                          <div class="content" style="border-radius: 10px;  background-color: rgb(207, 202, 202); max-width: 80px; text-align: center; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                            <div @click="selectedTable(0 , data.POSID, data)">  
                              <img
                                class="image-area"
                                style="width: 80px; margin: auto; display: block;"
                                :src="getPosImgUrl('pos.jpg')"
                              />
                            </div>
                            <div style="font-size:12px">
                              {{ data.POSNAME }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>  

                  </div>



                  
                </div> -->
                <div class="columns" v-if="ismenudetail" style="margin-top:5px; margin-left:5px; font-weight: bold;">
                  ຮ້ານທີ່ເລືອກ: {{ selectedposname }}
                </div>
                
                <div class="columns" v-if="ismenudetail">
                  <div class="column" style="text-align: right;">
                    <Multiselect
                      placeholder="ປະເພດເມນູ" 
                      v-model="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                      @input="onTypeSelectedForMenuList"
                    >
                    </Multiselect> 
                  </div>
                  <div class="column" style="text-align: right;">
                    <!-- <Multiselect
                      placeholder="ຊື່ເມນູ"
                      v-model="selectedMenu"
                      label="NAME"
                      track-by="ID"
                      :options="menuList"
                      @input="onMenuSelectedList"
                    >
                    </Multiselect>  -->
                    <input v-if="selectedType"
                      class="input"
                      placeholder="ຄົ້ນຫາສິນຄ້າ ຕາມປະເພດ"
                      v-model="searchproduct"
                      style=""
                      @keyup="searchProduct()"
                    />
                  </div>
                  
                </div>

                <div class="columns is-multiline is-mobile " style="overflow: auto; max-height: 600px;" v-if="ismenudetail">
                  <div
                    v-for="(menu, index) in filteredMenuList"
                    :key="index"
                    class="column is-one-third" style="padding: 3px;"
                    
                  >
                    <div :class="[menu.NUMBEROFMENU <= 0 ? 'runout' : 'normal']" style=" border-radius: 10px;"> 
                      <b-card  class="card "  > 
                        <div class="card-image" >
                          <figure class="image">
                              <img v-if="menu.NUMBEROFMENU <=0"
                            
                                style="  border-radius: 10px; "
                                :src="getImgUrl(menu.PHOTONAME)"
                              />
                              <img v-else
                              
                                @click="addMenuVCustomer(menu)"
                                style=" border-radius: 10px;"
                                :src="getImgUrl(menu.PHOTONAME)"
                              />
                            </figure>
                        </div>
                        <div class="card-content-menu" style=" margin-top: 10px">
                          <div class="content" :style="{ fontSize: isSmallScreen ? '14px' : '16px' }">
                              <div style="font-weight: bold;"> 
                                  {{ menu.ID }} {{ menu.NAME }}
                              </div>
                              <div v-if="menu.NUMBEROFMENU <=0"> 
                                {{ menu.UNITPRICE | formatNumberLocal}} 
                              </div>
                              <div style="color:red" v-else>
                                {{ menu.UNITPRICE | formatNumberLocal}} 
                              </div>
                              <div>
                                ({{ menu.NUMBEROFMENU | formatNumberLocal}}) 
                              </div>
                            </div>
                        </div>
                      </b-card>
                    </div>
                  
                    
                  </div>
                </div>
                <div class="columns" v-if="isShowPreprint">
                  <div class="column" >
                      <div>
                        <b-button v-if="isShowPrintButton"
                         
                          type="is-info" 
                          expanded
                          style="width: 100%;"
                          @click="doPrePrint()"
                          >ພິມໃບຮັບເງິນ</b-button
                        >
                      </div>
                      <div
                        style="margin-top: 25px; text-align: center;">
                          <span class="print-receipt-main-title">ໃບບິນຊຳລະເງິນ</span>
                      </div>
                      <div style="padding: 0 0 0 0; margin: 0 0 0 0 ;" >
                        <img
                            class="image-area"
                            style="width: 150px;"
                            :src="getImgUrl('symbol/logo.jpg')"
                            alt="Placeholder image"
                          />
                      </div>
                      <div class="paymentdetailselectedhightlight">
                        ຮ້ານ:
                        <span class="paymentdetailselectedhightlight">
                          {{ posname }}</span
                        >
                      </div>
                      <div class="paymentdetailselected print-receipt-titel">
                        ໂຕ:
                        <span class="paymentdetailselectedhightlight">
                          {{ tablename }} 
                        </span>
                        
                        &nbsp;&nbsp;
                        OrderID: <span class="paymentdetailselectedhightlight">{{ selectedOrderid}}</span>
                        <span v-if="isfullbill ==0 && batchid != 0">/&nbsp;&nbsp; {{batchid}}</span>
                      </div>
                      <div class="paymentdetailselected print-receipt-titel">
                        ວັນທີ:
                        <span class="paymentdetailselectedhightlight">
                          {{ selectedPaymentDate }}</span
                        >
                      </div>
                      <div>
                        <table class="table print-receipt">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="20%">
                                ເມນູ
                              </th>

                              <th class="has-text-right " width="10%">
                                ລວມ
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, index) in historydetail" :key="data.ID">
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td>
                                {{ data.MENUNAME }} <br />
                                ຈຳນວນ: {{ data.QUANTITY | formatNumberLocal }}
                                {{ data.UNIT }} <br />
                                ລາຄາ: {{ data.UNITPRICE | formatNumberLocal }}
                              </td>

                              <td class="has-text-right">
                                {{ data.TOTAL | formatNumberLocal }}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="2" class="has-text-centered">
                                ລວມ {{ historydetail.length | formatNumberLocal }} ລາຍການ
                              </th>
                              <th class="has-text-right">
                                {{
                                  _.sumBy(historydetail, (i) => Number(i["TOTAL"]))
                                    | formatNumberLocal
                                }}
                              </th>
                            </tr>
                            <tr v-if="discount > 0">
                              <th colspan="2" class="has-text-centered">
                                ສ່ວນລຸດ ( {{ discount}}%)
                              </th>
                              <th class="has-text-right">
                              {{ _.sumBy(historydetail, (i) => Number(i["TOTAL"]))*discount/100 | formatNumberLocal}}
                              </th>
                            </tr>
                            <tr>
                              <th colspan="2" class="has-text-centered">
                                ອມພ ( {{ vat}}%)
                              </th>
                              <th class="has-text-right">
                                {{ _.sumBy(historydetail, (i) => Number(i["TOTAL"]))*vat/100 | formatNumberLocal}}
                              </th>
                            </tr>
                            <tr>
                              <th colspan="2" class="has-text-centered">
                                ລວມທັງໝົດ
                              </th>
                              <th class="has-text-right">
                                {{ (_.sumBy(historydetail, (i) => Number(i["TOTAL"])))-(_.sumBy(historydetail, (i) => Number(i["TOTAL"]))*discount/100)+(_.sumBy(historydetail, (i) => Number(i["TOTAL"]))*vat/100) | formatNumberLocal}}
                              </th>
                            </tr>
                            
                          </tfoot>
                        </table>
                      </div>
                      <div class="print-receipt">
                        ຂອບໃຈທີ່ມາອຸດໜູນ
                      </div>
                      <div class="print-receipt">
                        ເບີໂທຕິດຕໍ່ 020 52238878
                      </div>

                  </div>
                </div>
              </div>

                  
              

            </div>
            
          </div>
        </div>
        <div class="column is-two-fifths" style="padding-right: 30px;">
          <div>
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div
                  class="very-important-title has-text-centered"
                  style=" margin: 20px 20px -40px 20px ;"
                >
                  ລາຍການອາຫານທີ່ສັ່ງ
                  <!-- <span style="color: red;" v-if="isSelect_date ==1 "> {{ dateFormatter(paydate) }}</span> -->
                  <span style="color: red;" > {{ dateFormatter(paydate) }}</span>
                </div>
              </div>
            </div>
            <!-- <div class="columns">
              <div
                class="column"
                style=" padding:1px 15px 1px 1px; text-align: right; font-size: 18px;"
              >
                {{ total | formatNumberLocal }} ກີບ
              </div>
            </div>
            <div class="columns" v-if="discount > 0">
              <div
                class="column"
                style=" padding:1px 15px 1px 1px; text-align: right; font-size: 18px;"
              >
                ສ່ວນລຸດ ({{ discount }}%): &nbsp; {{ discountamount | formatNumberLocal }} ກີບ 
              </div>
            </div>
            <div class="columns">
              <div
                class="column"
                style=" padding:1px 15px 1px 1px; text-align: right; font-size: 18px;"
              >
                ອມພ ({{ vat }}%):&nbsp; {{vatamount | formatNumberLocal }} ກີບ 
              </div>
            </div>
            <div class="columns">
              <div
                class="column"
                style=" padding:1px 15px 1px 1px; text-align: right; font-size: 32px;"
              >
                {{ finaltotal | formatNumberLocal }} ກີບ
              </div>
            </div> -->
            
            <div class="columns">
              <div class="column">
                <!-- <b-button
                  type="is-info"
                  expanded
                  style="width: 100%;"
                  @click="recentSwitch()"
                  >{{ recentSwitchTittle }}</b-button
                > -->
              </div>
              <div class="column">
                <!-- ລືບເມນູທັງໝົດບໍ່ໄດ້ ເພາະລູກຄ້າຊຳລະເງິນເອງ -->
                <!-- <b-button
                  v-if="(countunconfirmmenu !=0 || menuInWaitOrderToOtherPosList.length > 0 ) && tabletypeid !=4"
                  type="is-danger"
                  expanded
                  style="width: 100%;" 
                  @click="removeAllMenu"
                  >ລຶບເມນູທັງໝົດ</b-button> -->

                <!-- <b-button
                  v-else
                  disabled
                  type="is-danger"
                  expanded
                  style="width: 100%;" 
                  @click="removeAllMenu"
                  >ລຶບເມນູທັງໝົດ</b-button> -->
              </div>
              <!-- <div>
                {{ roleid }} -- {{ roleid }} -- {{ total }} -- {{ countunservemenu }} -- {{ checkbillstatus }} -- 
              </div> -->
              
              <div class="column" v-if="roleid==4">
                <b-button
                  v-if="total != 0 && countunservemenu == 0 && checkbillstatus > 0"
                  type="is-info" 
                  expanded
                  style="width: 100%;"
                  @click="openPrePrint(0)"
                  >ພິມໃບຮັບເງິນ </b-button
                >
                <!-- -- {{ total }}-- {{ countunservemenu }}-- {{ checkbillstatus }} -->
               
              </div>
              <!-- <div class="column" v-if="(roleid==4 || roleid==5) && total !== 0 && countunservemenu === 0 && checkbillstatus == 1">
                <b-button
                  type="is-info"
                  expanded
                  style="width: 100%;"
                  @click="changeCheckBillStatus(2)"
                  >Check bill </b-button>
              </div> -->
              
              <!-- <div class="column" v-if="(roleid==4 || roleid==5) && (total != null) && countunservemenu === 0 && checkbillstatus == 0 && isfullbill ==1">
                <b-button
                  type="is-success"
                  expanded
                  style="width: 100%;"
                  @click="changeCheckBillStatus(1,0)"
                  >ລູກຄ້າຂໍ Check bill</b-button>
              </div> -->
              <!-- <div class="column" v-else-if="roleid==4 && countunservemenu == 0 && ( total != null)"> 
                <b-button v-if="checkbillstatus == 0"
                  type="is-success"
                  expanded
                  style="width: 100%;"
                  @click="changeCheckBillStatus(1,0)"
                  >ລູກຄ້າຂໍ Check bill</b-button>
                <b-button
                  v-if="total != 0 && countunservemenu == 0 && checkbillstatus == 1 && isfullbill ==1 && ( total != null)"
                  type="is-success"
                  expanded
                  style="width: 100%;"
                  @click="openPaymentForm(1,0,0)"
                  >ຊຳລະເງິນ</b-button
                >
              </div> -->
              <!-- <div class="column" v-else-if="roleid==5 && countunservemenu == 0 && (total != null)">  
                  <b-button v-if="checkbillstatus == 0"
                    type="is-success"
                    expanded
                    style="width: 100%;"
                    @click="changeCheckBillStatus(1,0)"
                    >ລູກຄ້າຂໍ Check bill</b-button>
                    
              </div> -->
              
            </div>

            <div class="columns" v-if="menuInWaitOrderToOtherPosListLength > 0">
              <div class="column"> 
                <div style="font-size: 20px; font-weight: bold;">
                  ລໍຖ້າສົ່ງ Order ໄປຮ້ານອື່ນ
                </div>
                <table class="table is-fullwidth is-narrow is-small">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInWaitOrderToOtherPosList"
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1">
                        {{ index + 1 }}
                        <b-button
                          class="btn-group-margin"
                          type="is-danger"
                          size="is-small"
                          @click="removeMenu(data,-1)"
                          >X
                        </b-button>
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1">
                        {{ data.POSNAME }}/ {{data.TABLENAME}}  <br> 
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }}
                        

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1">
              
                        <div style=" float: right;">
                        
                          {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
  

                    </tr>
                  </tbody>
                </table>
                <div class="columns">
                  <div class="column">

                  </div>
                  <div class="column">
                  
                  </div>

                  <div class="column">
                      <b-button
                        type="is-info"
                        expanded
                        style="width: 100%;"
                        @click="updateOrderMenuStatus(0)" 
                        >ສົ່ງ order ໄປຮ້ານອື່ນ</b-button 
                      >
                    
                  </div>
                </div>
              </div>
            </div>

            <div class="columns" style="margin-top: 20px; margin-bottom: 20px;" v-if="countrejectmenu != 0">
              <div class="column" >
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການຖືກປະຕິເສດ
                </div>
                <table class="table is-fullwidth is-narrow is-small"  style="background-color: rgb(230, 179, 179);">  
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-centered " width="20%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                      <th class="has-text-centered " width="5%">
                        ສະຖານະ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in rejectList" 
                      :key="data.ID"
                    >
                      <td class="has-text-centered" >
                        {{ index + 1 }}<br />
                      </td>
                      <td style="text-align: center;" >
                        
                        <img
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        />
                        <br />
                        {{ data.MENUNAME}}
                      </td>
                      <td class="has-text-right ">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-centered" >
                        <div style=" float: right;">
                         
                         {{ data.QUANTITY }}<br> 
                          {{ data.MENUNIT }}
                      </div>
                        
                      </td>
                      <td class="has-text-right ">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td> 
                      <td class="has-text-centered ">
                         {{  data.REMARK }}
                        <br>
                        <b-button v-if="tabletypeid !=4"
                          type="is-danger"
                          size="is-small"
                          expanded
                          style="width: 100%;"
                          @click="updateOrderMenuDetailStatus(data.ORDERID,data.DETAILID, -4)"
                          >ຮັບຮູ້ ແລະ ແຈ້ງລູກຄ້າ</b-button
                        ><b-button v-if="tabletypeid ==4"
                          type="is-danger"
                          size="is-small"
                          expanded
                          style="width: 100%;"
                          @click="updateOrderMenuDetailStatus(data.ORDERID,data.DETAILID, -8)"
                          >ຮັບຮູ້ ແລະ ແຈ້ງລູກຄ້າ</b-button
                        >

                        
                       
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            
            <div class="columns" v-if="menuInOrderFromOtherPosStatusListLength > 0" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;">
              <div class="column"> 
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ລໍຖ້າລູກຄ້າຊຳລະເງິນ (ສັ່ງຮ້ານອື່ນ: {{ menuInOrderFromOtherPosStatusList[0].POSNAME }} {{   menuInOrderFromOtherPosStatusList[0].TABLENAME }})
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                  <thead> 
                    <tr>
                      <th class="has-text-centered " width="15%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInOrderFromOtherPosStatusList" 
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" :class="[data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' ? 'noti-background' : '']" >
                        {{ index + 1 }}
                        <!-- <span style='color:red' v-if="data.REMARK"> <br>{{data.REMARK}}<br></span>  -->
                        <!-- <span style='color:red' v-if="data.DETAILSTATUS==0 || data.DETAILSTATUS==1"> <br>ລໍຖ້າຮັບອໍເດີ<br></span>   
                        <span style='color:red' v-if="data.DETAILSTATUS =='2' && data.ISCOOK==1"> <br>ລໍຖ້າຫ້ອງຄົວ<br></span> 
                        <span style='color:red' v-if="data.DETAILSTATUS =='2' && data.ISCOOK==0"> <br>ລໍຖ້າກະກຽມ<br></span>  -->
                        <span style='color:red' v-if="data.DETAILSTATUS =='3'"> <br>ກຳລັງເຮັດ<br></span>   
                        <span style='color:red' v-if="data.DETAILSTATUS =='-2' "> <br>ຖືກປະຕິເສດ<br></span>  
                        <span style='color:red' v-if="data.DETAILSTATUS =='-3' "> <br>{{data.REMARK}}<br></span>  
                        <b-button v-if="data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' " 
                          class="btn-group-margin"
                          type="is-danger"
                          size="is-small"
                          @click="updateOrderMenuDetailStatus(data.ORDERID,data.DETAILID, -8)"
                          >ຮັບຮູ້ ແລະ ແຈ້ງລູກຄ້າ
                        </b-button>
                        <!-- <b-button v-if="data.DETAILSTATUS==4"
                            type="is-success"
                            size="is-small"
                            expanded
                            @click="updateOrderMenuDetailStatus(data.DETAILID, 5)" 
                            style="width: 100%; height: 100%;" 
                            >{{ data.DETAILSTATUSTITLE }} <br>ເສີບລູກຄ້າ
                        </b-button > -->

                        <span style='color:red' v-if="data.DETAILSTATUS =='4'"> <br>ສຳເລັດ ລໍຖ້າອາຫານ<br></span> 

                        
                      </td>
                      <td style="text-align: center;" :class="[data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' ? 'noti-background' : '']">  
                       
                        OrderID: {{ data.ORDERID }} <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }}
                        

                      </td>
                      <td class="has-text-right " :class="[data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' ? 'noti-background' : '']">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" :class="[data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' ? 'noti-background' : '']">
              
                        <div style=" float: right;">
                        
                          {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " :class="[data.DETAILSTATUS =='-2' || data.DETAILSTATUS =='-3' ? 'noti-background' : '']"> 
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
  

                    </tr>
                    
                  </tbody>
                </table>
                <div class="columns">
                  <div class="column">

                  </div>
                  <div class="column">
                  
                  </div>

                  
                </div>
              </div>
            </div>

            

            <div class="columns" v-if="menuInwaitotherconfirm > 0  && (PROPS_TABLETYPE ==4 || tabletypeid ==4)"> 
              <div class="column"> 
                <div style="font-size: 20px; font-weight: bold;">
                  ລໍຖ້າຢືນຢັນອໍເດີ້ : ({{ orderedPosname }} {{  orderedTablename }}): <span style='color:red; font-weight: bold;'>{{ menuInwaitotherconfirm }} ລາຍການ</span>   
                </div>
              </div>
            </div>

            <div class="columns" v-if="menuInWaitOrderListLength > 0  && (PROPS_TABLETYPE ==4 || tabletypeid ==4)" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;">
              <div class="column"> 
                <div style="font-size: 20px; font-weight: bold;">
                  <!-- ລໍຖ້າຮັບອໍເດີ້ ({{ orderedPosname }} {{  orderedTablename }}) -->
                  ລາຍການ ລໍຖ້າລູກຄ້າຊຳລະເງິນ  (ຮ້ານອື່ນສັ່ງ: {{ orderedPosname }} {{  orderedTablename }})
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInWaitOrderList"
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1">
                        {{ index + 1 }}
                        <br>
                        <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                          {{ data.MENUORDERTIME }}
                        </div>
                        
                      <!-- <b-button
                          class="btn-group-margin"
                          type="is-danger"
                          size="is-small"
                          @click="updateOrderMenuStatus(-2, data.DETAILID)"
                          >X
                        </b-button> -->
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1">
                        OrderID: {{ data.ORDERID  }}<br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1">
              
                        <div style=" float: right;">
                        
                          {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
  

                    </tr>
                  </tbody>
                </table>
                <div class="columns">
                  <div class="column">

                  </div>
                  <div class="column">
                    <!-- <b-button
                        type="is-danger"
                        expanded
                        style="width: 100%;"
                        @click="updateOrderMenuStatus(-2)" 
                        >ປະຕິເສດ</b-button
                      > -->
                  </div>

                  <div class="column">
                      
                      <!-- <b-button
                        type="is-info"
                        expanded
                        style="width: 100%;"
                        @click="updateOrderMenuStatus(2)" 
                        >ຮັບອໍເດີ້ ແລະ ສົ່ງຫ້ອງຄົວ</b-button
                      > -->
                    
                  </div>
                </div>
              </div>
            </div>

            <div class="columns" v-else style=" border-radius: 10px; margin-top: 10px;"> 
              <div class="column" v-if="menuInWaitOrderListLength > 0"> 
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ລໍຖ້າລູກຄ້າຊຳລະເງິນ
                </div>
                <table class="table is-fullwidth is-narrow is-small" style=" border-radius: 10px; ">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                  
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInWaitOrderList"
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1">
                        {{ index + 1 }}
                        <b-button v-if="data.UPDATEBY == username"  
                          class="btn-group-margin"
                          type="is-danger"
                          size="is-small"
                          @click="removeMenuBySeller(data,0)"
                          >X
                        </b-button>
                        <br>
                        <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                          {{ data.MENUORDERTIME }}
                        </div>
                                                
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1">
                        OrderID: {{ data.ORDERID }}
                          <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1">
              
                        <div style=" float: right;">
                        
                          {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
  

                    </tr>
                  </tbody>
                  <tfoot>
                      <tr>
                        <th colspan="4" class="has-text-right set-border">
                          ລວມ
                          {{ menuInWaitOrderListLength | formatNumberLocal }} ລາຍການ:  
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            {{ calculateTotal(menuInWaitOrderList) | formatNumberLocal }}   
                          </span>
                          
                        </th> 
                      </tr>
                     <tr v-if="calculateDiscountedTotal(menuInWaitOrderList) > 0">
                        <th colspan="4" class="has-text-right set-border">
                          ສ່ວນລຸດ ({{ getDiscount(menuInWaitOrderList) }}%):
                        </th>
                        <th class="has-text-right set-border">
          
                          -{{ calculateDiscountedTotal(menuInWaitOrderList) | formatNumberLocal }}
                          
                          
                        </th>
                      </tr>
                      <tr >
                        <th colspan="4" class="has-text-right set-border">
                          ສ່ວນລຸດເພີ່ມເຕີມ
                        </th>
                        <th  class="has-text-right set-border">  
                          <b-input v-model.trim="extraDiscount" type="number"> </b-input> %
                        </th>
                        
                      </tr>

                      <tr >
                        <th colspan="4" class="has-text-right set-border">  
                          ລວມສ່ວນລຸດ
                        </th>
                        <th  class="has-text-right set-border">
                          
                            {{ - calculateDiscountedFinalTotal (menuInWaitOrderList) | formatNumberLocal }}
                    
                          
                        </th>
                        
                      </tr>
                      
                      <tr v-if="calculateDiscountedTotal(menuInWaitOrderList) > 0">
                        <th colspan="4" class="has-text-right set-border">
                          ຍັງເຫຼືອ:
                        </th>
                        <th class="has-text-right set-border">
                         
                          {{ calculateTotal(menuInWaitOrderList)-calculateDiscountedTotal(menuInWaitOrderList)-extraDiscount | formatNumberLocal }}
                          
                          
                        </th>
                        
                      </tr>
                      <tr v-if="calculateVatTotal(menuInWaitOrderList) > 0">  
                        <th colspan="4" class="has-text-right set-border">
                          ອມພ ({{ getVat(menuInWaitOrderList) }}%):
                          
                        </th>
                        <th class="has-text-right set-border">
                          +{{ calculateVatTotal(menuInWaitOrderList) | formatNumberLocal }}
                          
                        </th>
                      </tr>
                      <tr>
                        <th colspan="4" class="has-text-right set-border">
                          ລວມທັງໝົດ:
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; color: red; font-size: 20px;">
                            {{ calculateFinalTotal(menuInWaitOrderList) | formatNumberLocal }}
                          </span>
                          
                        </th>
                      </tr>

                      
                    
                    </tfoot>
                </table>
                
                <div class="columns">
                  <div class="column" style="text-align: right;">
                    <b-button
                      class="btn-group-margin"
                      type="is-info"
                      @click="doPaymentBySeller(menuInWaitOrderList)"
                      >ຊຳລະດ້ວຍ QR Code
                    </b-button>

                    &nbsp;
                 
                    <b-button
                      class="btn-group-margin"
                      type="is-success"
                      @click="doManaulPayment(1)"
                      >ຊຳລະດ້ວຍ ເງິນສົດ
                    </b-button>
                    
                    &nbsp;

                    <b-button
                      class="btn-group-margin"
                      type="is-warning"
                      @click="doManaulPayment(4)"
                      >ບິນຫົວໜ້າ
                    </b-button>
                  </div>
                </div>
                
                <div class="columns">
                  <div class="column">

                  </div>
                  <div class="column">
                  
                  </div>

                  <div class="column">
                    <!-- ພະນັກງານບໍ່ໄດ້ກົດຮັບອໍເດີ້ແລ້ວ ເພາະລູກຄ້າຊຳລະເງິນເອງ -->
                      <!-- <b-button
                        type="is-info"
                        expanded
                        style="width: 100%;"
                        @click="updateOrderMenuStatus(1)"
                        >ຮັບອໍເດີ້</b-button
                      > -->
                    
                  </div>
                </div>
              </div>
            </div>

            

            <!-- <div class="columns" v-if="menuInOrderOtherWaitPaymentList.length > 0" style="background-color: #E1F5FE; border-radius: 10px; margin-top: 10px;">
              <div class="column"> 
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ທີ່ກຳລັງດຳເນີນການ (ສັ່ງຮ້ານອື່ນ: {{ menuInOrderOtherWaitPaymentList[0].POSNAME }} {{ menuInOrderOtherWaitPaymentList[0].TABLENAME }})
                </div>
                <div  v-for="(data0) in menuInOrderOtherWaitPaymentList" :key="data0.ORDERID">
                  <div> {{ data0.POSNAME }}/ {{ data0.ORDERID }}</div> 
                    <table class="table is-fullwidth is-narrow is-small" style="background-color: #E1F5FE; border-radius: 10px;">
                      <thead> 
                        <tr>
                          <th class="has-text-centered " width="15%">#</th> 
                          <th class="has-text-centered " width="30%">
                            ເມນູ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                      
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in menuInOrderOtherWaitPaymentList" 
                          :key="data.DETAILID" 
                        >
                          <td v-if="data.ORDERID == data0.ORDERID" class="has-text-centered"  >
                            {{ index + 1 }}
                            <span style='color:red' v-if="data.DETAILSTATUS =='5'"> <br>ເສີບແລ້ວ<br></span> 
                            <span style='color:red' v-if="data.DETAILSTATUS =='6'"> <br>ຊຳລະແລ້ວ<br></span> 
                          </td>
                          <td v-if="data.ORDERID == data0.ORDERID"  style="text-align: center;" >
                            {{ data.POSNAME }}/ {{data.TABLENAME}}  <br> 
                            OrderID: {{ data.ORDERID }}
                          <br>
                            <img 
                              style=" width: 100px; "
                              :src="getImgUrl(data.PHOTONAME)"
                            /><br />
                            {{ data.MENUNAME }}
                            

                          </td>
                          <td v-if="data.ORDERID == data0.ORDERID"  class="has-text-right " >
                            {{ data.UNITPRICE | formatNumberLocal }}
                          </td>
                          <td v-if="data.ORDERID == data0.ORDERID"  class="has-text-right" >
                  
                            <div style=" float: right;">
                            
                              {{ data.QUANTITY }}<br> 
                                {{ data.MENUNIT }}
                            </div>

                          </td>
                          <td v-if="data.ORDERID == data0.ORDERID"  class="has-text-right " > 
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </td>
      

                        </tr>
                        
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ filteredMenuListLength(data0.ORDERID) | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right">
                            {{ calculateSum(filteredMenuWaitPaymentList(data0.ORDERID)) | formatNumberLocal }}
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                    <br> 
                </div>

                    
                <div class="columns">
                  <div class="column">

                  </div>
                  <div class="column">
                  
                  </div>

                  
                </div>
              </div>
            </div> -->
            
            <div class="columns" v-if="menuInOrderNoCookListLength > 0 && roleid == 4" style="background-color: #E1F5FE; border-radius: 10px; margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ທີ່ບໍ່ສົ່ງຫ້ອງຄົວ <span v-if="tabletypeid ==4"> ({{ orderedPosname }} {{  orderedTablename }}) </span>
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="background-color: #E1F5FE; border-radius: 10px;">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                      <th class="has-text-right " width="5%">
                        ສະຖານະ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInOrderNoCookList" 
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1">
                        {{ index + 1 }}
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1">
                        OrderID: {{ data.ORDERID }}
                          <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1">
                        
                        <div style=" float: right;">
                         
                           {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS ==2">
                            <b-button
                              class="btn-group-margin"
                              type="is-primary" 
                              size="is-small"
                              expanded
                              style="width: 100%;"
                              @click="doPrePrintNoCook(data)"
                              >ພິມລາຍການ
                            </b-button>

                          <b-button v-if="data.DETAILSTATUS==2 && posid == posid "
                            type="is-success"
                            size="is-small"
                            expanded
                            @click="updateOrderMenuDetailStatus(data.ORDERID ,data.DETAILID, 4)"
                            style="width: 100%; height: 100%;" 
                            >ກະກຽມສຳເລັດ
                          </b-button> 
                          <br>

                          <!-- <b-button style="width: 100%;"
                              class="btn-group-margin"
                              type="is-danger"
                              size="is-small"
                              expanded
                              @click="rejectMenu(data.ORDERID, data.DETAILID,-3,data.TABLEID)"
                              >ປະຕິເສດ
                          </b-button> -->
                     
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="columns" v-if="menuInOrderListLength > 0" style=" border-radius: 10px;  margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ທີ່ກຳລັງດຳເນີນການ
                  <!-- <span v-if="tabletypeid ==4"> (ສົ່ງຮ້ານອື່ນ: {{ orderedPosname }} {{  orderedTablename }}) </span> -->
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ 
                      </th>
                      <th class="has-text-right " width="5%">
                        ສະຖານະ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInOrderList" 
                      :key="data.DETAILID" 
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ index + 1 }}
                        <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                          {{ data.MENUORDERTIME }}
                        </div>
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                       
                        OrderID: {{ data.ORDERID }}
                          <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        
                        <!-- <br />

                        <b-field style="padding: 7px;">{{ data.UNIT }}</b-field> -->

                        <!-- <b-field grouped style=" float: right;"> 
                          <b-field >
                            {{data.QUANTITY}}
                          </b-field>
                          <b-field style="magrin-top: 0px;">{{ data.MENUNIT }}</b-field>
                        </b-field> -->
                        <div style=" float: right;">
                         
                           {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        <b-field v-if="data.DETAILSTATUS==4">
                          <b-button v-if="data.DETAILSTATUS==4 && posid == posid "
                            type="is-success"
                            size="is-small"
                            expanded
                            @click="updateOrderMenuDetailStatus(data.ORDERID,data.DETAILID, 5)"
                            style="width: 100%; height: 100%;" 
                            >{{ data.DETAILSTATUSTITLE }} <br>ເສີບລູກຄ້າ</b-button 
                        > 
                        </b-field>
                        <b-field v-else>
                          <span style="color: red">{{ data.DETAILSTATUSTITLE }}</span> 
                        </b-field>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="columns" v-if="menuInOrderFromOtherPosListLength > 0" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ທີ່ກຳລັງດຳເນີນການ (ຮ້ານອື່ນສັ່ງ: {{ menuInOrderFromOtherPosList[0].ORDEREDPOSNAME }} {{  menuInOrderFromOtherPosList[0].ORDEREDTABLENAME }})
                  <!-- <span v-if="tabletypeid ==4"> (ສົ່ງຮ້ານອື່ນ: {{ orderedPosname }} {{  orderedTablename }}) </span> -->
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ 
                      </th>
                      <th class="has-text-right " width="5%">
                        ສະຖານະ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInOrderFromOtherPosList" 
                      :key="data.DETAILID" 
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ index + 1 }}
                        <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                          {{ data.MENUORDERTIME }}
                        </div>
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        OrderID: {{ data.ORDERID }}
                          <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        
                        <!-- <br />

                        <b-field style="padding: 7px;">{{ data.UNIT }}</b-field> -->

                        <!-- <b-field grouped style=" float: right;"> 
                          <b-field >
                            {{data.QUANTITY}}
                          </b-field>
                          <b-field style="magrin-top: 0px;">{{ data.MENUNIT }}</b-field>
                        </b-field> -->
                        <div style=" float: right;">
                         
                           {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        <b-field v-if="data.DETAILSTATUS==4">
                          <b-button v-if="data.DETAILSTATUS==4 && posid == posid "
                            type="is-success"
                            size="is-small"
                            expanded
                            @click="updateOrderMenuDetailStatus(data.ORDERID,data.DETAILID, 5)"
                            style="width: 100%; height: 100%;" 
                            >{{ data.DETAILSTATUSTITLE }} <br>ເສີບລູກຄ້າ</b-button 
                        > 
                        </b-field>
                        <b-field v-else>
                          <span style="color: red">{{ data.DETAILSTATUSTITLE }}</span> 
                        </b-field>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="columns" v-if="menuInOrderToOtherPosListLength > 0" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;"> 
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການ ທີ່ກຳລັງດຳເນີນການ (ສັ່ງຮ້ານອື່ນ: {{ menuInOrderToOtherPosList[0].POSNAME }} {{  menuInOrderToOtherPosList[0].TABLENAME }})
                  <!-- <span v-if="tabletypeid !=4">  (ສັ່ງຮ້ານອື່ນ) </span> -->
                  <!-- <span v-if="tabletypeid ==4">  (ຮ້ານອື່ນສັ່ງ) </span> -->
                </div>
                <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>
                      <th class="has-text-centered " width="30%">
                        ເມນູ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລາຄາ
                      </th>
                      <th class="has-text-right " width="10%">
                        ຈຳນວນ
                      </th>
                      <th class="has-text-right " width="10%">
                        ລວມ
                      </th>
                      <th class="has-text-right " width="5%">
                        ສະຖານະ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in menuInOrderToOtherPosList" 
                      :key="data.DETAILID"
                    >
                      <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ index + 1 }}
                        <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                          {{ data.MENUORDERTIME }}
                        </div>
                        
                      </td>
                      <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ data.POSNAME }} {{ data.TABELNAME }} <br>
                        OrderID: {{ data.ORDERID }}
                          <br>
                        <img 
                          style=" width: 100px; "
                          :src="getImgUrl(data.PHOTONAME)"
                        /><br />
                        {{ data.MENUNAME }} 
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{ data.UNITPRICE | formatNumberLocal }}
                      </td>
                      <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        
                        <!-- <br />

                        <b-field style="padding: 7px;">{{ data.UNIT }}</b-field> -->

                        <!-- <b-field grouped style=" float: right;"> 
                          <b-field >
                            {{data.QUANTITY}}
                          </b-field>
                          <b-field style="magrin-top: 0px;">{{ data.MENUNIT }}</b-field>
                        </b-field> -->
                        <div style=" float: right;">
                         
                           {{ data.QUANTITY }}<br> 
                            {{ data.MENUNIT }}
                        </div>

                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        {{
                          (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                        }}
                      </td>
                      <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.DETAILSTATUS <5">
                        <b-field v-if="data.DETAILSTATUS==4">
                          <!-- <b-button v-if="data.DETAILSTATUS==4 && posid == posid "
                            type="is-success"
                            size="is-small"
                            expanded
                            @click="updateOrderMenuDetailStatus(data.DETAILID, 5)"
                            style="width: 100%; height: 100%;" 
                            >{{ data.DETAILSTATUSTITLE }} <br>ເສີບລູກຄ້າ</b-button 
                        >  -->
                        <span style="color: red">ລໍຖ້າເສີບ</span> 
                        </b-field>
                        <b-field v-else>
                          <span style="color: red">{{ data.DETAILSTATUSTITLE }}</span> 
                        </b-field>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="columns" v-if="menuInOrderBatchListLength > 0" style="border-radius: 10px; margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                  ລາຍການເສີບແລ້ວ
                    </div>
                <div v-for="(batch) in menuInOrderBatchList" :key="batch.BATCHID" style="margin-bottom: -40px;">
                    <div style="font-size: 18px; font-weight: bold;">
                      OrderID: {{batch.ORDERID}}
                    </div>
                    <table class="table is-fullwidth is-narrow is-small" style="">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="30%">
                            ເມນູ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in menuInOrderBatchDetail" 
                          :key="data.DETAILID"
                        >
                          <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ index + 1 }}
                            <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                              {{ data.MENUORDERTIME }}
                            </div>
                            
                          </td>
                          <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <img 
                              style=" width: 100px; "
                              :src="getImgUrl(data.PHOTONAME)"
                            /><br />
                            {{ data.MENUNAME }} 
                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ data.UNITPRICE | formatNumberLocal }}
                          </td>
                          <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <div style=" float: right;">
                            
                              {{ data.QUANTITY }}<br> 
                                {{ data.MENUNIT }}
                            </div>

                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </td>
                          
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ batch.COUNTMENU | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right" >
                            {{
                               batch.TOTAL | formatNumberLocal
                            }}
                          </th>
                        </tr>
                        <tr v-if="discount > 0">
                          <th colspan="4" class="has-text-centered">
                            ສ່ວນລຸດ ( {{ discount}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*discount/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ອມພ ( {{ vat}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ 
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL)- (batch.TOTAL*discount/100) + (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        
                      </tfoot>
                    </table>
                    <!-- <div class="column" v-if="(roleid==4 || roleid==5) && batch.CHECKBILLSTATUS == 0 && isfullbill !=1 && (getBatchCount(batch.BATCHID) ===  menuInOrderBatchList[i].COUNTBATCH)">
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="changeCheckBillStatus(11, batch.BATCHID)"
                        >ລູກຄ້າຂໍແບ່ງ Check bill</b-button>
                    </div> -->
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0">
                      <b-button
                        type="is-info" 
                        expanded
                        style="width: 100%;"
                        @click="openPrePrint(batch.BATCHID, batch.TOTAL)"
                        >ພິມໃບຮັບເງິນ</b-button
                      >
                    
                    </div>
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0"> 
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="openPaymentForm(2,batch.BATCHID,batch.TOTAL)"
                        >ຊຳລະເງິນ</b-button
                      >
                      
                      
                    </div>
                    <br><br>
                </div>
                    
              </div>
            </div>

            <div class="columns" v-if="menuInOrderFromOtherPosBatchListLength > 0" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                       ລາຍການເສີບແລ້ວ (ຮ້ານອື່ນສັ່ງ: {{ menuInOrderFromOtherPosBatchList[0].ORDEREDPOSNAME }} {{ menuInOrderFromOtherPosBatchList[0].ORDEREDTABLENAME }})
                    </div>
                <div v-for="(batch) in menuInOrderFromOtherPosBatchList" :key="batch.BATCHID" style="margin-bottom: -40px;">
                    <div style="font-size: 18px; font-weight: bold;">
                      OrderID: {{batch.ORDERID}}
                    </div>
                    <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="30%">
                            ເມນູ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in menuInOrderFromOtherPosBatchDetail" 
                          :key="data.DETAILID"
                        >
                          <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ index + 1 }}
                            <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                              {{ data.MENUORDERTIME }}
                            </div>
                            
                          </td>
                          <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <img 
                              style=" width: 100px; "
                              :src="getImgUrl(data.PHOTONAME)"
                            /><br />
                            {{ data.MENUNAME }} 
                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ data.UNITPRICE | formatNumberLocal }}
                          </td>
                          <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <div style=" float: right;">
                            
                              {{ data.QUANTITY }}<br> 
                                {{ data.MENUNIT }}
                            </div>

                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </td>
                          
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ batch.COUNTMENU | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right" >
                            {{
                               batch.TOTAL | formatNumberLocal
                            }}
                          </th>
                        </tr>
                        <tr v-if="discount > 0">
                          <th colspan="4" class="has-text-centered">
                            ສ່ວນລຸດ ( {{ discount}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*discount/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ອມພ ( {{ vat}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ 
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL)- (batch.TOTAL*discount/100) + (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        
                      </tfoot>
                    </table>
                    <!-- <div class="column" v-if="(roleid==4 || roleid==5) && batch.CHECKBILLSTATUS == 0 && isfullbill !=1 && (getBatchCount(batch.BATCHID) ===  menuInOrderBatchList[i].COUNTBATCH)">
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="changeCheckBillStatus(11, batch.BATCHID)"
                        >ລູກຄ້າຂໍແບ່ງ Check bill</b-button>
                    </div> -->
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0">
                      <b-button
                        type="is-info" 
                        expanded
                        style="width: 100%;"
                        @click="openPrePrint(batch.BATCHID, batch.TOTAL)"
                        >ພິມໃບຮັບເງິນ</b-button
                      >
                    
                    </div>
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0"> 
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="openPaymentForm(2,batch.BATCHID,batch.TOTAL)"
                        >ຊຳລະເງິນ</b-button
                      >
                      
                      
                    </div>
                    <br><br>
                </div>
                    
              </div>
            </div>

            <div class="columns" v-if="menuInOrderToOtherPosBatchListLength > 0" style="background-color: #FFFDE7; border-radius: 10px; margin-top: 10px;">
              <div class="column">
                <div style="font-size: 20px; font-weight: bold;">
                       ລາຍການເສີບແລ້ວ (ສັ່ງຮ້ານອື່ນ: {{ menuInOrderToOtherPosBatchList[0].POSNAME }} {{ menuInOrderToOtherPosBatchList[0].TABLENAME }})
                    </div>
                <div v-for="(batch) in menuInOrderToOtherPosBatchList" :key="batch.BATCHID" style="margin-bottom: -40px;">
                    <div style="font-size: 18px; font-weight: bold;">
                      OrderID: {{batch.ORDERID}}
                    </div>
                    <table class="table is-fullwidth is-narrow is-small" style="background-color: #FFFDE7; border-radius: 10px; ">
                      <thead>
                        <tr>
                          <th class="has-text-centered " width="5%">#</th>
                          <th class="has-text-centered " width="30%">
                            ເມນູ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລາຄາ
                          </th>
                          <th class="has-text-right " width="10%">
                            ຈຳນວນ
                          </th>
                          <th class="has-text-right " width="10%">
                            ລວມ
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, index) in menuInOrderToOtherPosBatchDetail" 
                          :key="data.DETAILID"
                        >
                          <td class="has-text-centered" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ index + 1 }}
                            <div :class="{ 'differentdate': currentdate != data.ORIGINALMENUORDERTIME }">
                              {{ data.MENUORDERTIME }}
                            </div>
                            
                          </td>
                          <td style="text-align: center;" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <img 
                              style=" width: 100px; "
                              :src="getImgUrl(data.PHOTONAME)"
                            /><br />
                            {{ data.MENUNAME }} 
                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{ data.UNITPRICE | formatNumberLocal }}
                          </td>
                          <td class="has-text-right" v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            
                            <div style=" float: right;">
                            
                              {{ data.QUANTITY }}<br> 
                                {{ data.MENUNIT }}
                            </div>

                          </td>
                          <td class="has-text-right " v-if="data.DETAILSTATUS !=1 && data.ORDERID == batch.ORDERID">
                            {{
                              (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                            }}
                          </td>
                          
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ {{ batch.COUNTMENU | formatNumberLocal }} ລາຍການ
                          </th>
                          <th class="has-text-right" >
                            {{
                               batch.TOTAL | formatNumberLocal
                            }}
                          </th>
                        </tr>
                        <tr v-if="discount > 0">
                          <th colspan="4" class="has-text-centered">
                            ສ່ວນລຸດ ( {{ discount}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*discount/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ອມພ ( {{ vat}}%)
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        <tr>
                          <th colspan="4" class="has-text-centered">
                            ລວມ 
                          </th>
                          <th class="has-text-right">
                            {{ (batch.TOTAL)- (batch.TOTAL*discount/100) + (batch.TOTAL*vat/100) | formatNumberLocal}}
                          </th>
                        </tr>
                        
                      </tfoot>
                    </table>
                    <!-- <div class="column" v-if="(roleid==4 || roleid==5) && batch.CHECKBILLSTATUS == 0 && isfullbill !=1 && (getBatchCount(batch.BATCHID) ===  menuInOrderBatchList[i].COUNTBATCH)">
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="changeCheckBillStatus(11, batch.BATCHID)"
                        >ລູກຄ້າຂໍແບ່ງ Check bill</b-button>
                    </div> -->
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0">
                      <b-button
                        type="is-info" 
                        expanded
                        style="width: 100%;"
                        @click="openPrePrint(batch.BATCHID, batch.TOTAL)"
                        >ພິມໃບຮັບເງິນ</b-button
                      >
                    
                    </div>
                    <div class="column" v-if="roleid==4 && batch.CHECKBILLSTATUS > 0"> 
                      <b-button
                        type="is-success"
                        expanded
                        style="width: 100%;"
                        @click="openPaymentForm(2,batch.BATCHID,batch.TOTAL)"
                        >ຊຳລະເງິນ</b-button
                      >
                      
                      
                    </div>
                    <br><br>
                </div>
                    
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <div class="contain print-only">
      <div class="column" style=" padding-left: 10px; padding-right: 10px; margin-bottom: 20px;" v-if="isPrintNoCook">
        
        <div  style="margin-bottom: 20px;" class="print-page">
          <div
            style="font-size: 20px; text-align: left;">
              ລາຍການສິນຄ້າ
          </div>
          <div>
            <div class="paymentdetailselected print-receipt-titel">
              ຮ້ານ:
                {{ selectedPosName }} / ໂຕ: {{ selectedTableName }}
            </div>
            <div class="paymentdetailselected print-receipt-titel">
              ເວລາສັ່ງ:
            
                {{ selectedTXTIME }}
            </div>
            
            <div>
              ລາຍການ: {{ selectedMenuname }} x  {{ selectedMenuAmount}}
            </div>
          </div>
        </div>
        
      </div>
    </div>
    
    <div v-if="isLoading" class="loading-modal" @click.self="closeQRModal">
      <div class="modal-content">
        <div style="float: right;">
          <b-button
            type="is-danger" 
            expanded
            style=""
            @click="closeQRModal"
            >X</b-button
          >
        </div>
        
        <p class="modal-title">ສະແກນ Lapnet QR Code ເພື່ອຊຳລະ</p>
        <img :src="qrCodeUrl" alt="QR Code" class="qr-code-image" />
        <div class="modal-content2">
          <div >ຮ້ານ: {{ posname }}</div><br>  
          <div >ໂຕະ: {{ tablename }}</div><br>
          <div >ຈຳນວນເງິນ: <span  style="font-weight: bold;">{{ qrtotal | formatNumberLocal }} ກີບ</span></div>
        </div>

      </div>

      
    </div>

    <div v-if="isManualPaymentLoading" class="loading-modal">
      
      <div class="modal-content" style="height: 300px;">
        <p class="modal-title">ຊຳລະດ້ວຍວິທີອື່ນ</p>
        <div class="modal-content2">
          <div >ຮ້ານ: {{ posname }} | ໂຕະ: {{ tablename }}</div><br>
          <div >ຈຳນວນເງິນ: <span  style="font-weight: bold;">{{ qrtotal | formatNumberLocal }} ກີບ</span></div><br>
          <div style="margin-top: 10px; height: 50px;">
            <Multiselect
              placeholder="ເລືອກວິທີຊຳລະ"
              v-model="selectedPaymentMethod"
              label="NAME"
              track-by="ID"
              :options="paymentMethodList"
            >
            </Multiselect> 
          </div><br>
          <div>
            <b-button
              type="is-info" 
              expanded
              style="width: 100%;"
              @click="doManaulPayment"
              >ຊຳລະ</b-button
            >
          </div>
          
        </div>
        
      </div>
      
    </div>

    <div v-if="isSuccessPayment" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 80px;"/></p>
        <p class="modal-title">ຊຳລະເງິນ ສຳເລັດແລ້ວ</p>  
        <p style="font-size: 20px;" >ຮັບອໍເດີ້ແລ້ວ, ກຳລັງດຳເນີນການ</p>
        <b-button
          style="float: right; background-color: whitesmoke;"
          class="btn-group-margin"
           
          @click="hideSuccessModal()" 
          > <span style=" font-family: Phetsarath OT !important ;">ປິດ</span>
        </b-button> 
          
      </div>  
        
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: ["PROPS_TABLEIDFROMNOTI","PROPS_SELECTEDPOSID","PROPS_TABLETYPE"],
  data() {
    return {
      isSmallScreen: false,
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      posname: null, 
      username: null,
      
      total: 0,
      finaltotal: 0,
      allMenuInOrder: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      posphotopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/pos/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/pos/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle: "ເມນູ", 
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      selectedOrderid: null,
      selectedPaymentID: null,
      selectedPaymentApproved: null,
      selectedTotal: null,
      isDetailSelected: false,
      isPrint: false,
      productTypeList: [],
      selectedProductType: null,
      productkeyword: null,
      productBarcode: null,

      istablemenu: true,
      ismenu: false,
      ispayment: false,
      isreserve: false,
      tables: [],
      selectedtable: null,
      tablename: null,
      tabletypeid: this.PROPS_TABLETYPE ? this.PROPS_TABLETYPE : null, 
      servedby: null,
      tableid: null,
      orderid: null,
      totalforprint: 0,
      batchid: 0,
      isfullbill: null,
      menuList: [],
      originalmenuList: [],
      menuInWaitOrderList: [],
      menuInWaitOrderListLength: 0,

      menuInwaitotherconfirm: [],
      menuInwaitotherconfirmLength: 0,

      menuInWaitOrderToOtherPosList: [],
      menuInWaitOrderToOtherPosListLength: 0,

      menuInOrderFromOtherPosStatusList: [],
      menuInOrderFromOtherPosStatusListLength: 0,

      menuInOrderOtherWaitPaymentList: [],
      menuInOrderOtherWaitPaymentListLength: 0,

      waitpaymentfromothersum: [],
      waitpaymentfromothersumLength: 0,

      menuInOrderList: [],
      menuInOrderListLength: 0,

      menuInOrderFromOtherPosList: [],
      menuInOrderFromOtherPosListLength: 0,

      menuInOrderToOtherPosList: [],
      menuInOrderToOtherPosListLength: 0,

      menuInOrderBatchList: [],
      menuInOrderBatchListLength: 0,

      menuInOrderBatchDetail: [],
      menuInOrderBatchDetailLength: 0,


      menuInOrderFromOtherPosBatchList: [],
      menuInOrderFromOtherPosBatchListLength: 0,

      menuInOrderFromOtherPosBatchDetail: [],
      menuInOrderFromOtherPosBatchDetailLength: 0,


      menuInOrderToOtherPosBatchList: [],
      menuInOrderToOtherPosBatchListLength: 0,

      menuInOrderToOtherPosBatchDetail: [],
      menuInOrderToOtherPosBatchDetailLength: 0,

      menuInOrderNoCookList: [],
      menuInOrderNoCookListLength: 0,

      menuInWaiToCockList: [],
      menuInWaiToCockListLength: 0,

      countmenu: 0,
      posList: [],
      rejectList: [],
      countrejectmenu: 0,
      countunservemenu: 0,
      countunconfirmmenu: 0,
      paymentTypeList: [
        { ID: 1, NAME: 'ຮູບແບບຊຳລະ' },
        { ID: 1, NAME: 'ເງິນສົດ' },
        { ID: 2, NAME: 'ເງິນໂອນ'  },
        { ID: 3, NAME: 'ຄູປ໋ອງ'  },
        { ID: 4, NAME: 'ບິນຫົວໜ້າ'  },
      ],
      selectedPaymentType: null,
      currencyList: [],
      selectedCurrency: null,
      xrate: null,
      inlak: 0,
      payamount: 0,
      paymentItem: [],
      totalPayInLAK: 0,
      orderStatus: null,
      showCouponCodeInput: false,
      couponcode: null,
      possellerid: null,
      loadingData: null,
      selectedCurrencyTitle: null,
      sourceAmount: 0,
      returnInLAK: 0,
      payable: false,
      checkbillstatus: 0,
      TABLEIDFROMNOTI: this.PROPS_TABLEIDFROMNOTI ? this.PROPS_TABLEIDFROMNOTI : '',

      selectedType: null,
      typeList: [],

      vat: null,
      vatamount: null,
      discount: null,
      discountamount: null,
      main_posname: null,

      isShowPreprint: false,
      ismenudetail: true,
      isShowPrintButton: false,

      postList: [],
      selectedposname: null,
      selectedposid: this.PROPS_SELECTEDPOSID ? this.PROPS_SELECTEDPOSID : null,
      orderedPosname: null,
      orderedTablename: null,

      selectedMenuname : null,
      selectedMenuAmount : null,
      selectedPosName : null,
      selectedTXTIME : null,
      selectedTableName : null,
      isPrintNoCook: false,

      selectedMenu: null,
      menuDataSet: [],

      searchproduct: null,

      menuInorderBatchGroupList: [],

      currentdate: null,

      isLoading: false,
      isManualPaymentLoading: false,
      isSuccessPayment: false,
      qrcode: null,
      qrData: null,
      isMainWaitForCallBack: 0,

      checkimagepath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/pos/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/pos/",

      selectedPaymentMethod: null,
      paymentMethodList: [
        { ID: 1, NAME: 'ເງິນສົດ' },
        // { ID: 2, NAME: 'ເງິນໂອນ'  },
        // { ID: 3, NAME: 'ຄູປ໋ອງ'  },
        { ID: 4, NAME: 'ບິນຫົວໜ້າ'  },
      ],

      extraDiscount: 0,

      paydate: null,

      isSelect_date: 0,
    
    }
  },
  methods: {
    closeQRModal() {
      this.isLoading = false; 
    },
    removeMenuBySeller(data) {
      this.$swal({
        title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var params = {
            orderid: data.ORDERID,
            type: 0, // orderdertail status
            menuid: data.ORDERMENUID,
            menudetailid: data.DETAILID, 
            tableid: this.tableid,
            posid: this.posid, 
            username: this.username,
          };
          console.log("params: ", JSON.stringify(params))
          RestAPI.removeMenuFromOrder(params)
            .then(res => {
              if(res.data =="isInPaymentProgress"){
                this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ບໍ່ສາມາດລືບໄດ້", "", "error");
                return false;
              }
              // console.log("res:" + JSON.stringify(res.data));
              this.getMeun();
              this.getMenuInOrder();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },
    getCheckImage() {
      return this.checkimagepath + "check.png";
    },
    calculateTotal(items) {  
      // this.getPaymentData(items);
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => item.UNITPRICE * item.QUANTITY);
    },

    getDiscount(items) {  
      // eslint-disable-next-line no-undef
      const discount = items[0].DISCOUNT  || 0;
      return discount;
    },

    getVat(items) {  
      // eslint-disable-next-line no-undef
      const vat = items[0].VAT  || 0;
      return vat;
    },


    calculateDiscountedTotal(items) {  
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => {
        const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        return rowDiscount;
      });
    },

    calculateDiscountedFinalTotal(items) {  
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => {
        const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        return rowDiscount + extraDiscount;
      });
    },

    calculateVatTotal(items) {  
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        const rowVat = ((rowTotal -rowDiscount - extraDiscount) * item.VAT/100)  || 0; 
        return rowVat;
     }); 
    },

    calculateFinalTotal(items) {    
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        const rowVat = ((rowTotal -rowDiscount- extraDiscount) * item.VAT/100)  || 0; 
        return (rowTotal -  rowDiscount - extraDiscount) + rowVat;    
     }); 
    }, 
 

    clearTable(){
      this.$swal({
        title: "ຕ້ອງການ Clear ໂຕນີ້ແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
        var params = {
            tableid: this.tableid,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.clearTable(params)
            .then((res) => {
              if(res.data.code =='01'){
                  this.getMenuInOrder();
                  this.$swal("ສຳເລັດ", "Clear ໂຕະສຳເລັດ", "success");
              }else if(res.data.code =='02'){
                  this.$swal("ມີຂໍ້ຜິດພາດ" , "ຍັງມີລາຍການອາຫານ ທີ່ສັງພາຍໃນມື້", "error");
              }


              // 
              
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });  
    },
    getBatchCount(batchId) {
        const batch = this.menuInorderBatchGroupList.find(b => b.BATCHID === batchId);
        return batch ? batch.BATCHCOUNT : 0; // Return 0 if batch not found
    },

    searchProduct(){
      // console.log("searchproduct: " + this.searchproduct);
      this.menuList = [...this.originalmenuList];

    },
    filteredMenuWaitPaymentList(orderId) {
      return _.filter(this.menuInOrderOtherWaitPaymentList, { ORDERID: orderId });
    },
    calculateSum(filteredData) {
      return _.sumBy(filteredData, (i) => Number(i["UNITPRICE"]) * Number(i["QUANTITY"]));
    },
    filteredMenuListLength(orderId) {
      return this.filteredMenuWaitPaymentList(orderId).length;
    },
    changeCheckBillStatus(status, batchid){ 
      
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",  
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          if(status == 1){
            this.isfullbill  = 1;
            this.checkbillstatus = 1;
          }else if(status ==11){
            this.isfullbill = 0;
          }

          var params = {
            roleid: this.roleid,
            orderid: this.orderid,
            status: status,
            batchid: batchid,
            openbilluser: this.servedby,
            tabletypeid: this.tabletypeid,
            
            username: this.username, 
          };
          console.log("params: " + JSON.stringify(params)) 
          RestAPI.changeCheckBillStatus(params)
            .then((res) => {
              console.log("res.data: " + JSON.stringify(res.data)) 
              if(res.data==='not_correct_user' && this.tabletypeid != 4){
                this.$swal("ບໍ່ແມ່ນ Seller ທີ່ເປີດບິນ: " + this.servedby, "", "error");
                return false;
              }
              this.checkbillstatus = status == 1 ? 2 : 3;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຂໍ Checkbill ສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
                showClass: {
                  icon: 'swal2-icon',
                },
              });

              this.getPaymentItem();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });  

    },
    onSelectPaymentType(){
      if(this.selectedCurrencyTitle != null && this.selectedCurrencyTitle !='ສະກຸນເງິນ' && this.paymentTypeList != null && this.paymentTypeList !='ຮູບແບບຊຳລະ'){  
        this.payable = true;
      }
      
    },
    getCurrency(){
      this.currencyList = [{ ID:'0',TITLE: 'ສະກຸນເງິນ'},{ ID:'LAK',TITLE: 'ກີບ'}, { ID:'THB',TITLE: 'ບາດ'}, { ID:'USD',TITLE: 'ໂດລາ'}];
    },
    getExchangeRate(currency){
      RestAPI.getExchangeRate(currency)
        .then((res) => {
          this.xrate = res.data;
          // this.inlak = this.xrate * this.payamount;
          this.sourceAmount =  this.getcustomRound(this.payamount / this.xrate);
          this.payAmount();
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getVatDiscount(){
      RestAPI.getVatDiscount(this.posid)
        .then((res) => {
          this.vat = res.data.vat.VAT ;
          this.discount = res.data.discount.DISCOUNT ;
          this.discountamount = this.discount * this.total / 100;
          this.vatamount = (this.vat * ( this.total- (this.discount * this.total / 100) )/100);
          this.finaltotal = this.total - this.discountamount + this.vatamount; 
           
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getcustomRound(number) {
      if (number >= 0) {
        return Math.ceil(number);
      } else {
        return Math.floor(number);
      }
    },  

    onSelectedCurrency(){
      if(this.selectedCurrencyTitle != null && this.selectedCurrencyTitle !='ສະກຸນເງິນ' && this.paymentTypeList != null && this.paymentTypeList !='ຮູບແບບຊຳລະ'){  
        this.payable = true;   
      }
      // console.log("this.selectedCurrency: " + this.selectedCurrency.ID);
      this.selectedCurrencyTitle = this.selectedCurrency.TITLE;
      this.getExchangeRate(this.selectedCurrency.ID);
      
      
    },
    payAmount(){
      // this.inlak = this.xrate * this.payamount;
      this.inlak = this.xrate * this.sourceAmount;
      if(this.inlak- this.payamount > 0){
        this.returnInLAK  = this.inlak -this.payamount ;
      }else{
        this.returnInLAK  = 0;
      }
      
    },

    addPaymentItem(){ 
      // var formtotal =  Number(this.totalPayInLAK)+ Number(this.inlak);
      this.checkRemainPayment();
      // if(this.total < formtotal){
      //   this.$swal("ຈຳນວນເງິນລວມເກີນ", "", "error");
      //   return false;
      // }



      var params = {
        orderid: this.orderid,
        paymenttype: this.selectedPaymentType.ID,
        totallak: this.totalforprint,
        sourceamount: this.sourceAmount,
        currency: this.selectedCurrency.ID,
        xrate: this.xrate,
        payinlak: this.inlak,
        returninlak: this.returnInLAK,
        discount: this.discount,
        discountamount: this.totalforprint*this.discount/100,
        vat: this.vat,
        vatamount: this.totalforprint*this.vat/100,
        isfullbill: this.isfullbill,
        batchid : this.batchid,
        username: this.username
      };

      console.log("params: " + JSON.stringify(params))
 
      RestAPI.addPaymentItem(params)
        .then(() => {
          this.getPaymentItem();
          // console.log("res: " + JSON.stringify(res.data))
          // this.$swal("ສຳເລັດ", "ເພີ່ມການຊຳລະ", "success");    
          this.$swal({
            title: "ສຳເລັດ",
            text: "ເພີ່ມການຊຳລະ",
            icon: "success",
            timer: 500, // Set the timer in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the confirmation button
          });

          this.clearPaymentForm();
          this.orderStatus = 1;
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });

    },

    
    removePaymentItem(id){ 
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            id: id,
            username: this.username,
          };
          RestAPI.removePaymentItem(params)
            .then(() => {
              this.isDetailSelected = false;
              // this.$swal("ສຳເລັດ", "ຍົກເລີກລາຍການສຳເລັດ", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຍົກເລີກລາຍການສຳເລັດ",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });

              this.getPaymentItem();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });  

    },

    removeAllPaymentItem(){ 
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            orderid: this.orderid,
            username: this.username,
          };
          RestAPI.removeAllPaymentItem(params)
            .then(() => {
              this.isDetailSelected = false;
              // this.$swal("ສຳເລັດ", "ຍົກເລີກລາຍການສຳເລັດ", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຍົກເລີກລາຍການສຳເລັດ",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });

              this.getPaymentItem();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });  

    },

    updateOrderMenuDetailStatus(orderid,detailid, status){
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            posid: this.selectedposid,
            orderdetailidid: detailid,
            status: status,
            tableid: this.tableid,
            orderid: orderid,
            username: this.sellerid,
          };
          // console.log("para: " +JSON.stringify(params))
          RestAPI.updateOrderMenuDetailStatus(params)
            .then(() => {
              // this.$swal("ສຳເລັດ", "ດຳເນີນການ ສຳເລັດ", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ດຳເນີນການ ສຳເລັດ",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
              this.getMeun();
              this.getMenuInOrder();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });  
    },

    setPrintTimeToOrder(){ 
     
      var params = {
        orderid: this.orderid,
        tableid: this.tableid,
        username: this.username,
      };
      RestAPI.setPrintTimeToOrder(params)
      .then(() => {        
      })
      .catch((error) => {
        console.log(error);
        if (error == "Error: Request failed with status code 400") {
          this.$swal("Check Input Data", "", "error");
        } else {
          this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
        }
      });
        

    },

    confirmPayment(){ 
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            orderid: this.orderid,
            tableid: this.tableid,
            isfullbill: this.isfullbill,
            batchid: this.batchid,
            username: this.username,
          };
          
        console.log("params: " + JSON.stringify(params))
        RestAPI.confirmPayment(params)
          .then(() => {
            this.isDetailSelected = false;
            this.orderStatus = 2;

            // this.$swal("ສຳເລັດ", "ຢືນຢັນການຊຳລະສຳເລັດ", "success");
            this.$swal({
              title: "ສຳເລັດ",
              text: "ຢືນຢັນການຊຳລະສຳເລັດ",
              icon: "success",
              timer: 500, // Set the timer in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the confirmation button
            });

            this.getPaymentItem();
            this.getPaymentDetail();   
            // status ປ່ຽນ ບໍ່ໃຫ້ແກ້ ແລະ ໃຫ້ຂຶ້ນເມນູພິມ

            
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });

      //   }
      // });  

    },


    clearPaymentForm(){
      this.selectedPaymentType = null;
      this.sourceAmount = 0;
      this.selectedCurrency = null;
      this.xrate = null;
      this.inlak = 0;  
    },

    getPaymentItem() {
      RestAPI.getPaymentItem(this.orderid, this.isfullbill, this.batchid)
        .then((res) => {
          this.paymentItem = res.data.item;
          this.totalPayInLAK = Number(res.data.total.TOTALPAYINLAK)
          // console.log("Res: " + JSON.stringify(res.data))
          // console.log("total : " +this.total)
          // console.log("totalPayInLAK : " +this.totalPayInLAK)
          this.checkRemainPayment();
          
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getTables() {
      RestAPI.getPosTable(this.posid)
        .then((res) => {
          this.tables = res.data;
          this.posname = res.data[0].POSNAME;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getTableForNoti(){
      this.istablemenu = false;
      
      RestAPI.getPosTableFromNoti(this.posid, this.TABLEIDFROMNOTI)
        .then((res) => {
          this.selectedTable(1, this.pos, res.data)
          // console.log("table "+JSON.stringify(res.data))
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getPostList(){
      RestAPI.getSettingPosList()
        .then((res) => {
          this.posList = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    selectedPos(data){
      this.selectedposname = data.POSNAME;
      this.selectedposid = data.POSID;
      // this.$router.replace("/customer/"+ data.POSID +"/1");
    },

    selectedTable(type, posid, table){
      if(type==1){ // self pos
        this.tableid= table.ID;
        this.tablename = table.NAME;
        this.tabletypeid = table.TYPEID;

        
        if(table.STATUSTITLE=='available'){
          this.isreserve = false;
        }else if(table.STATUSTITLE=='reserve'){
          this.isreserve = true;
        }
        // console.log(" this.isreserve "+  this.isreserve)
        
        this.servedby = table.SELLERID ? table.SELLERID : null;
        this.istablemenu = false;
        this.ismenu = true;
        this.ispayment = false;
      }else if(type ==0){ // other pos
        this.isreserve = false;
        this.servedby = table.SELLERID ? table.SELLERID : null;
        this.istablemenu = false;
        this.ismenu = true;
        this.ispayment = false;
        // this.tabletypeid = table.TYPEID;

        this.selectedposid = posid;
        this.selectedposname = table.POSNAME

        console.log("type: 0 ")
      }

      this.getOrderedPos();
      
      
      console.log("selectedposid: " + this.selectedposid)
      
      this.getMeun();
      this.getMenuInOrder()
    },

    getOrderedPos(){
      console.log("this.tableid: " + this.tableid)
      RestAPI.getOrderedPos(this.tableid)
      .then((res) => {
        this.orderedPosname = res.data.POSNAME;
        this.orderedTablename = res.data.TABLENAME;
        
      }) 
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },

    changeTableStatus(status){
      
      this.$swal({
        title: "ຕ້ອງການຈອງໂຕະນີ້ແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          if(status==2){
            this.isreserve = true;
          }else{
            this.isreserve = false;
          }
          var params = {
            tableid: this.tableid,
            status: status,
            username: this.sellerid,
          };
          console.log("params: "+ JSON.stringify(params))
          RestAPI.changeTableStatus(params)
            .then(() => {
              this.backtotable();
              // this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");

              this.$swal({
                title: "ສຳເລັດ",
                text: "ດຳເນີນການສຳເລັດ",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
          }
      });

    },

    backtotable(){
      this.istablemenu = true;
      this.ismenu = false;
      this.ispayment = false;
      this.ismenudetail = true;
      this.isShowPreprint = false;
      this.getTables(); 
    },

    backtomenu(){
      this.istablemenu = false;
      this.ismenu = true;
      this.ispayment = false;
      this.getMenuInOrder();
    },

    openPaymentForm(type, batchid, batchtotal){
      
      this.batchid = batchid;
      this.inlak = 0;
      this.istablemenu = false;
      this.ismenu = false;
      this.ispayment = true;
      this.sourceAmount= 0;
      this.getPaymentItem();
      this.getCurrency();
      this.getVatDiscount();
      this.checkRemainPayment();
      this.selectedCurrency= null;

      if(type==1){
        this.totalforprint = this.total;
        
      }else if(type ==2){
        this.totalforprint = batchtotal;
      }      
    },

    checkRemainPayment(){
      // this.payamount = Number(this.finaltotal)-Number(this.totalPayInLAK);
      this.payamount = Number(this.totalforprint-(this.totalforprint*this.discount/100) + (this.totalforprint*this.vat/100))-Number(this.totalPayInLAK);
      // this.payamount = Number(this.totalforprint-(this.totalforprint*this.discount/100) + (this.totalforprint*this.vat/100));

      // console.log("Number(this.total): " + Number(this.total))
      // console.log("Number(this.totalPayInLAK): " + Number(this.totalPayInLAK))
      // console.log("Number(this.payamount): " + Number(this.payamount))
    },

    getMeun(){
      // console.log("getMeun TABLEIDFROMNOTI: " + this.TABLEIDFROMNOTI)  
      // console.log("getMeun PROPS_SELECTEDPOSID: " + this.PROPS_SELECTEDPOSID)  
      
      // this.selectedposid = this.PROPS_SELECTEDPOSID ? this.PROPS_SELECTEDPOSID : this.selectedposid;
      // console.log("getMeun selectedposid after: " + this.selectedposid) 
      RestAPI.getPosMenu(this.selectedposid)
      .then((res) => {
        // console.log("res: " + JSON.stringify(res.data)) 
        this.menuList = res.data.product;
        this.originalmenuList = res.data.product;

      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },

    openPrePrint(batchid, batchtotal = null){
      console.log("total 3:" + this.total)
      if(batchid == 0){
        this.totalforprint  = this.total;
      }else{
        this.totalforprint  = batchtotal;
      }
      this.batchid = batchid;
      this.setPrintTimeToOrder();
      this.istablemenu = false;
      this.ismenu  = true;
      this.ismenudetail = false;
      this.isShowPreprint = true;
      this.isShowPrintButton = false;
      this.ispayment = false;

      
      console.log("total 4:" + this.total)
    
      
    },
    doPrePrint(){
      this.isPrintNoCook = false;
      this.print();

    },

    doPrePrintNoCook(data) {
      this.isPrintNoCook = true;
      this.setDataNoCook(data);
      setTimeout(() => {
        this.print();
      }, 1000);
    },

    setDataNoCook(data) {
      this.selectedMenuname = data.MENUNAME;
      this.selectedMenuAmount = data.QUANTITY;
      this.selectedPosName = data.POSNAME;
      this.selectedTXTIME = data.LASTUPDATE;
      this.selectedTableName = data.TABLENAME;
    },

    printReceiptAfterPayment() {
      
      this.isDetailSelected= true;
      
      this.getPaymentDetail();      
      // console.log("print..... +++");
      this.isPrint = true;
      setInterval(this.print(), 10000);
      this.isPrint = false;
    },

    getPaymentDetail(){
      // console.log(" this.orderid: " +  this.orderid)
      // console.log(" this.isfullbill: " +  this.isfullbill)
      // if(this.isfullbill){
        RestAPI.getPaymentHistoryDetail(this.orderid, this.isfullbill, this.batchid)
        .then((res) => {
          if(res.data.length>0){
            this.historydetail = res.data.list;
            // console.log(" historydetail: " +  JSON.stringify(res.data));
            this.selectedPaymentDate = res.data.list.length == 0 ? null : res.data.list[0]['TXDATE'] ;
            this.selectedOrderid = res.data.list.length == 0 ? null : res.data.list[0]['ORDERID'] ;
            // this.selectedPaymentDate = "test";
            this.selectedTotal = res.data.list.length == 0 ? null : res.data.list[0]['ORDERTOTAL'];
            // this.selectedPaymentType = res.data.list.length == 0 ? null : res.data.paymenttype;
            // console.log("getPaymentDetail: " + JSON.stringify(res.data.list));
            
            if(this.isShowPreprint){
              this.isShowPrintButton = true;
            }
          }

          // console.log(" this.selectedOrderid: " +  this.selectedOrderid)
          
        
          
        }) 
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
      // }
      
    },

    printReceipt() {
      this.isPrint = true;
      // console.log("print.....");
      this.print();
      this.isPrint = false;
    },


    paymentDetail(data) {
      this.selectedPaymentDate = data.TXTIME;
      this.selectedPaymentID = data.ID;
      this.selectedPaymentApproved = data.ISAPPROVED;
      this.selectedTotal = data.TOTAL;
      this.selectedPaymentType = data.PAYMENTTYPE;

      RestAPI.getPaymentHistoryDetail(this.orderid)
        .then((res) => {
          this.isDetailSelected = true;
          this.historydetail = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    cancelPayment() {
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            paymentid: this.selectedPaymentID,
            username: this.username,
          };
          RestAPI.cancelPayment(params)
            .then(() => {
              this.isDetailSelected = false;
              // this.$swal("ສຳເລັດ", "ຍົກເລີກລາຍການສຳເລັດ", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຍົກເລີກລາຍການສຳເລັດ",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });

              this.getProducts();
              this.getPaymentHistory();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });
    },
    getPaymentHistory() {
      RestAPI.getPaymentHistory(this.username)
        .then((res) => {
          this.history = res.data;
          // console.log("his: "+ JSON.stringify(res.data))
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    recentSwitch() {
      this.isDetailSelected = false;
      if (this.isRecent == false) {
        this.recentSwitchTittle = "ເມນູ";
        this.productSwitchTittle = "ປະຫວັດການຊຳລະ";
        this.isRecent = true;
        this.getPaymentHistory();
      } else {
        this.recentSwitchTittle = "ປະຫວັດການຊຳລະ";
        this.productSwitchTittle = "ເມນູ";
        this.isRecent = false;
      }
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },

    getPosImgUrl(photoname) {
      var photopreview = photoname
        ? this.posphotopath + photoname
        : this.posphotopath + "unknown.png";
      return photopreview;
    },

    loadForms() {
      this.resetData();
      this.loadall = 1;
      this.datatable = true;
      this.currentmonthshow = "ທັງໝົດ";
      RestAPI.debtDailyProjectsReport()
        .then((res) => {
          this.dataforms = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    resetData() {},
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    addMenu(data) {
      console.log("data: " + JSON.stringify(data))
      // console.log("here: " )
      // if(this.roleid == 5 && this.servedby !=null){
      //   console.log("there: " )
      //   if(this.servedby != this.username){
      //     this.$swal("ນີ້ແມ່ນໂຕະທີ່ໃຫ້ບໍລິການໂດຍ "+ this.servedby, "", "error");
      //     return false;
      //   }
        
      // }else if(this.servedby== null){
      //   this.servedby = this.username;
      // }

      this.tabletypeid = this.PROPS_TABLETYPE ? this.PROPS_TABLETYPE : this.tabletypeid;
      
      // console.log("this.tabletypeid: " + this.tabletypeid)
      if(this.tabletypeid == 4){
        this.$swal("ຕ້ອງໃຫ້ຮ້ານອື່ນເລືອກເມນູ", "", "error"); 
        return false;
      }

      // console.log(this.roleid)
      // console.log(this.total)
      // if(this.roleid==4 && this.total == 0){
      //   this.$swal("POS User ບໍ່ສາມາດສັ່ງຄັ້ງທຳອິດໄດ້ ", "", "error");
      //   return false;
      // }
      
      if(this.servedby== null){
        this.servedby = this.sellerid;
      }
      var params = {
        posid: this.posid,
        selectedposid: this.selectedposid,
        tableid: this.tableid,
        menuid: data.ID,
        iscook: data.ISCOOK,
        unitprice: data.UNITPRICE,
        username: this.sellerid,
      };
      console.log("params: ", JSON.stringify(params))
      RestAPI.addMenutoOrder(params)
        .then((res) => {
          // console.log("res: " + JSON.stringify(res.data))
          if(res.data =="isInPaymentProgress"){
            this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ດຳເນີນການຕໍ່ບໍ່ໄດ້", "", "error");
            return false;
          }
          this.getMeun();
          this.getMenuInOrder(); 

          this.$swal({
            title: "ສຳເລັດ",
            text: "ເພີ່ມລາຍການ ສຳເລັດ!",
            icon: "success",
            timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
            showConfirmButton: false, // Hide the confirmation button
          });
          

          // if (res.data.isNotEenough == 1) {
          //   this.$swal("ສິນຄ້າບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ", "-", "error");
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          //   return false;
          // } else {
          //   // this.getMenuInOrder();
          //   // this.getProducts();
          // }
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    addMenuVCustomer(data) {
      console.log("addMenuVCustomer")
      if(this.servedby== null){
        this.servedby = this.sellerid;
      }

      var params = {
        posid: this.posid,
        selectedposid: this.selectedposid,
        tableid: this.tableid,
        menuid: data.ID,
        unitprice: data.UNITPRICE,
        phonenumber: this.sellerid,
        customerToken: 0,  
        ischeckphonenumber: 0,
        username: this.username,
        usertableid: this.tableid,
        iscustomer: 0,
        paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : moment().format("YYYY-MM-DD HH:mm:ss"),

      };
      console.log("params-: ", JSON.stringify(params))
      RestAPI.addMenutoOrderForCustomerBySeller(params)   
        .then((res) => {
          this.getMenuInOrder();

          if(res.data.code =="03"){
            this.$swal("ທ່ານເຂົ້າສັ່ງອາຫານຫຼາຍກວ່າ 1 ໂຕະ, ພົວພັນຮ້ານ", "", "error");
              return false;
            }else if (res.data.code == "04") {
              this.$swal("ບໍ່ສາມາດລົງທະບຽນເບີໂທໄດ້, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "05") {
              this.$swal("ກຳລັງບໍລິການລູກຄ້າທ່ານອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }else if (res.data.code == "06") {
              this.$swal("ເບີໂທນີ້ກຳລັງສັ່ງອາຫານໂຕະອື່ນຢູ່, ພົວພັນຮ້ານ", "-", "error");
              return false;
            }
            else if (res.data.code == "09") {
              this.$swal("ບໍ່ສາມາດກວດສອບເບີໂທ, ລອງໃໝ່", "-", "error");
              return false;
            }

         
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    removeMenu(data, type) {
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            orderid: data.ORDERID,
            posid: this.selectedposid,
            type: type,
            menuid: data.ORDERMENUID,
            menudetailid: data.DETAILID, 
            tableid: this.tableid,
            username: this.username,
          };
          // console.log("params: ", JSON.stringify(params))
          RestAPI.removeMenuFromOrder(params)
            .then(res => {
              if(res.data =="isInPaymentProgress"){
                this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ບໍ່ສາມາດລືບໄດ້", "", "error");
                return false;
              }
              // console.log("res:" + JSON.stringify(res.data));
              this.getMeun();
              this.getMenuInOrder();
              // this.getProducts();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });
    },
    removeAllMenu() {
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            posid: this.posid,
            tableid: this.tableid,
            orderid: this.orderid,
            username: this.username,
          };
          // console.log("params: ", JSON.stringify(params))
          RestAPI.removeAllMenuFromOrder(params)
            .then(res => {
              if(res.data =="isInPaymentProgress"){
                this.$swal("ຢູ່ໃນຂັ້ນຕອນຊຳລະ, ບໍ່ສາມາດລືບໄດ້", "", "error");
                return false;
              }
              this.getMeun();
              this.getMenuInOrder();
              // this.getProducts();
              this.isreserve = false;
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });
    },
    updateMenuQuantity(data) {
      var params = {
        posid: this.posid,
        tableid: this.tableid,
        menuid: data.ORDERMENUID,
        menudetailid: data.DETAILID,
        quantity: data.QUANTITY,
        orderid: data.ORDERID,
        quantitybeforupdate: data.QUANTITYBEFOREUPDATE,
        unitprice: data.UNITPRICE,
        username: this.sellerid,
      };

      // console.log("updateMenuQuantity: ", JSON.stringify(params));
      RestAPI.updateMenuQuantity(params)
        .then(res => {
          this.getMenuInOrder(); 
          this.getMeun();
          if(res.data=="notEnoughIngredient"){
            this.$swal("ສ່ວນປະກອບເມນູບໍ່ຄົບ, ກວດຄືນ", "", "error");
            return false;
          }else if(res.data=="lessThanZero"){
            this.$swal("ຈຳນວນນ້ອຍກວ່າ 0, ກວດຄືນ", "", "error");
            return false;
          }

          

        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    rejectMenu(orderid, detailid, status, tableid){

    this.$swal({
      title: "ເລືອກເຫດຜົນ",
      html: `
        <div>
          <select id="multi-select" multiple class="swal2-select">
            <option value="ອາຫານຫມົດ">ອາຫານຫມົດ</option>
            <option value="ເຫດຜົນອື່ນໆ">ເຫດຜົນອື່ນໆ</option>
          </select>
        </div>
      `,
      showCancelButton: true,
      showCloseButton: true,
      confirmButtonText: "ປະຕິເສດ Menu",
      cancelButtonText: "ຍົກເລີກ",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then((result) => {
      if (result.value) {
        var reason = Array.from(document.getElementById("multi-select").selectedOptions).map(option => option.textContent).join(", ");
        if(!reason){
          this.$swal("ກະລຸນາເລືອກເຫດຜົນ", "", "error");
          return false;
        }
        var params = {
          orderdetailidid: detailid,
          status: status,
          reason: reason,
          tableid: tableid,
          orderid: orderid,
          username: this.username,
        };
        // console.log("para: " +JSON.stringify(params))
        RestAPI.updateOrderMenuDetailStatus(params)
          .then(() => {
            this.$swal("ສຳເລັດ", "ດຳເນີນການ ສຳເລັດ", "success");
            this.getMeun();
            this.getMenuInOrder();     
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    });  
    },
    getMenuInOrder() {
      // console.log("this.posid: " +this.posid)
      // console.log("this.tableid: " +this.tableid)
      if(this.posid && this.tableid){
        // console.log("this.userProvinceID: " + this.userProvinceID)
        if(this.isSelect_date ==1){
          RestAPI.getMenuInOrderByDate(this.posid, this.tableid, this.dateFormatterYYYYMMDD(this.paydate))
          .then((res) => {
            // console.log("res.data.rejectList: " + JSON.stringify(res.data.menu.rejectList)); 
            // console.log("res.data.menu.total: " + JSON.stringify(res.data.menu));   
            // console.log("res.data.menu.waitlist: " + JSON.stringify(res.data.menu));  
            // console.log("menuInWaitOrderList.length: " + res.data.menu.waitlist.length);  
            
            if(res.data)   {
              this.menuInWaitOrderList = res.data.menu.waitlist; 
              this.menuInWaitOrderListLength =  this.menuInWaitOrderList ? this.menuInWaitOrderList.length : 0;

              this.orderid = this.menuInWaitOrderListLength > 0 ? this.menuInWaitOrderList[0].ORDERID: null;  

              this.menuInwaitotherconfirm = res.data.menu.waitotherconfirm; 
              this.menuInwaitotherconfirmLength =  this.menuInwaitotherconfirm ? this.menuInwaitotherconfirm.length : 0;

              this.menuInWaitOrderToOtherPosList = res.data.waittootherlist; 
              this.menuInWaitOrderToOtherPosListLength =  this.menuInWaitOrderToOtherPosList ? this.menuInWaitOrderToOtherPosList.length : 0;
              
              this.menuInOrderFromOtherPosStatusList = res.data.menu.statusfromotherlist; 
              this.menuInOrderFromOtherPosStatusListLength =  this.menuInOrderFromOtherPosStatusList ? this.menuInOrderFromOtherPosStatusList.length : 0;


              this.waitpaymentfromothersum = res.data.menu.waitpaymentfromothersum; 
              this.waitpaymentfromothersumLength =  this.waitpaymentfromothersum ? this.waitpaymentfromothersum.length : 0;

              this.menuInOrderList = res.data.menu.list; 
              this.menuInOrderListLength =  this.menuInOrderList ? this.menuInOrderList.length : 0;

              this.menuInOrderToOtherPosList = res.data.menu.tootherposlist; 
              this.menuInOrderToOtherPosListLength =  this.menuInOrderToOtherPosList ? this.menuInOrderToOtherPosList.length : 0;

              this.menuInOrderFromOtherPosList = res.data.menu.fromotherposlist; 
              this.menuInOrderFromOtherPosListLength =  this.menuInOrderFromOtherPosList ? this.menuInOrderFromOtherPosList.length : 0; 
              
              this.menuInOrderBatchList = res.data.menu.batchlist; 
              this.menuInOrderBatchListLength =  this.menuInOrderBatchList ? this.menuInOrderBatchList.length : 0;

              this.menuInOrderBatchDetail = res.data.menu.batchdetail; 
              this.menuInOrderBatchDetailLength =  this.menuInOrderBatchDetail ? this.menuInOrderBatchDetail.length : 0;

              this.menuInOrderFromOtherPosBatchList = res.data.menu.fromotherposbatchlist; 
              this.menuInOrderFromOtherPosBatchListLength =  this.menuInOrderFromOtherPosBatchList ? this.menuInOrderFromOtherPosBatchList.length : 0;

              this.menuInOrderFromOtherPosBatchDetail = res.data.menu.fromotherposbatchdetail; 
              this.menuInOrderFromOtherPosBatchDetailLength =  this.menuInOrderFromOtherPosBatchDetail ? this.menuInOrderFromOtherPosBatchDetail.length : 0;

              this.menuInOrderToOtherPosBatchList = res.data.menu.tootherposbatchlist; 
              this.menuInOrderToOtherPosBatchListLength =  this.menuInOrderToOtherPosBatchList ? this.menuInOrderToOtherPosBatchList.length : 0;            

              this.menuInOrderToOtherPosBatchDetail = res.data.menu.tootherposbatchdetail; 
              this.menuInOrderToOtherPosBatchDetailLength =  this.menuInOrderToOtherPosBatchDetail ? this.menuInOrderToOtherPosBatchDetail.length : 0;

              this.menuInorderBatchGroupList = res.data.menu.batchgrouplist
              this.menuInorderBatchGroupListLength =  this.menuInorderBatchGroupList ? this.menuInorderBatchGroupList.length : 0;

              this.menuInOrderNoCookList = res.data.menu.nocook; 
              this.menuInOrderNoCookListLength =  this.menuInOrderNoCookList ? this.menuInOrderNoCookList.length : 0;
              this.menuInWaiToCockList = res.data.menu.waittocock;
              this.menuInWaiToCockListLength =  this.menuInWaiToCockList ? this.menuInWaiToCockList.length : 0;

              // this.menuInOrderOtherWaitPaymentList = res.data.menu.waitpaymentfromotherlist; 

              this.total = res.data.menu.total["TOTAL"]
              this.checkbillstatus = res.data.menu.checkbill;
              
              
              console.log(" this.orderid: " + this.orderid); 
              
              this.isfullbill = res.data.menu.total["ISFULLBILL"];
              this.countmenu = res.data.menu.countmenu.count;
              this.rejectList = res.data.menu.rejectlist;
              this.countrejectmenu = res.data.menu.countrejectmenu.count;
              this.countunservemenu = res.data.menu.countunservemenu.count;
              this.countunconfirmmenu = res.data.menu.countunconfirmmenu.count;
              this.allMenuInOrder = res.data.menu;
            }
            

            this.getVatDiscount();
            this.checkRemainPayment();

            // if (res.data.isNotEenough == 1) {
            //   this.$swal(
            //     "ສິນຄ້າບາງລາຍການ ຈຳນວນບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ",
            //     "-",
            //     "error"
            //   );
            //   return false;
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
          });
        }else{
          RestAPI.getMenuInOrder(this.posid, this.tableid)
          .then((res) => {
            // console.log("res.data.rejectList: " + JSON.stringify(res.data.menu.rejectList)); 
            // console.log("res.data.menu.total: " + JSON.stringify(res.data.menu));   
            // console.log("res.data.menu.waitlist: " + JSON.stringify(res.data.menu));  
            // console.log("menuInWaitOrderList.length: " + res.data.menu.waitlist.length);  
            
            if(res.data)   {
              this.menuInWaitOrderList = res.data.menu.waitlist; 
              this.menuInWaitOrderListLength =  this.menuInWaitOrderList ? this.menuInWaitOrderList.length : 0;

              this.orderid = this.menuInWaitOrderListLength > 0 ? this.menuInWaitOrderList[0].ORDERID: null;  

              this.menuInwaitotherconfirm = res.data.menu.waitotherconfirm; 
              this.menuInwaitotherconfirmLength =  this.menuInwaitotherconfirm ? this.menuInwaitotherconfirm.length : 0;

              this.menuInWaitOrderToOtherPosList = res.data.waittootherlist; 
              this.menuInWaitOrderToOtherPosListLength =  this.menuInWaitOrderToOtherPosList ? this.menuInWaitOrderToOtherPosList.length : 0;
              
              this.menuInOrderFromOtherPosStatusList = res.data.menu.statusfromotherlist; 
              this.menuInOrderFromOtherPosStatusListLength =  this.menuInOrderFromOtherPosStatusList ? this.menuInOrderFromOtherPosStatusList.length : 0;


              this.waitpaymentfromothersum = res.data.menu.waitpaymentfromothersum; 
              this.waitpaymentfromothersumLength =  this.waitpaymentfromothersum ? this.waitpaymentfromothersum.length : 0;

              this.menuInOrderList = res.data.menu.list; 
              this.menuInOrderListLength =  this.menuInOrderList ? this.menuInOrderList.length : 0;

              this.menuInOrderToOtherPosList = res.data.menu.tootherposlist; 
              this.menuInOrderToOtherPosListLength =  this.menuInOrderToOtherPosList ? this.menuInOrderToOtherPosList.length : 0;

              this.menuInOrderFromOtherPosList = res.data.menu.fromotherposlist; 
              this.menuInOrderFromOtherPosListLength =  this.menuInOrderFromOtherPosList ? this.menuInOrderFromOtherPosList.length : 0; 
              
              this.menuInOrderBatchList = res.data.menu.batchlist; 
              this.menuInOrderBatchListLength =  this.menuInOrderBatchList ? this.menuInOrderBatchList.length : 0;

              this.menuInOrderBatchDetail = res.data.menu.batchdetail; 
              this.menuInOrderBatchDetailLength =  this.menuInOrderBatchDetail ? this.menuInOrderBatchDetail.length : 0;

              this.menuInOrderFromOtherPosBatchList = res.data.menu.fromotherposbatchlist; 
              this.menuInOrderFromOtherPosBatchListLength =  this.menuInOrderFromOtherPosBatchList ? this.menuInOrderFromOtherPosBatchList.length : 0;

              this.menuInOrderFromOtherPosBatchDetail = res.data.menu.fromotherposbatchdetail; 
              this.menuInOrderFromOtherPosBatchDetailLength =  this.menuInOrderFromOtherPosBatchDetail ? this.menuInOrderFromOtherPosBatchDetail.length : 0;

              this.menuInOrderToOtherPosBatchList = res.data.menu.tootherposbatchlist; 
              this.menuInOrderToOtherPosBatchListLength =  this.menuInOrderToOtherPosBatchList ? this.menuInOrderToOtherPosBatchList.length : 0;            

              this.menuInOrderToOtherPosBatchDetail = res.data.menu.tootherposbatchdetail; 
              this.menuInOrderToOtherPosBatchDetailLength =  this.menuInOrderToOtherPosBatchDetail ? this.menuInOrderToOtherPosBatchDetail.length : 0;

              this.menuInorderBatchGroupList = res.data.menu.batchgrouplist
              this.menuInorderBatchGroupListLength =  this.menuInorderBatchGroupList ? this.menuInorderBatchGroupList.length : 0;

              this.menuInOrderNoCookList = res.data.menu.nocook; 
              this.menuInOrderNoCookListLength =  this.menuInOrderNoCookList ? this.menuInOrderNoCookList.length : 0;
              this.menuInWaiToCockList = res.data.menu.waittocock;
              this.menuInWaiToCockListLength =  this.menuInWaiToCockList ? this.menuInWaiToCockList.length : 0;

              // this.menuInOrderOtherWaitPaymentList = res.data.menu.waitpaymentfromotherlist; 

              this.total = res.data.menu.total["TOTAL"]
              this.checkbillstatus = res.data.menu.checkbill;
              
              
              console.log(" this.orderid: " + this.orderid); 
              
              this.isfullbill = res.data.menu.total["ISFULLBILL"];
              this.countmenu = res.data.menu.countmenu.count;
              this.rejectList = res.data.menu.rejectlist;
              this.countrejectmenu = res.data.menu.countrejectmenu.count;
              this.countunservemenu = res.data.menu.countunservemenu.count;
              this.countunconfirmmenu = res.data.menu.countunconfirmmenu.count;
              this.allMenuInOrder = res.data.menu;
            }
            

            this.getVatDiscount();
            this.checkRemainPayment();

            // if (res.data.isNotEenough == 1) {
            //   this.$swal(
            //     "ສິນຄ້າບາງລາຍການ ຈຳນວນບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ",
            //     "-",
            //     "error"
            //   );
            //   return false;
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
          });
        }
        
      }
      
    },

    updateOrderMenuStatus(status, orderdetailid = null){
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
          tableid: this.tableid,
          status: status,
          orderdetailid: orderdetailid,
          fromother: this.tabletypeid == 4 ? 1 : 0,
          iscook: status == 2 ? 1 : 0,
          username: this.sellerid,
        };

        // console.log("params: ", JSON.stringify(params));
        RestAPI.updateOrderMenuStatus(params)
          .then( res => {
            // console.log("data: "+ JSON.stringify(res.data))
            if(res.data=="notEnoughIngredient"){
              this.$swal("ສ່ວນປະກອບບາງເມນູບໍ່ຄົບ, ກວດຄືນ", "", "error");
              return false;
            } 

            if(status==-2){
              this.$swal("ປະຕິເສດ Order ສຳເລັດ", "", "success");
              this.getMeun();
              this.getMenuInOrder();
              return false;
            } 

            this.getMeun();
            this.getMenuInOrder();
            // this.$swal("ສົ່ງຫ້ອງຄົວ ສຳເລັດ!", "", "success");
            this.$swal({
              title: "ສຳເລັດ",
              text: "ສຳເລັດ!",
              icon: "success",
              timer: 500, // Set the timer in milliseconds (2 seconds in this example)
              showConfirmButton: false, // Hide the confirmation button
            });
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      //   }
      // });
    },

    getPaymentData(items) {
      // console.log("items: " + JSON.stringify(items))
      var total = this.calculateFinalTotal(items);
      var orderid = items[0].ORDERID || 0;  
      var selectedposid = items[0].POSID || 0;
      var selectedtableid = items[0].TABLEID || 0;
      var data = { total: total, orderid: orderid, selectedposid: selectedposid, selectedtableid: selectedtableid};  // Correct syntax for object property definition
      // console.log("data: " + JSON.stringify(data))
      return data;
    },


    async doPaymentBySeller(item) {

      const paymentData = await this.getPaymentData(item);  

      this.$swal({
        title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          
          
          // console.log("paymentdata: " + JSON.stringify(paymentData))
          //wait untial getpaymentdate work done 
          this.qrtotal = paymentData.total;
          const params = {
            username: this.username,
            orderid: paymentData.orderid,
            finaltotal: paymentData.total,
            extraDiscount: this.extraDiscount,
            posid: this.posid,
            selectedposid: this.posid,
            phoneno: this.sellerid,
            tableid: this.tableid,
            selectedtableid: this.tableid,
            postype: 1, // pos
            paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : null,
            isselectdate: this.isSelect_date,
    
          };

          this.orderid = paymentData.orderid;
          console.log("params+++: " + JSON.stringify(params))
          // console.log("params+++ orderid: " + this.orderid)

          const loadingComponent = this.$loading.open({});

          RestAPI.doCustomerPaymentBySeller(params)
            .then((res) => {
              
              loadingComponent.close();  
                           
              if (res.data.code === "00") {
                this.qrData = res.data.data.qrcode;
                this.uuid = res.data.data.uuid;

                this.showLoadingModal(); // Show modal before starting the payment API call
                this.getMenuInOrder();

                this.isMainWaitForCallBack = 1;
                
                this.waitForCallback(paymentData.orderid, this.uuid); // Start polling for callback only on success
              } else if (res.data.code === "01") {
                this.hideLoadingModal();
                this.$swal("ຈຳນວນບໍ່ຖືກຕ້ອງ", "-", "error");
              } else if (res.data.code === "02") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດສ້າງ QR Code ໄດ້", "-", "error");
              } else if (res.data.code === "03") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກຂໍ້ມູນ QR! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "04") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກເບີໂທໄດ້! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "05") {
                this.hideLoadingModal();
                this.$swal("ສ້າງ QR CODE ຊ້ຳ! ພົວພັນຮ້ານ", "-", "error");
              }
            })
            .catch((error) => {
              this.hideLoadingModal(); // Ensure modal is hidden if there's an error
              console.error("Payment error:", error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },

    waitForCallback(orderid, uuid) {
      const checkInterval = 2000; // 3 seconds interval
      const maxAttempts = 60; // Maximum attempts to wait
      let attempts = 0; 

      // console.log("Starting polling process...");

      this.showLoadingModal();

      const interval = setInterval(() => {
        // console.log(`Polling attempt #${attempts + 1}`);

        const params = { 
          orderid: orderid,
          posid: this.posid,
          uuid: uuid,
          username: this.username,
        };

        console.log('params: '+JSON.stringify(params));

        RestAPI.checkPosSelfPaymentStatus(params)
          .then((res) => {
          
            // console.log(`Response on attempt #${attempts + 1}:`, res.data);
            // console.log("res.data-CALLBACK: " + JSON.stringify(res.data))
            if (res.data.CALLBACK === 1) {
              // console.log("Payment successful!");
              clearInterval(interval);
              this.hideLoadingModal();  
  
              // this.successModal();
              this.showSuccessModal();
              this.extraDiscount = 0;

              
              // this.$swal({
              //   title: "ສຳເລັດ",
              //   html: '<img :src="getCheckImage()" style="width: 50px;"/> ການຊຳລະສຳເລັດແລ້ວ' , 
              //   icon: "success", 
              //   confirmButtonText: "ປິດ",
              // });
            }
            
          })
          .catch((error) => {
            console.error("Polling error:", error);
          });

        attempts++;

        if (attempts >= maxAttempts) {
          // console.log("Max polling attempts reached.");
          clearInterval(interval);
          this.hideLoadingModal();

          // this.$swal("ບໍ່ມີການຕອບກັບຈາກທະນາຄານ ! <br> ພົວພັນຮ້ານຄ້າ", "", "error");   

          // this.$swal({
          //   title: "ໝົດເວລາລໍຖ້າ",
          //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",
          //   icon: "warning",
          //   confirmButtonText: "ປິດ",
          // });
        }
      }, checkInterval);
    },

    
    showLoadingModal() {
      // console.log("Loading modal shown");
      this.isLoading = true;
    },

    // Hide loading modal
    hideLoadingModal() {
      // console.log("Loading modal hidden");
      this.isMainWaitForCallBack = 0;
      this.isLoading = false;
    },

    showSuccessModal(){
      this.isSuccessPayment = true;
    },

    hideSuccessModal(){
      this.qrData = null;
      this.isSuccessPayment = false;
    },


    doPayment() {
      // this.$swal({
      //   title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
      //   showCancelButton: true,
      //   showCloseButton: true,
      //   confirmButtonText: "ຕ້ອງການ",
      //   cancelButtonText: "ບໍ່ຕ້ອງການ",
      //   confirmButtonColor: "#d33",
      //   cancelButtonColor: "#3085d6",
      // }).then((result) => {
      //   if (result.value) {
          var params = {
            username: this.username,
            alldata: this.allMenuInOrder,
            paymenttype: 1,
            posid: this.posid,
            customer: "",
            discount: 0,
          };

          //  console.log("params:"+ JSON.stringify(params))

          RestAPI.doPayment(params)
            .then((res) => {
              console.log("res----: " + JSON.stringify(res.data));
              if (res.data == "not enough") {
                this.$swal(
                  "ສິນຄ້າບາງລາຍການ ຈຳນວນບໍ່ພຽງພໍ, ກວດສອບຈຳນວນຄືນ",
                  "-",
                  "error"
                );
                return false;
              }

              // this.$swal("ຊຳລະສິນຄ້າ ສຳເລັດ!", "", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຊຳລະສິນຄ້າ ສຳເລັດ!",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });

              this.getMenuInOrder();
              this.getProducts();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
      //   }
      // });
    },

    doManaulPayment(type) {
  
      this.$swal({
        title: "ຕ້ອງການຊຳລະແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            paymenttype: type,
            postype: 1, // pos
            paydate: this.isSelect_date ==1 ? (this.dateFormatterYYYYMMDD(this.paydate) +" "+ moment().format("HH:mm:ss")) : null,
            isselectdate: this.isSelect_date,
            extraDiscount: this.extraDiscount,
            orderid: this.orderid,
            posid: this.posid,
            username: this.username,
          };

           console.log("params:"+ JSON.stringify(params))

          RestAPI.doManaulPayment(params)
            .then(() => {
            
              // this.$swal("ຊຳລະສິນຄ້າ ສຳເລັດ!", "", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຊຳລະສິນຄ້າ ສຳເລັດ!",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              this.extraDiscount = 0;
              this.getMenuInOrder();

            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },
    onTypeSelectedForMenuList() {
      this.searchproduct = null;
      this.menuList = [...this.originalmenuList];
    },
    getMenuType(){
      RestAPI.getSettingMenuType()
        .then(res => {
          this.typeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    // onMenuSelectedList(selectedItem) {
    //   // selectedItem will contain the selected object
    //   // console.log("Selected Menu: ", selectedItem);

    //   // Access ID, NAME, UNIPRICE properties if needed
    //   const { ID, NAME, UNITPRICE, ISCOOK } = selectedItem;
    //   console.log("ID:", ID, "NAME:", NAME, "UNITPRICE:", UNITPRICE, "ISCOOK:", ISCOOK);

    //   this.menuDataSet = { ID: ID, NAME: NAME, UNITPRICE: UNITPRICE, ISCOOK: ISCOOK };

    //   //this.addMenu(this.menuDataSet);      

    // },
    getProducts() {
      RestAPI.getSellerProducts(this.posid)
        .then((res) => {
          this.products = res.data;
          // console.log("res: " + JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getProductTypes() {
      RestAPI.getSellerProductTypes(this.posid)
        .then((res) => {
          this.productTypeList = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onProductTypeSelected() {
      // console.log("this.selectedProductType.ID: " + this.selectedProductType.ID)
      RestAPI.getSellerProductsByType(this.posid, this.selectedProductType.ID)
        .then((res) => {
          this.products = res.data;
          // console.log("res: " + JSON.stringify(res.data))
          this.selectedProductType = null;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    searchProductkey() {
      // console.log("productkey: " + this.productkeyword)
      var key;
      if (this.productkeyword) {
        key = this.productkeyword;
      } else {
        key = "ALL";
      }

      RestAPI.getSellerProductsByKeyword(this.posid, key)
        .then((res) => {
          this.products = res.data;
          // console.log("res: " + JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    // addProduct(data) {
    //   var params = {
    //     posid: this.posid,
    //     productid: data.PRODUCTID,
    //     username: this.sellerid,
    //   };

    searchProductBarcode() {
      RestAPI.getProductbyBarcode(this.posid, this.productBarcode)
        .then((res) => {
          // this.products = res.data;
          // console.log("res: " + JSON.stringify(res.data[0]));
          this.addProduct(res.data[0]);
          this.productBarcode = null;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getPosSellerID (){
      RestAPI.getPosSellerID(this.username)
        .then((res) => {
           this.possellerid = res.data;
           this.sellerid = res.data;
          //  console.log('possellerid: ' + JSON.stringify(res.data))
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    getPosSelectDate(){
      RestAPI.getPosSelectDate()
        .then(res => {
          // console.log(res.data);
          this.isSelect_date = res.data.ISSELECTDATE;
          this.paydate = this.isSelect_date == 1 ? res.data.PAYDATE : moment().format("YYYY-MM-DD");
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    // posCheckPaymentStatus (){
    //   var params = { 
    //       posid: this.posid,
    //       username: this.username,
    //     };
    //   RestAPI.posCheckPaymentStatus(params)
    //     .then(() => {
          
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
    //     });
    // },

    print() {
      window.print();
    },
    loadData () {
        this.loadingData = setInterval(() => {
          this.getMeun();
          this.getMenuInOrder(); 
          this.getTables();
          this.getPaymentDetail();
          // this.posCheckPaymentStatus();
          
   
        }, 5000)
    },

    loadMenu () {
        this.loadingData = setInterval(() => {
         
          this.getMenuInOrder();
          
   
        }, 5000)
    },
    handleResize() {
            this.isSmallScreen = window.innerWidth <= 600; // Adjust the threshold as needed
        }
  },
  beforeDestroy () {
      clearInterval(this.loadingData);
      window.removeEventListener('resize', this.handleResize);
  },
  created () {
      this.loadData();
      this.loadMenu();
  },
  
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.selectedposid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.main_posname = this.user.POSNAME;
    this.selectedposname = this.user.POSNAME;

    this.currentdate = moment().format('YYYY-MM-DD');
    

    // console.log("currentdate-: " + this.currentdate) 


    // console.log("this.user.POSID0: " + this.user.POSID)
    // console.log("selectedposid00: " + this.selectedposid)
    
    this.getPosSellerID();
      
    // this.sellerid = this.user.ROLEID == '4' ? this.possellerid : this.user.USERNAME;
    // console.log("user: " + JSON.stringify(this.user))
    this.getTables();
    this.getMenuType(); 
    this.getPostList();
    this.getMeun();
    this.getPosSelectDate();
    // this.posCheckPaymentStatus();
    // this.getOrderedPos();
    
    // console.log("this.tabletypeid: " + this.tabletypeid)
    // console.log("this.PROPS_TABLETYPE: " + this.PROPS_TABLETYPE)
    
    if(this.TABLEIDFROMNOTI){
      // console.log("this.user.POSID1: " + this.user.POSID)
      // this.istablemenu = false;
      // this.ismenudetail = true;
      // this.ismenu = true;
      // this.ispayment = false;
      // 
      this.getTableForNoti(); 
      
      this.paydate = this.isSelect_date == 1 ? this.paydate : moment().format("YYYY-MM-DD");

    }

    this.isSmallScreen = window.innerWidth <= 600; // Adjust the threshold as needed
        window.addEventListener('resize', this.handleResize);

  },
  computed: {
    qrCodeUrl() {  
      // Use the qrserver API to generate the QR code image URL
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(this.qrData)}`;
    },
    filteredMenuList() {
      if (this.selectedType && this.selectedType.ID) {
        if(!this.searchproduct){
          console.log("if searchproduct :" + this.searchproduct)
          // console.log("---menuList :" + JSON.stringify(this.menuList))
          return this.menuList.filter(element => {
            return element['TYPEID'] === this.selectedType.ID;  
          });
        }else{ // need help
          console.log("else searchproduct :" + this.searchproduct)
          return this.menuList.filter(element => {
            return (
              element['TYPEID'] === this.selectedType.ID &&
              element['NAME'].toLowerCase().includes(this.searchproduct.toLowerCase())
            );
          });
        }
        
      } else {
        return this.menuList; 
      }
    },
  },
};
</script>

<style scoped>
/* .card {
  background-color: red;
} */
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the modal is on top of other content */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%; /* Adjust width as needed */
  max-width: 500px;
}

.modal-content2 {
  padding: 0px 20px 20px 80px;
  border-radius: 10px;
  width: 100%; /* Full width of the modal */
  margin-top: 20px; /* Space between the QR code and content */
  text-align: left; /* Align content to the left */
}

.modal-content2 div {
  margin-bottom: 0px; /* Space between the lines */
  margin-top: -20px;
  font-size: 18px;
}

.modal-title {
  font-size: 26px;
  font-weight: bold; 
  margin-bottom: 10px; 
}

.qr-code-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

@media (max-width: 480px) {

  .modal-content2 {
    padding: 2px 2px 2px 50px; /* Adjust padding for very small screens */
  }

}

.differentdate{
  background-color: red;
  color: white;
}

.noti-background{
  background-color: rgb(230, 179, 179);
}
.card-image {
  max-height: 400px; /* Set a maximum height for the image container */
}

.image {
  /* margin: 0 auto; */
}
.card-content-table {
  padding: 10px 10px 10px 10px;
  width: 100%;
  height: 100%; 
}

.card-content-menu {
  padding: 10px 10px 10px 10px; 
}
.danger {
  background-color: red;
  color: white;
  padding: 5px;
}
.warning {
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}
.paymentdetailselectedhightlight {
  font-weight: bold;
}

.available{
  background-color: rgb(37, 164, 248);
  border-radius: 10px;
  color: white;
}
.reserve{
  background-color: rgb(233, 233, 12);
  border-radius: 10px;
  color: red; 
  font-weight: bold;

}
.sitting{
  background-color: rgb(250, 7, 80);
  border-radius: 10px;
  color: white;
}

.float-container {
    /* padding: 20px; */ 
}

.float-child-left {
    width: 40%;
    float: left;
    padding: 5px 0px 5px 5px;
    text-align: right;
    /* background-color: red; */
} 

.float-child-right {
    width: 60%; 
    float: left;
    padding: 5px 0px 5px 10px;
    /* background-color: green; */
} 

.float-child {
    float: left;
    padding: 5px 0px 5px 10px;
} 


.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
  font-weight: bold;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

.runout {
  background-color: grey;
}

.normal {
  background-color: white;
}



@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in portrait;
    color: black; /* Adjust if necessary */
  }
}
</style>
