<template>
  <nav
    class="navbar is-primary no-print"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <!-- <img src="@/assets/logo.png" width="30" height="30"> -->
        <span>&nbsp;&nbsp;{{ this.user.username }}</span>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': isMenuActive }"
        @click="isMenuActive = !isMenuActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isMenuActive }">
      <div class="navbar-start">
        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='4'">
          <!-- <a class="navbar-link">
            <span>ການຜະລິດ</span>
          </a>

          <div class="navbar-dropdown">
            

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/clinic/productionreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ບັນຫາການຜະລິດ</span>
            </router-link>
            
          </div> -->
        </div>

        

        
        
      </div>

      <div class="navbar-end">
        <!-- <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍງານ </span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='4'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/report"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>


            
          </div>

       
         
        </div> -->
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍການອາຫານ </span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='6'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/chef"
              :exact="true"
              active-class="is-active"
            >
              <span>ອາຫານທີ່ຕ້ອງການ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/chef/menu"
              :exact="true"
              active-class="is-active"
            >
              <span>ສ່ວນປະກອບອາຫານ</span>
            </router-link>

          </div>

       
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍງານ </span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='6'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/chef/report"
              :exact="true"
              active-class="is-active"
            >
              <span>ການເຮັດອາຫານ</span>
            </router-link>
            

          </div>

       
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <router-link style="color: white; font-size: 16px;padding-top: 0px; margin-top: -5px;" 
            @click.native="menuClicked"
            class="navbar-item custom-link"
            to="/pos/notification"
            :exact="true"
            active-class="is-active"
          >
            <span>ແຈ້ງເຕືອນ </span><br>
            <span style="color: red; font-weight: bold; margin-left: 5px;">
              {{ newNoti }}/ {{ newNotiSound }}
            </span>
          </router-link>
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ຜູ້ໃຊ້ງານ</span>
          </a>
          <div class="navbar-dropdown">
            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/form/userProfile"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍ້ມູນສ່ວນຕົວ</span>
            </router-link>

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/login"
              :exact="true"
              active-class="is-active"
            >
              <span>ອອກຈາກລະບົບ</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import RestAPI from "../../RestAPI";
export default {
  name: "ReportMenu",
  data() {
    return {
      isMenuActive: false,
      roleid: null,
      username: null,
      newNoti: 0,
      newNotiSound: 0,
      loadingData: null,
      sound:
        "http://45.77.43.249/pos/api/uploads/sound/sound.mp3",
        showPopup: false,
    };
  },
  beforeDestroy () {
      clearInterval(this.loadingData);
  },
  created () {
      this.loadData()
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    // console.log("roleid: "+ JSON.stringify(this.roleid))
    
  },
  computed: {
    // user: get("loginData"),
    
  },
  methods: {
    menuClicked() {
      this.isMenuActive = false;
    },
    initiateSound() {
      this.showPopup = false;
      this.playSound();
    },
    openPopup() {
      this.showPopup = true;
    },
    getUserNotification(){
      RestAPI.getUserNotification( this.roleid, this.username) 
      .then((res) => {
        this.newNoti = res.data.noti;
        this.newNotiSound = res.data.sound;
        if(this.newNotiSound > 0){
          this.playSound();
        }
      })
      .catch((error) => {
        console.log(error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      });
    },
    loadData () {
        this.loadingData = setInterval(() => {
          this.getUserNotification();
        }, 5000)
    },
  
    playSound() {
      if (this.sound) {
        var audio = new Audio(this.sound);
        audio.play();
      }
    },

  }
};
</script>

<style scoped>
.navbar-item {
  white-space: nowrap;
}
.navbar-end {
  padding-right: 60px;
}
</style>
