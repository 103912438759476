<template>
  <section class="section">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h2 class="title">
            Admin/ Products
          </h2>
        </div>
      </div>
      <b-tabs v-model="activeTab" v-if="roleid == 1">
        <b-tab-item label="ຟອມເພີ່ມ/ ແກ້ໄຂສິນຄ້າ">
          <div class="columns">
            <div class="column">
              <h3 class="title">ເພິ່ມ/ ແກ້ໄຂສິນຄ້າ</h3>
            </div>
          </div>
          <div class="columns" v-if="adddataform">
            <div class="column">
              <div class="columns">
                <div class="column">
                  <b-field label="ຊື່ສິນຄ້າ">
                    <b-input v-model.trim="productname"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="barcode">
                    <b-input v-model.trim="barcode"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ຫົວໜ່ວຍ">
                    <b-input v-model.trim="unit"> </b-input>
                  </b-field>
                </div>
                <div class="column">
                  <b-field label="ປະເພດ">
                    <Multiselect
                      placeholder="ເລືອກ"
                      v-model.trim="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                    >
                    </Multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column"></div>
                <div class="column">
                  <b-field grouped position="is-right">
                    <div class="buttons">
                      <b-button
                        expanded
                        type="is-primary"
                        onclick="document.getElementById('fileupload').click()"
                        >ເລືອກຮູບ
                      </b-button>
                    </div>
                  </b-field>
                  <b-field label="" style="float: right">
                    <b-field v-if="saveAction">
                      <button class="button is-info" @click="saveProduct">
                        ບັນທຶກສິນຄ້າ
                      </button>
                    </b-field>
                    <b-field v-if="updateAction">
                      <div class="columns">
                        <div class="column">
                          <b-field>
                            <button
                              class="button is-primary"
                              @click="updateProduct"
                            >
                              ແກ້ໄຂສິນຄ້າ
                            </button>
                          </b-field>
                        </div>
                        <div class="column">
                          <b-field>
                            <button
                              class="button is-info"
                              @click="openSaveBranchForm"
                            >
                              ຟອມເພີ່ມສິນຄ້າ
                            </button>
                          </b-field>
                        </div>
                      </div>
                    </b-field>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field
                    grouped
                    position="is-centered"
                    v-if="isSelectPhoto || updateAction"
                  >
                    <img
                      data-v-xcxcc
                      :src="photopreview"
                      width="500"
                      height="500"
                    />
                    <!-- <img data-v-xcxcc src="https://accounting.houngsanaxay.com/accountingapi//khong0.PNG" /> -->
                  </b-field>
                  <input
                    type="file"
                    id="fileupload"
                    @change="onFileSelected"
                    style="visibility:hidden;"
                  />
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="ສິນຄ້າ ທີ່ເປີດໃຊ້ງານ">
          <div class="columns">
            <div class="column">
              <b-field style="margin-left: 20px">
                <b-field label="ຄົ້ນຫາສິນຄ້າ" style="margin-right: 15px;">
                </b-field>
                <b-field>
                  <input
                    type="text"
                    class="input"
                    v-model="search"
                    v-on:keyup="productSearch"
                  />
                </b-field>
              </b-field>
              <b-field label="ສິນຄ້າ ທີ່ເປີດໃຊ້ງານ">
                <table class="table is-fullwidth is-narrow is-small">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>

                      <th class="has-text-left " width="15%">
                        ສິນຄ້າ
                      </th>
                      <th class="has-text-left " width="15%">
                        Barcode
                      </th>

                      <th class="has-text-left " width="15%">
                        ຫົວໜ່ວຍ
                      </th>
                      <th class="has-text-left " width="15%">
                        ປະເພດ
                      </th>
                      <th class="has-text-left " width="15%">
                        ຮູບ
                      </th>
                      <th class="has-text-centered " width="10%">
                        ຈັດການ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in activeproducts"
                      :key="data.PRODUCTID"
                    >
                      <td class="has-text-centered">{{ index + 1 }}</td>

                      <td>
                        {{ data.PRODUCTNAME }}
                      </td>
                      <td>
                        {{ data.BARCODE }}
                      </td>
                      <td>
                        {{ data.UNIT }}
                      </td>
                      <td>
                        {{ data.PRODUCTTYPENAME }}
                      </td>
                     
                      <td>
                        <!-- {{data.PHOTONAME}} -->
                        <img
                          data-v-xcxcc
                          :src="getImgUrl(data.PHOTONAME)"
                          width="100"
                          height="100"
                        />
                      </td>

                      <td class="has-text-centered">
                        <b-field group>
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="productGradeForm(data)"
                              >ຈັດເກຼດສິນຄ້າ
                            </b-button>
                          </b-field>

                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-primary"
                              size="is-small"
                              @click="updateProductForm(data)"
                              >ແກ້ໄຂ
                            </b-button>
                          </b-field>
                          <b-field>
                            <b-button
                              class="btn-group-margin"
                              type="is-danger"
                              size="is-small"
                              @click="changeProductStatus(data.PRODUCTID, 0)"
                              >ປິດໃຊ້ງານ
                            </b-button>
                          </b-field>
                        </b-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-field>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="ສິນຄ້າ ທີ່ຖືກປິດ">
          <div class="columns scrollme">
            <div class="column">
              <b-field label="ສິນຄ້າ ທີ່ຖືກປິດ">
                <table class="table is-fullwidth is-narrow is-small">
                  <thead>
                    <tr>
                      <th class="has-text-centered " width="5%">#</th>

                      <th class="has-text-left " width="15%">
                        ສິນຄ້າ
                      </th>

                      <th class="has-text-left " width="15%">
                        ຫົວໜ່ວຍ
                      </th>
                      <th class="has-text-left " width="15%">
                        ປະເພດ
                      </th>
                      <th class="has-text-left " width="15%">
                        ຮູບ
                      </th>
                      <th class="has-text-left " width="10%">
                        ຈັດການ
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(data, index) in inactiveproducts"
                      :key="data.PRODUCTID"
                    >
                      <td class="has-text-centered">{{ index + 1 }}</td>

                      <td>
                        {{ data.PRODUCTNAME }}
                      </td>
                      <td>
                        {{ data.UNIT }}
                      </td>
                      <td>
                        {{ data.PRODUCTTYPENAME }}
                      </td>
                      <td>
                        <img
                          data-v-xcxcc
                          :src="getImgUrl(data.PHOTONAME)"
                          width="100"
                          height="100"
                        />
                      </td>
                      <td>
                        <b-field>
                          <b-button
                            class="btn-group-margin"
                            type="is-primary"
                            size="is-small"
                            @click="changeProductStatus(data.PRODUCTID, 1)"
                            >ເປີດໃຊ້ງານ
                          </b-button>
                        </b-field>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-field>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item label="ຈັດເກຼດສິນຄ້າ">
          <div class="columns scrollme">
            <div class="column">
              <b-field :label="recommenttitle">
                <div class="columns">
                  <div class="column">
                    <b-field label="ຊື່ເກຼດ">
                      <b-input v-model.trim="productgrade"> </b-input>
                    </b-field>
                   
                    <b-field label="ຊື່່ຫົວໜ່ວຍ">
                      <b-input v-model.trim="gradunit"> </b-input>
                    </b-field>
                  
                    <b-field label="ຫົວໜ່ວຍ(ຈັກກິໂລ)">
                      <b-input v-model.trim="subunit"> </b-input>
                    </b-field>
                    <b-field label="">
                      <button
                        v-if="isaddgrade"
                        class="button is-info"
                        @click="addProductGrade"
                      >
                        ເພີ່ມເກຼດ
                      </button>

                      <button
                        v-if="!isaddgrade"
                        class="button is-primary"
                        @click="updateProductGrade"
                      >
                        ແກ້ໄຂເກຼດ
                      </button>
                    </b-field>
                  </div>
                  <div class="column"></div>
                  <div class="column"></div>
                </div>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <table class="table is-fullwidth is-narrow is-small">
                <thead>
                  <tr>
                    <th class="has-text-centered " width="5%">#</th>
                    <th class="has-text-left " width="15%">
                      ຊື່ເກຼດ
                    </th>
                   
                    <th class="has-text-centered " width="10%">
                      ຊື່ຫົວໜ່ວຍ
                    </th> 
                    <th class="has-text-centered " width="10%">
                      ຫົວໜ່ວຍ
                    </th>
                    <th class="has-text-centered " width="10%">
                      ຈັດການ
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(data, index) in productgradelist" :key="data.ID">
                    <td class="has-text-centered">{{ index + 1 }}</td>
                    <td class="has-text-left ">
                      {{ data.PRODUCTNAME_SUB }}
                    </td>
                    <td class="has-text-centered ">
                      {{ data.GRADUNIT }}
                    </td>
                    <td class="has-text-centered "> 
                      {{ data.SUBUNIT }}
                    </td>

                    <td class="has-text-centered">
                      <b-field group>
                        <b-field>
                          <b-button
                            class="btn-group-margin"
                            type="is-primary"
                            size="is-small"
                            @click="updateProductGradeForm(data)"
                            >ແກ້ໄຂ
                          </b-button>
                        </b-field>
                      </b-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  components: { Multiselect },
  props: ["MONTH"],
  data() {
    return {
      roleid: null,
      userName: null,
      selectedType: null,
      typeList: [],
      // isProvinceSelected: false,
      // selectedProvince: null,
      // provinceList:  [],
      // selectedUser: null,
      // userList: [],

      activeproducts: [],
      inactiveproducts: [],
      productid: null,
      oldproductname: null,
      productname: null,
      oldunit: null,
      unit: null,
      oldphotoname: null,
      barcode: 123,
      oldbarcode: 123,


      selectedFile: null,
      url: "uploadimage", // function name in api site
      photoname: null,
      photopath:
        process.env.NODE_ENV === "production"
          ? "https://agrilao.com/pos/api/uploads/products/"
          : "http://localhost/agrilao-pos/agri-pos-api/uploads/products/",
      // photopath: process.env.NODE_ENV === "production" ? "http://51.222.85.54/otrustla-manager/api/uploads/products/" : "http://localhost/otrustla-manager/api/uploads/products/",
      photopreview: null,
      isSelectPhoto: false,

      // oldbranchenname: null,
      // branchenname: null,
      // countryid: null,
      // provinceid: null,
      // oldprovinceid: null,
      // responseusername: null,
      // oldresponseusername: null,

      productgrade: null,
      productgrade_eng: null,
      subunit: null,
      gradunit: null,
      gradunit_eng: null,
      productgradelist: [],
      productgradeid: null,
      isaddgrade: true,

      activeTab: 1,
      saveAction: true,
      updateAction: false,
      adddataform: true,
      updatedataform: false,

      recommenttitle: null,
      provinceList: [],
      activeSubTab: 0,

      search: null,

      // selectedBranchProvince: null,
      // branchProvinceList: [],
    };
  },
  methods: {
    getProductTypes() {
      RestAPI.getProductTypes()
        .then((res) => {
          // console.log("con: " + JSON.stringify(res.data));
          this.typeList = res.data.activeProductType;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getResponseUser() {
      RestAPI.getResponseUser(3)
        .then((res) => {
          // console.log("con: "+ JSON.stringify(res.data))
          if (this.updateAction == true) {
            this.userList = res.data;
            this.userList.push(this.selectedUser);
          }

          this.userList = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getProvince() {
      RestAPI.getProvincebyCountry(this.selectedCountry.ID)
        .then((res) => {
          // console.log("country selected...")
          this.provinceList = res.data;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getAllProvince() {
      RestAPI.getAllProvince()
        .then((res) => {
          this.branchProvinceList = res.data;
          //  console.log("branchProvinceList: " + JSON.stringify(this.branchProvinceList))
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onSelectBranchProvince(branchid, provinceid) {
      var params = {
        branchid: branchid,
        provinceid: provinceid.ID,
        username: this.username,
      };

      // console.log("pa: " + JSON.stringify(params))

      RestAPI.addProvinceToBranch(params)
        .then(() => {
          this.countryid = null;
          this.provinceid = null;
          this.getBranches();
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },
    saveProduct() {
      // console.log("this.selectedProvince.ID: " + this.selectedProvince.length)
      if (
        this.productname === null ||
        this.unit === null ||
        this.selectedType === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          name: this.productname,
          producttypeid: this.selectedType.ID,
          unit: this.unit,
          barcode: this.barcode,
          photoname: this.photoname,
          username: this.username,
        };
        console.log("params: " + JSON.stringify(params));
        RestAPI.saveProduct(params)
          .then((res) => {
            // console.log("data: " + JSON.stringify(res.data))
            if (res.data == "duplicate productname") {
              this.$swal("ຊື່ສິນຄ້າຊ້ຳ", "-", "error");
              return false;
            }

            if (res.data == "duplicate barcode") {
              this.$swal("barcode ຊ້ຳ", "-", "error");
              return false;
            }

            this.photopreview = null;
            this.$swal("ບັນທືກສິນຄ້າ ສຳເລັດ!", "", "success");

            this.productname = null;
            this.unit = null;
            this.selectedType = null;
            this.activeTab = 1;
            this.getProducts();
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },
    getProducts() {
      RestAPI.getAllProducts()
        .then((res) => {
          // console.log("list:"+ JSON.stringify(res.data))
          this.activeproducts = res.data.activeproducts;
          this.inactiveproducts = res.data.inactiveproducts;
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getProdudtGrade() {
      RestAPI.getProductGrade(this.productid)
        .then((res) => {
          this.productgradelist = res.data.grade;
          console.log(" this.productgradelist "+ JSON.stringify(res.data));
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    productGradeForm(data) {
      this.activeTab = 3;
      this.recommenttitle = "ຈັດເກຼດ " + data.PRODUCTNAME;
      this.productid = data.PRODUCTID;
      this.getProdudtGrade(data.PRODUCTID);
    },

    updateProductGradeForm(data) {
      this.productgrade = data.PRODUCTNAME_SUB;
      this.productgrade_eng = data.PRODUCTNAME_ENSUB;
      this.subunit = data.SUBUNIT;
      this.gradunit = data.GRADUNIT;
      this.gradunit_eng = data.GRADUNIT_ENG;
      this.productgradeid = data.ID;
      this.isaddgrade = false;
    },

    updateProductGrade() {
      if (
        this.productgrade === null ||
        this.subunit === null ||
        this.gradunit === null 
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          productgradeid: this.productgradeid,
          productgrade: this.productgrade,
          subunit: this.subunit,
          gradunit: this.gradunit,
          username: this.username,
        };
        console.log("params: " + JSON.stringify(params));
        RestAPI.updateProductGrade(params)
          .then(() => {
            this.photopreview = null;
            this.$swal("ແກ້ໄຂເກຼດສິນນຄ້າ ສຳເລັດ!", "", "success");
            this.productgrade = null;
            this.subunit = null;
            this.gradunit = null;
            this.getProdudtGrade(this.productid);
            this.isaddgrade = true;
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },
    addProductGrade() {
      if (
        this.productgrade === null ||
        this.gradunit === null ||
        this.subunit === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          productid: this.productid,
          productgrade: this.productgrade,
          subunit: this.subunit,
          gradunit: this.gradunit,
          username: this.username,
        };
        console.log("params: " + JSON.stringify(params));
        RestAPI.addProductGrade(params)
          .then(() => {
            this.photopreview = null;
            this.$swal("ເພີ່ມເກຼດສິນນຄ້າ ສຳເລັດ!", "", "success");
            this.productgrade = null;
            this.subunit = null;
            this.gradunit = null;
            this.getProdudtGrade(this.productid);
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },

    updateProductForm(data) {
      console.log(data);
      this.activeTab = 0;
      this.saveAction = false;
      this.updateAction = true;
      this.oldproductname = data.PRODUCTNAME;
      this.productname = data.PRODUCTNAME;

      this.productid = data.PRODUCTID;
      this.unit = data.UNIT;
      this.photoname = data.PHOTONAME;
      this.typeid = data.PRODUCTTYPEID;
      this.barcode = data.BARCODE;
      this.selectedType = {
        ID: data.PRODUCTTYPEID,
        NAME: data.PRODUCTTYPENAME,
      };
      this.photopreview = this.photopath + data.PHOTONAME;
    },
    openSaveBranchForm() {
      this.activeTab = 0;
      this.saveAction = true;
      this.updateAction = false;
      this.productname = null;
      this.unit = null;
      this.photoname = null;
    },
    updateProduct() {
      
      if (
        this.productname === null ||
        this.unit === null ||
        this.selectedType === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
      } else {
        var params = {
          id: this.productid,
          newname: this.productname,
          oldname: this.oldproductname,
          producttypeid: this.selectedType.ID,
          unit: this.unit,
          newbarcode: this.barcode,
          oldbarcode: this.oldbarcode,
          photoname: this.photoname,
          username: this.username,
        };

        console.log("pa: " + JSON.stringify(params));

        RestAPI.updateProduct(params)
          .then((res) => {
            // console.log("data: "+ JSON.stringify(res.data))
            if (res.data == "duplicate productname") {
              this.$swal("ຊື່ສິນຄ້າຊ້ຳ", "-", "error");
              return false;
            }

            this.photopreview = null;
            this.activeTab = 1;
            this.saveAction = true;
            this.updateAction = false;
            this.productname = null;
            this.barcode = null;
            this.unit = null;
            this.producttypeid = null;
            this.getProducts();
          })
          .catch((error) => {
            console.log(error);
            if (error == "Error: Request failed with status code 400") {
              this.$swal("Check Input Data", "", "error");
            } else {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            }
          });
      }
    },
    changeProductStatus(productid, status) {
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            productid: productid,
            status: status,
            username: this.username,
          };
          console.log("pararm: " + JSON.stringify(params));
          RestAPI.changeProductStatus(params)
            .then(() => {
              this.$swal("ດຳເນີນການສຳເລັດ!", "", "success");
              this.getProducts();
            })
            .catch((error) => {
              this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
            });
        }
      });
    },

    onFileSelected(event) {
      this.isSelectPhoto = true;
      console.log("url: "+ this.url)
      // console.log("photopath: " + this.photopath)
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile);
      axios.post(this.url, fd).then((res) => {
        this.photoname = res.data;
        this.photopreview = this.photopath + res.data;
      });
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
    productSearch() {
      if (this.search) {
        var params = {
          search: this.search,
        };
        RestAPI.searchAdminProducts(params)
          .then((res) => {
            this.activeproducts = res.data.activeproducts;
            this.inactiveproducts = res.data.inactiveproducts;
            // console.log("products: " + JSON.stringify(this.products));
          })
          .catch((error) => {
            console.log(error);
            this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
          });
      } else {
        this.getProducts();
      }
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getProducts();
    this.getProductTypes();
    // this.getResponseUser();
    // this.getAllProvince();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
