<template>
  <section>
    <div class="contain no-print">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍງານສິນຄ້າເສຍຫາຍ</div>
              </div>
            </div>

            
            <div class="columns" style="min-height: 800px;">
              

              <div class="column" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 30px 30px 30px 30px">
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ລາຍງານສິນຄ້າເສຍຫາຍ</span>
                </div>  
                <div class="columns" style=" margin-top: 10px;">
                  <div class="column">
                    <b-field>
                          
                          <b-field label="ຈາກວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="startdate"
                            >
                            </b-datepicker>
                          </b-field>
                          <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                          <b-field label="ເຖິງວັນທີ">
                            <b-datepicker
                              :date-formatter="dateFormatter"
                              v-model="enddate"
                            >
                            </b-datepicker>
                          </b-field>
                          
                        </b-field>
                  </div>
                  
                  <div class="column" style="text-align: left; margin-top: 0px;" v-if="posid == null">
                    <b-field >
                        <b-field label="ເລືອກ POS">
                          <Multiselect
                            placeholder="ເລືອກ POS"
                            v-model="selectedPos"
                            label="NAME"
                            track-by="ID"
                            :options="posList"
                          >
                          </Multiselect>
                        </b-field>
                    </b-field>
                          
                  </div>
                  <div class="column" style="text-align: right; margin-top: 30px;">
                    <b-field >
                      <b-button type="is-info" expanded style="width: 100%;" @click="onSeletedPos()">ດືງລາຍງານ</b-button>
                    </b-field>
                  </div>
                </div>
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="10%">
                          ວັນ ແລະ ເວລາ
                        </th>
                        <th class="has-text-left " width="10%">
                          ຮ້ານ
                        </th>
                        <th class="has-text-left " width="5%">
                          ປະເພດ
                        </th>

                        <th class="has-text-left " width="10%">
                          ສິນຄ້າ
                        </th>
                        <th class="has-text-right " width="10%">
                          ຈຳນວນ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in productbrokenlist"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.TXTIME }}
                        </td>
                        <td class="has-text-left">
                          {{ data.POSNAME }}
                        </td>
                       
                        <td class="has-text-left">
                          {{ data.TYPENAME  }}
                        </td>
                        <td class="has-text-left">
                          {{ data.PRODUCTNAME  }}
                        </td>

                        <td class="has-text-right"> 
                          {{ data.QUANTITY }}  {{ data.UNIT}}
                        </td>
                        
                      </tr>
                      
                    </tbody>
                    
                  </table>
                
              </div>
              
            </div>

          </div>

          
          
        </div>
        
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,

      startdate: null,
      enddate: null,
      
      productbrokenlist: [],
      selectedPos: null,
      posList: [],
      
    };
  },
  methods: {
    getAllProductBroken(){
      var posid = this.selectedPos ? this.selectedPos.ID : this.posid;
      RestAPI.getAllProductBroken(posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.productbrokenlist = res.data;

        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    onSeletedPos(){
      this.getAllProductBroken();
      
    },
    getPostList(){
      RestAPI.getPosList()
        .then(res => {
          this.posList = res.data;
        })
        .catch(error => { 
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    this.startdate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.enddate = new Date();

    console.log("posid: " + this.posid);

    this.getPostList();
    this.getAllProductBroken();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }
}

</style>
