<template>
  <section>
    <div class="contain no-print" v-if="!isPrint">
      <div class="columns" >
        <div class="column" >
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ລາຍການ ສິນຄ້າເສຍຫາຍ</div>
              </div>
            </div>

            
            <div class="columns" >
              <div class="column" v-if="roleid==4" style="background-color: white; border-radius: 10px; margin: 20px 10px 0px 20px; padding: 30px 30px 30px 30px;">  
                <div style="margin-top: 0px;">
                    <span style="font-size: 28px;">ເລືອກສິນຄ້າ ທີ່ເສຍຫາຍ</span>
                </div> 
                <div class="columns"> 
                 
                  <div class="column" style="text-align: right;">
                    <Multiselect
                      placeholder="ປະເພດສິນຄ້າ"
                      v-model="selectedMenuType"
                      label="NAME"
                      track-by="ID"
                      :options="menuTypeList"
                      @input="onSeletedMenuType"
                    >
                    </Multiselect>
                  </div>
                  <div class="column" style="text-align: right;">
                    <b-button 
                      type="is-danger"
                      expanded
                      style="width: 100%;"
                      @click="addEmergencyProductBrokenRequest()"
                      >ຂໍແຈ້ງ ສິນຄ້າເສຍຫາຍ</b-button
                    >
                  </div>
                  
                </div> 
                <table class="table is-fullwidth is-narrow is-small" >
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="10%">#</th>
                        <th class="has-text-centered " width="20%">ປະເພດ</th>
                        <th class="has-text-centered " width="60%">
                          ສິນຄ້າ
                        </th>
                        <th class="has-text-centered " width="20%">
                          ຈຳນວນ
                        </th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in menuList"
                        :key="data.PRODUCTID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>
                        <td class="has-text-centered">
                          {{ data.TYPENAME }}
                           
                        </td>
                        <td class="has-text-centered">
                          {{ data.PRODUCTNAME }} <br>
                            <img
                            style=" width: 50px; "
                            :src="getProductImgUrl(data.PHOTONAME)"
                          />
                        </td>
                        <td class="has-text-centered">
                          <b-field grouped style=" float: right;"> 
                              <b-field>
                                <input
                                  type="number"
                                  class="input"
                                  v-model="data.QUANTITY"
                                  style=" width: 100px; text-align: right;"
                                />
                              </b-field>
                              <b-field style="padding: 7px;">{{ data.UNIT }}</b-field>
                          </b-field>
                        </td>
                      </tr>
                      
                    </tbody>
                   
                  </table>
              </div>

              <div class="column" v-if="roleid== 2 || roleid== 3 || roleid== 4" style="background-color: white; border-radius: 10px; margin: 20px 20px 30px 10px; padding: 0px 50px 50px 50px">
                <div >
                  <div style="margin-top: 28px;">
                      <span style="font-size: 28px;">ລາຍການ ສິນຄ້າເສຍຫາຍ</span>
                  </div>

                  <b-tabs v-model="activeTab" >
                    <b-tab-item label="ລາຍການ ສິນຄ້າເສຍຫາຍ">
                      <b-field>
                          <b-field label="ຈາກວັນທີ">
                            <b-datepicker
                                :date-formatter="dateFormatter"
                                v-model="startdate"
                              >
                              </b-datepicker>
                            </b-field>
                            <b-field style="padding-left: 20px; padding-right: 20px;">  </b-field>
                            <b-field label="ເຖິງວັນທີ">
                              <b-datepicker
                                :date-formatter="dateFormatter"
                                v-model="enddate"
                              >
                              </b-datepicker>
                            </b-field>
                            <b-field style="padding-left: 20px; padding-top: 32px;" >
                              <b-button type="is-info" expanded style="width: 100%;" @click="getRequest()">ລາຍງານ</b-button>
                            </b-field>

                                
                        </b-field>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-centered " width="20%">
                                POS 
                              </th>
                              <th class="has-text-centered " width="20%">
                                ຈຳນວນ <br>ສິນຄ້າ
                              </th>

                              <th class="has-text-centered " width="20%">
                                ວັນທີ
                              </th>
                              <th class="has-text-centered " width="20%">
                                ສະຖານະ
                              </th>
                              
                              <th class="has-text-centered " width="15%">
                              ລາຍລະອຽດ 
                              </th>
                              
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in requests"
                              :key="data.REQUESTID"
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-centered">
                                {{ data.POSNAME }} 
                              </td>
                              <td class="has-text-centered" >
                                {{ data.NUMBEROFPRODUCT | formatNumberLocal }} 
                              </td>
                              
                              <td class="has-text-centered" >
                                {{ data.TXTIME}} 
                              </td>
                              <td class="has-text-centered" >
                                <span v-if="data.NUMBEROFPRODUCT ==0" style="color: red;">  
                                  ບໍ່ມີສິນຄ້າ
                                </span>
                                <span v-else>
                                  {{ data.STATUSTITLE }} <br>
                                </span>
                                
                                <div>
                                  <b-button v-if="data.STATUS ==0 && roleid==4 && data.NUMBEROFPRODUCT >0"
                                      type="is-success"
                                      expanded
                                      style="width: 100%;"
                                      @click="changeRequestStatus(data.REQUESTID,data.POSID,1)"
                                      >ຢືນຢັນ
                                  </b-button>
                                </div>
                                <div style="margin-top: 10px;">
                                  <b-button v-if="data.STATUS ==0 && roleid==4 && data.NUMBEROFPRODUCT >0" 
                                      type="is-danger"
                                      expanded
                                      style="width: 100%;"
                                      @click="changeRequestStatus(data.REQUESTID,data.POSID,-9)"
                                      >ຍົກເລີກ
                                  </b-button>
                                </div>
                                
                              </td>
                              <td class="has-text-centered" >
                                <b-button 
                                    type="is-info"
                                    expanded
                                    style="width: 100%;"
                                    @click="requestDetail(data.REQUESTID, data.STATUS, data.POSNAME, data.TXTIME)"
                                    >ລາຍລະອຽດ
                                </b-button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                     
                    </b-tab-item>

                    <b-tab-item label="ລາຍລະອຽດ">
                      

                        <b-field class="detailtitle">ສິນຄ້າທີ່ແຈ້ງເສຍຫາຍ</b-field>
                        <table class="table is-fullwidth is-narrow is-small">
                          <thead>
                            <tr>
                              <th class="has-text-centered " width="5%">#</th>
                              <th class="has-text-left " width="20%">
                                ສິນຄ້າ 
                              </th>
                              <th class="has-text-centered " width="20%">
                                ຈຳນວນ<br>ເສຍຫາຍ
                              </th>
                              <th class="has-text-centered " width="20%">
                                ຈຳນວນ<br>ອະນຸມັດ
                              </th>
                              <th class="has-text-centered " width="10%"> 
                                ລາຄາຕໍ່ໜ່ວຍ
                              </th>
                              <th class="has-text-centered " width="20%">
                                ເປັນເງິນ
                              </th>
                              
                             
                              
                              
                            </tr>
                          </thead>
                          <tbody>
                            <tr 
                              v-for="(data, index) in summarydata"
                              :key="data.ID"  
                            >
                              <td class="has-text-centered">{{ index + 1 }}</td>
                              <td class="has-text-left">
                                {{ data.PRODUCTNAME }} 
                              </td>
                              <td class="has-text-right" >
                                {{ data.QUANTITY | formatNumberLocal }}  {{ data.UNIT}} 
                              </td>
                              <td class="has-text-centered" >
                                <b-field v-if="roleid == 3 && data.STATUS==1">
                                  {{ data.QUANTITY_PROVIDE | formatNumberLocal}} 
                                </b-field>
                                <b-field v-else-if="data.STATUS>=2">
                                  {{ data.CHECKQUANTITY | formatNumberLocal}}   
                                </b-field>
                              </td>

                              <td class="has-text-right" >
                                <b-field v-if="roleid == 3 && data.STATUS==1">
                                  {{ data.B_UNITPRICE | formatNumberLocal}} 
                                </b-field>
                                <b-field v-else-if=" data.STATUS>1">
                                  {{ data.A_UNITPRICE | formatNumberLocal}} 
                                </b-field>
                              </td>
                              

                              <td class="has-text-right" >
                                <b-field v-if="roleid == 3 && data.STATUS==1">
                                  {{ data.QUANTITY_PROVIDE*data.B_UNITPRICE | formatNumberLocal}} 
                                </b-field>
                                <b-field v-else-if="data.STATUS>1">
                                  {{ data.TOTALPRICE | formatNumberLocal}} 
                                </b-field>
                                  
                                 
                                </td>
                                
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th colspan="5" class="has-text-right">  
                                ລວມ 
                              </th>
                              <th class="has-text-right" v-if="selectedRequestStatus >=2">
                                {{ _.sumBy(summarydata, i => i["TOTALPRICE"]*1) | formatNumberLocal }}
                              </th>
                              <th class="has-text-right" v-else-if="selectedRequestStatus ==1">
                                {{ _.sumBy(summarydata, i => i["B_UNITPRICE"] * i["QUANTITY_PROVIDE"]*1) | formatNumberLocal }}
                              </th>
                              
                            </tr>
                          </tfoot>
                        </table>
                        <div class="columns">
                          <div class="column">
                            
                          </div>
                          <div class="column">
                            
                          </div>
                          <div class="column">
                            <b-button v-if="roleid == 3 && selectedRequestStatus ==1" 
                                type="is-danger"
                                expanded
                                style="width: 100%;"
                                @click="changeRequestStatus(selectedRequestid, '', '-1')"
                                >ປະຕິເສດ 
                            </b-button>

                            <b-button v-if="roleid == 2 && selectedRequestStatus ==2"
                                type="is-danger"
                                expanded
                                style="width: 100%;"
                                @click="changeRequestStatus(selectedRequestid, '', '-2')"
                                >ປະຕິເສດ 
                            </b-button>
                          </div>
                          <div class="column">
                            <b-button  v-if="roleid == 3  && selectedRequestStatus ==1"
                                type="is-info"
                                expanded
                                style="width: 100%;"
                                @click="changeRequestStatus(selectedRequestid,'', 2)"
                                >ຂໍອະນຸມັດ
                            </b-button>

                            <b-button  v-if="roleid == 2 && selectedRequestStatus ==2"
                                type="is-info"
                                expanded
                                style="width: 100%;"
                                @click="changeRequestStatus(selectedRequestid,'', 3)"
                                >ອະນຸມັດ
                            </b-button>

                            <b-button  v-if="roleid == 3 && selectedRequestStatus >= 3"
                                type="is-info" 
                                expanded
                                style="width: 100%;"
                                @click="printReceipt()"
                                >ພິມໃບແຈ້ງສິນຄ້າເສຍຫາຍ
                            </b-button><br><br>
<!-- 
                            <b-button  v-if="roleid == 3 && selectedRequestStatus == 3"
                                type="is-danger"
                                expanded
                                style="width: 100%;"
                                @click="updateProductToPos(selectedRequestid, 5)"
                                >ສົ່ງສິນຄ້າໃຫ້ POS
                            </b-button> -->

                            

                            <b-button  v-if="roleid == 4 && selectedRequestStatus ==5"
                                type="is-success"
                                expanded
                                style="width: 100%;"
                                @click="updateProductToPos(selectedRequestid, 6)"
                                >ຮັບເອົາສິນຄ້າ
                            </b-button>
                            <b-field v-if="selectedRequestStatus==6">
                              ໄດ້ຮັບສິນຄ້າແລ້ວ
                            </b-field>

                            

                          </div>
                          
                        </div>
                        
                    </b-tab-item>

                    
                  </b-tabs>
                  <div>
                    
                  </div>
                </div>
                
              </div>
              
            </div>

            
            
      
             

          </div>

          
          
        </div>
        
      </div>
    </div>
    <div class="contain print-only">
      <div class="column">
        <!-- <div v-show="isDetailSelected"> -->
          <div
            style="margin-top: 18px; text-align: center;">
              <span class="print-receipt-main-title">ລາຍການສິນຄ້າເສາຍຫາຍ {{ posname }}</span> <br>
              <span style="font-size: 16px;"> {{ txtime }}</span>
          </div>

          <table class="table is-fullwidth is-narrow is-small">
            <thead>
              <tr>
                <th class="has-text-centered " width="5%">#</th>
                <th class="has-text-left " width="20%">
                  ສິນຄ້າ 
                </th>
                <th class="has-text-centered " width="30%">
                  ຈຳນວນ
                </th>
                <th class="has-text-centered " width="20%">
                  ເປັນເງິນ
                </th>
                
                
                
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(data, index) in summarydata"
                :key="data.ID"
              >
                <td class="has-text-centered">{{ index + 1 }}</td>
                <td class="has-text-left">
                  {{ data.PRODUCTNAME }} 
                </td>
                <td class="has-text-right" >
                  {{ data.CHECKQUANTITY | formatNumberLocal }}  {{ data.UNIT}} 
                </td>
                
                <td class="has-text-right" >
                   
                    <b-field>
                      {{ data.TOTALPRICE | formatNumberLocal}} 
                    </b-field>
                    
                  </td>
                 
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th colspan="3" class="has-text-right"> 
                  ລວມ 
                </th>
                <th colspan="1" class="has-text-right">
                  {{ _.sumBy(summarydata, i => i["TOTALPRICE"]) | formatNumberLocal }}
                </th>
                
              </tr>
            </tfoot>
          </table>
      </div>
    </div>
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      username: null,
      productInBasketList: [],
      total: 0,
      allDataInBacket: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/menu/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/menu/",
      productphotopath:  
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      isRecent: false,
      recentSwitchTittle: "ການຊຳລະລ່າສຸດ",
      productSwitchTittle : "ລາຍການສິນຄ້າ",
      history: [],
      historydetail: [],
      selectedPaymentDate: null,
      orderid: null,
      selectedTotal: null,
      selectedPaymentType: null,
      isDetailSelected: false,
      isPrint: false,
     
      menuTypeList: [],
      selectedMenuType: null,
      menuList:[],
      requests:[],
      startdate: null,
      enddate: null,
      activeTab: 0,
      formdata: [],
      summarydata: [],
      selectedRequestid: null,
      selectedRequestStatus: null,
      posname: null,
      txtime: null,

    };
  },
  methods: {
    printReceipt(){
      this.isPrint = true;
      console.log("print.....")
      this.print();
      this.isPrint = false;
    },
   
    getMenuType(){
      RestAPI.getPosProductType()
        .then(res => {
          this.menuTypeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    onSeletedMenuType(){
      this.getPosProductByType(this.selectedMenuType.ID);
    },

    getPosProductByType(typeid){
      RestAPI.getPosProductByType(typeid, this.posid)
        .then(res => {
          this.menuList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    

    addEmergencyProductBrokenRequest(){
      var params = {
        posid: this.posid,
        product: this.menuList,
        username: this.username,
      };
      RestAPI.addEmergencyProductBrokenRequest(params)
        .then((res) => {
          this.getRequest();
          this.onSeletedMenuType();
          // console.log("res:" + JSON.stringify(res.data)) 
          if(res.data==0){
            this.$swal("ກະລູນາປ້ອນຂໍ້ມູນໃຫ້ຖືກຕ້ອງ", "", "error");
          }else if(res.data >0){
            this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");    
          }
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    getRequest(){
      var posid = this.roleid ==4 ? this.posid : 'ALL';
      RestAPI.getPosProductBrokenRequest(posid, this.dateFormatterYYYYMMDD(this.startdate), this.dateFormatterYYYYMMDD(this.enddate))
        .then(res => {
          this.requests = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    requestDetail(requestid, status, posname, txtime){
      this.activeTab = 1;
      this.selectedRequestid = requestid;
      this.selectedRequestStatus = status;
      this.posname = posname;
      this.txtime = txtime;
      RestAPI.getProductBrokenRequestDetail(requestid)
        .then(res => {
          this.formdata = res.data.formdata;
          this.summarydata = res.data.summarydata;
          
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    changeRequestStatus(requestid, posid = null, status){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            requestid: requestid,
            status: status,
            summarydata: this.summarydata,
            posid: posid ? posid : this.summarydata[0].POSID,
            username: this.username,
          };
          console.log("params: " + JSON.stringify(params))
          RestAPI.changeProductBrokenRequestStatus(params)
            .then(() => {
              this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");    
              this.getRequest();
              this.activeTab = 0;
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
          
    },

    updateProductToPos(requestid, status){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            posid: this.posid,
            requestid: requestid,
            summarydata : this.summarydata,
            status: status,
            username: this.username,
          };
          RestAPI.updateProductToPos(params)
            .then(() => {
             
              this.$swal("ສຳເລັດ", "ດຳເນີນການສຳເລັດ", "success");    
              this.getRequest();
              this.selectedRequestStatus = status;
              
            }) 
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
          
    },
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
    print() {
      window.print();
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },

    getProductImgUrl(photoname) {
      var photopreview = photoname
        ? this.productphotopath + photoname
        : this.productphotopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.posid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.sellerid =  this.user.USERNAME;
    var currentDate = new Date();

    // Set the start date to the first day of the current month
    this.startdate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    

    // this.startdate = new Date('Y-m-01');
    
    this.enddate = new Date();

    // console.log("this.startdate: " + this.startdate)
    this.getMenuType();
    this.getRequest();
    
    
  },
  computed: {},
};
</script>

<style scoped>

.detailtitle{
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
}
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    /* size: 6in 9in;
    size: portrait; */
  }
}

</style>
