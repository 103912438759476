import Vue from "vue";
import App from "./App.vue";
import router from "./router/router";
import store from "./vuex/store";
import axios from "axios";
import VueAxios from 'vue-axios'
import interceptorSetup from "./axios.interceptor";

import buefy from "buefy";
import "buefy/dist/buefy.css";
import "./assets/style.scss";
import mixin from "./mixin/mixin";
import VueSweetalert2 from "vue-sweetalert2";
import { library } from "@fortawesome/fontawesome-svg-core";
import lodash from "lodash";

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

import {
  faUpload,
  faCoffee,
  faBookOpen,
  faCheck,
  faCheckCircle,
  faPencilAlt,
  faPlusCircle,
  faSignOutAlt,
  faTimes,
  faUser,
  faArrowLeft,
  faEdit,
  faPrint,
  faRedo,
  faClock,
  faKey,
  faHome,
  faCreditCard,
  faFileAlt,
  faFileContract,
  faSave,
  faSearch,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
  faUpload,
  faCoffee,
  faPlusCircle,
  faCheckCircle,
  faBookOpen,
  faSignOutAlt,
  faUser,
  faCheck,
  faPencilAlt,
  faTimes,
  faArrowLeft,
  faEdit,
  faPrint,
  faRedo,
  faClock,
  faKey,
  faHome,
  faCreditCard,
  faFileAlt,
  faSave,
  faSearch,
  faTrash,
  faFileContract,
  faPlus
);

Vue.config.productionTip = false;
import VueProgressBar from "vue-progressbar";
import VModal from "vue-js-modal";


import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

import VueBreadcrumbs from 'vue-2-breadcrumbs';
Vue.use(VueBreadcrumbs,VueAxios, axios, VModal);


// Vue.use(VueAxios, axios)

import KProgress from 'k-progress';
Vue.component('k-progress', KProgress);
 
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)


Vue.use(VueProgressBar, VModal, {
  color: "#FF5722",
  failedColor: "red",
  height: "5px",
  position: "relative"
});

interceptorSetup();

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
Object.defineProperty(Vue.prototype, "_", { value: lodash });

Vue.use(buefy);
Vue.use(VModal);
Vue.use(VueSweetalert2);
Vue.component("font-awesome-icon", FontAwesomeIcon); // registered globally

export const EventBus = new Vue();

Vue.mixin(mixin);
export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
