<template>
  <nav
    class="navbar is-primary no-print"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <!-- <img src="@/assets/logo.png" width="30" height="30"> -->
        <span>&nbsp;&nbsp;{{ this.user.username }}</span>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': isMenuActive }"
        @click="isMenuActive = !isMenuActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isMenuActive }">
      <div class="navbar-start">
        <!-- <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='1'">
          <a class="navbar-link">
            <span>ລາຍງານ</span>
          </a>

          <div class="navbar-dropdown">
            

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/clinic/productionreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານ1</span>
            </router-link>
            
          </div>
        </div> -->

        

        
        
      </div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='1'">
          <a class="navbar-link">
            <span>ຕັ້ງຄ່າ ສິນຄ້າ- MiniMart</span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/minimartproduct"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ສິນຄ້າ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/minimartproducttype"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ປະເພດສິນຄ້າ</span>
            </router-link>
            
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/minimartproductunit"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ຫົວໜ່ວຍສິນຄ້າ</span>
            </router-link>
            
          </div>
              
        </div>

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='1'">
          <a class="navbar-link">
            <span>ຕັ້ງຄ່າ ສິນຄ້າ- POS</span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/product"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ສິນຄ້າ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/producttype"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ປະເພດສິນຄ້າ</span>
            </router-link>
            
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/productunit"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ຫົວໜ່ວຍສິນຄ້າ</span>
            </router-link>
            
          </div>
              
        </div>

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='1'">
          <a class="navbar-link">
            <span>ຕັ້ງຄ່າ ເມນູ -POS</span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/menu"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ເມນູ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/menutype"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ປະເພດເມນູ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/menusubtype"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ປະເພດເມນູຍ່ອຍ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/menuunit"
              :exact="true"
              active-class="is-active"
            >
              <span>ຕັ້ງຄ່າ ຫົວໜ່ວຍເມນູ</span>
            </router-link>

         
            
          </div>
              
        </div>

        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='1'">
          <a class="navbar-link">
            <span>ຕັ້ງຄ່າ VAT/ ສ່ວນລຸດ</span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/posvat"
              :exact="true"
              active-class="is-active"
            >
              <span>ຈັດການ VAT</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/setting/posdiscount"
              :exact="true"
              active-class="is-active"
            >
              <span>ຈັດການ ສ່ວນລຸດ</span>
            </router-link>
                    
            
          </div>
              
        </div>

        

        
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ຜູ້ໃຊ້ງານ: {{ username }}</span>
          </a>
          <div class="navbar-dropdown">
            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/form/userProfile"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍ້ມູນສ່ວນຕົວ</span>
            </router-link>

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/login"
              :exact="true"
              active-class="is-active"
            >
              <span>ອອກຈາກລະບົບ</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "ReportMenu",
  data() {
    return {
      isMenuActive: false,
      roleid: null,
      username: null,
    };
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    
    console.log("user: "+ JSON.stringify(this.user))
    
  },
  computed: {
    // user: get("loginData"),
    
  },
  methods: {
    menuClicked() {
      this.isMenuActive = false;
    }
  }
};
</script>

<style scoped>
.navbar-item {
  white-space: nowrap;
}
.navbar-end {
  padding-right: 60px;
}
</style>
