<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດການ ສິນຄ້າ</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
                <div class="column">
                  <b-tabs v-model="activeTab" v-if="roleid == 2">
                    <b-tab-item label="ຟອມເພີ່ມ/ ແກ້ໄຂສິນຄ້າ">
                      <div class="columns">
                        <div class="column">
                          <h3 class="title">ເພິ່ມ/ ແກ້ໄຂສິນຄ້າ</h3>
                        </div>
                      </div>
                      <div class="columns" v-if="adddataform">
                        <div class="column">
                          <div class="columns">
                            <div class="column">
                              <b-field label="ຊື່ສິນຄ້າ">
                                <b-input v-model.trim="product"> </b-input>
                              </b-field>
                            </div>
                          
                            <div class="column">
                              <b-field grouped style=" margin-bottom: -5px;">
                                <b-field label="ປະເພດ">
                                </b-field>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-success"
                                    size="is-small"
                                    @click="addProductType()"
                                    ><span style="font-size: 24px;">+</span>
                                  </b-button>
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedType"
                                  label="NAME"
                                  track-by="ID"
                                  :options="typeList"
                                  @input="onTypeSelected()"
                                >
                                </Multiselect>
                              </b-field>

                            </div>                           

                            <div class="column">
                              <b-field grouped style=" margin-bottom: -5px;">
                                <b-field label="ຫົວໜ່ວຍ">
                                </b-field>
                                <b-field>
                                  <b-button
                                    class="btn-group-margin"
                                    type="is-success"
                                    size="is-small"
                                    @click="addProductUnit()"
                                    ><span style="font-size: 24px;">+</span>
                                  </b-button>
                                </b-field>
                              </b-field>
                              <b-field>
                                <Multiselect
                                  placeholder="ເລືອກ"
                                  v-model.trim="selectedunit"
                                  label="NAME"
                                  track-by="ID"
                                  :options="unitList"
                                >
                                </Multiselect>
                              </b-field>

                            
                            </div>
                                                        
                          </div>
                          <div class="columns">
                            <div class="column"></div>
                            <div class="column">
                              <b-field grouped position="is-right">
                                <div class="buttons">
                                  <b-button
                                    expanded
                                    type="is-primary"
                                    onclick="document.getElementById('fileupload').click()"
                                    >ເລືອກຮູບ
                                  </b-button>
                                </div>
                              </b-field>
                              <b-field label="" style="float: right">
                                <b-field v-if="saveAction">
                                  <button class="button is-info" @click="saveProduct">
                                    ບັນທຶກສິນຄ້າ
                                  </button>
                                </b-field>
                                <b-field v-if="updateAction">
                                  <div class="columns">
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-primary"
                                          @click="updateProduct"
                                        >
                                          ແກ້ໄຂສິນຄ້າ
                                        </button>
                                      </b-field>
                                    </div>
                                    <div class="column">
                                      <b-field>
                                        <button
                                          class="button is-info"
                                          @click="openSaveProductForm"
                                        >
                                          ລ້າງຟອມ
                                        </button>
                                      </b-field>
                                    </div>
                                  </div>
                                </b-field>
                              </b-field>
                            </div>
                          </div>
                          <div class="columns">
                            <div class="column">
                              <b-field
                                grouped
                                position="is-centered"
                                v-if="isSelectPhoto || updateAction"
                              >
                                <img
                                  data-v-xcxcc
                                  :src="photopreview"
                                  width="500"
                                  height="500"
                                />
                              </b-field>
                              <input
                                type="file"
                                id="fileupload"
                                @change="onFileSelected"
                                style="visibility:hidden;"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab-item>

                    <b-tab-item label="ສິນຄ້າ ທີ່ເປີດໃຊ້ງານ">
                      <b-field label="ເລືອກປະເພດສິນຄ້າ">
                        <Multiselect
                          placeholder="ເລືອກ"
                          v-model.trim="selectedType"
                          label="NAME"
                          track-by="ID"
                          :options="typeList"
                          @input="onTypeSelectedForProductList()"
                        >
                        </Multiselect>
                      </b-field>

                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ສິນຄ້າ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດ
                            </th>
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                            
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in filteredProductList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-centered">
                              {{data.ID}} - {{ data.PRODUCT }}<br>
                              <img
                                data-v-xcxcc
                                :src="getImgUrl(data.PHOTONAME)"
                                width="100"
                                height="100"
                              />
                            </td>
                            <td class="has-text-centered">
                              {{ data.TYPE }}
                            </td>
                           
                            <td class="has-text-centered">
                              {{ data.UNIT }}
                            </td>
                           
                            <td class="has-text-centered">
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-primary"
                                  size="is-small"
                                  @click="updateProductForm(data)"
                                  >ແກ້ໄຂ
                                </b-button>
                              </b-field>
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-danger"
                                  size="is-small"
                                  @click="changeItemStatus(data.ID, 0)"
                                  >ປິດໃຊ້ງານ
                                </b-button>
                              </b-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>

                    <b-tab-item label="ສິນຄ້າ ທີ່ຖືກປິດ">
                      <table class="table is-fullwidth is-narrow is-small">
                        <thead>
                          <tr>
                            <th class="has-text-centered " width="5%">#</th>

                            <th class="has-text-centered " width="15%">
                              ເມນູ
                            </th>

                            <th class="has-text-centered " width="15%">
                              ປະເພດ
                            </th>
                            
                            <th class="has-text-centered " width="15%">
                              ຫົວໜ່ວຍ
                            </th>
                           
                            <th class="has-text-centered " width="10%">
                              ຈັດການ
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in inActiveDataList"
                            :key="data.ID"
                          >
                            <td class="has-text-centered">{{ index + 1 }}</td>

                            <td class="has-text-centered">
                              {{ data.PRODUCT }}<br>
                              <img
                                data-v-xcxcc
                                :src="getImgUrl(data.PHOTONAME)"
                                width="100"
                                height="100"
                              />
                            </td>
                            <td class="has-text-centered">
                              {{ data.TYPE }}
                            </td>
                            
                            <td class="has-text-centered">
                              {{ data.UNIT }}
                            </td>
                            <td class="has-text-centered">
                              <b-field>
                                <b-button
                                  class="btn-group-margin"
                                  type="is-info"
                                  size="is-small"
                                  @click="changeItemStatus(data.ID, 1)"
                                  >ເປີດໃຊ້ງານ
                                </b-button>
                              </b-field>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </b-tab-item>
                    
                  </b-tabs>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
import axios from "axios";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  data() {
    return {
      roleid: null,
      username: null,
      url: "uploadproduct", // function name in api site
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/products/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/products/",
      photopreview: this.photopath +'unknown.png',
      isSelectPhoto: false,
      selectedFile: null,
      selectedProduct: null,
      product: null,


      activeTab: 1,
      saveAction: true,
      updateAction: false,
      adddataform: true,
      updatedataform: false,

      photoname: null,
      productid: null,
      oldproduct: null,
      typeList: [],
      selectedType: null,
      unitList: [], 
      selectedunit: null,

      activeDataList: [],
      originalDataList: [],
      inActiveDataList: [],


      
      
    };
  },
  methods: {
    
    onProductSelected() {
      this.productList.forEach(element => {
        if(element['ID'] == this.selectedProduct.ID){
          this.productUnit = element['UNIT']
        }
      });
    },
    
    saveProduct(){ 
      
      if (
        this.product === null ||
        this.selectedType === null ||
        this.selectedunit == null ||
        this.photoname === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            product: this.product,
            typeid: this.selectedType.ID,
            unit: this.selectedunit.ID,
            photoname: this.photoname,
            username: this.username,
          };
          // console.log("params: " + JSON.stringify(params))
          RestAPI.saveProduct(params)
            .then(() => {
              // this.getProductList();
              // this.activeTab = 1
              // this.product = null;
              // this.selectedType = null;
              // this.selectedunit = null;
              // this.photoname = null;
              // this.photopreview = null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ເພີ່ມເມູນສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              window.location.reload();
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });  

    },
    getProductList(){
      RestAPI.getSettingProductList()
        .then(res => {
          this.activeDataList = res.data.activedata;
          this.originalDataList = res.data.activedata;
          this.inActiveDataList = res.data.inactivedata;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    updateProduct(){
      if (
        this.product === null ||
        this.selectedType === null ||
        this.selectedunit == null ||
        this.photoname === null
      ) {
        this.$swal("ກະລຸນາປ້ອນຂໍ້ມູນໃຫ້ຄົບຖ້ວນ", "", "error");
        return false;
      }

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            productid : this.productid,
            oldproduct: this.oldproduct,
            product: this.product,
            typeid: this.selectedType.ID,
            unit: this.selectedunit.ID,
            photoname: this.photoname,
            username: this.username,
          };
          console.log("params: " + JSON.stringify(params))
          RestAPI.updateProduct(params)
            .then((res) => {
              console.log("res: " + JSON.stringify(res.data))
              if(res.data==='duplicate menuname'){
                this.$swal("ຊື່ເມນູຊ້ຳ, ລອງໃໝ່", "", "error");
                return false;
              }
              this.getProductList();
              this.activeTab = 1;
              this.product = null;
              this.selectedType = null;
              this.selectedunit = null;
              this.photoname = null;
              this.photopreview = null;
              this.$swal({
                title: "ສຳເລັດ",
                text: "ແກ້ໄຂຂໍ້ມູນເມນູສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    openSaveProductForm(){
      this.menuname = null;
      this.selectedType = null;
      this.selectedsubtype = null;
      this.selectedunit = null;
      this.selectedUsedIngredient = null;
      this.photoname = null;
      this.photopreview = this.photopath +'unknown.png';
    },
    updateProductForm(data){
      this.activeTab = 0;
      this.saveAction = false;
      this.updateAction = true;
      this.productid = data.ID
      this.oldproduct = data.PRODUCT;
      this.product = data.PRODUCT;
      this.selectedType = {
        ID: data.TYPEID,
        NAME: data.TYPE,
      };
      this.selectedunit = {
        ID: data.UNITID,
        NAME: data.UNIT,
      };

      this.photoname = data.PHOTONAME;
      this.photopreview = this.photopath + data.PHOTONAME;
    },
    changeItemStatus(ID, STATUS){
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            itemid : ID,
            table: 'pos_product', 
            status : STATUS,
            username: this.username,
          };
          RestAPI.changeItemStatus(params)
            .then(() => {
              this.getProductList();
              this.activeTab = 1
              this.$swal({
                title: "ສຳເລັດ",
                text: "ປ່ຽນສະຖານະສຳເລັດ",
                icon: "success",
                timer:1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },

    onTypeSelected(){
      this.selectedunit = null;
      this.getSettingProductUnitByType();

    },

    onTypeSelectedForProductList() {
      this.activeDataList = [...this.originalDataList];
    },
    
    getSettingProductUnitByType(){
      RestAPI.getSettingProductUnitByType(this.selectedType.ID)
        .then(res => {
          this.unitList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    getProductType(){
      RestAPI.getSettingProductType()
        .then(res => {
          this.typeList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    addProductType(){
      this.$router.push({ name: 'producttypesetting', params: { prop_activetab: 0 } });
    },
  
    addProductUnit(){
      this.$router.push({ name: 'productunitsetting', params: { prop_activetab: 0 } });
    },
  

    
    onFileSelected(event) {
      this.isSelectPhoto = true;
      console.log("url: "+ this.url)
      console.log("photopath: " + this.photopath)
      this.selectedFile = event.target.files[0];
      const fd = new FormData();
      fd.append("image", this.selectedFile);
      axios.post(this.url, fd).then((res) => {
        this.photoname = res.data;
        this.photopreview = this.photopath + res.data;

        this.selectedFile= null;
        
      });
    },

    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      return photopreview;
    },
   
    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getProductType();
    this.getProductList();
    
    
  },
  computed: {
    filteredProductList() {
      if (this.selectedType && this.selectedType.ID) {
        return this.activeDataList.filter(element => {
          return element['TYPEID'] === this.selectedType.ID;
        });
      } else {
        return this.activeDataList; // Return the entire list if selectedType or its ID is not available
      }
    },
  },
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}


</style>
