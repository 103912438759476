import { render, staticRenderFns } from "./menusubtypesetting.vue?vue&type=template&id=0df1d894&scoped=true&"
import script from "./menusubtypesetting.vue?vue&type=script&lang=js&"
export * from "./menusubtypesetting.vue?vue&type=script&lang=js&"
import style0 from "./menusubtypesetting.vue?vue&type=style&index=0&id=0df1d894&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0df1d894",
  null
  
)

export default component.exports