import Vue from "vue";
import Router from "vue-router";
import home from "../views/home.vue";
import login from "../views/login.vue";
import customer from "../views/customer.vue";
import store from "./../vuex/store";

import register from "../views/register.vue";
// import admin from "../views/pos-admin/admin-main.vue";
import adminproducts from "../views/pos-admin/admin-products.vue";
import adminsellerreport from "../views/pos-admin/admin-sellerreport.vue";
import adminstockreport from "../views/pos-admin/admin-stockreport.vue";
import adminbuypricehistory from "../views/pos-admin/admin-buypricehistory.vue";
import adminminimartbuypricehistory from "../views/pos-admin/admin-minimartbuypricehistory.vue";


import stock from "../views/pos-stock/stock-main.vue";
import stockaftersell from "../views/pos-stock/stock-aftersell.vue";
import stockproducts from "../views/pos-stock/stock-products.vue";
import stockproductreport from "../views/pos-stock/stock-productreport.vue";
import stockstockreport from "../views/pos-stock/stock-stockreport.vue";
import manager from "../views/pos-manager/manager-products.vue";
import seller from "../views/pos-seller/seller-main.vue";
import sellerreport from "../views/pos-seller/seller-report.vue";
import sellerduplicatepaymentreport from "../views/pos-seller/seller-duplicatepayment-report.vue";
import sellerwaitpayment from "../views/pos-seller/seller-waitpayment.vue";
import sellerallpaymentreport from "../views/pos-seller/seller-allpaymentreport.vue";
import sellerallrefundreport from "../views/pos-seller/seller-allrefundreport.vue";
import sellerallmoneyreport from "../views/pos-seller/seller-allmoneyreport.vue";
import sellerbossmoneyreport from "../views/pos-seller/seller-bossmoneyreport.vue";
import sellerallproductreport from "../views/pos-seller/seller-allproductreport.vue";
import sellerallmenureport from "../views/pos-seller/seller-allmenureport.vue";
import postablesetting from "../views/pos-seller/table-setting.vue";
import posmenuremain from "../views/pos-seller/pos-menuremain.vue";


import sellerstock from "../views/pos-seller/seller-stock.vue";
import sellerproductbroken from "../views/pos-seller/seller-productbroken.vue";
import sellerremainmenu from "../views/pos-seller/seller-remainmenu.vue";
import allstock from "../views/pos-seller/seller-allstock.vue";
import notification from "../views/pos-seller/seller-notification.vue";

import posmenu from "../views/pos-seller/seller-menu.vue";

import chef from "../views/pos-seller/seller-chef.vue";
import chefreport from "../views/pos-seller/seller-chef-report.vue";
import chefmenu from "../views/pos-seller/seller-chef-menu.vue";
import posproductrequest from "../views/pos-seller/pos-productrequest.vue";
import posproductdirectrequest from "../views/pos-seller/pos-productdirectrequest.vue";
import posproductprovideandsend from "../views/pos-seller/pos-productprovideandsend.vue";

import posproductbrokenrequest from "../views/pos-seller/pos-productbrokenrequest.vue";



import managerproductgrading from "../views/pos-manager/manager-products.vue";
import managersellerreport from "../views/pos-manager/manager-sellerreport.vue";
import managerstockreport from "../views/pos-manager/manager-stockreport.vue";


import menusetting from "../views/pos-setting/menusetting.vue";
import menutypesetting from "../views/pos-setting/menutypesetting.vue";
import menusubtypesetting from "../views/pos-setting/menusubtypesetting.vue";
import menuunitsetting from "../views/pos-setting/menuunitsetting.vue";

import productsetting from "../views/pos-setting/productsetting.vue";
import producttypesetting from "../views/pos-setting/producttypesetting.vue";
import productunitsetting from "../views/pos-setting/productunitsetting.vue";
import posvat from "../views/pos-setting/posvat.vue";
import posdiscount from "../views/pos-setting/posdiscount.vue";
import pospaydate from "../views/pos-setting/pospaydate.vue";

//minimart
import minimart from "../views/minimart/minimart-main.vue";
import minimartallproductreport from "../views/minimart/minimart-allproductreport.vue";
import minimartallpaymentreport from "../views/minimart/minimart-allpaymentreport.vue";
import minimartallmoneyreport from "../views/minimart/minimart-allmoneyreport.vue";
import minimartbossmoneyreport from "../views/minimart/minimart-bossmoneyreport.vue";
import minimartallrefundreport from "../views/minimart/minimart-allrefundreport.vue";
import minimartstock from "../views/minimart/minimart-stock.vue";

import minimartproduct from "../views/minimart/minimart-product.vue";

import minimartproductrequest from "../views/minimart/minimart-productrequest.vue";
import minimartproductbrokenrequest from "../views/minimart/minimart-productbrokenrequest.vue";
import minimartproductbroken from "../views/minimart/minimart-productbroken.vue";
import settingminimartproduct from "../views/pos-setting/minimartproductsetting.vue";
import settingminimartproducttype from "../views/pos-setting/minimartproducttypesetting.vue";
import settingminimartproductunit from "../views/pos-setting/minimartproductunitsetting.vue";











Vue.use(Router);

const router = new Router({
  // mode: "hash",
  routes: [
    {
      path: "/customer/:posid/:tableid",
      name: "customer",
      component: customer,
      meta: {
				requiresAuth: false
			}
    },
    {
      path: "/login",
      name: "login",
      component: login
    },
    {
      path: "/register",
      name: "register",
      component: register
    },
    {
      path: "/",
      name: "home",
      component: home,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller",
      name: "seller",
      component: seller,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/seller/report",
      name: "sellerreport",
      component: sellerreport,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller/duplicatepaymentreport",
      name: "sellerduplicatepaymentreport",
      component: sellerduplicatepaymentreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/waitpayment",
      name: "sellerwaitpayment",
      component: sellerwaitpayment,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller/allpaymentreport",
      name: "sellerallpaymentreport",
      component: sellerallpaymentreport,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller/allrefundreport",
      name: "sellerallrefundreport",
      component: sellerallrefundreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/allmoneyreport",
      name: "sellerallmoneyreport",
      component: sellerallmoneyreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/bossmoneyreport",
      name: "sellerbossmoneyreport",
      component: sellerbossmoneyreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/allproductreport",
      name: "sellerallproductreport",
      component: sellerallproductreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/allmenureport",
      name: "sellerallmenureport",
      component: sellerallmenureport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/pos/tablesetting",
      name: "postablesetting",
      component: postablesetting,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/menuremain",
      name: "pos/menuremain",
      component: posmenuremain,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/seller/stock",
      name: "sellerstock",
      component: sellerstock,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller/productbroken",
      name: "sellerproductbroken",
      component: sellerproductbroken,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/seller/remainmenu",
      name: "sellerremainmenu",
      component: sellerremainmenu,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/allstock",
      name: "allstock",
      component: allstock,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/notification",
      name: "notification",
      component: notification,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/menu",
      name: "posmenu",
      component: posmenu,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/chef",
      name: "chef",
      component: chef,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/chef/report",
      name: "chefreport",
      component: chefreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/chef/menu",
      name: "chefmenu",
      component: chefmenu,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/productrequest",
      name: "posproductrequest",
      component: posproductrequest,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/pos/productdirectrequest",
      name: "posproductdirectrequest",
      component: posproductdirectrequest,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/productprovideandsend",
      name: "posproductprovideandsend",
      component: posproductprovideandsend,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/pos/productbrokenrequest",
      name: "posproductbrokenrequest",
      component: posproductbrokenrequest,
      meta: {  
        requiresAuth: true
      }
    },

    {
      path: "/manager",
      name: "manager",
      component: manager,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/manager/productgrading",
      name: "managerproductgrading",
      component: managerproductgrading,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/manager/sellerreport",
      name: "managersellerreport",
      component: managersellerreport,
      meta: {
        requiresAuth: true
      }
    },

    
    {
      path: "/manager/stockreport",
      name: "managerstockreport",
      component: managerstockreport,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/menu",
      name: "menusetting",
      component: menusetting,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/setting/menutype",
      name: "menutypesetting", 
      component: menutypesetting,
      meta: {
        requiresAuth: true
      },
      props: true
    },
    {
      path: "/setting/menusubtype",
      name: "menusubtypesetting",
      component: menusubtypesetting,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/setting/menuunit",
      name: "menuunitsetting",
      component: menuunitsetting,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/product",
      name: "productsetting",
      component: productsetting,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/producttype",
      name: "producttypesetting",
      component: producttypesetting,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/productunit",
      name: "productunitsetting",
      component: productunitsetting,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/posvat",
      name: "posvat",
      component: posvat,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/posdiscount",
      name: "posdiscount",
      component: posdiscount,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/setting/pospaydate",
      name: "pospaydate",
      component: pospaydate,
      meta: {
        requiresAuth: true
      }
    },

   

    

   

    // {
    //   path: "/admin",
    //   name: "admin",
    //   component: admin,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },
    {
      path: "/admin/products",
      name: "adminproducts",
      component: adminproducts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/sellerreport",
      name: "adminsellerreport",
      component: adminsellerreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin/stockreport",
      name: "adminstockreport",
      component: adminstockreport,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/admin/buypricehistory",
      name: "adminbuypricehistory",
      component: adminbuypricehistory,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/admin/minimartbuypricehistory",
      name: "adminminimartbuypricehistory",
      component: adminminimartbuypricehistory,
      meta: {
        requiresAuth: true
      }
    },

    {
      path: "/stock",
      name: "stock",
      component: stock,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/stock/aftersell",
      name: "stockaftersell",
      component: stockaftersell,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/stock/products",
      name: "stockproducts",
      component: stockproducts,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/stock/productreport",
      name: "stockproductreport",
      component: stockproductreport,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/stock/stockreport",
      name: "stockstockreport",
      component: stockstockreport,
      meta: {
        requiresAuth: true
      }
    },

    // start minimart
    {
      path: "/minimart",
      name: "minimart",
      component: minimart,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/allproductreport",
      name: "minimartallproductreport",
      component: minimartallproductreport,
      meta: {
        requiresAuth: true
      },
      props: true
    },


    {
      path: "/minimart/allpaymentreport",
      name: "minimartallpaymentreport",
      component: minimartallpaymentreport,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/allmoneyreport",
      name: "minimartallmoneyreport",
      component: minimartallmoneyreport,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/bossmoneyreport",
      name: "minimartbossmoneyreport",
      component: minimartbossmoneyreport,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/allrefundreport",
      name: "minimartallrefundreport",
      component: minimartallrefundreport,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/stock",
      name: "minimartstock",
      component: minimartstock,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/productrequest",
      name: "minimartproductrequest",
      component: minimartproductrequest,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/productbrokenrequest",
      name: "minimartproductbrokenrequest",
      component: minimartproductbrokenrequest,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/productbroken",
      name: "minimartproductbroken",
      component: minimartproductbroken,
      meta: {
        requiresAuth: true
      },
      props: true
    },


    {
      path: "/setting/minimartproduct",
      name: "settingminimartproduct",
      component: settingminimartproduct,
      meta: {
        requiresAuth: true
      },
      props: true
    },
    {
      path: "/setting/minimartproducttype",
      name: "settingminimartproducttype",
      component: settingminimartproducttype,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/setting/minimartproductunit",
      name: "settingminimartproductunit",
      component: settingminimartproductunit,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    {
      path: "/minimart/product",
      name: "minimartproduct",
      component: minimartproduct,
      meta: {
        requiresAuth: true
      },
      props: true
    },

    // {
    //   path: "/minimart/productrequest",
    //   name: "minimartproductrequest",
    //   component: minimartproductrequest,
    //   meta: {
    //     requiresAuth: true
    //   }
    // },

    


    // end mini mart
    

    

    {
      path: "*",
      name: "404",
      component: () =>
        import(/* webpackChunkName: "notfound" */ "@/views/notFound.vue")
    }
  ]
});
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  const token = store.get("token");
  if (to.name === 'customer') {
		// Allow access to the "customer" route without authentication
		next();
	} else if (requiresAuth && !token) {
    // next("/login");
    next("/login");

  } else if (requiresAuth && token) {
    const exp = store.get("decodedToken@exp");
    const now = new Date().getTime() / 1000; //time in second
    if (exp > now) {
      // const expin = exp - now;
      // if (expin < 60) {
      // } else {
      // }
      next();
    } else {
      Vue.swal({
        type: "error",
        text: "ໝົດເວລາ ນຳໃຊ້ລະບົບ, ກະລຸຸນາ ເຂົ້າລະບົບອີກຄັ້ງ"
      });
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
