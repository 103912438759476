<template>
  <section>
    <div class="contain no-print">
      
      <div class="columns" >
        
        <div class="column is-three-fifths" style="background-color: #f2f4f8; ">
          <div class="columns" >
            <div class="column" style="padding: 30px 30px 10px 30px ;">
              <div
                class="very-important-title has-text-centered"
                style=" margin: 0px 20px 0px 20px ;"
              >
                {{posname}} 
                
              </div>
            </div>
          </div>
          <div class="columns" >
            <div class="column" style="padding: 0px 30px 30px 30px ; ">

              <div class="columns">
                
                  <div class="column" style="">  
                    <b-field label="Barcode">
                      <input
                          ref="barcodeInput"
                          style="height: 43px;"
                          type="text"
                          placeholder="Scan Barcode"
                          class="input"
                          v-model="productbybarcode"
                          @keyup.enter="searchProductBybarcode"            
                          
                      />

                    </b-field>
                  </div>
                  

                <div class="column">
                  <b-field label="ປະເພດສິນຄ້າ">
                    <Multiselect
                      placeholder="ປະເພດສິນຄ້າ"
                      v-model="selectedType"
                      label="NAME"
                      track-by="ID"
                      :options="typeList"
                      @input="onMinimartTypeSelected"
                    >
                    </Multiselect> 
                  </b-field>
                  
                </div>
                <div class="column" >  
                  <b-field label="ຄົ້ນຫາສິນຄ້າ">
                    <input
                        style="height: 43px;"
                        type="text"
                        placeholder="ຄົ້ນຫາສິນຄ້າ"
                        class="input"
                        v-model="productkeyword"
                        @keyup="searchProductkey"
                        
                    />
                  </b-field>
                  
                    
                </div>
                
              </div>
              <div class="columns is-multiline is-mobile " style="overflow: auto; max-height: 1000px;">
                <div
                  v-for="(product, index) in productList"
                  :key="index"
                  class="column is-one-forth" style="padding: 10px;"
                  
                >
                  <div :class="[product.QUANTITY <= 0 ? 'runout' : 'normal']" style=" border-radius: 10px; "> 
                    <b-card  class="card "  > 
                      <div class="card-image" style=" width: 200px; ">
                        <figure class="image">
                            <img v-if="product.QUANTITY <=0"
                          
                              style="  border-radius: 10px; height: 150px;"
                              :src="getImgUrl(product.PHOTONAME)" 
                            />
                            <img v-else-if="paymentStatus < 3"
                            
                              @click="addProduct(product)" height="100px"
                              style=" border-radius: 10px; width: 150px;"
                              :src="getImgUrl(product.PHOTONAME)"
                            />
                            <img v-else
                              @click="closebillbefore()" height="100px"
                              style=" border-radius: 10px; width: 150px;"
                              :src="getImgUrl(product.PHOTONAME)"
                            />
                          </figure>
                      </div>
                      <div class="card-content-menu" style=" margin-top: 10px; height: 90px;">
                        <div class="content" :style="{ fontSize: isSmallScreen ? '14px' : '16px' }">
                            <div style="font-weight: bold;"> 
                                {{ product.ID }}-{{ product.NAME }} 
                            </div>
                            <div v-if="product.QUANTITY <=0"> 
                              <span style="color: crimson;">ສິນຄ້າໝົດ</span>
                            </div>
                            <div style="color:red" v-else>
                              {{ product.UNITPRICE | formatNumberLocal}} 
                            </div>
                            <div v-if="product.QUANTITY > 0">
                              ({{ product.QUANTITY | formatNumberLocal}}) 
                            </div>
                          </div>
                      </div>
                    </b-card>
                  </div>
                
                  
                </div>
              </div>
            </div>

          </div>
          
        </div>

        <div class="column is-two-fifths">
          <div class="columns" >
            <div class="column" style="padding: 30px 30px 10px 30px ;">
              <div
                class="very-important-title has-text-centered"
                style=" margin: 0px 20px 0px 20px ;"
              >
                ລາຍການສິນຄ້າ
                
              </div>
            </div>
          </div>
          <div class="columns" >
            <div class="column" style="padding: 0 30px 30px 30px ;">

              <div class="columns" v-if="productsInOrder.length > 0 " style="background-color: #E1F5FE; border-radius: 10px; margin-top: 10px;">
                <div class="column"> 
                   <div class="columns">
                    <div class="column">
                      <div style="font-size: 20px; font-weight: bold;">
                        <!-- ລໍຖ້າຮັບອໍເດີ້ ({{ orderedPosname }} {{  orderedTablename }}) -->
                        ລາຍການ ສິນຄ້າທັງໝົດ <br>
                        OrderID: {{ productsInOrder[0].ORDERID }}
                      </div>
                    </div>
                    <div class="column">
                      <div style="font-size: 20px; font-weight: bold;">
                          <b-button v-if="paymentStatus !=3"
                            class="btn-group-margin"  
                            type="is-danger"
                            @click="removeProductFromOrder(currentOrderid,'','ALL')"
                            >ລຶບສິນຄ້າທັງໝົດ
                          </b-button>
                      </div>
                    </div>
                    <div class="column" style="text-align: right;">
                      ລວມທັງໝົດ: <span style="font-weight: bold; font-size: 20px; color: red;">
                        <span style="font-weight: bold; color: red; font-size: 20px;" v-if="paymentStatus ==3">
                          {{  totalLAK | formatNumberLocal }}
                        </span>
                        <span style="font-weight: bold; color: red; font-size: 20px;" v-else>
                          {{ calculateFinalTotal(productsInOrder) | formatNumberLocal }}
                        </span>
                      </span>  ກີບ
                    </div>
                   </div>
                  
                  <div>
                    
                  </div>
                  <table class="table is-fullwidth is-narrow is-small" style="background-color: #E1F5FE; border-radius: 10px; ">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>
                        <th class="has-text-centered " width="30%">
                          ສິນຄ້າ
                        </th>
                        <th class="has-text-right " width="10%">
                          ລາຄາ
                        </th>
                        <th class="has-text-right " width="15%">
                          ຈຳນວນ
                        </th>
                        <th class="has-text-right " width="10%">
                          ລວມ
                        </th>
                    
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in productsInOrder"
                        :key="data.DETAILID"
                      >
                        <td class="has-text-centered">
                          {{ index + 1 }}<br>
                         
                          
                          <b-button v-if="paymentStatus !=3"
                            class="btn-group-margin"  
                            type="is-danger"
                            size="is-small"
                            @click="removeProductFromOrder(data.ORDERID, data.DETAILID,'1')"
                            >X
                          </b-button>
                          
                        </td>
                        <td style="text-align: center;">
                          
                          <img 
                            style=" width: 100px; "
                            :src="getImgUrl(data.PHOTONAME)"
                          /><br />
                          {{ data.PRODUCTNAME }} 
                        </td>
                        <td class="has-text-right ">
                          {{ data.UNITPRICE | formatNumberLocal }}
                        </td>
                        <td class="has-text-right">
                
                          <div style=" float: right;">
                          
                        
                            <b-field style="margin-top: -10px; margin-bottom: 0px;">
                              <b-field style="margin-top: 15px;" v-if="paymentStatus !=3">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #FF2B56; color: white; margin: 0px 0px 10px 0px; padding: 5px 18px; border-radius: 5px; cursor: pointer;"
                                  @click="decreaseQuantity(data)"
                                >-</span>
                              </b-field>
                              <b-field style="margin: 15px 5px 5px 5px;">
                                <span style="font-size: 18px; font-weight: bold;">{{ data.QUANTITY }}</span>
                                {{ data.MENUNIT }}
                              </b-field>
                              <b-field style="margin-top: 15px;" v-if="paymentStatus !=3">
                                <span
                                  style="font-size: 20px; font-weight: bold; background-color: #22C65B; color: white; margin: 0px 0px 10px 0px; padding: 5px 15px; border-radius: 5px; cursor: pointer;"
                                  @click="increaseQuantity(data)"
                                >+</span>
                              </b-field>
                            </b-field>
                            <br> 
                            
                          </div>

                        </td>
                        <td class="has-text-right ">
                          {{
                            (data.UNITPRICE * data.QUANTITY) | formatNumberLocal
                          }}
                        </td>
    

                      </tr>
                    </tbody>

                    <tfoot>
                      <tr>
                        <th colspan="4" class="has-text-right set-border">
                          ລວມ
                          {{ productsInOrder.length | formatNumberLocal }} ລາຍການ 
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 20px;">
                            {{ calculateTotal(productsInOrder) | formatNumberLocal }}   
                          </span>
                          
                        </th> 
                      </tr>
                     <tr v-if="calculateDiscountedTotal(productsInOrder) > 0">
                        <th colspan="4" class="has-text-right set-border">
                          ສ່ວນລຸດ ({{ getDiscount(productsInOrder) }}%):
                        </th>
                        <th class="has-text-right set-border">
          
                          -{{ calculateDiscountedTotal(productsInOrder) | formatNumberLocal }}
                          
                          
                        </th>
                      </tr>
                      <tr >
                        <th colspan="4" class="has-text-right set-border">
                          ສ່ວນລຸດເພີ່ມເຕີມ
                        </th>
                        <th  class="has-text-right set-border">
                          <b-field v-if="paymentStatus !=3" > 
                            <b-input  v-model.trim="extraDiscount" type="number"> </b-input> %
                          </b-field>
                          <b-field v-else>
                            {{ extraDiscountFromBD*100 | formatNumberLocal }}%
                          </b-field>
                          
                        </th>
                        
                      </tr>
                      <tr >
                        <th colspan="4" class="has-text-right set-border">  
                          ລວມສ່ວນລຸດ
                        </th>
                        <th  class="has-text-right set-border">
                          <span style="font-weight: bold;  font-size: 16px;" v-if="paymentStatus ==3">
                            {{  - totalDiscount | formatNumberLocal }}
                          </span>
                          <b-field v-else> 
                            {{ - calculateDiscountedFinalTotal (productsInOrder) | formatNumberLocal }}
                          </b-field>
                          
                        </th>
                        
                      </tr>
                      
                      <tr v-if="calculateDiscountedTotal(productsInOrder) > 0">
                        <th colspan="4" class="has-text-right set-border">
                          ຍັງເຫຼືອ:
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; font-size: 16px;" v-if="paymentStatus ==3">
                            {{  totalBeforvat | formatNumberLocal }}  
                          </span>
                          <span style="font-weight: bold;  font-size: 16px;" v-else>
                            {{ calculateTotal(productsInOrder)-calculateDiscountedFinalTotal(productsInOrder) | formatNumberLocal }}
                          </span>
                          
                          
                        </th>
                        
                      </tr>
                      <tr v-if="calculateVatTotal(productsInOrder) > 0">  
                        <th colspan="4" class="has-text-right set-border">
                          ອມພ ({{ getVat(productsInOrder) }}%):
                          
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; color: red; font-size: 16px;" v-if="paymentStatus ==3">
                            +{{  vatAmount | formatNumberLocal }}
                          </span>
                          <span style="font-weight: bold; color: red; font-size: 16px;" v-else>
                            +{{ calculateVatTotal(productsInOrder) | formatNumberLocal }}  
                          </span>  
                          
                        </th>
                      </tr>
                      <tr>
                        <th colspan="4" class="has-text-right set-border">
                          ລວມທັງໝົດ:
                        </th>
                        <th class="has-text-right set-border">
                          <span style="font-weight: bold; color: red; font-size: 20px;" v-if="paymentStatus ==3">
                            {{  totalLAK | formatNumberLocal }}
                          </span>
                          <span style="font-weight: bold; color: red; font-size: 20px;" v-else>
                            {{ calculateFinalTotal(productsInOrder) | formatNumberLocal }}
                          </span>
                          
                        </th>
                      </tr>

                      
                    
                    </tfoot>
                  </table>
                  <div class="columns" v-if="paymentStatus ==3 && isPrinted == 0">
                    <div class="column" style="text-align: right;" >
                      <b-button 
                        class="btn-group-margin"
                        type="is-info"
                        @click="print()"
                        >ພິມໃບຮັບເງິນ
                      </b-button>
                      <b-button 
                        class="btn-group-margin"
                        type="is-warning"
                        @click="updateOrder()" 
                        >ປິດລາຍການ
                      </b-button>
                    </div>
                  </div>
                  <div class="columns" v-if="paymentStatus !=3">
                    <div class="column" style="text-align: right;" >
                      <b-button 
                        class="btn-group-margin"
                        type="is-info"
                        @click="doPaymentBySeller(productsInOrder)"
                        >ຊຳລະດ້ວຍ QR Code
                      </b-button>

                      &nbsp;
                  
                      <b-button
                        class="btn-group-margin"
                        type="is-success"
                        @click="doManaulPayment(1)"
                        >ຊຳລະດ້ວຍ ເງິນສົດ
                      </b-button>
                      
                      &nbsp;

                      <b-button
                        class="btn-group-margin"
                        type="is-warning"
                        @click="doManaulPayment(4)"
                        >ບິນຫົວໜ້າ
                      </b-button>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">

                    </div>
                    <div class="column">
                      <!-- <b-button
                          type="is-danger"
                          expanded
                          style="width: 100%;"
                          @click="updateOrderMenuStatus(-2)" 
                          >ປະຕິເສດ</b-button
                        > -->
                    </div>

                    <div class="column">
                        
                        <!-- <b-button
                          type="is-info"
                          expanded
                          style="width: 100%;"
                          @click="updateOrderMenuStatus(2)" 
                          >ຮັບອໍເດີ້ ແລະ ສົ່ງຫ້ອງຄົວ</b-button
                        > -->
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


          
        
      </div>
    </div>

    <div v-if="isLoading" class="loading-modal">
      <div class="modal-content">
        <div style="float: right;">
          <b-button
            type="is-danger" 
            expanded
            style=""
            @click="closeQRModal"
            >X</b-button
          >
        </div>
        <p class="modal-title">ສະແກນ Lapnet QR Code ເພື່ອຊຳລະ</p>
        <img :src="qrCodeUrl" alt="QR Code" class="qr-code-image" />
        <div class="modal-content2">
          <div >ຮ້ານ: {{ posname }}</div><br>  
          <div >ລະຫັດ: {{ selectedOrderID }}</div><br>  
          <div >ຈຳນວນເງິນ: <span  style="font-weight: bold;">{{ qrtotal | formatNumberLocal }} ກີບ</span></div>
        </div>
      </div>

      
      
    </div>

    <div v-if="isSuccessPayment" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 80px;"/></p>
        <p class="modal-title">ຊຳລະເງິນ ສຳເລັດແລ້ວ</p>  
        <p style="font-size: 20px;" >ກະລຸນາກວດສອບ ສິນຄ້າຂອງທ່ານ</p>
        <b-button
          style="float: right; background-color: whitesmoke;"
          class="btn-group-margin"
           
          @click="hideSuccessModal()" 
          > <span style=" font-family: Phetsarath OT !important">ປິດ</span>
        </b-button> 
          
      </div>  
        
    </div>

    <div v-if="isShowSuccessAddProduct" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 50px;"/></p>
        <p class="modal-title">ເພີ່ມສິນຄ້າ ສຳເລັດ</p>     
      </div>
      
    </div>

    <div v-if="isShowSuccessRemoveProduct" class="loading-modal">
      <div class="modal-content">
        <p ><img :src="getCheckImage()" class="fade-in" style="width: 50px;"/></p>
        <p class="modal-title">ລຶບສິນຄ້າ ສຳເລັດ</p>     
      </div>
      
    </div>

    <div class="contain print-only">
      <div class="column">
        <!-- <div v-show="isDetailSelected"> -->
          <div
            style="margin-top: 25px; text-align: center;">
              <span class="print-receipt-main-title">ໃບບິນຊຳລະເງິນ</span>
          </div>
          <div style="background-color: red; padding: 0 0 0 0; margin: 0 0 0 0 ;" >
            <img
                class="image-area"
                style="width: 150px;"
                :src="getImgUrl('symbol/logo.jpg')"
                alt="Placeholder image"
              />
          </div>
          <div class="paymentdetailselected" >
            ຮ້ານ: <span class="paymentdetailselectedhightlight"> {{ posname }} </span>
          </div>
          <div class="paymentdetailselected">
            OrderID:  <span class="paymentdetailselectedhightlight">{{  currentOrderid}}</span>
                      
          </div>
          <div class="paymentdetailselected" >
            ວັນທີ: <span class="paymentdetailselectedhightlight"> {{ confirmPaymentTime }}</span>
          </div>
          
          <div>
            <table class="table is-fullwidth is-narrow is-small">
              <thead>
                <tr>
                  <th class="has-text-centered " width="5%">#</th>
                  <th class="has-text-left " width="20%">
                    ສິນຄ້າ
                  </th>

                  <th class="has-text-right " width="10%">
                    ລວມ
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in productsInOrder" :key="data.DETAILID">
                  <td class="has-text-centered">{{ index + 1 }}</td>
                  <td class="paymentdetailselectedhightlight">
                    {{ data.PRODUCTNAME }} <br />
                    ຈຳນວນ: {{ data.QUANTITY | formatNumberLocal }}
                    {{ data.MENUNIT }} <br />
                    ລາຄາ: {{ data.UNITPRICE | formatNumberLocal }}
                  </td>

                  <td class="has-text-right paymentdetailselectedhightlight">
                    {{ data.MENUTOTAL | formatNumberLocal }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th colspan="2" class="has-text-right set-border">
                    ລວມ
                    {{ productsInOrder.length | formatNumberLocal }} ລາຍການ 
                  </th>
                  <th class="has-text-right set-border">
                    <span style="font-weight: bold; font-size: 20px;">
                      {{ calculateTotal(productsInOrder) | formatNumberLocal }}   
                    </span>
                    
                  </th> 
                </tr>
                <tr v-if="calculateDiscountedTotal(productsInOrder) > 0">
                  <th colspan="2" class="has-text-right set-border">
                    ສ່ວນລຸດ ({{ getDiscount(productsInOrder) }}%):
                  </th>
                  <th class="has-text-right set-border">
    
                    -{{ calculateDiscountedTotal(productsInOrder) | formatNumberLocal }}
                    
                    
                  </th>
                </tr>
                <tr >
                  <th colspan="2" class="has-text-right set-border">
                    ສ່ວນລຸດເພີ່ມເຕີມ
                  </th>
                  <th  class="has-text-right set-border">
                    <b-field v-if="paymentStatus !=3" > 
                      <b-input  v-model.trim="extraDiscount" type="number"> </b-input>
                    </b-field>
                    <b-field v-else>
                      {{ extraDiscountFromBD*100 | formatNumberLocal }}%
                    </b-field>
                    
                  </th>
                  
                </tr>
                <tr >
                  <th colspan="2" class="has-text-right set-border">  
                    ລວມສ່ວນລຸດ
                  </th>
                  <th  class="has-text-right set-border">
                    <span style="font-weight: bold;  font-size: 16px;" v-if="paymentStatus ==3">
                      {{  - totalDiscount | formatNumberLocal }}
                    </span>
                    <b-field v-else> 
                      {{ - calculateDiscountedFinalTotal (productsInOrder) | formatNumberLocal }}
                    </b-field>
                    
                  </th>
                  
                </tr>
                
                <tr v-if="calculateDiscountedTotal(productsInOrder) > 0">
                  <th colspan="2" class="has-text-right set-border">
                    ຍັງເຫຼືອ:
                  </th>
                  <th class="has-text-right set-border">
                    
                    {{ calculateTotal(productsInOrder)-calculateDiscountedFinalTotal(productsInOrder) | formatNumberLocal }}
                    
                    
                  </th>
                  
                </tr>
                <tr v-if="calculateVatTotal(productsInOrder) > 0">  
                  <th colspan="2" class="has-text-right set-border">
                    ອມພ ({{ getVat(productsInOrder) }}%):
                    
                  </th>
                  <th class="has-text-right set-border">
                    +{{ calculateVatTotal(productsInOrder) | formatNumberLocal }}
                    
                  </th>
                </tr>
                <tr>
                  <th colspan="2" class="has-text-right set-border">
                    ລວມທັງໝົດ:
                  </th>
                  <th class="has-text-right set-border">
                    <span style="font-weight: bold; color: red; font-size: 20px;" v-if="paymentStatus ==3">
                      {{  totalLAK | formatNumberLocal }}
                    </span>
                    
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="print-receipt">
            ຂອບໃຈທີ່ມາອຸດໜູນ
          </div>
          <div class="print-receipt">
            ເບີໂທຕິດຕໍ່ 020 52238878
          </div>

      </div>
    </div>
    
    
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
// import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  components: { Multiselect },
  props: [],
  data() {
    return {
      isSmallScreen: false,
      products: [],
      sellerid: null,
      roleid: null,
      posid: null,
      posname: null,
      username: null,
      
      total: 0,
      finaltotal: 0,
      allMenuInOrder: [],
      photopath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/minimart/"
          : "http://45.77.43.249/pos/api/uploads/minimart/",

     loadingData: null,
     selectedType: null,
     typeList: [],
     productList: [],
     originalproductList: [],
     searchproduct: null,
     productkeyword: null,

     productbybarcode: null,

     productsInOrder: [],

     qrData: null,
     uuid: null,
     isLoading: false,
     selectedOrderID: null,

     isMainWaitForCallBack: 0,

     currentOrderid: null,
     paymentStatus: 0,
     isPrinted: 0,
     extraDiscountFromBD: 0,

     isShowSuccessAddProduct: false,
     isShowSuccessRemoveProduct: false,

     isSuccessPayment: false,

     checkimagepath:
        process.env.NODE_ENV === "production"
          ? "http://45.77.43.249/pos/api/uploads/pos/"
          : "http://localhost/hsnx-pos-api-vapi/api/uploads/pos/",
    
      selectedPaymentMethod: null,
      paymentMethodList: [
        { ID: 1, NAME: 'ເງິນສົດ' },
        // { ID: 2, NAME: 'ເງິນໂອນ'  },
        // { ID: 3, NAME: 'ຄູປ໋ອງ'  },
        { ID: 4, NAME: 'ບິນຫົວໜ້າ'  },
      ],
      extraDiscount: 0,

      confirmPaymentTime: null,
      totalLAK: 0,
      totalDiscount: 0,
      totalBeforvat: 0,
      vatAmount: 0,
    }
  },
  methods: {

    closebillbefore(){
      this.$swal("ພິມ ແລະ ປິດລາຍການທີ່ຄ້າງກ່ອນ", "", "error");
    },
    updateOrder(){
      this.$swal({
        title: "ຕ້ອງການປິດລາຍການ ແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            orderid: this.currentOrderid,
            username: this.username,
          };


          RestAPI.updateMinimartOrder(params)
            .then(() => {
              this.paymentStatus = 0;
              this.getProductinOrder();

            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },

    closeQRModal() {
      this.isLoading = false; 
    },

    doManaulPayment(type) {
  
      this.$swal({
        title: "ຕ້ອງການຊຳລະແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            paymenttype: type,
            postype: 2, // minimart
            extraDiscount: this.extraDiscount,  
            orderid: this.currentOrderid,
            posid: this.selectedposid,
            username: this.username,
          };

          console.log("params:"+ JSON.stringify(params))

          RestAPI.doManaulPayment(params)
            .then((res) => {
              if(res.data !="success"){
                this.$swal("ດຳເນີນການບໍ່ສຳເລັດ", "", "error");
                return false;
              }
              // this.$swal("ຊຳລະສິນຄ້າ ສຳເລັດ!", "", "success");
              this.$swal({
                title: "ສຳເລັດ",
                text: "ຊຳລະສິນຄ້າ ສຳເລັດ!",
                icon: "success",
                timer: 500, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              this.extraDiscount = 0;
              this.getProductinOrder();

            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },

    showSuccessModal(){
      this.isSuccessPayment = true;
    },

    hideSuccessModal(){
      this.qrData = null;
      this.isSuccessPayment = false;
    },

    showSuccessAddProduct(){
      this.isShowSuccessAddProduct = true;
      setTimeout(() => {
        this.isShowSuccessAddProduct = false;
      }, 1000);

    },

    showSuccessRemoveProduct(){
      this.isShowSuccessRemoveProduct = true;
      setTimeout(() => {
        this.isShowSuccessRemoveProduct = false;
      }, 1000);

    },

    getCheckImage() {
      return this.checkimagepath + "check.png";
    },

    removeProductFromOrder(orderid, orderdetailid = null, type) {
      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          var params = {
            orderid: orderid,
            orderdetailid: orderdetailid, 
            type: type,
            username: this.username,
          };
          // console.log("params-: ", JSON.stringify(params))
          RestAPI.removeProductFromOrder(params)
            .then(res => {
              if(res.data !="success"){
                this.$swal("ດຳເນີນການບໍ່ສຳເລັດ", "", "error");
                return false;
              }

              this.getProductType();
              this.getProduct();
              this.getProductinOrder();

              this.showSuccessRemoveProduct();
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },
    
    hideLoadingModal() {
      this.isMainWaitForCallBack = 0;
      this.isLoading = false;
    },

    showLoadingModal() {
      this.isLoading = true;
    },

    waitForCallback(orderid, uuid) {
      const checkInterval = 1000; // 3 seconds interval
      const maxAttempts = 60; // Maximum attempts to wait
      let attempts = 0;

      // console.log("Starting polling process..."); 

      this.showLoadingModal();

      const interval = setInterval(() => {
        // console.log(`Polling attempt #${attempts + 1}`);

        const params = { 
          orderid: orderid,
          posid: this.selectedposid,  
          uuid: uuid,   
          username: this.username,
        };

        // console.log('params: '+JSON.stringify(params));

        RestAPI.checkPosSelfPaymentStatus(params)  
          .then((res) => {

            if(res.data==="no"){
              this.hideLoadingModal();
              clearInterval(interval);
              this.$swal("ລືບລາຍການ ແລ້ວປ້ອນສິນຄ້າຄືນໃໝ່", "-", "error");

            }

            // console.log(`Response on attempt #${attempts + 1}:`, res.data);
            // console.log("res.data-CALLBACK: " + JSON.stringify(res.data))
            if (res.data.CALLBACK === 1) {
              // console.log("Payment successful!");
              clearInterval(interval);
              this.hideLoadingModal();
              
              this.getProductType();
              this.getProduct();
              this.getProductinOrder();

              this.showSuccessModal();
              this.extraDiscount = 0;

              
              // this.$swal({
              //   title: "ສຳເລັດ",
              //   html: '<img :src="getCheckImage()" style="width: 50px;"/> ການຊຳລະສຳເລັດແລ້ວ' , 
              //   icon: "success", 
              //   confirmButtonText: "ປິດ",
              // });
            }

           
          })
          .catch((error) => {
            console.error("Polling error:", error);
          });

        attempts++;
  
        if (attempts >= maxAttempts) {
          // console.log("Max polling attempts reached.");
          clearInterval(interval);
          this.hideLoadingModal();

          this.$swal("ບໍ່ມີການຕອບກັບຈາກທະນາຄານ ! <br> ພົວພັນຮ້ານຄ້າ", "", "error");   

          // this.$swal({
          //   title: "ໝົດເວລາລໍຖ້າ",   
          //   text: "ບໍ່ມີການຕອບກັບຈາກທະນາຄານ",
          //   icon: "warning",
          //   confirmButtonText: "ປິດ",
          // });
        }
      }, checkInterval);
    },

    async doPaymentBySeller(item) {
      const paymentData = await this.getPaymentData(item);  
      this.$swal({
        title: "ຕ້ອງການດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          
          
          // console.log("paymentdata: " + JSON.stringify(paymentData))
          //wait untial getpaymentdate work done 
          this.qrtotal = paymentData.total;
          this.selectedOrderID = paymentData.orderid;
          const params = {
            username: this.username,
            orderid: paymentData.orderid,
            finaltotal: paymentData.total,
            extraDiscount: this.extraDiscount,
            posid: paymentData.selectedposid,
            selectedposid: '',
            phoneno: '',
            tableid: '',
            selectedtableid:'',
            postype: 2, // minimart
    
          };
          // console.log("params: " + JSON.stringify(params))
          const loadingComponent = this.$loading.open({});

          RestAPI.doCustomerPaymentBySeller(params)
            .then((res) => {
              // console.log("res: " + JSON.stringify(res))
              loadingComponent.close();  
              

              if (res.data.code === "00" || res.data.code === "05") {
                this.qrData = res.data.data.qrcode;
                this.uuid = res.data.data.uuid;

                this.showLoadingModal(); // Show modal before starting the payment API call
      
                this.isMainWaitForCallBack = 1;
                
                this.waitForCallback(paymentData.orderid, this.uuid); // Start polling for callback only on success
              } else if (res.data.code === "01") {
                this.hideLoadingModal();
                this.$swal("ຈຳນວນບໍ່ຖືກຕ້ອງ", "-", "error");
              } else if (res.data.code === "02") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດສ້າງ QR Code ໄດ້", "-", "error");
              } else if (res.data.code === "03") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກຂໍ້ມູນ QR! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "04") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດບັນທຶກເບີໂທໄດ້! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }else if (res.data.code === "06") {
                this.hideLoadingModal();
                this.$swal("ບໍ່ສາມາດຕັດສະຕ໋ອກໄດ້! ກະລະນາລອງໃໝ່ອີກຄັ້ງ", "-", "error");
              }
              // else if (res.data.code === "05") {
              //   this.hideLoadingModal();
              //   this.$swal("ສ້າງ QR CODE ຊ້ຳ! ພົວພັນຮ້ານ", "-", "error");
              // }
            })
            .catch((error) => {
              this.hideLoadingModal(); // Ensure modal is hidden if there's an error
              console.error("Payment error:", error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      });
    },

    getPaymentData(items) {
      // console.log("items: " + JSON.stringify(items))
      var total = this.calculateFinalTotal(items);
      var orderid = items[0].ORDERID || 0;  
      var selectedposid = items[0].POSID || 0;
      var data = { total: total, orderid: orderid, selectedposid: selectedposid};  // Correct syntax for object property definition
      // console.log("data: " + JSON.stringify(data))
      return data;
    },

    calculateTotal(items) {  
      // this.getPaymentData(items);
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => item.UNITPRICE * item.QUANTITY);
    },

    getDiscount(items) {  
      // eslint-disable-next-line no-undef
      const discount = items[0].DISCOUNT  || 0;
      return discount;
    },

    getVat(items) {  
      // eslint-disable-next-line no-undef
      const vat = items[0].VAT  || 0;
      return vat;
    },


    calculateDiscountedTotal(items) {  
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => {
        const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        return rowDiscount;
      });
    },

    calculateDiscountedFinalTotal(items) {  
      // eslint-disable-next-line no-undef
      return _.sumBy(items, (item) => {
        const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        return rowDiscount + extraDiscount;
      });
    },

    calculateVatTotal(items) {  
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        const rowVat = ((rowTotal -rowDiscount - extraDiscount) * item.VAT/100)  || 0; 
        return rowVat;
     }); 
    },

    calculateFinalTotal(items) {    
     // eslint-disable-next-line no-undef
     return _.sumBy(items, (item) => {
      const rowTotal = (item.UNITPRICE || 0) * (item.QUANTITY || 0);
        const rowDiscount = (rowTotal * (item.DISCOUNT / 100))  || 0; // Use item-specific discount
        const extraDiscount = (rowTotal * (this.extraDiscount / 100))  || 0; 
        const rowVat = ((rowTotal -rowDiscount- extraDiscount) * item.VAT/100)  || 0; 
        return (rowTotal -  rowDiscount - extraDiscount) + rowVat;    
     }); 
    }, 

    // searchProductBybarcode(){
    //   if (!this.productbybarcode) {
    //     this.productList = [...this.originalProductList];
    //   } else {
    //     this.productList = this.originalProductList.filter(
    //       (p) => p.BARCODE.toLowerCase() === this.productbybarcode.toLowerCase()
    //     );
    //   }

    //   // Ensure focus remains on the barcode input field
    //   this.$nextTick(() => {
    //     this.$refs.barcodeInput.focus();
    //   });
    // },

    // searchProductBybarcode(){
    //   if (!this.productbybarcode) {
    //     this.productList = [...this.originalProductList];
    //   } else {
    //     this.productList = this.originalProductList.filter(
    //       (p) => p.BARCODE.toLowerCase() === this.productbybarcode.toLowerCase()
    //     );
    //   }

    //   // Ensure focus remains on the barcode input field
    //   this.$nextTick(() => {
    //     this.$refs.barcodeInput.focus();
    //   });
    // },

    searchProductBybarcode(){
      var params = {
        minimartid: this.selectedposid,
        // productid: data.ID,
        // unitprice: data.UNITPRICE,
        barcode: this.productbybarcode,
        username: this.username,
      };
      console.log("params: ", JSON.stringify(params))
      RestAPI.addPrducttoOrderByBarCode(params)
        .then((res) => {
          if(res.data=='notfound'){
            this.$swal("ບໍ່ພົບສິນຄ້າ " , "", "error");
            return false;
          }
          this.productbybarcode = null;

          this.getProduct();
          this.getProductinOrder(); 

          // this.showSuccessAddProduct();
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },
    getImgUrl(photoname) {
      var photopreview = photoname
        ? this.photopath + photoname
        : this.photopath + "unknown.png";
      // console.log("photoname: " + photoname)
      // console.log("photopreview: " + photopreview)
      return photopreview;
    },

    // searchProductkey() {
    //   var key;
    //   if (this.productkeyword) {
    //     key = this.productkeyword;
    //   } else {
    //     key = "ALL";
    //   }

    //   console.log("key: " + key)
    //   RestAPI.getMiniMartProductsByKeyword(this.selectedposid, key)
    //     .then((res) => {
    //       this.productList = res.data;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
    //     });
    // },

    increaseQuantity(data){
      data.QUANTITY += 1;
      this.updateProductQuantity(data);
    },
    decreaseQuantity(data){
      if (data.QUANTITY > 0) {
        data.QUANTITY -= 1;
        this.updateProductQuantity(data);
      }
    },

    updateProductQuantity(data){
      var params = {
        minimartid: this.selectedposid,
        orderid: data.ORDERID,
        productid: data.PRODUCTID,
        quantity: data.QUANTITY,
        quantitybeforupdate: data.QUANTITYBEFOREUPDATE,
        unitprice: data.UNITPRICE,
        username: this.username,
      };
      // console.log("params: ", JSON.stringify(params)) 
      RestAPI.updateProductQuantity(params)
        .then(() => {

          this.getProduct();
          this.getProductinOrder(); 

          this.showSuccessAddProduct();
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },

    addProduct(data){
      var params = {
        minimartid: this.selectedposid,
        productid: data.ID,
        unitprice: data.UNITPRICE,
        username: this.username,
      };
      // console.log("params: ", JSON.stringify(params))
      RestAPI.addPrducttoOrder(params)
        .then(() => {

          this.getProduct();
          this.getProductinOrder(); 

          // this.showSuccessAddProduct();
          
        })
        .catch((error) => {
          console.log(error);
          if (error == "Error: Request failed with status code 400") {
            this.$swal("Check Input Data", "", "error");
          } else {
            this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
          }
        });
    },
    // getImgUrl(photoname) {
    //   var photopreview = photoname
    //     ? this.photopath + photoname
    //     : this.photopath + "unknown.png";
    //   // console.log("photoname: " + photoname)
    //   // console.log("photopreview: " + photopreview)
    //   return photopreview;
    // },
    
    async getProductType() {
      try {
        const res = await RestAPI.getSettingMinimartProductType();
        this.typeList = res.data;
      } catch (error) {
        console.error("Error fetching product types:", error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      }
    },
    getProductinOrder(){
      RestAPI.getMinimartProductinOrder(this.selectedposid)
        .then((res) => {
          
          if(res.data.status==1){
            this.productsInOrder = res.data.data;
            this.currentOrderid = res.data.data[0].ORDERID ;
            this.paymentStatus =  res.data.data[0].STATUS ;
            this.isPrinted =  res.data.data[0].ISPRINTED ;
            this.extraDiscountFromBD =  res.data.data[0].EXTRADISCOUNT ;
            this.confirmPaymentTime =  res.data.data[0].CONFIRMPAYMENTTIME ;
            this.totalDiscount =  res.data.data[0].TOTALDISCOUNT ;
            this.vatAmount = res.data.data[0].VATAMOUNT
            this.totalLAK =  res.data.data[0].TOTALLAK ;
            this.totalBeforvat = this.totalLAK - this.vatAmount;   
            
            
           
            // console.log("paymentStatus : " + JSON.stringify(res.data.data))  
          }else{
            this.productsInOrder = [];
          }
          
           
        })
        .catch((error) => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },
    
    async getProduct() {
      try {
        const res = await RestAPI.getMinimartProduct(this.selectedposid);
        this.originalProductList = res.data;
        this.productList = [...res.data];
        // console.table(this.productList);
      } catch (error) {
        console.error("Error fetching products:", error);
        this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
      }
    },
    onMinimartTypeSelected() {
      if (!this.selectedType) return;
      this.productList = this.originalProductList.filter(
        (p) => p.TYPEID === this.selectedType.ID
      );
      // console.log("productList:" + JSON.stringify(this.productList)) 
      console.log("filteredProductList:" + JSON.stringify(this.filteredProductList)) 
    },
    searchProductkey() {
      if (!this.productkeyword) {
        this.productList = [...this.originalProductList];
      } else {
        this.productList = this.originalProductList.filter((p) =>
          p.NAME.toLowerCase().includes(this.productkeyword.toLowerCase())
        );
      }
      // console.table(this.productList);
    },
    print() {
      window.print();
    },
  },
  computed: {
    qrCodeUrl() {  
      // Use the qrserver API to generate the QR code image URL
      return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(this.qrData)}`;
    },
    // filteredProductList() {
    //   if (!this.selectedType) return this.productList; // Show all products if no type is selected

    //   return this.productList.filter((p) => {
    //     // Check if the product matches the selected type
    //     const matchesType = p.TYPEID === this.selectedType.ID;
    //     // Check if the product name matches the search input
    //     const matchesSearch = this.searchproduct
    //       ? p.NAME.toLowerCase().includes(this.searchproduct.toLowerCase())
    //       : true; // If no search, return all matching type products

    //     return matchesType && matchesSearch;
    //   });
    // },

  },
  mounted() {
    this.posname = this.user.POSNAME;
    this.selectedposid = this.user.POSID;
    this.username = this.user.USERNAME;
    this.getProductType();
    this.getProduct();
    this.getProductinOrder();

    this.$nextTick(() => {
      this.$refs.barcodeInput.focus();
    });
  },
};
</script>

<style scoped>
/* .card {
  background-color: red;
} */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  width: 50%;
  height: 200px;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.popup button:hover {
  background-color: #2980b9;
}

.popup-content{
  color: black;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}



.popup-buttons {
  margin-top: auto; /* This pushes the buttons to the bottom */
}

.popup button {
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
}

.fade-in {
  animation: fadeIn 0.2s ease-in-out;  /* Adjust duration as needed */    
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }  
}


/* Custom modal styles for loading */
.loading-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Make sure the modal is on top of other content */
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 80%; /* Adjust width as needed */
  max-width: 500px;
}

.modal-content2 {
  padding: 0px 20px 20px 80px;
  border-radius: 10px;
  width: 100%; /* Full width of the modal */
  margin-top: 20px; /* Space between the QR code and content */
  text-align: left; /* Align content to the left */
}

.modal-content2 div {
  margin-bottom: 0px; /* Space between the lines */
  margin-top: -20px;
  font-size: 18px;
}

.modal-title {
  font-size: 26px;
  font-weight: bold; 
  margin-bottom: 10px; 
}

.qr-code-image {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

@media (max-width: 480px) {

  .modal-content2 {
    padding: 2px 2px 2px 50px; /* Adjust padding for very small screens */
  }

}

.spinner {
  width: 30px;
  height: 30px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.custom-border-color {
    border-color: red; /* Set your desired border color */
  }
.card-image {
  max-height: 400px; /* Set a maximum height for the image container */
}

.image {
  /* margin: 0 auto; */
}
.card-content-table {
  padding: 10px 10px 10px 10px;
  width: 100%;
  height: 100%; 
}

.card-content-menu {
  padding: 10px 10px 10px 10px; 
}
.danger {
  background-color: red;
  color: white;
  padding: 5px;
}
.warning {
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title { 
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected {
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight {
  font-weight: bold;
}

.available{
  background-color: rgb(37, 164, 248);
  border-radius: 10px;
  color: white;
}
.reserve{
  background-color: rgb(233, 233, 12);
  border-radius: 10px;
  color: red; 
  font-weight: bold;

}
.sitting{
  background-color: rgb(250, 7, 80);
  border-radius: 10px;
  color: white;
}

.float-container {
    /* padding: 20px; */ 
}

.float-child-left {
    width: 40%;
    float: left;
    padding: 5px 0px 5px 5px;
    text-align: right;
    /* background-color: red; */
} 

.float-child-right {
    width: 60%; 
    float: left;
    padding: 5px 0px 5px 10px;
    /* background-color: green; */
} 

.float-child {
    float: left;
    padding: 5px 0px 5px 10px;
} 


.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}

.runout {
  background-color: grey;
}

.normal {
  background-color: white;
}

.fullscreen-container {
  height: 100vh;
  width: 100vw;
  padding: 0 30px 0 30px;
}

.custom-style {
    /* Add your custom styles here */
    color: red;
    font-weight: bold;
    /* Add any other styles you need */
  }


@media print {
  @page {
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px 0px 0px !important;
    size: 6in 9in;
    size: portrait;
  }

  
}

</style>
