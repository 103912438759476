<template>
  <nav
    class="navbar is-primary no-print"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <!-- <img src="@/assets/logo.png" width="30" height="30"> -->
        <span>&nbsp;&nbsp;{{ this.user.username }}</span>
      </router-link>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{ 'is-active': isMenuActive }"
        @click="isMenuActive = !isMenuActive"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu" :class="{ 'is-active': isMenuActive }">
      <div class="navbar-start">
       
        
        
      </div>

      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable" v-if="roleid=='2' || roleid=='3'">
          <a class="navbar-link">
            <span>ຈັດການສິນຄ້າ </span>
          </a>
          <div class="navbar-dropdown" >
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍສິນຄ້າ-POS ຜ່ານເມນູ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productdirectrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍສິນຄ້າ-POS</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/admin/buypricehistory"
              :exact="true"
              active-class="is-active"
            >
              <span>ປະຫວັດລາຄາຊື້ - POS</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productbrokenrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ແຈ້ງສິນຄ້າເສຍຫາຍ-POS</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/productbroken"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານສິນຄ້າເສຍຫາຍ-POS</span>
            </router-link>
            

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/allstock"
              :exact="true"
              active-class="is-active"
            >
              <span>ສິນຄ້າໃນ stock - POS</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/productrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍສິນຄ້າ-Minimart</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/admin/minimartbuypricehistory"
              :exact="true"
              active-class="is-active"
            >
              <span>ປະຫວັດລາຄາຊື້-Minimart</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/productbrokenrequest"
              :exact="true"
              active-class="is-active"
            >
              <span>ແຈ້ງສິນຄ້າເສຍຫາຍ-Minimart</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/productbroken"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານສິນຄ້າເສຍຫາຍ-Minimart</span>
            </router-link>
             
            <!-- <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/pos/productprovideandsend"
              :exact="true"
              active-class="is-active"
            >
              <span>ຈັດຊື້ ແລະ ຈັດສົ່ງສິນຄ້າ</span>
            </router-link> -->

           

            

        

            
          </div>

       
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍງານການຂາຍ POS</span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='3'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allmenureport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານເມນູຂາຍດີ</span>
            </router-link>
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allpaymentreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/bossmoneyreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານບິນຫົວໜ້າ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allmoneyreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຮັບເງິນ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allrefundreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍການຄືນເງິນ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/seller/allproductreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການນຳໃຊ້ສິນຄ້າ</span>
            </router-link>

            
          </div>

          
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ລາຍງານການຂາຍ MiniMart</span>
          </a>
          <div class="navbar-dropdown" v-if="roleid=='3'">
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/allproductreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານສິນຄ້າຂາຍດີ</span>
            </router-link>
            
            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/allpaymentreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຂາຍ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/allmoneyreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍງານການຮັບເງິນ</span>
            </router-link>

            <router-link 
              @click.native="menuClicked"
              class="navbar-item"
              to="/minimart/allrefundreport"
              :exact="true"
              active-class="is-active"
            >
              <span>ລາຍການຄືນເງິນ</span>
            </router-link>            
          </div>

          
         
        </div>
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link">
            <span>ຜູ້ໃຊ້ງານ: {{ username }}</span>
          </a>
          <div class="navbar-dropdown">
            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/form/userProfile"
              :exact="true"
              active-class="is-active"
            >
              <span>ຂໍ້ມູນສ່ວນຕົວ</span>
            </router-link>

            <router-link
              @click.native="menuClicked"
              class="navbar-item"
              to="/login"
              :exact="true"
              active-class="is-active"
            >
              <span>ອອກຈາກລະບົບ</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: "ReportMenu",
  data() {
    return {
      isMenuActive: false,
      roleid: null,
      username: null,
    };
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    // console.log("roleid: "+ JSON.stringify(this.roleid))
    
  },
  computed: {
    // user: get("loginData"),
    
  },
  methods: {
    menuClicked() {
      this.isMenuActive = false;
    }
  }
};
</script>

<style scoped>
.navbar-item {
  white-space: nowrap;
}
.navbar-end {
  padding-right: 60px;
}
</style>
