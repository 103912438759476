<template>
  <section>
    <div class="contain no-print">
      <div class="columns" > 
        <div class="column">
          <div class="scrollme" style="background-color: #f2f4f8; padding: 20px; min-height: 1000px;">
            <div class="columns">
              <div class="column" style="padding: 5px ;">
                <div class="very-important-title has-text-centered" style=" margin: 0px 20px 0px 20px ;">ຈັດການ VAT</div>
              </div>
            </div>

            <div class="columns" style=" background-color: white; border-radius: 10px; margin: 10px 10px 10px 10px;  padding: 30px 30px; min-height: 1000px;">
                <div class="column">
                  
                     
                  <table class="table is-fullwidth is-narrow is-small">
                    <thead>
                      <tr>
                        <th class="has-text-centered " width="5%">#</th>

                        <th class="has-text-centered " width="20%">
                          ຮ້ານຄ້າ
                        </th>

                        
                        <th class="has-text-centered " width="20%">
                          VAT
                        </th>
                        <th class="has-text-centered " width="20%">
                          ຈັດກນ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in posVatList"
                        :key="data.ID"
                      >
                        <td class="has-text-centered">{{ index + 1 }}</td>

                        <td class="has-text-centered">
                          {{ data.POS }}<br>
                          
                        </td>
                        <td class="has-text-centered">
                          <b-field v-if="isUpdate">
                            <input  
                            type="number"
                            class="input" 
                            v-model.trim="data.VAT"/> 
                          </b-field>
                          <b-field v-else>
                            {{ data.VAT }}
                          </b-field>
                          
                          
                        </td>
                        <td class="has-text-centered">
                          <b-field>
                            <b-button v-if="isUpdate"
                              class="btn-group-margin"
                              type="is-info"
                              size="is-small"
                              @click="saveForm(data)"
                              >ບັນທຶກ
                            </b-button>
                            <b-button v-else
                              class="btn-group-margin"
                              type="is-primary"
                              size="is-small"
                              @click="updateForm(data)"
                              >ແກ້ໄຂ
                            </b-button>
                          </b-field>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import RestAPI from "../../RestAPI";
import moment from "moment";
// import Multiselect from "vue-multiselect";

export default {
  // components: { Multiselect },
  props: ["prop_activetab"],
  data() {
    return {
      roleid: null,
      username: null,
      posVatList: [],
      vat: 0,
      isUpdate: false,

      
    };
  },
  methods: {
    saveForm(){
      this.isUpdate = false;

      this.$swal({
        title: "ຕ້ອງານດຳເນີນການແທ້ບໍ່?",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "ຕ້ອງການ",
        cancelButtonText: "ບໍ່ຕ້ອງການ",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
      }).then((result) => {
        if (result.value) {
          var params = {
            posvat: this.posVatList,
            username: this.username,
          };
          RestAPI.saveVat(params)
            .then(() => {
              this.getPosVatList();
              this.$swal({
                title: "ສຳເລັດ",
                text: "ອັບເດດສຳເລັດ",
                icon: "success",
                timer: 1000, // Set the timer in milliseconds (2 seconds in this example)
                showConfirmButton: false, // Hide the confirmation button
              });
              
            })
            .catch((error) => {
              console.log(error);
              if (error == "Error: Request failed with status code 400") {
                this.$swal("Check Input Data", "", "error");
              } else {
                this.$swal("ມີຂໍ້ຜິດພາດ: " + error, "-", "error");
              }
            });
        }
      }); 
    },
    updateForm(){
      this.isUpdate = true;
    },
   
    getPosVatList(){
      RestAPI.getPosVatList()
        .then(res => {
          this.posVatList = res.data;
        })
        .catch(error => {
          console.log(error);
          this.$swal("ມີຂໍ້ຜິດພາດ", "-", "error");
        });
    },

    
    dateFormatter(date) {
      return new moment(date).format("DD/MM/YYYY");
    },
    dateFormatterYYYYMMDD(date) {
      return new moment(date).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.roleid = this.user.ROLEID;
    this.username = this.user.USERNAME;
    this.getPosVatList();
    
    
  },
  computed: {},
};
</script>

<style scoped>
.card-image{
  width: 250px; 
  height: 250px
}

.image {
  /* margin: 0 auto; */
}
.card-content {
  padding-top: 10px;
}
.danger{
  background-color: red; 
  color: white;
  padding: 5px;
}
.warning{
  background-color: rgb(235, 175, 11);
  color: white;
  padding: 5px;
}

.very-important-title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 40px;
}

.paymentdetailselected{
  margin-top: 5px;
  margin-bottom: 5px;
}
.paymentdetailselectedhightlight{
  font-weight: bold;
}

.print-receipt{
  font-size: 10px;
}
.print-receipt-titel{
  font-size: 12px;
}

.print-receipt {
  font-size: 16px;
}
.print-receipt-titel {
  font-size: 18px;
}

.print-receipt-main-title{
  font-size: 20px !important;
  font-weight: bold;

}


</style>
